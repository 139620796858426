import clsx from "clsx";
import { navPaths } from "../../../constants/navbar.constants";
import { useLocation, useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress, TextField } from "@mui/material";
import MessageItem from "../Components/MessageItem";
import MessageModal from "../Components/MessageModal";
import { useState } from "react";
import SendMessageModal from "../Components/SendMessageModal";
import { Slide, ToastContainer } from "react-toastify";
import useGetMessages from "../Hooks/useListMessage";

const GuildMessage = () => {
  const tele = window.Telegram.WebApp;
  const navigate = useNavigate();
  const location = useLocation();

  tele.BackButton.show();
  tele.BackButton.onClick(() => navigate(navPaths.GUILD_DETAILS));

  const [isOpenMessageModal, setIsOpenMessageModal] = useState<any>({
    data: null,
    isOpen: false,
  });
  const [isOpenSendMessageModal, setIsOpenSendMessageModal] = useState(false);
  const [page] = useState(1);

  const isOwner = location?.state?.isOwner;
  const guildId = location?.state?.guild_id;

  const { MessageList, isLoadingMessages, refreshMessages } = useGetMessages({
    page,
    guild_id: guildId,
  });

  return (
    <>
      <ToastContainer
        position="top-left"
        closeOnClick
        transition={Slide}
        hideProgressBar
        limit={1}
        stacked
        className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingMessages}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className={clsx(
          "pt-4 px-4 pb-[100px] bg-[#F2FFE0] dark:bg-transparent h-screen relative z-30",
          "bg-contain bg-no-repeat bg-center"
        )}
        style={{ backgroundImage: `url("/images/guild/bg_tree.png")` }}
      >
        <div className="w-screen h-screen backdrop-blur-xl absolute z-0"></div>
        <div className="relative z-10">
          <p className="text-[24px] font-extrabold">Guild board</p>
        </div>

        <div className="relative z-10 mt-6 h-full">
          <div className="w-full mb-4">
            <TextField
              InputProps={{
                startAdornment: (
                  <>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="Iconly/Light/Search">
                        <g id="Search">
                          <circle
                            id="Ellipse_739"
                            cx="9.80492"
                            cy="9.8059"
                            r="7.49047"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            id="Line_181"
                            d="M15.0156 15.4043L17.9523 18.3334"
                            stroke="#130F26"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                      </g>
                    </svg>
                  </>
                ),
              }}
              className="w-full outline-none border-[1px] border-[#00000033] bg-[#fff] rounded-[8px]"
              placeholder="Search"
              size="small"
            ></TextField>
          </div>

          {isOwner && (
            <div className="mb-3">
              <button
                onClick={() => setIsOpenSendMessageModal(true)}
                className={clsx(
                  "normal-case text-base font-extrabold text-white py-3 w-full rounded-xl flex items-center justify-center gap-2",
                  "btn-hover bg-gradient-to-r from-[#97C35B] to-[#61A700] shadow-[0_4px_0px_#4C7E0B]",
                  "disabled:bg-[#4E4E4E] disabled:text-[#F3F3F3] disabled:shadow-[0_3px_0_#4E4E4E]"
                )}
              >
                Post news
              </button>
            </div>
          )}

          <div className="h-[calc(100%-100px)] overflow-auto">
            {MessageList?.items?.map((item: any, index: number) => {
              return (
                <MessageItem
                  key={item?.id}
                  openMessage={() =>
                    setIsOpenMessageModal({ isOpen: true, data: item })
                  }
                  data={item}
                  number={index}
                />
              );
            })}
          </div>
        </div>
      </div>
      <MessageModal
        isShowModal={isOpenMessageModal.isOpen}
        showModal={() => setIsOpenMessageModal({ data: null, isOpen: false })}
        data={isOpenMessageModal?.data}
        triggerRefresh={() => console.log("object")}
      />
      <SendMessageModal
        guildId={guildId}
        isShowModal={isOpenSendMessageModal}
        showModal={setIsOpenSendMessageModal}
        triggerRefresh={() => refreshMessages()}
      />
    </>
  );
};

export default GuildMessage;
