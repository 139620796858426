import { forwardRef } from "react";
import clsx from "clsx";
import { Button } from "@mui/material";
import { useScreenSize } from "../../../Hooks/useScreenSize";
import { formatDecimals } from "../../../utils/formatNumber";
// import { LoadingButton } from "@mui/lab";
// import useFeedBird from "../Hooks/useFeedBird";
// import { toast } from "react-toastify";
// import { sleep } from "../../../utils/time";

const FoodInfoModal = forwardRef(
  (
    {
      rate,
      isShowModal,
      showModal,
      triggerReload,
      leaderBird,
      isAbleToFeed,
      MaxEnergy,
      CurrentEnergy,
    }: {
      rate: any;
      isShowModal: boolean;
      showModal: any;
      triggerReload: () => void;
      leaderBird: any;
      isAbleToFeed: boolean;
      MaxEnergy: any;
      CurrentEnergy: any;
    },
    ref: any
  ) => {
    const { height } = useScreenSize();
    // const { feedBirdAsync } = useFeedBird();

    const foods = [
      {
        img: "/images/bird/small_worm/common.png",
        name: "common",
        value: 0.05,
      },
      {
        img: "/images/bird/small_worm/uncommon.png",
        name: "uncommon",
        value: 0.11,
      },
      { img: "/images/bird/small_worm/rare.png", name: "rare", value: 0.34 },
      { img: "/images/bird/small_worm/epic.png", name: "epic", value: 2.45 },
      {
        img: "/images/bird/small_worm/legendary.png",
        name: "legendary",
        value: 23.26,
      },
      // {
      //   img: "/images/icons/token_icon.png",
      //   name: "seed",
      //   value: 0.05,
      // },
    ];

    // const handleFeedBird = () => {
    //   const FeedingSeed =
    //     (1 / formatDecimals(rate?.["seed"])) *
    //     (Number(MaxEnergy) - Number(CurrentEnergy));
    //   const requestBody = {
    //     bird_id: leaderBird?.data?.id,
    //     seed_amount: Number((FeedingSeed * 10 ** 9).toFixed(0)),
    //   };

    //   feedBirdAsync(requestBody)
    //     .then(async () => {
    //       toast.error("Feed bird successfully", {
    //         style: { maxWidth: 337, height: 40, borderRadius: 8 },
    //         autoClose: 2000,
    //       });
    //     })
    //     .catch((error) => {
    //       toast.error(error?.response?.data?.message, {
    //         style: { maxWidth: 337, height: 40, borderRadius: 8 },
    //         autoClose: 2000,
    //         toastId: "catchFail",
    //       });
    //     })
    //     .finally(async () => {
    //       await sleep(500);
    //       showModal(false);
    //       triggerReload();
    //     });
    // };

    return (
      <div
        className={clsx(
          "bg-black/70 absolute z-50 inset-0 flex items-center justify-center px-7 ",
          isShowModal ? "visible" : " invisible"
        )}
      >
        <div
          ref={ref}
          className={clsx(
            "bg-[#F2FFE0] w-[100%] rounded-[20px] p-[24px] flex flex-col gap-4 items-center justify-center relative",
            isShowModal ? "translate-y-0" : "translate-y-[400%]",
            "transition-all duration-300 ease-in-out",
            height > 650 ? "" : "h-[80%]"
          )}
        >
          <>
            <div className={clsx("font-semibold flex justify-between w-full ")}>
              <p>Food</p>
              <p>Energy</p>
            </div>
            <div className="w-full h-[calc(100%-80px)] overflow-auto">
              {foods.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="grid grid-cols-5 text-sm w-full border-b-[1px] border-[#CFDEBA]"
                  >
                    <div className="col-span-1 flex items-center justify-start">
                      <img
                        src={item.img}
                        alt=""
                        className="w-[48px] h-[48px]"
                      ></img>
                    </div>
                    <div className="capitalize col-span-3 flex items-center pl-3 justify-start">
                      <p>{item.name === "seed" ? `${item.name}` : item.name}</p>
                    </div>
                    <div className="col-span-1 flex items-center justify-end font-bold">
                      <p>
                        {!!rate?.[item.name]
                          ? formatDecimals(rate?.[item.name])
                          : 0.1}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </>

          <div className="flex gap-2 items-center w-full">
            {/* <LoadingButton
              disabled={
                !isAbleToFeed || Number(MaxEnergy) - Number(CurrentEnergy) === 0
              }
              onClick={() => handleFeedBird()}
              loading={isFeedingBird}
              sx={{ border: 1 }}
              className={clsx(
                "normal-case text-[16px] text-[#4C7E0B] py-3 lsm:py-[18px] w-full h-[60px] rounded-xl ",
                "btn-hover border-[3px] border-[#97C35B] shadow-[0_4px_0px_#4C7E0B] ",
                "disabled:border-none disabled:shadow-none"
              )}
            >
              <div className="font-extrabold">
                <p>Feed fully</p>
                <div className="flex items-center justify-center gap-1">
                  <img
                    src="/images/icons/token_icon.png?v=3"
                    width={18}
                    height={18}
                    alt="token"
                  ></img>
                  <p className="text-base">
                    {(
                      (1 / formatDecimals(rate?.["seed"])) *
                      (Number(MaxEnergy) - Number(CurrentEnergy))
                    ).toFixed(2)}
                  </p>
                </div>
              </div>
            </LoadingButton> */}
            <Button
              sx={{ border: 1 }}
              onClick={() => showModal(false)}
              className={clsx(
                "font-bold capitalize text-[16px] text-white py-2 lsm:py-[18px] w-full h-[60px] rounded-xl ",
                "btn-hover bg-gradient-to-r from-[#97C35B] to-[#61A700] drop-shadow-[0_4px_0px_#4C7E0B] border-none"
              )}
            >
              Yep
            </Button>
          </div>
        </div>
      </div>
    );
  }
);

export default FoodInfoModal;
