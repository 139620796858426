import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function openBox(captcha_fallback: string) {
  return await api.post("/api/v1/beta-gratitude-mystery-box/open", {
    captcha_fallback,
  });
}

export default function useOpenBox() {
  return useMutation({
    mutationKey: ["openBox"],
    mutationFn: openBox,
  });
}
