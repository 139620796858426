import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerError, ServerResponse } from "../../../types/api";
import { Worm } from "../../../types/worm";
import { Result } from "../../../types/utils";

export const getAllWorm = async (): Promise<Result<any, ServerError>> => {
  const response = await fetchApi("GET", "/worms/me-all");
  if (response.error) {
    // Throw to retry
    throw new Error("network-error");
  }

  const wormResponse: ServerResponse<Worm> = await response.data.json();
  if (wormResponse.error) {
    return {
      error: wormResponse.error,
    };
  }

  return {
    data: wormResponse.data,
  };
};

export default function useAllWorm() {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["AllWorm"],
    queryFn: getAllWorm,
    retry: 1,
  });

  return {
    AllWorms: data,
    isGettingAllWorms: isLoading,
    refreshAllWorms: refetch,
  };
}
