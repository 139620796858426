import Cd from "react-countdown";
interface CountdownProps {
  date: number | string;
  onComplete?: () => void;
  isShowDay?: boolean;
}

export default function Countdown({ date, onComplete }: CountdownProps) {
  return date ? (
    <Cd
      date={date}
      onComplete={onComplete}
      renderer={({ hours, minutes, seconds }: any) => {
        return (
          <div className="mt-2">
            {hours === 0 && minutes === 0 && seconds === 0 ? (
              <p className="font-semibold text-[11px] text-[#fff]">Claim</p>
            ) : (
              <div className="flex gap-1 justify-center text-sm">
                <div className="font-semibold text-[11px] text-[#fff] flex justify-center">
                  {<span>{String(hours).padStart(2, "0")}:</span>}
                  {<span>{String(minutes).padStart(2, "0")}:</span>}
                  {<span>{String(seconds).padStart(2, "0")}</span>}
                </div>
              </div>
            )}
          </div>
        );
      }}
      key={date}
    />
  ) : (
    <></>
  );
}
