import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerError, ServerResponse } from "../../../types/api";
import { Result } from "../../../types/utils";

export const getLeaderBird = async ({
  id,
}: {
  id: number;
}): Promise<Result<any, ServerError>> => {
  const response = await fetchApi("GET", `/bird/${id}`);
  if (response.error) {
    // Throw to retry
    throw new Error("network-error");
  }

  const myBirdResponse: ServerResponse<any[]> = await response.data.json();
  if (myBirdResponse.error) {
    return {
      error: myBirdResponse.error,
    };
  }

  return {
    data: myBirdResponse.data,
  };
};

export default function useGetLeaderBird({ id }: { id: number }) {
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["LeaderBird"],
    queryFn: () => getLeaderBird({ id }),
    retry: 1,
  });

  return {
    leaderBird: data,
    isLoadingLeaderBird: isFetching,
    refreshLeaderBird: refetch,
  };
}
