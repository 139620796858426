interface IProps {
  value: number;
  bgColor?: string;
}

export const Wave = ({
  fill = "#5EBE58",
  duration = "3s",
  begin = "0",
  className,
}: {
  fill?: string;
  duration?: string;
  begin?: string;
} & any) => (
  <svg
    className={className}
    width="200%"
    height="100%"
    viewBox="0 0 1000 1000"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    overflow="auto"
    shapeRendering="auto"
    fill="#ffffff"
  >
    <defs>
      <path
        id="wavepath"
        d="M 0 2000 0 500 Q 38 467 76 500 t 76 0 76 0 76 0 76 0 76 0 76 0 76 0 76 0 76 0 76 0 76 0 76 0 76 0 76 0 76 0  v1000 z"
      />
      <path id="motionpath" d="M -152 0 0 0" />
    </defs>
    <g>
      <use xlinkHref="#wavepath" y="467" fill={fill}>
        <animateMotion dur={duration} repeatCount="indefinite" begin={begin}>
          <mpath xlinkHref="#motionpath" />
        </animateMotion>
      </use>
    </g>
  </svg>
);

const MoonGraph = ({ value, bgColor }: IProps) => {
  const totalPercentage = 100;

  return (
    <div className="aspect-square w-full max-w-[480px]">
      <div className="relative h-full w-full overflow-hidden">
        <div className="relative h-full w-full overflow-hidden rounded-full bg-[#E3E3E3]">
          <div
            className={`absolute h-full w-full transition-all duration-1000 ease-in-out`}
            style={{
              bottom: `calc(${value - totalPercentage - 6}%)`,
              backgroundColor: bgColor,
            }}
          >
            <Wave
              className="absolute -left-4 top-[1px] -translate-y-full"
              duration="2.75s"
            />
          </div>
          <div
            className={`absolute h-full w-full transition-all duration-1000 ease-in-out`}
            style={{
              bottom: `calc(${value - totalPercentage - 5}%)`,
              backgroundColor: bgColor,
            }}
          >
            <Wave
              className="absolute -left-10 top-[1px] -translate-y-full"
              fill={bgColor}
              duration="1.75s"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoonGraph;
