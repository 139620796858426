import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { Result } from "../../../types/utils";
import { ServerError, ServerResponse } from "../../../types/api";
import { HolyWaterBoosts } from "../../../types/boosts";

export const getWaterMissions = async (): Promise<
  Result<HolyWaterBoosts[], ServerError>
> => {
  const response = await fetchApi("GET", "/upgrades/tasks/progresses");
  if (response.error) {
    // Throw to retry
    throw new Error("network-error");
  }

  const holywaterMissionsResponse: ServerResponse<HolyWaterBoosts[]> =
    await response.data.json();
  if (holywaterMissionsResponse.error) {
    return {
      error: holywaterMissionsResponse.error,
    };
  }

  return {
    data: holywaterMissionsResponse.data,
  };
};

export default function useGetWaterMissions() {
  const { data, isPending, refetch } = useQuery({
    queryKey: ["WaterMissions"],
    queryFn: () => getWaterMissions(),
    retry: 1,
  });

  return {
    holyWaterMissions: data,
    isLoadingWaterMissions: isPending,
    refreshWaterMissions: refetch,
  };
}
