import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { Result } from "../../../types/utils";
import { ServerError, ServerResponse } from "../../../types/api";
import { Refer } from "../../../types/referees";

export const getAcountReferees = async (): Promise<
  Result<Refer[], ServerError>
> => {
  const response = await fetchApi("GET", "/profile/referees");
  if (response.error) {
    // Throw to retry
    throw new Error("network-error");
  }

  const refereesResponse: ServerResponse<Refer[]> = await response.data.json();
  if (refereesResponse.error) {
    return {
      error: refereesResponse.error,
    };
  }

  return {
    data: refereesResponse.data,
  };
};

export default function useGetAcountReferees() {
  const { data, isPending, refetch } = useQuery({
    queryKey: ["AcountReferees"],
    queryFn: () => getAcountReferees(),
  });

  return {
    referees: data,
    isLoadingReferees: isPending,
    refreshReferees: refetch,
  };
}
