/* eslint-disable react-hooks/exhaustive-deps */
import {
  CHAIN,
  TonProofItemReplySuccess,
  useTonConnectUI,
  useTonWallet,
} from "@tonconnect/ui-react";
import clsx from "clsx";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import TonWeb from "tonweb";
import { useAccountV2 } from "../../../components/Hooks/useRegisterV2";
import { missionsTypes } from "../../../constants/missions.constants";
import { navPaths } from "../../../constants/navbar.constants";
import { useMissionStore } from "../../../store/missionStore";
import {
  formatDecimals,
  formatNumberFloatFix,
  getNumberFormatUs,
} from "../../../utils/formatNumber";
import { getMobileOS } from "../../../utils/helper";
import { sleep } from "../../../utils/time";
import LoadingScreen from "../../Global/Pages/LoadingScreen";
import { useAccountBalance } from "../../Home/Hooks/useGetAcountBalance";
import ConfirmConnectModal from "../../Profile/Components/ConfirmConnectModal";
import useConectWallet from "../../Profile/Hooks/useConnectWallet";
import useInitConect from "../../Profile/Hooks/useInitConectWallet";
import DailyMissonModal from "../Components/DailyMissonModal";
import MissionsModal from "../Components/MissionsModal";
import CompletedMissionSection from "../Components/Sections/CompletedMissionSection";
import DailySection from "../Components/Sections/DailySection";
import RemainMissionSection from "../Components/Sections/RemainMissionSection";
import TonMission from "../Components/Sections/TonMission";
import { useCheckMission } from "../Hooks/useDoMissions";
import { useCheckDailyMission, useDailyMissions } from "../Hooks/useGetDaily";
import { useMissions } from "../Hooks/useGetMissions";
import { Task } from "../../../types/task";

const MissionsPage = () => {
  const {
    accountV2: account,
    isLoading: isLoadingProfile,
    refetch: refeshAcountV2,
  } = useAccountV2();
  const tonWallet = useTonWallet();
  const location = useLocation();
  const navigate = useNavigate();
  const tele = window.Telegram.WebApp;
  const setHasMissions = useMissionStore((state: any) => state.setHasMission);

  const {
    missions: missionsDatas,
    refetch: refreshMissions,
    isLoading: isLoadingMissions,
  } = useMissions();

  const { checkMission, isLoading } = useCheckMission();
  const { dailyMissions } = useDailyMissions();
  const { checkDailyMission, isPending: checkDailyLoading } =
    useCheckDailyMission();
  const { balance, refetch: refreshBalance } = useAccountBalance();

  tele.BackButton.show();
  tele.BackButton.onClick(() => handleBackBtn());

  const [isOpen, setisOpen] = useState<{
    isOpen: boolean;
    type: string;
    missions: any[];
  }>({ isOpen: false, type: "", missions: [] });
  const [isOpenDailyMission, setIsOpenDailyMission] = useState<any>({
    isOpen: location?.state?.isOpenDailyModal ?? false,
    type: "",
    data: null,
  });

  const isDesktop = window.innerHeight < 610;

  const handleBackBtn = () => {
    navigate(location?.state?.fromBird ? navPaths.BIRD : "/");
    setisOpen({ isOpen: false, type: "", missions: [] });
  };

  const handleDoMission = (item: any) => {
    if (item?.task_user === null || !item?.task_user?.completed) {
      if (getMobileOS() === "Other") {
        if (
          item.type === missionsTypes.PLAY_APP ||
          (item.type === missionsTypes.COLLABORATION && item?.metadata?.is_tma) // if mission tpye is collab and is tma, open tele link
        ) {
          const isCLicked = localStorage.getItem(`isOpenOnDesktop_${item.id}`);

          if (!isCLicked) {
            localStorage.setItem(`isOpenOnDesktop_${item.id}`, "true");
            tele.openTelegramLink(item?.metadata?.url ?? "");
          } else {
            localStorage.removeItem(`isOpenOnDesktop_${item.id}`);
          }
        }

        if (
          item.type === missionsTypes.COLLABORATION &&
          !item?.metadata?.is_tma
        ) {
          tele.openLink(item?.metadata?.url ?? "");
        }

        if (item.type === missionsTypes.TELEGRAM__JOIN) {
          tele.openTelegramLink(item?.metadata?.url ?? "");
        }
        if (item.type === missionsTypes.SHARE_STORIES) {
          toast.success("You need to open the mobile app", {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          });
          return;
        }
      } else if (item.type === missionsTypes.PLAY_APP) {
        tele.openTelegramLink(item?.metadata?.url ?? "");
      } else if (item.type === missionsTypes.COLLABORATION) {
        if (item?.metadata?.is_tma) {
          tele.openTelegramLink(item?.metadata?.url ?? "");
        } else {
          tele.openLink(item?.metadata?.url ?? "");
        }
      }

      if (
        item.type === missionsTypes.TELEGRAM_BOOST ||
        item.type === missionsTypes.TELEGRAM_JOIN_NO_BOT ||
        item.type === missionsTypes.TELEGRAM__JOIN
      ) {
        tele.openTelegramLink(item?.metadata?.url ?? "");
      }

      if (item.type === missionsTypes.SHARE_STORIES) {
        tele.shareToStory(item?.metadata?.story_media, {
          text: item?.metadata?.story_content,
        });
      }

      checkMission(item)
        .then(async (data) => {
          if (data?.data === "Mission completed") {
            toast.success(data.data, {
              style: { maxWidth: 337, height: 40, borderRadius: 8 },
              autoClose: 2000,
            });
            await sleep(500);
            refreshBalance();
            refreshMissions();
            return;
          }

          // redirect to refer page when refer-premium mission is not done yet
          if (
            data?.data === "incomplete task" ||
            data?.data === "Incomplete task"
          ) {
            if (item.type === missionsTypes.REFER_PREMIUM) {
              navigate(navPaths.FRIENDS);
              return;
            }
            toast.error("Incomplete mission", {
              style: { maxWidth: 337, height: 40, borderRadius: 8 },
              autoClose: 2000,
            });
            return;
          }

          toast.error(data?.data, {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          });
        })
        .catch((err) => {
          toast.error(
            String(err?.response?.data?.message).replace("task", "mission"),
            {
              style: { maxWidth: 337, height: 40, borderRadius: 8 },
              autoClose: 2000,
            }
          );
        });
      return;
    }
    if (
      item.type === missionsTypes.TELEGRAM_BOOST ||
      item.type === missionsTypes.TELEGRAM_JOIN_NO_BOT ||
      item.type === missionsTypes.PLAY_APP ||
      item.type === missionsTypes.TELEGRAM__JOIN
    ) {
      tele.openTelegramLink(item?.metadata?.url ?? "");
      return;
    }
    if (item.type === missionsTypes.COLLABORATION) {
      if (item?.metadata?.is_tma) {
        tele.openTelegramLink(item?.metadata?.url ?? "");
      } else {
        tele.openLink(item?.metadata?.url ?? "");
      }
    }
    if (item.type === missionsTypes.SHARE_STORIES) {
      tele.shareToStory(item?.metadata?.story_media, {
        text: item?.metadata?.story_content,
      });
    }
  };

  const handleDoDailyMission = async () => {
    const reward = await checkDailyMission();
    if (reward.error) {
      toast.error(String(reward.error.message).replace("task", "mission"), {
        style: { maxWidth: 337, height: 40, borderRadius: 8 },
        autoClose: 2000,
      });
      return;
    }

    toast.success("Mission completed", {
      style: { maxWidth: 337, height: 40, borderRadius: 8 },
      autoClose: 2000,
    });
  };

  const missions = useMemo(() => {
    if (!missionsDatas?.data?.length) {
      return [];
    }

    const missionList = (missionsDatas?.data ?? [])?.filter((item: any) => {
      const isPremium = !!tele?.initDataUnsafe?.user?.is_premium;
      if (isPremium) {
        return item?.type !== missionsTypes.SIGN_IN && item?.tickets === 0;
      }
      return (
        item?.type !== missionsTypes.SIGN_IN &&
        item?.tickets === 0 &&
        !item?.metadata?.premium_only
      );
    });

    const hasMissions = missionList.some(
      (mission) => mission.task_user === null || !mission.task_user.completed
    );
    setHasMissions(hasMissions);

    return missionList;
  }, [missionsDatas]);

  const missionGroups = useMemo(() => {
    const groups: {
      [key: string]: {
        name: string;
        order: number;
        subgroups: {
          [key: string]: {
            subgroup_img: string;
            type: string;
            name: string;
            order: number;
            missions: any[];
          };
        };
      };
    } = {};
    for (let i = 0; i < missions.length; i++) {
      const missionGroupName =
        missions.filter((item: any) => item.type !== "ton-wallet-verify")[i]
          ?.metadata?.group_name ?? "Others";
      const missionGroupOrder =
        Number(missions[i]?.metadata?.group_order) ?? 999_999_999;
      const missionSubgroupName =
        missions[i]?.metadata?.subgroup_name ?? "Common";
      const missionSubgroupType =
        missions[i]?.metadata?.subgroup_type ?? "common";
      const missionSubgroupOrder =
        Number(missions[i]?.metadata?.subgroup_order) ?? 999_999_999;
      const subgroupImgUrl =
        missions[i]?.metadata?.subgroup_img ?? "/images/timeout.png?v=3";
      const group = groups[missionGroupName] ?? {
        name: missionGroupName,
        order: missionGroupOrder,
        subgroups: {},
      };

      const subgroup = group.subgroups[missionSubgroupType] || {
        subgroup_img: subgroupImgUrl,
        type: missionSubgroupType,
        name: missionSubgroupName,
        order: missionSubgroupOrder,
        missions: [],
      };

      subgroup.missions.push(missions[i]);

      group.subgroups[missionSubgroupType] = subgroup;
      groups[missionGroupName] = group;
    }

    return Object.values(groups)
      .map((group) => ({
        ...group,
        subgroups: Object.values(group.subgroups).sort(
          (a, b) => a.order - b.order
        ),
      }))
      .sort((a, b) => a.order - b.order);
  }, [missions]);

  const modalMissions = missions.filter(
    (item: any) => item.metadata.subgroup_name === isOpen.type
  );

  const sortedGroups = missionGroups.sort((a, b) => {
    const isACompleted = a.subgroups.every((subgroup) =>
      subgroup.missions.every(
        (mission) =>
          mission?.task_user !== null || mission?.task_user?.completed === true
      )
    );
    const isBCompleted = b.subgroups.every((subgroup) =>
      subgroup.missions.every(
        (mission) =>
          mission?.task_user !== null || mission?.task_user?.completed === true
      )
    );

    if (!isACompleted && isBCompleted) {
      return -1;
    }
    if (isACompleted && !isBCompleted) {
      return 1;
    }
    return 0;
  });

  const completedMissions = useMemo(() => {
    if (!missionsDatas?.data?.length) {
      return [];
    }

    const completed = missions
      ?.filter(
        (item: any) =>
          item.metadata.group_name !== "The Open League" &&
          item.task_user !== null &&
          item?.task_user?.completed
      )
      .sort((a, b) => a.sort - b.sort);

    return completed;
  }, [missions]);

  const tonMissions = useMemo(() => {
    if (!missions) {
      return [];
    }
    return missions?.filter(
      (item: any) => item.metadata.group_name === "The Open League"
    );
  }, [missionsDatas]);

  const [tonConnectUI] = useTonConnectUI();
  const [tonChecking, setTonChecking] = useState<boolean>(false);

  const checkTonTransaction = (task: any, recheck?: boolean) => {
    if (!task) {
      return;
    }
    setShowRecheck(true);
    setTonChecking(true);
    checkMission({ ...task, type: "ton-wallet-verify-success" })
      .then(async (data) => {
        if (data?.data?.toLowerCase() === "mission completed") {
          toast.success(data.data, {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          });
          refreshBalance();
          refreshMissions();
          return;
        }
        if (data?.data?.toLowerCase() === "incomplete task") {
          toast.error("Incomplete mission", {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          });
          return;
        }
        if (recheck) {
          toast.error(
            "Task claim can take some time. It's related to TON Blockchain performance",
            {
              style: { maxWidth: 337, height: 55, borderRadius: 8 },
              autoClose: 2000,
            }
          );
          return;
        }
        toast.error(data?.data, {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
        });
      })
      .catch((err) => {
        toast.error(
          String(err?.response?.data?.message).replace("task", "mission"),
          {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          }
        );
      })
      .finally(() => {
        setTonChecking(false);
        setShowRecheck(false);
      });
  };
  const handleCheckTonStatus = (tonMission: any) => {
    if (!account?.data?.wallet_address_ton) {
      toast.error("Please connect your TON wallet", {
        style: { maxWidth: 337, height: 40, borderRadius: 8 },
        autoClose: 2000,
        toastId: "connect-ton-wallet",
      });
      // navigate(navPaths.PROFILE);
    }
    if (!tonConnectUI.connected) {
      try {
        tonConnectUI.openModal();
      } catch (error) {
        console.log("tonConnectUI err", error);
      }
      return;
    }
    handleDoTonTransaction(tonMission);
  };

  const [showRecheck, setShowRecheck] = useState<boolean>(false);
  const handleDoTonTransaction = async (tonMission: any) => {
    // const tonMission = tonMissions.find(
    //   (item: any) => item.type === "ton-wallet-verify",
    // );
    setTonChecking(true);
    let a = new TonWeb.boc.Cell();
    a.bits.writeUint(0, 32);
    a.bits.writeString(`${tonMission?.id}:${account?.data?.id}`);
    const payload = TonWeb.utils.bytesToBase64(await a.toBoc());

    const transaction = {
      validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
      network:
        tonMission?.metadata?.ton_network === "testnet"
          ? CHAIN.TESTNET
          : CHAIN.MAINNET,
      messages: [
        {
          address: tonMission?.metadata?.ton_recepient_address,
          amount: (tonMission?.metadata?.amount * 10 ** 9)?.toString() || "0",
          payload: payload,
        },
      ],
    };
    if (tonMission?.task_user) {
      checkMission(tonMission)
        .then(async (data) => {
          if (data?.data?.toLowerCase() === "mission completed") {
            toast.success(data.data, {
              style: { maxWidth: 337, height: 40, borderRadius: 8 },
              autoClose: 2000,
            });
            refreshBalance();
            refreshMissions();
            return;
          }
          if (data?.data?.toLowerCase() === "incomplete task") {
            try {
              const rs = await tonConnectUI.sendTransaction(transaction);
              if (rs.boc) {
                checkTonTransaction(tonMission);
              }
            } catch (error) {
              console.log("tonConnectUI sendTransaction err", error);
              setTonChecking(false);
            }
            return;
          }
          toast.error(data?.data, {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          });
        })
        .catch((err) => {
          toast.error(
            String(err?.response?.data?.message).replace("task", "mission"),
            {
              style: { maxWidth: 337, height: 40, borderRadius: 8 },
              autoClose: 2000,
            }
          );
        })
        .finally(() => {
          setTonChecking(false);
        });
      return;
    }
    try {
      const rs = await tonConnectUI.sendTransaction(transaction);
      if (rs.boc) {
        checkTonTransaction(tonMission);
      }
    } catch (error) {
      console.log("tonConnectUI sendTransaction err", error);
      setTonChecking(false);
    }
  };

  const [data, setData] = useState<any>();
  const [isOpenConfirmConnectModal, setIsOpenConfirmConnectModal] =
    useState<boolean>(false);
  const confirmConnectModalRef = useRef<any>();

  const { mutateAsync: connectInitAsync } = useInitConect();
  const { mutateAsync: connectWalletAsync } = useConectWallet();

  useEffect(() => {
    !isLoadingProfile &&
      !account?.data?.wallet_address_ton &&
      connectInitAsync()
        .then((data) => {
          setData(data?.data?.data);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isLoadingProfile]);

  useEffect(() => {
    if (!tonConnectUI) {
      return;
    }
    tonConnectUI.setConnectRequestParameters({
      state: "ready",
      value: { tonProof: data },
    });
  }, [data, tonConnectUI]);

  const body = useMemo(() => {
    const proof = tonWallet?.connectItems?.tonProof as TonProofItemReplySuccess;

    return {
      address: tonWallet?.account?.address,
      network: tonWallet?.account?.chain,
      proof: {
        ...proof?.proof,
        stateInit: tonWallet?.account?.walletStateInit,
      },
    };
  }, [tonWallet]);

  const handleSaveWallet = () => {
    connectWalletAsync({
      address: body.address || "",
      network: body.network || "",
      proof: body.proof,
    })
      .then(() => {
        toast.error("Connect wallet succes fully", {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
        });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
        });
      })
      .finally(async () => {
        await sleep(500);
        refeshAcountV2();
      });
  };

  useEffect(() => {
    if (
      tonConnectUI?.connected &&
      account?.data?.wallet_address_ton &&
      tonWallet?.account?.address !== account?.data?.wallet_address_ton
    ) {
      toast.error(
        "This wallet has a different address than the wallet you linked",
        {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
        }
      );
      tonConnectUI.disconnect();
    }

    if (
      tonWallet?.connectItems?.tonProof &&
      !account?.data?.wallet_address_ton
    ) {
      setIsOpenConfirmConnectModal(true);
      return;
    }
  }, [tonWallet, tonConnectUI]);

  return (
    <>
      {isLoadingMissions ? (
        <LoadingScreen />
      ) : (
        <div className=" pt-5 px-4 pb-[60px] relative z-40 h-screen bg-[#FFFFFF]">
          <ToastContainer
            containerId={"missionToastContainer"}
            position="top-left"
            closeOnClick
            transition={Slide}
            hideProgressBar
            limit={1}
            stacked
            className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
          />

          <div className=" flex flex-col items-center">
            <div className="flex items-center gap-1">
              <img
                className="size-[34px]"
                src="/images/icons/token_icon.png?v=3"
                alt="token"
              ></img>
              <p className="text-[32px] font-semibold">
                {getNumberFormatUs(
                  formatNumberFloatFix(Number(formatDecimals(balance)) ?? 0, 2)
                )}
              </p>
            </div>
            <div>
              <p className="text-[12px] text-center poppins-regular">
                Tip 1: Complete more tasks = earn more airdrops!
              </p>
            </div>
          </div>

          <div
            className={clsx(
              isDesktop ? "mt-3" : "mt-5",
              "overflow-auto max-h-[calc(100%-100px)] "
            )}
          >
            {/* ton transaction mission */}
            {tonMissions.length > 0 && (
              <TonMission
                isDoingMission={isLoading}
                Mission={tonMissions}
                tonChecking={tonChecking}
                handleCheckTonStatus={handleCheckTonStatus}
                handleDoMission={(item: any) => handleDoMission(item)}
                showRecheck={showRecheck}
                handleRecheckTonMission={(tonMission: Task) => {
                  checkTonTransaction(tonMission, true);
                }}
              />
            )}

            {/* daily missions */}
            <DailySection />

            {/* remaining missions  */}
            {!!sortedGroups.length && (
              <RemainMissionSection
                isDoingMission={isLoading}
                sortedGroups={missions}
                missionGroups={missionGroups}
                handleDoMission={(item: any) => handleDoMission(item)}
              />
            )}

            {/* completd mission  */}
            {!!completedMissions?.length && (
              <CompletedMissionSection
                handleDoMission={(item: any) => handleDoMission(item)}
                completedMissions={completedMissions}
              />
            )}
          </div>
          {isOpen.isOpen && (
            <MissionsModal
              isLoading={isLoading}
              handleDoMission={(item: string) => handleDoMission(item)}
              missions={modalMissions}
              type={isOpen.type}
              closeModal={() =>
                setisOpen({ isOpen: false, type: "", missions: [] })
              }
              isOpen={isOpen.isOpen}
              reFetch={() => {
                refreshMissions();
              }}
            ></MissionsModal>
          )}

          {isOpenDailyMission.isOpen && dailyMissions.length && (
            <DailyMissonModal
              isLoading={checkDailyLoading}
              handleDoMission={() => handleDoDailyMission()}
              data={dailyMissions ?? []}
              closeModal={() =>
                setIsOpenDailyMission({ isOpen: false, type: "", data: null })
              }
            />
          )}
          <ConfirmConnectModal
            ref={confirmConnectModalRef}
            isShowModal={isOpenConfirmConnectModal}
            showModal={setIsOpenConfirmConnectModal}
            handleStart={() => handleSaveWallet()}
            value={tonWallet?.account}
            disconectWallet={() => {
              setIsOpenConfirmConnectModal(false);
              if (tonConnectUI && tonConnectUI.connected) {
                tonConnectUI.disconnect();
              }
            }}
          />
        </div>
      )}
    </>
  );
};

export default MissionsPage;
