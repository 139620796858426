import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import Spiner from "../Components/Games/Spiner";
import Battle from "../Components/Games/Battle";
import LuckySpiner from "../Components/Games/LuckySpin";

const Boots = () => {
  const navigate = useNavigate();
  const tele = window.Telegram.WebApp;
  tele.BackButton.show();
  tele.BackButton.onClick(() => handleBackBtn());
  const handleBackBtn = () => {
    navigate("/");
  };

  return (
    <div
      className={clsx(
        "overflow-auto pt-5 px-4 relative z-40 h-screen bg-[#fff]"
      )}
    >
      <ToastContainer
        position="top-left"
        closeOnClick
        transition={Slide}
        hideProgressBar
        limit={1}
        stacked
        className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
      />

      <div className="flex flex-col items-center gap-2">
        <p className=" text-[24px] font-semibold">Games</p>
        <p className="text-[12px] text-center poppins-regular">
          Collect free rewards every day
        </p>
      </div>
      <div className="pt-3 mb-3 font-semibold  flex items-center justify-start gap-1">
        <div>
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.87725 0.982442C7.07762 0.339185 7.92238 0.339187 8.12275 0.982441L9.10026 4.12059C9.3167 4.81545 9.94621 5.30564 10.6778 5.30564H13.841C14.1468 5.30564 14.3744 5.50101 14.4638 5.78808C14.5536 6.07647 14.4808 6.39146 14.219 6.58991L11.6598 8.5294C11.0812 8.9679 10.8487 9.73374 11.0642 10.4256L12.0417 13.5638C12.1472 13.9025 12.0177 14.1982 11.7969 14.3656C11.5792 14.5306 11.2953 14.5583 11.0411 14.3656L8.48192 12.4261C7.89791 11.9835 7.1021 11.9835 6.51808 12.4261L3.95893 14.3656C3.70472 14.5583 3.42083 14.5306 3.20311 14.3656C2.98227 14.1982 2.85277 13.9025 2.95827 13.5638L3.93578 10.4256C4.15129 9.73374 3.9188 8.9679 3.34019 8.5294L0.781044 6.58991C0.519179 6.39146 0.446377 6.07647 0.536209 5.78808C0.625629 5.50101 0.853247 5.30564 1.15896 5.30564H4.32224C5.05379 5.30564 5.6833 4.81545 5.89974 4.12059L6.87725 0.982442Z"
              stroke="black"
            />
          </svg>
        </div>
        <p className="text-[18px] poppins-semibold">Game List</p>
      </div>

      <div className="overflow-auto h-[calc(100%-155px)] pb-[100px]">
        <Spiner />
        <Battle />
        <LuckySpiner />
      </div>
    </div>
  );
};

export default Boots;
