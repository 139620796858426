import { LoadingButton } from "@mui/lab";
import clsx from "clsx";
import { forwardRef } from "react";
import { toast } from "react-toastify";
import {
  formatDecimals,
  formatNumberFloatFix,
} from "../../../utils/formatNumber";
import useClaimGuildReward from "../Hooks/useClaimGuildReward";
import useGetUserBenefit from "../Hooks/useGetUserBenefit";
import { sleep } from "../../../utils/time";

const BonusRewardModal = forwardRef(
  (
    {
      isShowModal,
      showModal,
      distributeRate,
      triggerRefresh,
      isOwner,
      isJoinedCurrentGuild,
    }: {
      isShowModal: boolean;
      showModal: any;
      distributeRate: number;
      isOwner: boolean;
      triggerRefresh: () => void;
      isJoinedCurrentGuild: boolean;
    },
    ref: any
  ) => {
    const { mutateAsync: claimRewardAsync, isPending: isClaiming } =
      useClaimGuildReward();
    const { userBenefit, refreshUserBenefit } = useGetUserBenefit();

    const handleClaimReward = () => {
      const lastClaimReward = new Date(userBenefit?.data?.created_at).getTime();
      const now = new Date().getTime();
      if (now - lastClaimReward < 3600000) {
        const distanceFromStart = now - lastClaimReward;
        const timeRemain = Math.floor((3600000 - distanceFromStart) / 60000);
        if (!toast.isActive("cant_claim")) {
          toast.error(
            `Claim after ${
              timeRemain <= 1 ? `1 minute` : `${timeRemain} minutes`
            }`,
            {
              toastId: "cant_claim",
              autoClose: 2000,
              style: { borderRadius: 8 },
            }
          );
        }
        return;
      }

      claimRewardAsync()
        .then(() => {
          toast.error("Claim reward succesfully", {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
            toastId: "leave",
          });
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
            toastId: "catchFail",
          });
        })
        .then(async () => {
          await sleep(500);
          refreshUserBenefit();
        });
    };

    const benefitAmount = userBenefit?.data?.amount;

    return (
      <div
        className={clsx(
          "bg-black/70 absolute z-50 inset-0 flex items-center justify-center px-7 ",
          isShowModal ? "visible" : " invisible"
        )}
      >
        <div
          ref={ref}
          className={clsx(
            "bg-[#F2FFE0] w-[100%] rounded-[20px] p-[24px] flex flex-col gap-4 items-center justify-center relative",
            isShowModal ? "translate-y-0" : "translate-y-[400%]",
            "transition-all duration-300 ease-in-out"
          )}
        >
          {/* close btn  */}
          <div
            onClick={() => showModal(false)}
            className=" absolute -right-2 -top-5 btn-hover bg-white rounded-full drop-shadow-[0_4px_0px_#4C7E0B]"
          >
            <svg
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23 46C10.2422 46 0 35.7578 0 23C0 10.332 10.2422 0 23 0C35.668 0 46 10.332 46 23C46 35.7578 35.668 46 23 46ZM15.7227 15.7227C14.8242 16.6211 14.8242 17.9688 15.7227 18.7773L19.9453 23L15.7227 27.2227C14.8242 28.1211 14.8242 29.4688 15.7227 30.2773C16.5312 31.1758 17.8789 31.1758 18.6875 30.2773L22.9102 26.0547L27.1328 30.2773C28.0312 31.1758 29.3789 31.1758 30.1875 30.2773C31.0859 29.4688 31.0859 28.1211 30.1875 27.2227L25.9648 23L30.1875 18.7773C31.0859 17.9688 31.0859 16.6211 30.1875 15.7227C29.3789 14.9141 28.0312 14.9141 27.1328 15.7227L22.9102 19.9453L18.6875 15.7227C17.8789 14.9141 16.5312 14.9141 15.7227 15.7227Z"
                fill="url(#paint0_linear_3658_4024)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3658_4024"
                  x1="6.9"
                  y1="6.9"
                  x2="34.5"
                  y2="41.975"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#97C35B" />
                  <stop offset="1" stopColor="#63A803" />
                </linearGradient>
              </defs>
            </svg>
          </div>

          <div className={clsx("font-bold text-center text-[20px] w-full")}>
            Bonus reward
          </div>

          <div className="w-full grid grid-cols-12 gap-3 bg-[#E4F0D3] rounded-[12px] px-2 py-3">
            <div className="flex items-center justify-center col-span-3">
              <div className="w-[40px] h-[40px] flex items-center justify-center  ">
                <div className="relative">
                  {isOwner && (
                    <img
                      src="images/guild/bonus.png"
                      className="w-[40px] h-[47px]"
                      alt=""
                    ></img>
                  )}
                  {!isOwner && (
                    <img
                      className="size-10"
                      src={`/images/guild/member_benefit.png`}
                      alt=""
                    ></img>
                  )}
                  {/* <div
                    className={clsx(
                      "absolute w-[28px] bg-[#17AE37] border-[1px] border-[#E4F0D3] text-center text-white rounded-[11px]",
                      "left-[50%] -translate-x-[50%] -bottom-1 text-[10px]"
                    )}
                  >
                    5%
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-span-9 text-[12px] flex items-center">
              <p className="">
                {isOwner
                  ? "Earn 5% from the guild's total hunts"
                  : "Guild's Reward: You receive an additional 5% of your bird hunted"}
              </p>
            </div>
          </div>

          {distributeRate > 0 && (
            <div className="w-full grid grid-cols-12 gap-3 bg-[#E4F0D3] rounded-[12px] p-2 py-3">
              <div className="flex justify-center col-span-3">
                <div className="w-[40px] h-[40px] flex items-center justify-center  ">
                  <div className="relative">
                    {isOwner && (
                      <img
                        src="/images/guild/bonus.png"
                        className="w-[40px] h-[47px]"
                        alt=""
                      ></img>
                    )}
                    {!isOwner && (
                      <img
                        src="/images/guild/master_benefit.png"
                        className="size-10"
                        alt=""
                      ></img>
                    )}
                    {/* <div
                      className={clsx(
                        "absolute w-[28px] bg-[#17AE37] border-[1px] border-[#E4F0D3] text-center text-white rounded-[11px]",
                        "left-[50%] -translate-x-[50%] -bottom-1 text-[10px]"
                      )}
                    >
                      {`${distributeRate}%`}
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-span-9 text-[12px] flex items-center">
                {isOwner ? (
                  <p className="">{`Distribute ${distributeRate}%, keeping ${
                    5 - distributeRate
                  }% for yourself`}</p>
                ) : (
                  <div className="flex flex-col items-end gap-2">
                    <p className="">{`Master's Reward: You receive an additional ${distributeRate}% of your bird hunted.`}</p>
                    <LoadingButton
                      loading={isClaiming}
                      onClick={() => handleClaimReward()}
                      disabled={
                        isClaiming || !isJoinedCurrentGuild || !benefitAmount
                      }
                      className={clsx(
                        "font-bold  normal-case text-sm text-white py-2 rounded-xl flex items-center justify-center gap-2",
                        "btn-hover bg-gradient-to-r from-[#97C35B] to-[#61A700] shadow-[0_4px_0px_#4C7E0B]",
                        "disabled:bg-[#4E4E4E] disabled:text-[#F3F3F3] disabled:shadow-[0_3px_0_#4E4E4E]"
                      )}
                    >
                      Claim
                      {/* <div className="flex justify-center items-center gap-[2px]  ">
                        <p>{`${distributeRate} %`}</p>
                      </div> */}
                      <div className="flex items-center gap-1">
                        <img
                          src="/images/icons/token_icon.png?v=3"
                          width={13}
                          height={13}
                          alt="token"
                        ></img>
                        <p className="text-[12px] font-semibold">
                          {/* {formatDecimals(userBenefit?.data?.amount ?? 0)} */}
                          {formatNumberFloatFix(
                            Number(formatDecimals(benefitAmount)) ?? 0,
                            2
                          )}
                        </p>
                      </div>
                    </LoadingButton>
                  </div>
                )}
              </div>
            </div>
          )}

          {isOwner && (
            <div className="w-full">
              <LoadingButton
                loading={isClaiming}
                onClick={() => handleClaimReward()}
                disabled={
                  benefitAmount === 0 &&
                  (isClaiming || !isJoinedCurrentGuild || !benefitAmount)
                }
                className={clsx(
                  "font-extrabold normal-case text-base text-white py-3 w-full rounded-xl flex items-center justify-center gap-2",
                  "btn-hover bg-gradient-to-r from-[#97C35B] to-[#61A700] shadow-[0_4px_0px_#4C7E0B]",
                  "disabled:bg-[#4E4E4E] disabled:text-[#F3F3F3] disabled:shadow-[0_3px_0_#4E4E4E]"
                )}
              >
                Claim
                {/* <div className="flex justify-center items-center gap-[2px] col-span-2 ">
                  <p>{`${5 - distributeRate} %`}</p>
                </div> */}
                <div className="flex items-center gap-1">
                  <img
                    src="/images/icons/token_icon.png?v=3"
                    width={13}
                    height={13}
                    alt="token"
                  ></img>
                  <p className="text-[16px] font-semibold">
                    {formatNumberFloatFix(
                      Number(formatDecimals(benefitAmount)) ?? 0,
                      2
                    )}
                  </p>
                </div>
              </LoadingButton>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default BonusRewardModal;
