import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function sendMessage({
  content,
  guild_id,
}: {
  content: string;
  guild_id: string;
}) {
  return await api.post("/api/v1/guild/notification/create", {
    content,
    guild_id,
  });
}

export default function useSendMessage() {
  return useMutation({
    mutationKey: ["sendMessage"],
    mutationFn: sendMessage,
  });
}
