import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function mergeEgg(id: string[]) {
  return await api.post(`/api/v1/egg-piece-merge`, {
    egg_piece_ids: id,
  });
}

export default function useMergeEgg() {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["mergeEgg"],
    mutationFn: mergeEgg,
  });

  return { mergeEggAsync: mutateAsync, isMergingEgg: isPending };
}
