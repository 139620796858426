import clsx from "clsx";
import { ITEM_TYPES } from "../Pages/Market";
import { AnimatePresence, motion } from "framer-motion";

const Filter = ({
  showFilter,
  typeFilter,
  handleTypeFilter,
}: {
  showFilter: boolean;
  typeFilter: string;
  handleTypeFilter: any;
}) => {
  // const { innerWidth } = window;

  return (
    <AnimatePresence initial={false} mode="wait">
      <motion.div
        animate={{
          height: showFilter ? 74 : 0,
          marginTop: showFilter ? 16 : 0,
          overflow: showFilter ? "visible" : "hidden",
          opacity: showFilter ? 1 : 0,
        }}
        transition={{ duration: 0 }}
        className={clsx(
          "grid grid-cols-3 gap-x-2 gap-y-2 font-[500]"
          // innerWidth !== 400 &&
          //   innerWidth !== 420 &&
          //   innerWidth !== 384 &&
          //   "duration-300"
          // showFilter
          //   ? "mt-5 max-h-[74px] opacity-100"
          //   : "mt-0 h-0 opacity-0 overflow-hidden"
        )}
      >
        {ITEM_TYPES.filter(v => v.type !== "basic").map((type, index) => {
          return (
            <div
              key={index}
              className={clsx(
                type.type !== typeFilter && "dark:bg-white/90",
                "flex items-center justify-center text-xs lsm:text-sm cursor-pointer capitalize rounded-lg",
                // (index === 1 || index === 4) && "mx-1",
                type.type === typeFilter && type.type === "common"
                  ? `border-b-[3px] border-b-[#000] dark:border-b-[#4C7E0B] dark:bg-[#8d8d8d] border dark:border-none border-black  text-white`
                  : type.type === typeFilter && type.type !== "common"
                  ? "border-b-[3px] border-b-[#000] dark:border-b-[#4C7E0B] border dark:border-none border-black  text-white"
                  : "dashed-border",
                type.type !== typeFilter &&
                  type.type === "common" &&
                  "dashed-border-common",
                type.type !== typeFilter &&
                  type.type === "rare" &&
                  "dashed-border-rare",
                type.type !== typeFilter &&
                  type.type === "epic" &&
                  "dashed-border-epic",
                type.type !== typeFilter &&
                  type.type === "legendary" &&
                  "dashed-border-legendary",
                type.type !== typeFilter &&
                  type.type === "mythic" &&
                  "dashed-border-mythic"
              )}
              style={{
                color: type.type === "common" ? "#000" : type.color,
                backgroundColor:
                  (type.type === typeFilter && `${type.color}`) || undefined,
              }}
              onClick={() => handleTypeFilter(type.type)}
            >
              {type.type}
            </div>
          );
        })}
      </motion.div>
    </AnimatePresence>
  );
};

export default Filter;
