export const getStreakDays = (day: number) => {
  if (day <= 7) {
    return [1, 2, 4, 7];
  }
  if (day > 7 && day <= 18) {
    return [9, 12, 15, 18];
  }
  if (day > 18 && day <= 35) {
    return [23, 27, 31, 35];
  }
  if (day > 35 && day <= 48) {
    return [39, 42, 45, 48];
  }
  if (day > 48 && day <= 60) {
    return [51, 54, 57, 60];
  }
  return [];
};

export const getProgessPercentage = (count: number) => {
  if (
    count === getStreakDays(count)?.[0] ||
    (count > getStreakDays(count)?.[0] && count < getStreakDays(count)?.[1])
  ) {
    return 0;
  }
  if (
    count === getStreakDays(count)?.[1] ||
    (count > getStreakDays(count)?.[1] && count < getStreakDays(count)?.[2])
  ) {
    return 33;
  }
  if (
    count === getStreakDays(count)?.[2] ||
    (count > getStreakDays(count)?.[2] && count < getStreakDays(count)?.[3])
  ) {
    return 63;
  }
  if (
    count === getStreakDays(count)?.[3] ||
    (count > getStreakDays(count)?.[2] && count < getStreakDays(count)?.[3])
  ) {
    return 100;
  }
  return 0;
};

export const getOrdinalNumber = (n: number) => {
  const suffixes = ["th", "st", "nd", "rd"];
  const value = n % 100;
  return n + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0]);
};
