import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function buyBox(id: string) {
  return await api.post(`/api/v1/iap/boxes/checkout`, {
    package_id: id,
  });
}

export default function useBuyBox() {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["buyBox"],
    mutationFn: buyBox,
  });

  return { buyBoxAsync: mutateAsync, isBuyingBox: isPending };
}
