import { birdLevel } from "../MysteryBox/constanst";

export const guildLevels = [
  "village",
  "town",
  "kingdom",
  "empire",
  "metropolis",
  "planetary",
  "galactic",
];

export const birdBackgroundColors = {
  [birdLevel.sparrow]: "#F0CBAD",
  [birdLevel.penguin]: "#F2FFE0",
  [birdLevel.parrot]: "#A4E59B",
  [birdLevel.owl]: "#BDD1EC",
  [birdLevel.hawk]: "#E2B7FF",
  [birdLevel.phoenix]: "#FFD6AE",
};

export const birdBorderColors = {
  [birdLevel.sparrow]: "#D66F4A",
  [birdLevel.penguin]: "#3F5A78",
  [birdLevel.parrot]: "#48C936",
  [birdLevel.owl]: "#50ABE5",
  [birdLevel.hawk]: "#BC67F4",
  [birdLevel.phoenix]: "#F1AB64",
};
