import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { navPaths } from "../../../constants/navbar.constants";
import { useMissions } from "../../Missions/Hooks/useGetMissions";
import { memo } from "react";

const ProfileButton = memo(({ achieve }: { achieve: boolean }) => {
  const tele = window.Telegram.WebApp;
  const navigate = useNavigate();
  const { missions } = useMissions();

  const joinChannelTask = missions?.data?.find(
    (task) => task?.id === "e3b5e992-8b0a-4cb0-8a70-ca317fbc758e",
  );

  return (
    <div
      className={clsx(
        "flex items-end justify-between gap-2 mt-6",
        // isMobile ? "mt-6" : "",
      )}
    >
      <div className="flex gap-[10px] items-center cursor-pointer w-full">
        <div
          onClick={() => navigate(navPaths.PROFILE)}
          className="size-[40px] aspect-square rounded-[50%] overflow-hidden "
          style={{
            background:
              "radial-gradient(circle, rgba(247,255,235,1) 0%, rgba(171,225,94,1) 100%)",
          }}
        >
          <img
            // src={
            //   achieve
            //     ? "/images/profile/achieve-avatar.png"
            //     : "/images/trees/8.png?v=4"
            // }
            src="/images/trees/main.png"
            alt=""
            className={achieve ? "object-contain p-1" : ""}
          ></img>
        </div>
        {/* <div>
          <p className="dark:text-[#fff] text-[12px] font-semibold w-[130px] truncate">
            {userName()}
          </p>
        </div> */}
        {!joinChannelTask?.task_user?.completed && (
          <div
            onClick={() =>
              tele.openTelegramLink(joinChannelTask?.metadata?.url ?? "")
            }
            className="bg-[#2238001A] dark:bg-white py-2 px-5 rounded-[5px] flex-1 poppins-regular font-semibold text-xs flex items-center gap-1 cursor-pointer"
          >
            <span>
              Join <span className="text-[#528D00]">SEED updates</span> for
              airdrop details
            </span>
            <img
              src="/images/icons/token_icon.png?v=3"
              alt="token"
              className="w-3 aspect-square"
            ></img>
          </div>
        )}
        {/* {joinChannelTask?.task_user?.completed &&
          !transactionTask?.task_user?.completed && (
            <div
              onClick={() => navigate(navPaths.MISSIONS)}
              className="bg-[#2238001A] dark:bg-white pt-[9px] pb-[8px] px-5 rounded-[5px] flex-1 poppins-regular font-semibold text-xs flex items-center gap-1 cursor-pointer"
            >
              <span>
                Verify on-chain users now to{" "}
                <span className="text-[#528D00]">guarantee your airdrop</span>!
              </span>
            </div>
          )} */}
        {joinChannelTask?.task_user?.completed && (
          <div
            onClick={() => navigate(navPaths.PROFILE)}
            className="bg-[#2238001A] dark:bg-white py-2 px-5 rounded-[5px] flex-1 poppins-regular font-semibold text-xs flex items-center gap-1 cursor-pointer"
          >
            <span>
              Collect more badges to get even
              <span className="text-[#528D00]"> more rewards</span>!
            </span>
          </div>
        )}
      </div>

      {/* <div
         onClick={() =>
           navigate(
             !!MemberDetails?.guild_rank
               ? navPaths.GUILD_LIST
               : navPaths.GUILD_WELCOME,
             { state: { rank: MemberDetails?.guild_rank } },
           )
         }
         className="w-[100px] flex flex-col items-center gap-1 cursor-pointer rounded-md py-[6px] "
       >
         <div className="flex items-center gap-3">
           <div className="flex items-center gap-1">
             <p className="text-[12px] capitalize font-semibold text-[#4C7E0B]">
               {!!MemberDetails?.guild_rank
                 ? MemberDetails?.guild_rank
                 : "Guild"}
             </p>
             <svg
               width="5"
               height="9"
               viewBox="0 0 5 9"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <path
                 d="M1.08594 0.5625L4.4375 4.125C4.53125 4.24219 4.60156 4.38281 4.60156 4.5C4.60156 4.64062 4.53125 4.78125 4.4375 4.89844L1.08594 8.46094C0.875 8.69531 0.523438 8.69531 0.289062 8.48438C0.0546875 8.27344 0.0546875 7.92188 0.265625 7.6875L3.26562 4.5L0.265625 1.33594C0.0546875 1.10156 0.0546875 0.75 0.289062 0.539062C0.523438 0.328125 0.875 0.328125 1.08594 0.5625Z"
                 fill="#4C7E0B"
               />
             </svg>
           </div>

           <img
             className="size-8"
             src={`/images/guild/guild_level/${
               !!MemberDetails?.guild_rank
                 ? MemberDetails?.guild_rank
                 : "village"
             }.png`}
             alt=""
           ></img>
         </div>
         <div className="w-full h-[6px] rounded-[20px] bg-[#CADCB1] overflow-hidden">
           <div className="w-[50%] h-full rounded-[20px] bg-gradient-to-r from-[#7FBF2C] to-[#4C7E0B]"></div>
         </div>
       </div> */}
    </div>
  );
});

export default ProfileButton;
