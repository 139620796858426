import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function setInitConect() {
  return await api.post("/api/v1/profile/wallets/ton/init");
}

export default function useInitConect() {
  return useMutation({
    mutationKey: ["setInitConect"],
    mutationFn: setInitConect,
  });
}
