import clsx from "clsx";

const ArrowBtn = ({ onClick }: { onClick: () => void }) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        "cursor-pointer size-10 flex items-center justify-center"
      )}
    >
      <svg
        width="19"
        height="30"
        viewBox="0 0 19 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_dd_7594_6022)">
          <path
            d="M6.5625 3.5L15.5 13C15.75 13.3125 15.9375 13.6875 15.9375 14C15.9375 14.375 15.75 14.75 15.5 15.0625L6.5625 24.5625C6 25.1875 5.0625 25.1875 4.4375 24.625C3.8125 24.0625 3.8125 23.125 4.375 22.5L12.375 14L4.375 5.5625C3.8125 4.9375 3.8125 4 4.4375 3.4375C5.0625 2.875 6 2.875 6.5625 3.5Z"
            fill="#66AA07"
          />
        </g>
        <defs>
          <filter
            id="filter0_dd_7594_6022"
            x="0.8125"
            y="0.875"
            width="18.125"
            height="28.3125"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_7594_6022"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_7594_6022"
              result="effect2_dropShadow_7594_6022"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_7594_6022"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </button>
  );
};

export default ArrowBtn;
