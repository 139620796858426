import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerResponse } from "../../../types/api";

export const getGuildsData = async ({
  guild_rank,
  page,
  sort,
  season_id,
  search,
}: {
  guild_rank: string;
  page: number;
  sort?: string;
  season_id: string;
  search?: string;
}) => {
  const response = await fetchApi(
    "GET",
    `/guild/list?guild_rank=${guild_rank}&page=${page}&sort_by=${sort}&season_id=${season_id}&search=${search}`,
  );
  if (response.error) {
    throw new Error("network-error");
  }

  const getGuildsDataResponse: ServerResponse<any> = await response.data.json();
  if (getGuildsDataResponse.error) {
    return {
      error: getGuildsDataResponse.error,
    };
  }

  return getGuildsDataResponse.data;
};

export default function useGetGuildList({
  guild_rank,
  page,
  sort,
  season_id,
  search,
}: {
  guild_rank: string;
  page: number;
  sort?: string;
  season_id: string;
  search?: string;
}) {
  const { data, isPending, refetch, isRefetching } = useQuery({
    queryKey: ["GuildList"],
    queryFn: () =>
      !!season_id &&
      getGuildsData({ guild_rank, page, sort, season_id, search }),
    retry: 1,
  });

  return {
    guildList: data,
    isLoadingGuildList: isPending,
    refreshGuildList: refetch,
    isRefreshingGuildList: isRefetching,
  };
}
