import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function startHunt({
  id,
  task_level,
}: {
  id: string;
  task_level: number;
}) {
  return await api.post(`/api/v1/bird-hunt/start`, {
    bird_id: id,
    task_level,
  });
}

export default function useStartHunt() {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["startHunt"],
    mutationFn: startHunt,
  });

  return { startHuntAsync: mutateAsync, isStartingHunt: isPending };
}
