import clsx from "clsx";
import { motion } from "framer-motion";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { useAccount } from "../../../components/Hooks/useRegister";
import {
  inventoryTab,
  tabButtons,
} from "../../../constants/inventory.constants";
import EggMarket from "../Components/EggMarket";
import WormMarket from "../Components/WormMarket";

export const ITEM_TYPES = [
  {
    id: 0,
    type: "basic",
    color: "#8D391B",
    description: "80%",
    eggfloorPrice: 10000000000,
    wormfloorPrice: 100000000,
  },
  {
    id: 0,
    type: "common",
    color: "#CDCDCD",
    description: "80%",
    eggfloorPrice: 10000000000,
    wormfloorPrice: 100000000,
  },
  {
    id: 1,
    type: "uncommon",
    color: "#149712",
    description: "16%",
    eggfloorPrice: 23000000000,
    wormfloorPrice: 200000000,
  },
  {
    id: 2,
    type: "rare",
    color: "#005BC6",
    description: "top 3.4%",
    eggfloorPrice: 73000000000,
    wormfloorPrice: 600000000,
  },
  {
    id: 3,
    type: "epic",
    color: "#B148E3",
    description: "top 0.2%",
    eggfloorPrice: 529000000000,
    wormfloorPrice: 2450000000,
  },
  {
    id: 4,
    type: "legendary",
    color: "#DA8F00",
    description: "top 0.05%",
    eggfloorPrice: 5024000000000,
    wormfloorPrice: 23260000000,
  },
  {
    id: 5,
    type: "mythic",
    color: "#C70000",
    description: "top 0.01%",
    eggfloorPrice: 0,
    wormfloorPrice: 0,
  },
];

export const ITEM_BIRD_TYPES = [
  {
    id: 0,
    type: "sparrow",
    color: "#8D391B",
    description: "80%",
    eggfloorPrice: 10000000000,
    wormfloorPrice: 100000000,
  },
  {
    id: 0,
    type: "penguin",
    color: "#CDCDCD",
    description: "80%",
    eggfloorPrice: 10000000000,
    wormfloorPrice: 100000000,
  },
  {
    id: 1,
    type: "parrot",
    color: "#149712",
    description: "16%",
    eggfloorPrice: 23000000000,
    wormfloorPrice: 200000000,
  },
  {
    id: 2,
    type: "owl",
    color: "#005BC6",
    description: "top 3.4%",
    eggfloorPrice: 73000000000,
    wormfloorPrice: 600000000,
  },
  {
    id: 3,
    type: "hawk",
    color: "#B148E3",
    description: "top 0.2%",
    eggfloorPrice: 529000000000,
    wormfloorPrice: 2450000000,
  },
  {
    id: 4,
    type: "phoenix",
    color: "#DA8F00",
    description: "top 0.05%",
    eggfloorPrice: 5024000000000,
    wormfloorPrice: 23260000000,
  },
  {
    id: 5,
    type: "mythic",
    color: "#C70000",
    description: "top 0.01%",
    eggfloorPrice: 0,
    wormfloorPrice: 0,
  },
];

const Market = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tele = window.Telegram.WebApp;
  tele.BackButton.show();
  tele.BackButton.onClick(() => handleBackBtn());

  const handleBackBtn = () => {
    navigate("/");
  };

  const { account } = useAccount();
  const userID = account?.data?.id || "";

  const [tab, setTab] = useState(inventoryTab.worm);
  const handleChangeTab = (tab: { id: number; title: string }) => {
    setTab(tab.id);
  };

  return (
    <div className="flex flex-col h-[100vh] relative bg-[#F2FFE0] dark:bg-transparent z-10">
      <ToastContainer
        position="top-left"
        closeOnClick
        transition={Slide}
        hideProgressBar
        limit={1}
        stacked
        className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
      />
      {/* <div className="text-[#1B3000] flex  text-sm font-extrabold mb-4">
        {tabButtons.map((btn) => (
          <button
            key={btn.id}
            onClick={() => handleChangeTab(btn)}
            className={clsx(
              "px-5 w-[33.33%] h-[48px] relative",
              tab === btn.id && "text-[#149712]",
              btn.id === inventoryTab.bird
                ? "pointer-events-none text-[#1B300080]"
                : "",
            )}
          >
            {btn.title}
            {tab === btn.id && (
              <motion.div
                className="flex items-center justify-center rounded-[38px] border-[2px] border-[#1B3000] absolute inset-0 bg-transparent bg-white"
                layoutId="tabButton"
              />
            )}
          </button>
        ))}
      </div> */}
      <div className="text-[#325900] mx-4 mb-4 flex items-center overflow-hidden font-extrabold mt-3 bg-[#F6F6F6] rounded-[38px] border-[1px] border-[#1B3000]">
        {tabButtons?.map((btn, index) => (
          <div
            key={btn.id}
            onClick={() => handleChangeTab(btn)}
            className={clsx(
              "px-5 w-[33.33%] h-[48px] relative cursor-pointer flex items-center justify-center",
              index === 2 ? "pointer-events-none text-[#1B300080]" : "",
            )}
          >
            <span className="z-10">
              {btn.title === "Birds" ? "Others" : btn.title}
            </span>
            {tab === btn.id && (
              <motion.div
                className="flex items-center justify-center rounded-[38px] border-[2px] border-[#1B3000] absolute inset-0 bg-[#FFFFFF]"
                layoutId="tabButton"
              />
            )}
          </div>
        ))}
      </div>
      {tab === inventoryTab.egg && (
        <EggMarket userID={userID} location={location} />
      )}
      {tab === inventoryTab.worm && (
        <WormMarket userID={userID} location={location} />
      )}
    </div>
  );
};

export default Market;
