import React from "react";

const DisableTicket = () => {
  return (
    <img
      className="h-[33px] w-[34px]"
      src="images/lucky_spin/disable_ticket.png"
      alt=""
    ></img>
  );
};

export default DisableTicket;
