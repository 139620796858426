import React from "react";

const DisableSellIc = () => {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 7.5001V10.5251C28 12.5001 26.75 13.7501 24.775 13.7501H20.5V5.0126C20.5 3.6251 21.6375 2.4876 23.025 2.5001C24.3875 2.5126 25.6375 3.0626 26.5375 3.9626C27.4375 4.8751 28 6.1251 28 7.5001Z"
        fill="#292D32"
      />
      <path
        d="M3 8.75V26.25C3 27.2875 4.175 27.875 5 27.25L7.1375 25.65C7.6375 25.275 8.3375 25.325 8.7875 25.775L10.8625 27.8625C11.35 28.35 12.15 28.35 12.6375 27.8625L14.7375 25.7625C15.175 25.325 15.875 25.275 16.3625 25.65L18.5 27.25C19.325 27.8625 20.5 27.275 20.5 26.25V5C20.5 3.625 21.625 2.5 23 2.5H9.25H8C4.25 2.5 3 4.7375 3 7.5V8.75Z"
        fill="#A9ABAD"
      />
      <path
        d="M15.5 15.3252H11.75C11.2375 15.3252 10.8125 15.7502 10.8125 16.2627C10.8125 16.7752 11.2375 17.2002 11.75 17.2002H15.5C16.0125 17.2002 16.4375 16.7752 16.4375 16.2627C16.4375 15.7502 16.0125 15.3252 15.5 15.3252Z"
        fill="#292D32"
      />
      <path
        d="M11.75 12.2002H15.5C16.0125 12.2002 16.4375 11.7752 16.4375 11.2627C16.4375 10.7502 16.0125 10.3252 15.5 10.3252H11.75C11.2375 10.3252 10.8125 10.7502 10.8125 11.2627C10.8125 11.7752 11.2375 12.2002 11.75 12.2002Z"
        fill="#292D32"
      />
      <path
        d="M7.9624 10.0127C7.2624 10.0127 6.7124 10.5752 6.7124 11.2627C6.7124 11.9502 7.2749 12.5127 7.9624 12.5127C8.6499 12.5127 9.2124 11.9502 9.2124 11.2627C9.2124 10.5752 8.6499 10.0127 7.9624 10.0127Z"
        fill="#292D32"
      />
      <path
        d="M7.9624 15.0127C7.2624 15.0127 6.7124 15.5752 6.7124 16.2627C6.7124 16.9502 7.2749 17.5127 7.9624 17.5127C8.6499 17.5127 9.2124 16.9502 9.2124 16.2627C9.2124 15.5752 8.6499 15.0127 7.9624 15.0127Z"
        fill="#292D32"
      />
    </svg>
  );
};

export default DisableSellIc;
