import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function claimGuildReward() {
  return await api.post("/api/v1/guild/user-benefit-claim");
}

export default function useClaimGuildReward() {
  return useMutation({
    mutationKey: ["claimGuildReward"],
    mutationFn: claimGuildReward,
  });
}
