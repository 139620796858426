import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function openBox(id: string) {
  return await api.post(`/api/v1/iap/boxes/${id}/open`);
}

export default function useOpenBox() {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["openBox"],
    mutationFn: openBox,
  });

  return { openBoxAsync: mutateAsync, isOpeningBox: isPending };
}
