import StartBtn from "../StartBtn";
import { navPaths } from "../../../../constants/navbar.constants";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

const DailySection = () => {
  const navigate = useNavigate();
  return (
    <div className={clsx("mb-3 rounded-2xl")}>
      <div className="pb-[10px] font-semibold flex items-center justify-start gap-1">
        <div>
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.87725 0.982442C7.07762 0.339185 7.92238 0.339187 8.12275 0.982441L9.10026 4.12059C9.3167 4.81545 9.94621 5.30564 10.6778 5.30564H13.841C14.1468 5.30564 14.3744 5.50101 14.4638 5.78808C14.5536 6.07647 14.4808 6.39146 14.219 6.58991L11.6598 8.5294C11.0812 8.9679 10.8487 9.73374 11.0642 10.4256L12.0417 13.5638C12.1472 13.9025 12.0177 14.1982 11.7969 14.3656C11.5792 14.5306 11.2953 14.5583 11.0411 14.3656L8.48192 12.4261C7.89791 11.9835 7.1021 11.9835 6.51808 12.4261L3.95893 14.3656C3.70472 14.5583 3.42083 14.5306 3.20311 14.3656C2.98227 14.1982 2.85277 13.9025 2.95827 13.5638L3.93578 10.4256C4.15129 9.73374 3.9188 8.9679 3.34019 8.5294L0.781044 6.58991C0.519179 6.39146 0.446377 6.07647 0.536209 5.78808C0.625629 5.50101 0.853247 5.30564 1.15896 5.30564H4.32224C5.05379 5.30564 5.6833 4.81545 5.89974 4.12059L6.87725 0.982442Z"
              stroke="black"
            />
          </svg>
        </div>
        <p className="text-[18px]">Daily Task</p>
      </div>
      <div
        className={clsx(
          "z-10 relative cursor-pointer grid grid-cols-12 gap-3 bg-white rounded-xl p-3 w-full ",
          "border-[1px] border-[#F3F3F3] border-solid"
        )}
      >
        <div className="col-span-2 flex items-center">
          <div>
            <img
              className="size-10"
              src={`/images/icons/daily.png`}
              alt="storage"
            ></img>
          </div>
        </div>
        <div className="col-span-6 flex items-center">
          <div className="">
            <p className="font-medium text-sm">Login Bonus</p>
            {/* <div className="flex items-center gap-1">
              +
              <img
                className="size-[13px]"
                src="/images/icons/token_icon.png?v=3"
                alt="token"
              ></img>
              <p className=" text-[12px] font-semibold">
                {formatNumberUS(0.555)}
              </p>
            </div> */}
          </div>
        </div>
        <div className="col-span-4 flex items-center justify-end">
          <StartBtn onClick={() => navigate(navPaths.STREAK)}>Start</StartBtn>
        </div>
      </div>
    </div>
  );
};

export default DailySection;
