import { bonusesWormBgColor, bonusesWormBorderColor } from "../constants";
import clsx from "clsx";

const OpeningPackages = ({
  packages,
  handleBuy,
  isBuyingBox,
}: {
  packages: any;
  handleBuy: (id: string) => void;
  isBuyingBox: boolean;
}) => {
  const renderRarity = (id: string) => {
    if (id === "iap_box__silver") {
      return (
        <>
          <p>Uncommon: 50%</p>
          <p>Rare: 40%</p>
          <p>Epic: 10%</p>
        </>
      );
    }
    if (id === "iap_box__golden") {
      return (
        <>
          <p>Rare: 50%</p>
          <p>Epic: 40%</p>
          <p>Legend: 10%</p>
        </>
      );
    }
    if (id === "iap_box__diamond") {
      return (
        <>
          <p>Epic: 50%</p>
          <p>Legend: 50%</p>
        </>
      );
    }
  };

  return (
    <>
      {packages
        .filter(
          (item: any) => item.limit > item.purchased || item.global_limit === 0
        )
        .sort((a: any, b: any) => a.sort - b.sort)
        .map((item: any) => {
          return (
            <div key={item.id} className="mb-[40px] ">
              <div
                className={clsx(
                  "border-[2px] border-[#4C7E0B] rounded-[16px] py-4 px-3 relative h-[165px] bg-[#F2FFE0]",
                  "grid grid-cols-12"
                )}
                style={{
                  boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.22) inset",
                }}
              >
                <div className="col-span-5 flex flex-col items-center">
                  <img
                    src={`images/purchase/${item.id}.png`}
                    className="w-[96px] h-[96px]"
                    alt=""
                  ></img>
                  {item.global_limit !== 0 && (
                    <div className="py-[2px] mt-[6px] bg-[#5B6251] text-[12px] rounded-[16px] text-center text-[#fff]">
                      {`${item.global_purchased}/${item.global_limit}`}
                    </div>
                  )}
                  <p className="font-spicy-rice-regular text-center text-[20px] text-[#4C7E0B]">
                    {item.name}
                  </p>
                </div>
                <div className="col-span-7">
                  <div className="flex items-center">
                    <div className="flex items-end dark:text-[#4C7E0B]">
                      <img
                        src="images/purchase/mystery_egg.png"
                        className="w-[54px] h-[68px]"
                        alt=""
                      ></img>
                      <p className="font-spicy-rice-regular -translate-x-4">
                        {`x${item.quantity}`}
                      </p>
                    </div>
                    <div className="text-[14px] text-[#4C7E0B] font-medium">
                      {renderRarity(item.id)}
                    </div>
                  </div>
                  {item.worm_bonuses?.length > 0 ? (
                    <div className="pl-2 mt-2">
                      <div className="flex gap-3 items-center">
                        {item.worm_bonuses?.map((worm: any) => {
                          return (
                            <div
                              className={clsx(
                                "flex items-end w-[38px] h-[38px] rounded-[8px] relative",
                                "border-[2px] p-[7px] dark:text-[#4C7E0B]",
                                worm.type === "legendary"
                                  ? "bg-gradient-to-bl from-[#FDF24499] to-[#FF910899]"
                                  : ""
                              )}
                              style={{
                                backgroundColor: bonusesWormBgColor[worm.type],
                                borderColor: bonusesWormBorderColor[worm.type],
                              }}
                            >
                              <img
                                src={`images/inventory/worm/${worm.type}.png`}
                                className="w-[23px] h-[23px]"
                                alt=""
                              ></img>
                              <p className="absolute -bottom-2 -right-2 font-spicy-rice-regular translate-y-1">
                                {`x${worm.amount}`}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="flex gap-2 items-center">
                        <img
                          src="images/purchase/mystery_egg.png"
                          className="w-[54px] h-[68px]"
                          alt=""
                        ></img>
                        <p className="font-medium text-sm text-[#4C7E0B]">
                          Opening the gift box will reveal any egg
                        </p>
                      </div>
                    </>
                  )}
                </div>

                <button
                  disabled={
                    isBuyingBox ||
                    (item.limit !== 0 && item.purchased === item.limit)
                  }
                  onClick={() => {
                    handleBuy(item.id);
                  }}
                  className={clsx(
                    "border-[2px]  w-[160px] border-[#4C7E0B] rounded-[8px] py-[9px] px-4 absolute right-4 -bottom-6 bg-[#F2FFE0]",
                    "flex justify-center gap-[2px] text-sm font-semibold text-[#4C7E0B]"
                  )}
                >
                  {item.limit !== 0 && item.purchased === item.limit ? (
                    <p>Limit reached</p>
                  ) : (
                    <div className="flex justify-center gap-[2px]">
                      <p className="font-semibold">Purchase</p>
                      <div>
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.0702 0.879808C8.37436 0.167017 9.38281 0.161608 9.6946 0.871095L11.4832 4.94113C11.6852 5.40082 12.1123 5.72188 12.61 5.78818L17.0426 6.37859C17.8002 6.4795 18.0823 7.42861 17.5029 7.92699L14.1838 10.7817C13.7712 11.1366 13.5942 11.694 13.7265 12.2219L14.8865 16.8494C15.0809 17.625 14.2287 18.2404 13.5536 17.8119L9.64309 15.3299C9.17263 15.0313 8.57205 15.0313 8.10159 15.3299L4.38868 17.6865C3.68911 18.1305 2.81755 17.4558 3.07205 16.6673L3.90358 14.091C4.22568 13.0931 4.90911 12.2515 5.81974 11.7315L8.61184 10.1371C8.82285 10.0166 8.71358 9.69336 8.47274 9.72561L5.66899 10.1011C4.58504 10.2462 3.48717 9.96331 2.60839 9.31233L0.7472 7.9336C0.109364 7.46111 0.367916 6.4527 1.15441 6.3454L5.21298 5.79169C5.71433 5.72329 6.14286 5.39648 6.34145 4.93109L8.0702 0.879808Z"
                            fill="url(#paint0_linear_5802_5141)"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_5802_5141"
                              x1="13.375"
                              y1="2.125"
                              x2="4"
                              y2="17.75"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#FCFF4C" />
                              <stop offset="1" stopColor="#FE8B04" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                      <p className="font-extrabold">{item.price}</p>
                    </div>
                  )}
                </button>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default OpeningPackages;
