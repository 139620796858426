/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@mui/material";
import clsx from "clsx";
import Modal from "../../../components/common/Modal";
import Loading from "../../../components/common/Loading";
import { useCheckMission } from "../Hooks/useDoWaterMission";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { navPaths } from "../../../constants/navbar.constants";

interface ModalPropsType {
  isPending: boolean;
  data: any;
  closeModal: () => void;
  handleOpenDailyMission: () => void;
  closeWaterMissionModal: () => void;
  reFetch: () => void;
}

const WaterMissionsModal = ({
  data,
  closeModal,
  handleOpenDailyMission,
  closeWaterMissionModal,
  reFetch,
}: ModalPropsType) => {
  const navigate = useNavigate();
  const tele = window.Telegram.WebApp;
  const { checkMission, isLoading } = useCheckMission();

  const handleSubmitMission = (item: any) => {
    if (!item?.task_user?.completed) {
      if (item.type === "like and retweet") {
        tele.openLink(item.metadata.url);
        checkMission(item)
          .then(async (data) => {
            if (data?.data === "Mission completed") {
              toast.success(data.data, {
                style: { maxWidth: 337, height: 40, borderRadius: 8 },
                autoClose: 2000,
              });
              reFetch();
              return;
            }

            if (data?.data === "incomplete task") {
              toast.error("Incomplete mission", {
                style: { maxWidth: 337, height: 40, borderRadius: 8 },
                autoClose: 2000,
              });
            }
          })
          .catch((err) => {
            toast.error(
              String(err?.response?.data?.message).replace("task", "mission"),
              {
                style: { maxWidth: 337, height: 40, borderRadius: 8 },
                autoClose: 2000,
              }
            );
          });
        return;
      }
      if (item.type === "check-in") {
        checkMission(item)
          .then(async (data) => {
            if (data?.data === "Mission completed") {
              toast.success(data.data, {
                style: { maxWidth: 337, height: 40, borderRadius: 8 },
                autoClose: 2000,
              });
              reFetch();
              return;
            }

            if (data?.data === "incomplete task") {
              handleOpenDailyMission();
            }
            toast.error("Incomplete mission", {
              style: { maxWidth: 337, height: 40, borderRadius: 8 },
              autoClose: 2000,
            });
            return;
          })
          .catch((err) => {
            toast.error(
              String(err?.response?.data?.message).replace("task", "mission"),
              {
                style: { maxWidth: 337, height: 40, borderRadius: 8 },
                autoClose: 2000,
              }
            );
          });
        return;
      }
      if (item.type === "refer") {
        checkMission(item)
          .then(async (data) => {
            if (data?.data === "Mission completed") {
              toast.success(data.data, {
                style: { maxWidth: 337, height: 40, borderRadius: 8 },
                autoClose: 2000,
              });
              reFetch();
              return;
            }
            if (data?.data === "incomplete task") {
              navigate(navPaths.FRIENDS);
            }
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message, {
              style: { maxWidth: 337, height: 40, borderRadius: 8 },
              autoClose: 2000,
            });
          });
        return;
      }
    }
  };

  const isNotDone = data?.filter(
    (item: any) =>
      item.task_user === null || item.task_user.repeats < item.repeats
  );

  return (
    <>
      <Modal closeModal={closeModal}>
        <div className="w-full flex flex-col h-[calc(100%-32px)]">
          <div className=" w-full">
            <button
              onClick={closeWaterMissionModal}
              className={clsx(
                "absolute left-0 top-0 text-xl flex items-center justify-center text-[25px]",
                "capitalize font-bold text-white rounded-xl w-8 h-8 p-0",
                "bg-[#61A700]"
              )}
            >
              <i className="bx bx-chevron-left"></i>
            </button>
            <div className="flex flex-col items-center ">
              <p className="text-[24px] font-bold">{`${isNotDone?.length} Missions Available`}</p>
              <p className="text-center text-[15px]">
                Each mission will give you new levels. You can complete the
                missions in any order.
              </p>
            </div>
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <div className="pt-[20px] max-h-[340px] flex-1 overflow-auto">
              {data
                ?.sort((a: any, b: any) => {
                  const isACompleted =
                    a.task_user !== null && a.task_user.repeats >= a.repeats;
                  const isBCompelted =
                    b.task_user !== null && b.task_user.repeats >= b.repeats;
                  if (!isACompleted && isBCompelted) {
                    return -1; // a comes first if it has null value
                  } else if (isACompleted && !isBCompelted) {
                    return 1; // b comes first if it has null value
                  } else {
                    return 0; // otherwise, maintain current order
                  }
                })
                .map((item: any, index: number) => {
                  return (
                    <button
                      disabled={isLoading}
                      key={item.id}
                      onClick={() => {
                        handleSubmitMission(item);
                      }}
                      rel="noreferrer"
                      className={clsx("text-center relative w-full mt-1")}
                    >
                      {item.task_user != null &&
                        (item.task_user.repeats || 0 >= item.repeats) && (
                          <div
                            className={clsx(
                              "flex items-center justify-center absolute right-4 -top-4 z-20"
                            )}
                          >
                            <img
                              src="/images/holy/check_mark.png"
                              className="w-[29px] h-[31px]"
                              alt=""
                            ></img>
                          </div>
                        )}
                      <div
                        key={index}
                        className={clsx(
                          "z-10 py-3 px-4 relative cursor-pointer grid grid-cols-12 gap-3 bg-white rounded-2xl p-4 w-full mb-[18px] ",
                          item.task_user?.repeats || 0 >= item.repeats
                            ? "border-[1px] border-solid border-[#000] drop-shadow-none brightness-50"
                            : "border-[1px] border-[#4D7F0C] border-solid drop-shadow-[0_4px_0px_#4D7F0C]"
                        )}
                      >
                        <div className="col-span-10 flex">
                          <div className=" flex items-center mr-4">
                            <img
                              src={`/images/holy/${item.type}.png`}
                              className="w-8 h-8"
                              alt=""
                            ></img>
                          </div>

                          <div className=" flex items-center justify-start text-start text-[15px]">
                            {item.name}
                          </div>
                        </div>
                      </div>
                    </button>
                  );
                })}
            </div>
          )}

          <div className="pt-3 pb-1 h-[90px]">
            <Button
              onClick={closeModal}
              className={clsx(
                "capitalize w-full font-bold text-white py-[14px] rounded-[38px]",
                "bg-[#61A700]"
              )}
            >
              Got it
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default WaterMissionsModal;
