import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useState } from "react";

const PriceSelecter = ({ changePriceSort }: { changePriceSort: any }) => {
  const [value, setValue] = useState("LATEST");

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
    changePriceSort(event.target.value);
  };
  return (
    <FormControl fullWidth>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={handleChange}
        sx={{
          border: "2px solid #A4BC82",
          borderRadius: "8px",
          "&:hover": {
            "&& fieldset": {
              border: "none",
            },
          },

          boxShadow: "none",
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: "none",
            },
        }}
        className="w-full h-[45px] bg-white"
      >
        <MenuItem value={"LATEST"}>Latest</MenuItem>
        <MenuItem value={"DESC"}>Highest price</MenuItem>
        <MenuItem value={"ASC"}>Lowest price</MenuItem>
      </Select>
    </FormControl>
  );
};

export default PriceSelecter;
