/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";

const Feather = ({ number, type }: any) => {
  return (
    <>
      <img
        className={clsx(`feather-fall-${number + 1}`, "w-[50px] h-[30px] ")}
        src={`/images/bird/feather/${type}.png?v=5`}
        alt=""
      ></img>
    </>
  );
};

const Feathers = ({ type }: { type: string }) => {
  return (
    <>
      {[...Array(2)].map((item, index) => {
        return <Feather type={type} key={index} number={index}></Feather>;
      })}
    </>
  );
};

export default Feathers;
