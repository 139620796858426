import LoadingButton from "@mui/lab/LoadingButton";
import clsx from "clsx";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { Slide, toast, ToastContainer } from "react-toastify";
import ModalContainer from "../../../components/common/ModalContainer";
import { useBirdStore } from "../../../store/birdStore";
import ChooseBirdModal from "../Components/ChooseBirdModal";
import HistoryReleaseBird from "../Components/HistoryReleaseBird";
import ReleaseModal from "../Components/ReleaseModal";
import ReleaseSuccessModal from "../Components/ReleaseSuccessModal";
import useReleaseBird from "../Hooks/useReleaseBird";

const BirdCage = () => {
  const navigate = useNavigate();

  const tele = window.Telegram.WebApp;
  tele.BackButton.show();
  tele.BackButton.onClick(() => navigate("/"));

  const birdInCage = useBirdStore((state: any) => state.birdInCage);
  const setBirdInCage = useBirdStore((state: any) => state.setBirdInCage);

  const { releaseBirdData, mutateReleaseBird, isLoading } = useReleaseBird({
    bird_id: birdInCage?.id,
  });
  const [showBirdCageModal, setShowBirdCageModal] = useState<boolean>(false);
  const [showReleaseModal, setShowReleaseModal] = useState<boolean>(false);
  const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);

  const handleShowHistory = () => setShowHistoryModal(true);
  const handleChooseBird = () => setShowBirdCageModal(true);
  const handleShowReleaseModal = () => setShowReleaseModal(true);

  const [showReleaseSuccessModal, setShowReleaseSuccessModal] =
    useState<boolean>(false);

  const handleReleaseBird = () => {
    mutateReleaseBird().catch((err) => {
      const msg = err?.response?.data?.message === 'bird is not in the inventory' ? 'Bird is hunting. Please wait to release' : (err?.response?.data?.message || 'Unknown error')
      toast.error(msg, {
        style: { maxWidth: 337, height: 40, borderRadius: 8 },
        autoClose: 2000,
      });
    });
  };

  useEffect(() => {
    return () => {
      setBirdInCage(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (releaseBirdData?.error) {
      toast.error(releaseBirdData?.error, {
        style: { maxWidth: 337, height: 40, borderRadius: 8 },
        autoClose: 2000,
        toastId: "release-bird",
      });
    }
    if (releaseBirdData?.data) {
      setBirdInCage(null);
      setShowReleaseSuccessModal(true);
      setShowReleaseModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseBirdData]);

  return (
    <div className="bg-[#f1ffe0] h-screen relative z-[99]">
      <ToastContainer
        position="top-left"
        closeOnClick
        transition={Slide}
        hideProgressBar
        limit={1}
        stacked
        className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
      />
      <div className="px-[14px] py-5">
        <div
          className={clsx(
            "px-4 flex flex-col gap-5 pb-32 overflow-y-scroll",
            isMobile ? "h-screen lsm:h-auto" : "h-screen",
          )}
        >
          <div className="flex items-center justify-between">
            <span>
              Join the <span className="font-[800]">#FreeDurov</span> Movement:
              <br />
              Release for Freedom!
            </span>
            <div onClick={handleShowHistory} className="cursor-pointer">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 0C18.6094 0 24 5.39062 24 12C24 18.6562 18.6094 24 12 24C9.51562 24 7.26562 23.2969 5.34375 22.0312C4.82812 21.7031 4.6875 21 5.01562 20.4844C5.39062 19.9688 6.09375 19.8281 6.60938 20.1562C8.15625 21.1875 9.98438 21.75 12 21.75C17.3438 21.75 21.75 17.3906 21.75 12C21.75 6.65625 17.3438 2.25 12 2.25C8.53125 2.25 5.48438 4.07812 3.75 6.75H6.375C6.98438 6.75 7.5 7.26562 7.5 7.875C7.5 8.53125 6.98438 9 6.375 9H1.125C0.46875 9 0 8.53125 0 7.875V2.625C0 2.01562 0.46875 1.5 1.125 1.5C1.73438 1.5 2.25 2.01562 2.25 2.625V5.01562C4.40625 2.01562 7.96875 0 12 0ZM12 6C12.6094 6 13.125 6.51562 13.125 7.125V11.5781L16.125 14.5781C16.5938 15.0469 16.5938 15.75 16.125 16.1719C15.7031 16.6406 15 16.6406 14.5781 16.1719L11.2031 12.7969C10.9688 12.6094 10.875 12.3281 10.875 12V7.125C10.875 6.51562 11.3438 6 12 6Z"
                  fill="#203900"
                />
              </svg>
            </div>
          </div>

          <motion.div
            initial={{ opacity: 0, scale: 0.5, y: -500 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.5, type: "spring" }}
            onClick={handleChooseBird}
            className="flex items-center justify-center w-full cursor-pointer relative"
          >
            <img
              alt=""
              className="w-[70%]"
              src="/images/bird-cage/bird_cage.png"
            />
            <img
              alt=""
              className={clsx(
                "absolute inset-0 mx-auto mt-auto mb-[21%]",
                birdInCage ? "w-[28%]" : "w-[25%]",
              )}
              src={
                birdInCage
                  ? `/images/bird/${birdInCage?.type}.png`
                  : "/images/bird-cage/no_bird_incage.png"
              }
            />
          </motion.div>

          <div className="text-sm text-[#545454] flex flex-col gap-3">
            <p>
              In support of digital freedom, we invite you to symbolically
              release Birds as part of campaign{" "}
              <span className="font-[800]">#FreeDurov</span>.
            </p>
            {/* <p>
              This simple act represents our shared hope for a world where
              leaders like Durov are free to innovate without restrictions.
            </p> */}
            <p>
              You will get the "
              <span className="font-[800]">Wings of Freedom</span>" badge & have
              a <span className="font-[800]">15%</span>. chance of receiving a{" "}
              <span className="font-[800]">Next-level Egg</span> for your
              meaningful contribution.
            </p>
          </div>

          {isDesktop && (
            <div className="absolute left-0 right-0 bottom-0 flex items-center pt-10 pb-7 bg-gradient-to-t from-[#f1ffe0] from-70% to-transparent">
              <LoadingButton
                onClick={handleShowReleaseModal}
                disabled={!birdInCage}
                loading={false}
                className={clsx(
                  "text-[16px] w-fit font-[800] text-white py-[12px] px-5 rounded-xl normal-case mx-auto",
                  "btn-click-no-shadow bg-gradient-to-r from-[#97C35B] to-[#61A700] shadow-[0_4px_0px_#4C7E0B]",
                  "disabled:shadow-[0_4px_0px_#4E4E4E] disabled:text-[#F3F3F3]",
                )}
              >
                {"Release for Freedom"}
              </LoadingButton>
            </div>
          )}

          {isMobile && (
            <>
              <div className="absolute left-0 right-0 bottom-0 flex lsm:hidden items-center pt-10 pb-7 bg-gradient-to-t from-[#f1ffe0] from-70% to-transparent">
                <LoadingButton
                  onClick={handleShowReleaseModal}
                  disabled={!birdInCage}
                  loading={false}
                  className={clsx(
                    "text-[16px] w-fit font-[800] text-white py-[12px] px-5 rounded-xl normal-case mx-auto",
                    "btn-click-no-shadow bg-gradient-to-r from-[#97C35B] to-[#61A700] shadow-[0_4px_0px_#4C7E0B]",
                    "disabled:shadow-[0_4px_0px_#4E4E4E] disabled:text-[#F3F3F3]",
                  )}
                >
                  {"Release for Freedom"}
                </LoadingButton>
              </div>

              <LoadingButton
                onClick={handleShowReleaseModal}
                disabled={!birdInCage}
                loading={false}
                className={clsx(
                  "hidden lsm:flex text-[16px] w-fit font-[800] text-white py-[12px] px-5 rounded-xl normal-case mx-auto",
                  "btn-click-no-shadow bg-gradient-to-r from-[#97C35B] to-[#61A700] shadow-[0_4px_0px_#4C7E0B]",
                  "disabled:shadow-[0_4px_0px_#4E4E4E] disabled:text-[#F3F3F3]",
                )}
              >
                {"Release for Freedom"}
              </LoadingButton>
            </>
          )}
        </div>

        <ModalContainer>
          {showBirdCageModal && (
            <ChooseBirdModal setShowBirdCageModal={setShowBirdCageModal} />
          )}
        </ModalContainer>

        <ModalContainer>
          {showReleaseModal && !showReleaseSuccessModal && (
            <ReleaseModal
              setShowReleaseModal={setShowReleaseModal}
              handleReleaseBird={handleReleaseBird}
              isLoading={isLoading}
            />
          )}
        </ModalContainer>

        <ModalContainer>
          {showReleaseSuccessModal && (
            <ReleaseSuccessModal
              releaseBirdData={releaseBirdData}
              setShowReleaseSuccessModal={setShowReleaseSuccessModal}
            />
          )}
        </ModalContainer>

        <ModalContainer>
          {showHistoryModal && (
            <HistoryReleaseBird setShowHistoryModal={setShowHistoryModal} />
          )}
        </ModalContainer>
      </div>
    </div>
  );
};

export default BirdCage;
