import { LoadingButton } from "@mui/lab";
import clsx from "clsx";
import { useEffect, useMemo, useRef, useState } from "react";
import FoodInfoModal from "../Components/FoodInfoModal";
import { useClickOutside } from "../../../Hooks/useClickOutside";
import { useNavigate } from "react-router-dom";
import useGetMyWorms from "../Hooks/useGetMyWorms";
import LoadingScreen from "../../Global/Pages/LoadingScreen";
import FeedingItemList from "../Components/FeedingItemList";
import { navPaths } from "../../../constants/navbar.constants";
import { motion } from "framer-motion";
import useFeedBird from "../Hooks/useFeedBird";
import { useBirdStore } from "../../../store/birdStore";
import { Slide, toast, ToastContainer } from "react-toastify";
import useGetSetting from "../../../Hooks/useGetSetting";
import { formatDecimals } from "../../../utils/formatNumber";
import { sleep } from "../../../utils/time";
import useGetLeaderBird from "../Hooks/useGetLeaderBirdInfo";

const FeedBird = () => {
  const tele = window.Telegram.WebApp;
  const navigate = useNavigate();
  const StoredBird = useBirdStore((state: any) => state.bird);
  const setStoreBird = useBirdStore((state: any) => state.setBirddItem);

  const { data, isPending: isLoadingSetting } = useGetSetting();
  const { myWorms, isLoadingMyWorms, refreshMyWorms } = useGetMyWorms({
    page: 1,
  });
  const { leaderBird, isLoadingLeaderBird, refreshLeaderBird } =
    useGetLeaderBird({
      id: StoredBird?.id,
    });
  const { feedBirdAsync, isFeedingBird } = useFeedBird();

  const [isShowFoodModal, setIsShowFoodModal] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<any>({
    common: 0,
    uncommon: 0,
    rare: 0,
    epic: 0,
    legendary: 0,
  });

  const [nextFillPercentage, setNextFillPercentage] = useState<number>();
  const [fillValue, setFillValue] = useState<number>(0);

  const foodInfoModalRef = useRef<any>();
  const currentFillRef = useRef<any>();
  const nextFillRef = useRef<any>();

  tele.BackButton.show();
  tele.BackButton.onClick(() => navigate(navPaths.BIRD));

  useClickOutside(foodInfoModalRef, () => {
    setIsShowFoodModal(false);
  });

  const EnergyRate = data?.data?.data?.[`transfer-worm-to-energy`];
  const MaxEnergy = formatDecimals(leaderBird?.data?.energy_max);
  const CurrentEnergy = formatDecimals(leaderBird?.data?.energy_level);
  const currentPercentage = (CurrentEnergy / Number(MaxEnergy)) * 100;

  const isAbleToFeed = useMemo(() => {
    return CurrentEnergy !== MaxEnergy;
  }, [CurrentEnergy, MaxEnergy]);

  useEffect(() => {
    if (!currentFillRef.current) {
      return;
    }

    currentFillRef.current.style.width =
      (CurrentEnergy / Number(MaxEnergy)) * 100 + "%";
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!nextFillRef.current) {
      return;
    }

    const nextFillValue = Object.keys(quantity).reduce((acc, key) => {
      return acc + quantity[key] * formatDecimals(EnergyRate?.[key]);
    }, 0);
    const nextFillPercentage = (nextFillValue / Number(MaxEnergy)) * 100;

    const totalEnergyPercentage = currentPercentage + nextFillPercentage;
    setFillValue(nextFillValue);

    if (totalEnergyPercentage > 100) {
      nextFillRef.current.style.width = "100%";
      setNextFillPercentage(100);
      return;
    }

    nextFillRef.current.style.width = Math.ceil(totalEnergyPercentage) + "%";
    setNextFillPercentage(Math.ceil(totalEnergyPercentage));
  });

  const handleChangeQuantity = (item: any, action: string) => {
    if (action === "minus") {
      quantity[item.type] > 0 &&
        setQuantity((prevClicks: any) => ({
          ...prevClicks,
          [item.type]: prevClicks[item.type] - 1,
        }));
      return;
    }

    quantity[item.type] < item.quantity &&
      setQuantity((prevClicks: any) => ({
        ...prevClicks,
        [item.type]: prevClicks[item.type] + 1,
      }));
  };

  const handleFeedBird = (feedWith: string) => {
    const wormsByType: any[] = [];
    Object.keys(quantity).forEach((key: string) => {
      const ids = myWorms?.data?.items?.filter(
        (item: any) => item.type === key
      );
      wormsByType.push(ids.splice(0, quantity[key]));
    });
    const wormIds = wormsByType.flat().map((item: any) => item.id);

    const FeedingSeed =
      (1 / EnergyRate?.["seed"]) * Number(MaxEnergy) - CurrentEnergy;

    const requestBody =
      feedWith === "worm"
        ? {
            bird_id: leaderBird?.data?.id,
            worm_ids: wormIds,
          }
        : {
            bird_id: leaderBird?.data?.id,
            seed_amount: Number((FeedingSeed * 10 ** 9).toFixed(0)),
          };

    feedBirdAsync(requestBody)
      .then(async (data) => {
        setStoreBird(data?.data?.data);
        toast.error("Feed bird successfully", {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
        });

        if (feedWith === "worm") {
          await sleep(500);
          refreshMyWorms();
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
          toastId: "catchFail",
        });
      })
      .finally(async () => {
        await sleep(500);
        refreshLeaderBird();
      });
  };

  return (
    <>
      {isLoadingMyWorms || isLoadingSetting || isLoadingLeaderBird ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer
            position="top-left"
            closeOnClick
            transition={Slide}
            hideProgressBar
            limit={1}
            stacked
            className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
          />
          <div className="bg-[#F2FFE0] h-screen p-4 relative z-10">
            <div className="w-full flex  gap-2 items-center justify-center relative">
              <p className="text-[20px] font-extrabold capitalize">
                {leaderBird?.data?.type}
              </p>
              <button onClick={() => setIsShowFoodModal(true)}>
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.612903"
                    y="0.612903"
                    width="17.7742"
                    height="17.7742"
                    rx="8.8871"
                    stroke="#1B3000"
                    strokeWidth="1.22581"
                  />
                  <path
                    d="M8.55191 11.5701V10.9093C8.54473 9.96119 9.00441 9.31477 9.9884 8.72581C10.8216 8.21585 11.1448 7.76336 11.1448 7.00202V6.98765C11.1448 6.10421 10.4553 5.4506 9.38508 5.4506C8.30053 5.4506 7.61101 6.08984 7.53918 7.10975L7.52482 7.11694L6.3038 7.12412V7.10975C6.38281 5.56552 7.41708 4.33014 9.43535 4.33014C11.2453 4.33014 12.4807 5.4506 12.4807 6.92301V6.93737C12.4807 8.05784 11.8989 8.85509 10.9796 9.40814C10.0674 9.94682 9.80884 10.349 9.80884 11.1032V11.5701H8.55191ZM9.27734 14.9027C8.69556 14.9027 8.27898 14.4861 8.27898 13.9044C8.27898 13.3154 8.69556 12.8988 9.27734 12.8988C9.85912 12.8988 10.2685 13.3154 10.2685 13.9044C10.2685 14.4861 9.85912 14.9027 9.27734 14.9027Z"
                    fill="#1B3000"
                  />
                </svg>
              </button>
            </div>
            <div className="flex justify-center mt-3">
              <div className="w-[80%] h-[10px] relative">
                <div
                  style={{ width: `calc(${currentPercentage}% + 26px)` }}
                  className="absolute flex justify-end z-10"
                >
                  {CurrentEnergy > 0 && (
                    <div className="flex flex-col items-center">
                      <p className="bg-[#61665A] text-[#fff] w-[60px] text-center rounded-xl px-1 py-0">
                        {CurrentEnergy}
                      </p>
                      <img src="images/bird/down_triangle.svg" alt=""></img>
                    </div>
                  )}
                </div>
                {currentPercentage !== nextFillPercentage && (
                  <div
                    style={{ width: `calc(${nextFillPercentage}% + 26px)` }}
                    className="absolute flex justify-end"
                  >
                    <div className="flex flex-col items-center">
                      <p className="bg-[#61665A] text-[#fff] w-[60px] text-center rounded-xl px-1 py-0">
                        {fillValue}
                      </p>
                      <img src="images/bird/down_triangle.svg" alt=""></img>
                    </div>
                  </div>
                )}
                <div className="absolute w-[calc(100%+26px)] flex justify-end">
                  <div className="flex flex-col items-center">
                    <p className="bg-[#61665A] text-[#fff] w-[60px] text-center rounded-xl px-1 py-0">
                      {MaxEnergy}
                    </p>
                    <img src="images/bird/down_triangle.svg" alt=""></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full relative flex-col flex items-center justify-center mt-5 mb-5">
              <div className="flex items-center justify-center absolute left-[30px] -top-[15px] z-40">
                <div
                  className={clsx(
                    "w-[50px] h-[50px] bg-[#E3E3E3] rounded-[50%] border-[2px] border-[#fff] flex items-center justify-center relative overflow-hidden"
                  )}
                >
                  <div className="absolute z-10">
                    <img
                      src="images/bird/food.svg"
                      className=""
                      width={24}
                      height={22}
                      alt=""
                    ></img>
                  </div>
                  <div className="absolute z-0">
                    <img
                      src="images/bird/food_fill.svg"
                      className=""
                      width={43}
                      height={42}
                      alt=""
                    ></img>
                  </div>
                </div>
              </div>

              <div className="relative w-[80%] h-[22px] border-[2.4px] border-[#D9D9D9] overflow-hidden z-10">
                <motion.div
                  animate={{ width: `${currentPercentage}%` }}
                  className="absolute h-[22px] bg-[#6D9833] z-30"
                ></motion.div>
                <div
                  ref={nextFillRef}
                  className="absolute h-[22px] bg-[#97C35B] z-20"
                ></div>
              </div>
            </div>

            <div className="h-[calc(100%-190px)]">
              <FeedingItemList
                feedingRate={EnergyRate}
                quantities={quantity}
                feedingItems={myWorms?.data?.items}
                handleChangeQuantity={(item, action) =>
                  handleChangeQuantity(item, action)
                }
              />
            </div>

            <div className="w-full h-[110px] py-4 flex gap-2">
              <LoadingButton
                disabled={Object.values(quantity).every((value) => value === 0)}
                onClick={() => handleFeedBird("worm")}
                loading={isFeedingBird}
                sx={{ border: 1 }}
                className={clsx(
                  "font-extrabold capitalize text-[16px] py-2 lsm:py-[18px] w-full h-[60px] rounded-xl ",
                  "btn-hover bg-[#F2FFE0] shadow-[0_3px_0px_#4C7E0B] text-[#4C7E0B] border-[2px] border-[#97C35B]",
                  "disabled:border-none disabled:shadow-none"
                )}
              >
                Feed
              </LoadingButton>
              <LoadingButton
                disabled={!isAbleToFeed}
                onClick={() => handleFeedBird("seed")}
                loading={isFeedingBird}
                sx={{ border: 1 }}
                className={clsx(
                  "normal-case text-[16px] text-white py-3 lsm:py-[18px] w-full h-[60px] rounded-xl ",
                  "btn-hover bg-gradient-to-r from-[#97C35B] to-[#61A700] shadow-[0_4px_0px_#4C7E0B] border-none",
                  "disabled:border-none disabled:shadow-none"
                )}
              >
                <div className="font-extrabold">
                  <p>Feed fully</p>
                  <div className="flex items-center justify-center gap-1">
                    <img
                      src="/images/icons/token_icon.png?v=3"
                      width={18}
                      height={18}
                      alt="token"
                    ></img>
                    <p className="text-base">
                      {!isAbleToFeed
                        ? 0
                        : (
                            (1 / EnergyRate?.["seed"]) * Number(MaxEnergy) -
                            CurrentEnergy
                          ).toFixed(2)}
                    </p>
                  </div>
                </div>
              </LoadingButton>
            </div>

            <FoodInfoModal
              rate={EnergyRate}
              ref={foodInfoModalRef}
              isShowModal={isShowFoodModal}
              showModal={setIsShowFoodModal}
              triggerReload={function (): void {
                throw new Error("Function not implemented.");
              }}
              leaderBird={undefined}
              isAbleToFeed={false}
              MaxEnergy={undefined}
              CurrentEnergy={undefined}
            />
          </div>
        </>
      )}
    </>
  );
};

export default FeedBird;
