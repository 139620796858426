import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function upgradeDistributGuild({
  guild_id,
  distribution_rate,
  top_member,
}: {
  guild_id: string;
  top_member: number;
  distribution_rate: number;
}) {
  return await api.post("/api/v1/guild/update-distribute", {
    guild_id,
    top_member,
    distribution_rate,
  });
}

export default function useUpgradeDistributeGuild() {
  return useMutation({
    mutationKey: ["upgradeDistributGuild"],
    mutationFn: upgradeDistributGuild,
  });
}
