import { useMutation } from "@tanstack/react-query";
import { api, fetchApi } from "../../../config/api";
import { ServerError, ServerResponse } from "../../../types/api";
import { Nullable, Result } from "../../../types/utils";
import { Task } from "../../../types/task";
import { useState } from "react";
import { sleep } from "../../../utils/time";

export async function doMissions(id: string) {
  return await api.post(`/api/v1/tasks/${id}`);
}

export default function useDoMissions() {
  return useMutation({
    mutationKey: ["doMissions"],
    mutationFn: doMissions,
    retry: 0,
    // retryDelay: 3000,
  });
}

// V2
const MISSION_CHECK_LIMIT = 3;
const MISSION_CHECK_INTERVAL = 2000;

export const requestCheckMission = async (
  missionId: string,
): Promise<Result<string, ServerError>> => {
  const response = await fetchApi("POST", `/tasks/${missionId}`);
  if (response.error) {
    return {
      error: {
        code: "network",
        message: response.error.message,
      },
    };
  }

  const messageResponse: ServerResponse<string> = await response.data.json();
  if (messageResponse.error) {
    return {
      error: messageResponse.error,
    };
  }

  return {
    data: messageResponse.data,
  };
};

export const requestCheckMissionProgress = async (
  missionMessageId: string,
): Promise<Nullable<any>> => {
  const response = await fetchApi(
    "GET",
    `/tasks/notification/${missionMessageId}`,
  );
  if (response.error || !response.data.ok) {
    // Just ignore network error ?
    return null;
  }

  const progressResponse: ServerResponse<any> = await response.data.json();
  if (progressResponse.error) {
    return null;
  }

  return progressResponse.data;
};

export const useCheckMission = () => {
  const [loading, setLoading] = useState(false);
  // TODO: Change to useMutation for optimistic UI later

  const checkMission = async (
    mission: Pick<Task, "id" | "repeats" | "type">,
  ): Promise<
    Result<
      | "Mission completed"
      | "Incomplete task"
      | "Request timeout"
      | "incomplete task",
      ServerError
    >
  > => {
    // Start checking
    setLoading(true);

    if (mission.type === "ton-wallet-verify-success") {
      for (let i = 0; i < 10; i++) {
        const response = await requestCheckMission(mission.id);
        if (response.error) {
          return {
            error: response.error,
          };
        }

        const messageId = response.data;
        for (let i = 0; i < 6; i++) {
          const missionStatus = await requestCheckMissionProgress(messageId);
          if (missionStatus?.error === "incomplete task" && i < 5) {
            break;
          }
          if (missionStatus == null) {
            await sleep(MISSION_CHECK_INTERVAL);
            continue;
          }
          if (missionStatus?.data?.repeats >= mission.repeats) {
            setLoading(false);
            return {
              data: "Mission completed",
            };
          }

          setLoading(false);
          return {
            data: missionStatus.error,
          };
        }
      }
    } else {
      // Other Tasks

      // Failed to get mission progress check id
      const response = await requestCheckMission(mission.id);
      if (response.error) {
        return {
          error: response.error,
        };
      }

      const CHECK_LIMIT =
        mission?.type === "ton-wallet-verify-success"
          ? 10
          : MISSION_CHECK_LIMIT;
      // Check if mission is done
      const messageId = response.data;
      for (let i = 0; i < CHECK_LIMIT; i++) {
        const missionStatus = await requestCheckMissionProgress(messageId);
        if (missionStatus == null) {
          await sleep(MISSION_CHECK_INTERVAL);
          continue;
        }

        if (missionStatus?.data?.repeats >= mission.repeats) {
          setLoading(false);
          return {
            data: "Mission completed",
          };
        }

        setLoading(false);
        return {
          data: missionStatus.error,
        };
      }
    }

    // Failed to check mission progress
    setLoading(false);
    return { data: "Request timeout" };
  };

  return { isLoading: loading, checkMission };
};
