import { RouteObject } from "react-router-dom";
import { navPaths } from "../../constants/navbar.constants";
import { CreateAccountV2 } from "./Pages/CreateAccountV2";
// import CreatePage from "./Pages/CreateAcount";

export const registerRoute: RouteObject = {
  path: navPaths.REGISTER,
  // Component: CreatePage,
  Component: CreateAccountV2,
};
