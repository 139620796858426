import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";
import { Result } from "../../../types/utils";

export const releaseBird = async (id: {
  bird_id: string;
}): Promise<Result<boolean, string>> => {
  // const response = await fetchApi(
  //   "POST",
  //   `/event-bird-release`,
  //   JSON.stringify(id),
  // );
  // if (response.error) {
  //   return {
  //     error: response.error.message,
  //   };
  // }

  // const releaseBirdResponse: any = await response.data.json();
  // if (releaseBirdResponse.error) {
  //   return {
  //     error: releaseBirdResponse.error.message,
  //   };
  // }

  // if (releaseBirdResponse?.message) {
  //   return {
  //     error: releaseBirdResponse?.message,
  //   };
  // }

  // return {
  //   data: true,
  // };
  return await api.post("/api/v1/event-bird-release", { bird_id: id.bird_id });
};

export default function useReleaseBird(id: { bird_id: string }) {
  const { data, mutateAsync, isPending } = useMutation({
    mutationKey: ["releaseBird"],
    mutationFn: () => releaseBird(id),
  });

  return {
    releaseBirdData: data,
    mutateReleaseBird: mutateAsync,
    isLoading: isPending,
  };
}
