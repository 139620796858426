import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const usePurchaseStore = create(
  persist(
    (set) => ({
      item: 0,
      setPurchasedItem: (item: any) => set({ item: item }),
    }),
    {
      name: "purchased-box-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
