import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerError, ServerResponse } from "../../../types/api";
import { Result } from "../../../types/utils";

export const getMyBirds = async ({
  page,
}: {
  page: number;
}): Promise<Result<any, ServerError>> => {
  const response = await fetchApi("GET", `/bird/me?page=${page}`);
  if (response.error) {
    // Throw to retry
    throw new Error("network-error");
  }

  const myBirdResponse: ServerResponse<any[]> = await response.data.json();
  if (myBirdResponse.error) {
    return {
      error: myBirdResponse.error,
    };
  }

  return {
    data: myBirdResponse.data,
  };
};

export default function useGetMyBirds({ page }: { page: number }) {
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["MyBirds"],
    queryFn: () => getMyBirds({ page }),
    retry: 1,
  });

  return {
    myBirds: data,
    isLoadingMyBirds: isFetching,
    refreshMyBirds: refetch,
    itemBirdPerPage: data?.data?.page_size,
  };
}
