import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function feedBird({
  bird_id,
  worm_ids,
  seed_amount,
}: {
  bird_id: string;
  worm_ids?: string[];
  seed_amount?: number;
}) {
  return await api.post(`/api/v1/bird-feed`, {
    bird_id,
    worm_ids,
    seed_amount,
  });
}

export default function useFeedBird() {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["feedBird"],
    mutationFn: feedBird,
  });

  return { feedBirdAsync: mutateAsync, isFeedingBird: isPending };
}
