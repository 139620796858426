import { formatDateTimeHour } from "../../../utils/time";

const SpinHistory = ({ items }: { items: any }) => {
  return (
    <div className="bg-[#E4F0D3] rounded-[12px] mt-4 p-4 flex flex-col gap-3">
      <p className="font-medium">{`Fusion History (${items?.length})`}</p>
      <div className="max-h-[220px] overflow-auto">
        {items?.map((item: any) => {
          return (
            <div
              key={item.id}
              className="grid grid-cols-12 gap-2 text-sm items-center border-b-[1px] border-[#D7E2C7] py-[6px]"
            >
              <div className="col-span-2">
                <img
                  className="w-[24px] h-[31px]"
                  src={`images/egg/${item.type}.png`}
                  alt=""
                ></img>
              </div>
              <div className="col-span-6 capitalize">
                <p>{`${item.type} egg piece`}</p>
                <p className="text-[12px] uppercase text-[#616161]">{`#${item.id?.slice(
                  item?.id?.length - 9,
                  item?.id?.length
                )}`}</p>
              </div>
              <div className="col-span-4 text-end text-[#616161]">
                {formatDateTimeHour(item?.created_at)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SpinHistory;
