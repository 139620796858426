import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerResponse } from "../../../types/api";
import { Boosts } from "../../../types/boosts";

export const getAcountBoosts = async () => {
  const response = await fetchApi("GET", "/profile/boosts");
  if (response.error) {
    return [];
  }

  const boostsResponse: ServerResponse<Boosts[]> = await response.data.json();
  if (boostsResponse.error) {
    return [];
  }

  return boostsResponse.data;
};

export default function useGetAcountBoosts() {
  const { data, isPending, refetch } = useQuery({
    queryKey: ["AcountBoosts"],
    queryFn: getAcountBoosts,
  });

  return { boosts: data ?? [], boostsLoading: isPending, refetch };
}
