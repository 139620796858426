import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function petBird({ id, rate }: { id: string; rate: number }) {
  return await api.post(`/api/v1/bird-happiness`, {
    bird_id: id,
    happiness_rate: rate,
  });
}

export default function usePetBird() {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["petBird"],
    mutationFn: petBird,
  });

  return { petBirdAsync: mutateAsync, isPettingBird: isPending };
}
