import clsx from "clsx";
import { useState } from "react";
import { missionsTypes } from "../../../../constants/missions.constants";
import {
  formatDecimals,
  getNumberFormatUs,
} from "../../../../utils/formatNumber";
import { twitterUrl } from "../../Utils/missions";
import StartBtn from "../StartBtn";

const RemainMissionSection = ({
  sortedGroups,
  missionGroups,
  handleDoMission,
  isDoingMission,
}: {
  sortedGroups: any;
  missionGroups: any;
  handleDoMission: (id: any) => void;
  isDoingMission: boolean;
}) => {
  const tele = window.Telegram.WebApp;
  const [loadingItem, setLoadingItem] = useState("");

  const areAllMissionsCompleted = (data: any) => {
    return data.missions.every(
      (mission: any) =>
        mission?.task_user !== null && mission?.task_user?.completed
    );
  };

  const groupTasksByGroupName = (tasks: any) => {
    return tasks
      .filter((item: any) => item.metadata.group_name !== "The Open League")
      .reduce((groups: any, task: any) => {
        const groupName = task.metadata.group_name;
        if (!groups[groupName]) {
          groups[groupName] = [];
        }
        groups[groupName].push(task);
        return groups;
      }, {});
  };

  const groupedTasksArray = Object.entries(
    groupTasksByGroupName(sortedGroups)
  ).map(([groupName, missions]: any) => ({
    groupName,
    missions,
    groupOrder: parseInt(missions?.[0].metadata.group_order, 10) || 0,
  }));

  return (
    <>
      {groupedTasksArray
        ?.sort((a, b) => a.groupOrder - b.groupOrder)
        .map((group: any, index: number) => {
          const allMissionsCompleted = areAllMissionsCompleted(group);
          if (allMissionsCompleted || group?.name === "Ton Task") {
            return undefined;
          }
          return (
            <>
              <div className="pb-[10px] font-semibold  flex items-center justify-start gap-1">
                <div>
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.87725 0.982442C7.07762 0.339185 7.92238 0.339187 8.12275 0.982441L9.10026 4.12059C9.3167 4.81545 9.94621 5.30564 10.6778 5.30564H13.841C14.1468 5.30564 14.3744 5.50101 14.4638 5.78808C14.5536 6.07647 14.4808 6.39146 14.219 6.58991L11.6598 8.5294C11.0812 8.9679 10.8487 9.73374 11.0642 10.4256L12.0417 13.5638C12.1472 13.9025 12.0177 14.1982 11.7969 14.3656C11.5792 14.5306 11.2953 14.5583 11.0411 14.3656L8.48192 12.4261C7.89791 11.9835 7.1021 11.9835 6.51808 12.4261L3.95893 14.3656C3.70472 14.5583 3.42083 14.5306 3.20311 14.3656C2.98227 14.1982 2.85277 13.9025 2.95827 13.5638L3.93578 10.4256C4.15129 9.73374 3.9188 8.9679 3.34019 8.5294L0.781044 6.58991C0.519179 6.39146 0.446377 6.07647 0.536209 5.78808C0.625629 5.50101 0.853247 5.30564 1.15896 5.30564H4.32224C5.05379 5.30564 5.6833 4.81545 5.89974 4.12059L6.87725 0.982442Z"
                      stroke="black"
                    />
                  </svg>
                </div>
                <p className="text-[18px]">{group.groupName}</p>
              </div>
              <div
                key={`mission-group-${index}`}
                className={clsx(
                  "",
                  index !== missionGroups.length ? "mb-4" : "",
                  "relative z-20 border-[1px] border-[#F3F3F3] rounded-2xl bg-[#fff] ",
                  "px-3 pt-3"
                )}
              >
                {group.missions
                  ?.filter((item: any) => {
                    return !item?.task_user || !item?.task_user?.completed;
                  })
                  .sort((a: any, b: any) => a.sort - b.sort)
                  .map((item: any, missionIndex: number) => {
                    return (
                      <div
                        className={clsx(
                          "z-10 relative cursor-pointer grid grid-cols-12 gap-3 bg-white  w-full mb-[12px] ",
                          "border-[#F3F3F3] border-solid",
                          missionIndex === group.missions.length - 1
                            ? ""
                            : "border-b-[1px] pb-3"
                        )}
                      >
                        <div className="col-span-2 flex items-center">
                          <div>
                            <img
                              className="size-10"
                              src={item?.metadata?.image_url}
                              alt="storage"
                            ></img>
                          </div>
                        </div>
                        <div className="col-span-7 flex items-center">
                          <div className="">
                            <p className="text-sm">{item.name}</p>
                            <div className="flex items-center gap-1">
                              <span className="mb-[2px]">+</span>
                              <img
                                className="size-[13px]"
                                src="/images/icons/token_icon.png?v=3"
                                alt="token"
                              ></img>
                              <p className=" text-[12px] font-semibold">
                                {getNumberFormatUs(
                                  formatDecimals(item?.reward_amount ?? 0),
                                  3
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-3 flex items-center justify-end">
                          <StartBtn
                            isLoading={
                              loadingItem === item.id && isDoingMission
                            }
                            onClick={() => {
                              if (item.type === missionsTypes.TWITTER_FOLLOW) {
                                setLoadingItem(item.id);
                                const Url = twitterUrl(item);
                                tele.showPopup(
                                  {
                                    message: `Do you want to open ${item.metadata.name}`,
                                    buttons: [
                                      {
                                        id: "link",
                                        type: "default",
                                        text: "Open",
                                      },
                                      { type: "cancel" },
                                    ],
                                  },
                                  async function (btn: any) {
                                    if (btn === "link") {
                                      if (
                                        item?.task_user === null ||
                                        !item?.task_user?.completed
                                      ) {
                                        tele.openLink(
                                          item.type ===
                                            missionsTypes.TWITTER_FOLLOW
                                            ? Url
                                            : item.metadata.url
                                        );

                                        handleDoMission(item);
                                        return;
                                      }
                                      tele.openLink(
                                        item.type ===
                                          missionsTypes.TWITTER_FOLLOW
                                          ? Url
                                          : item.metadata.url
                                      );
                                    } else {
                                    }
                                  }
                                );
                                return;
                              }
                              setLoadingItem(item.id);
                              handleDoMission(item);
                            }}
                          >
                            Start
                          </StartBtn>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </>
          );
        })}
    </>
  );
};

export default RemainMissionSection;
