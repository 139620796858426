import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { Result } from "../../../types/utils";
import { ServerError, ServerResponse } from "../../../types/api";
import { Refer } from "../../../types/referees";

export const getHistoryReleaseBird = async (): Promise<
  Result<any, ServerError>
> => {
  const response = await fetchApi("GET", "/event-bird-release-history");
  if (response.error) {
    // Throw to retry
    throw new Error("network-error");
  }

  const historyReleaseBird: ServerResponse<Refer[]> =
    await response.data.json();
  if (historyReleaseBird.error) {
    return {
      error: historyReleaseBird.error,
    };
  }

  return {
    data: historyReleaseBird.data,
  };
};

export default function useGetHistoryReleaseBird() {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["getHistoryReleaseBird"],
    queryFn: getHistoryReleaseBird,
  });

  return {
    historyReleaseBird: data,
    isLoading,
    refreshHistoryReleaseBird: refetch,
  };
}
