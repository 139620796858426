/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import ArrowBtn from "../../../components/common/ArrowBtn";
import GuildItem from "../Components/GuildItem";
import MyGuild from "../Components/MyGuild";
import GuildManualModal from "../Components/GuildManualModal";
import { useEffect, useMemo, useRef, useState } from "react";
import { useClickOutside } from "../../../Hooks/useClickOutside";
import SortGuildSelector from "../Components/SortGuildSelector";
import CreateGuildModal from "../Components/CreateGuildModal";
import ReceivedModal from "../Components/ReceivedModal";
import { guildLevels } from "../constant";
import { useLocation, useNavigate } from "react-router-dom";
import useGetGuildList from "../Hooks/useGetListGuild";
import { Slide, ToastContainer } from "react-toastify";
import { Backdrop, CircularProgress } from "@mui/material";
import useGetCurrentSeason from "../Hooks/useGetCurrentSeason";
import { useUserStore } from "../../../store/userStore";
import useGetPriceList from "../Hooks/useGetPriceList";
import { useGuildPricetore } from "../../../store/guildPriceStore";
import useGuildDetail from "../Hooks/useGuildDetails";
import useMemberDetails from "../Hooks/useMemberDetails";
import { useDebounce } from "../../../Hooks/useDebounce";
import { navPaths } from "../../../constants/navbar.constants";
import { useGuildID } from "../../../store/useGuildId";

const GuildList = () => {
  const tele = window.Telegram.WebApp;
  const navigate = useNavigate();
  const user = useUserStore((state: any) => state.user);
  const setPriceList = useGuildPricetore((state: any) => state.setGuildPrice);
  const location = useLocation();
  const setGuildId = useGuildID((state: any) => state.setGuildId);

  tele.BackButton.show();
  tele.BackButton.onClick(() => navigate("/"));

  const userId = user?.id;

  const guildTab = guildLevels.findIndex(
    (item: string) => item === location?.state?.rank
  );

  const [isShowManualModal, setIsShowManualModal] = useState(false);
  const [isShowCreateGuildModal, setIsShowCreateGuildModal] =
    useState<any>(false);
  const [isOpenReceivedFiftModal, setIsOpenReceivedFiftModal] = useState(false);
  const [selectedGuildLevel, setGuildLevel] = useState<number>(
    guildTab === -1 ? 0 : guildTab
  );
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<any>("total_hunted");

  const changePriceSort = (value: any) => {
    setPage(1);
    setSort(value);
  };
  const { currentSeason, isLoadingCurrentSeason } = useGetCurrentSeason();
  const { priceList, isLoadingPriceList } = useGetPriceList();

  const [searchValue, setsearchValue] = useState<string>();
  const debounceValue = useDebounce(searchValue, 1000);

  const seasonId = useMemo(() => {
    if (!currentSeason?.data) {
      return "";
    }
    return currentSeason?.data?.id;
  }, [currentSeason]);

  const {
    guildList,
    refreshGuildList,
    isLoadingGuildList,
    isRefreshingGuildList,
  } = useGetGuildList({
    guild_rank: guildLevels[selectedGuildLevel],
    page,
    sort,
    season_id: seasonId,
    search: debounceValue || "",
  });

  const { MemberDetails, MemberDetailsLoading, refeshMemberDetails } =
    useMemberDetails({ sort_by: "total_hunted" });
  const myGuildId = useMemo(() => {
    if (!userId || !guildList) {
      return "";
    }

    const item = MemberDetails?.guild_id;

    return item;
  }, [userId, guildList, MemberDetails]);

  const { GuildDetail, GuildDetailLoading, refeshGuildDetail } = useGuildDetail(
    { id: myGuildId, sort }
  );

  const guildManualModalRef = useRef<any>();
  const createGuildModalRef = useRef<any>();

  useEffect(() => {
    refeshGuildDetail();
  }, [myGuildId, MemberDetails]);

  useEffect(() => {
    if (!priceList) {
      setPriceList(null);
    }

    setPriceList(priceList?.data);
  }, [priceList]);

  useEffect(() => {
    refreshGuildList();
  }, [selectedGuildLevel, sort, seasonId, page]);

  useClickOutside(guildManualModalRef, () => {
    setIsShowManualModal(false);
  });

  // const [createdGuild] = useMemo(() => {
  //   if (!MemberDetails || !MemberDetails?.guild_id) {
  //     return [false];
  //   }
  //   if (!guildList?.items?.length) {
  //     return [true];
  //   }

  //   const myGuild = guildList?.items?.find(
  //     (item: any) => item.guild_id === MemberDetails?.guild_id
  //   );

  //   return [!!myGuild];
  // }, [guildList, MemberDetails]);

  const [createdGuild] = useMemo(() => {
    return [MemberDetails?.guild_id];
  }, [MemberDetails]);

  const handleChangeText = (e: { target: { value: any } }) => {
    const value = e.target.value;
    setsearchValue(value);
  };

  useEffect(() => {
    refreshGuildList();
  }, [debounceValue]);

  return (
    <>
      <ToastContainer
        position="top-left"
        closeOnClick
        transition={Slide}
        hideProgressBar
        limit={1}
        stacked
        className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          isRefreshingGuildList ||
          isLoadingGuildList ||
          isLoadingCurrentSeason ||
          isLoadingPriceList ||
          GuildDetailLoading ||
          MemberDetailsLoading
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div
        className={clsx(
          "pt-6 px-4 pb-[100px] bg-[#F2FFE0] h-screen relative z-30",
          "bg-contain bg-no-repeat bg-center"
        )}
        style={{ backgroundImage: `url("/images/guild/bg_tree.png")` }}
      >
        <div className="w-screen h-screen backdrop-blur-xl absolute z-0 top-0 left-0"></div>
        <div className="flex justify-between items-center relative z-10">
          <button
            onClick={() => {
              setGuildId("72173d30-b2e3-4977-a7bc-85eb3ad337e3");
              navigate(navPaths.GUILD_DETAILS);
            }}
            className="absolute cursor-pointer top-0 right-0 bg-[#000] text-[#fff] text-sm font-semibold py-[5px] pr-[6px] pl-7 flex gap-[6px] items-center justify-between rounded-[30px]"
          >
            <img
              className="w-[41px] h-[49px] absolute -left-4 top-[30%] -translate-y-[50%]"
              src="/images/guild/okx_icon.png"
              alt=""
            ></img>
            Join OKX
            <svg
              width="6"
              height="11"
              viewBox="0 0 6 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.43359 0.65625L5.37109 4.8125C5.48047 4.9401 5.53516 5.08594 5.53516 5.25C5.53516 5.41406 5.48047 5.56901 5.37109 5.71484L1.43359 9.87109C1.14193 10.1263 0.841146 10.1263 0.53125 9.87109C0.257812 9.57943 0.248698 9.26953 0.503906 8.94141L4.00391 5.25L0.503906 1.53125C0.248698 1.22135 0.257812 0.920573 0.53125 0.628906C0.841146 0.373698 1.14193 0.382812 1.43359 0.65625Z"
                fill="white"
              />
            </svg>
          </button>
          <div
            className={clsx(
              "rotate-180",
              selectedGuildLevel <= 0 && "opacity-0"
            )}
          >
            <ArrowBtn
              onClick={() => {
                if (selectedGuildLevel > 0) {
                  setPage(1);
                  setGuildLevel(selectedGuildLevel - 1);
                }
              }}
            />
          </div>

          <div className="flex flex-col justify-center items-center gap-2">
            <img
              className="size-[180px]"
              src={`/images/guild/guild_level/${guildLevels[selectedGuildLevel]}.png`}
              alt=""
            ></img>
            <p className="text-[32px] font-extrabold capitalize">
              {guildLevels[selectedGuildLevel]}
            </p>
          </div>
          <div
            className={clsx(
              guildLevels.length - 1 === selectedGuildLevel && "opacity-0"
            )}
          >
            <ArrowBtn
              onClick={() => {
                if (guildLevels.length - 1 > selectedGuildLevel) {
                  setPage(1);
                  setGuildLevel(selectedGuildLevel + 1);
                }
              }}
            />
          </div>
        </div>

        <div className="flex justify-between items-center my-4 relative z-10">
          <div className="flex items-center gap-3">
            <div className="w-[100px]">
              <SortGuildSelector changeSort={changePriceSort} />
            </div>
            <div className="border-2 border-[#95AF73] rounded-lg overflow-hidden h-9 w-[100px] bg-white flex items-center px-3 gap-2">
              <SearchIcon />
              <input
                value={searchValue}
                onChange={handleChangeText}
                className="h-full w-full text-[12px] outline-none"
                placeholder="Search..."
              />
            </div>
          </div>
          {!createdGuild && (
            <>
              <button
                onClick={() => setIsShowCreateGuildModal(true)}
                className={clsx(
                  "font-bold w-[120px] lsm:max-w-[140px] text-white text-xs lsm:text-sm py-3 lsm:px-4 lsm:w-full rounded-xl flex items-center justify-center gap-1",
                  "btn-hover bg-gradient-to-r from-[#97C35B] to-[#61A700] drop-shadow-[0_4px_0px_#4C7E0B]"
                )}
              >
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 6.5C13 6.79167 12.9062 7.03125 12.7188 7.21875C12.5312 7.40625 12.2917 7.5 12 7.5H7.5V12C7.5 12.2917 7.40625 12.5312 7.21875 12.7188C7.03125 12.9062 6.79167 13 6.5 13C6.20833 13 5.96875 12.9062 5.78125 12.7188C5.59375 12.5312 5.5 12.2917 5.5 12V7.5H1C0.708333 7.5 0.46875 7.40625 0.28125 7.21875C0.09375 7.03125 0 6.79167 0 6.5C0 6.20833 0.09375 5.96875 0.28125 5.78125C0.46875 5.59375 0.708333 5.5 1 5.5H5.5V1C5.5 0.708333 5.59375 0.46875 5.78125 0.28125C5.96875 0.09375 6.20833 0 6.5 0C6.79167 0 7.03125 0.09375 7.21875 0.28125C7.40625 0.46875 7.5 0.708333 7.5 1V5.5H12C12.2917 5.5 12.5312 5.59375 12.7188 5.78125C12.9062 5.96875 13 6.20833 13 6.5Z"
                    fill="white"
                  />
                </svg>
                Create Guild
              </button>
            </>
          )}
        </div>
        <div className="relative z-10 mb-4">
          <p className="text-sm font-extrabold">Top 100</p>
        </div>

        <div className="relative bg-[#FFFFFF80] rounded-2xl h-[calc(100%-270px)] shadow-[0_0_28px_#D8E7C3]">
          <div className={clsx("overflow-auto", "h-full")}>
            {guildList?.items?.map((item: any, index: number) => {
              const rank =
                (guildList?.page - 1) * guildList?.page_size + (index + 1);
              return (
                <GuildItem
                  guildType={guildLevels[selectedGuildLevel]}
                  key={item.id}
                  rank={sort === "total_hunted" ? rank : item?.rank_index}
                  item={item}
                />
              );
            })}
          </div>
          {createdGuild &&
            !MemberDetailsLoading &&
            MemberDetails?.guild_rank === guildLevels[selectedGuildLevel] && (
              <div
                className={clsx(
                  "absolute left-0 right-0 bottom-0 bg-[#fff] shadow-[0_0_12px_#CCF0AF] rounded-b-2xl"
                )}
              >
                <MyGuild myGuildData={GuildDetail} />
              </div>
            )}
        </div>
        {/* {guildList?.total > guildList?.page_size && (
          <div className="relative">
            <Pagination
              total={guildList?.total}
              page={page}
              setPage={setPage}
              itemsPerPage={guildList?.page_size}
            />
          </div>
        )} */}
      </div>

      <GuildManualModal
        ref={guildManualModalRef}
        isShowModal={isShowManualModal}
        showModal={setIsShowManualModal}
      />

      <CreateGuildModal
        ref={createGuildModalRef}
        isShowModal={isShowCreateGuildModal}
        showModal={setIsShowCreateGuildModal}
        triggerRefresh={() => {
          setPage(1);
          refreshGuildList();
          refeshGuildDetail();
          refeshMemberDetails();
        }}
      />

      <ReceivedModal
        isShowModal={isOpenReceivedFiftModal}
        showModal={setIsOpenReceivedFiftModal}
      />
    </>
  );
};

const SearchIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
        stroke="black"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.9995 14.0005L11.1328 11.1338"
        stroke="black"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default GuildList;
