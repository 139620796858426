const StreakGuide = () => {
  return (
    <div className="bg-[#fff] text-sm ">
      <p className="font-bold text-center text-base font-inter">Streak Bonus</p>
      <div className="font-inter mt-1 ">
        <p className="mb-2">
          🔥 Streak tracks days of continuous check-ins. Keep it to earn Eggs
          Spinner tickets.
        </p>
        <p>❌ Skip a day, your streak will reset.</p>
      </div>
    </div>
  );
};

export default StreakGuide;
