const MissionItem = () => {
  return (
    <img
      className="w-full h-full"
      src="/images/lucky_spin/mission_item.png"
      alt=""
    ></img>
  );
};

export default MissionItem;
