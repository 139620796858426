import clsx from "clsx";
import { ChangeEvent, useEffect, useState } from "react";
import { useDebounce } from "../../../Hooks/useDebounce";

const Pagination = ({
  total,
  page,
  setPage,
  itemsPerPage,
}: {
  total: number;
  page: number;
  setPage: any;
  itemsPerPage: number;
}) => {
  const [pageState, setPageState] = useState<number | string>(page);
  const lastPage = Math.ceil(total / itemsPerPage);

  const debounceValue = useDebounce(pageState, 500);

  const handleNextPage = () => setPageState((prev: any) => prev + 1);
  const handlePrevPage = () =>
    page > 1 && setPageState((prev: any) => prev - 1);

  const handleChangePage = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number((event?.target?.value).replace(/\D+/g, ""));
    if (value <= 0 || !value) {
      setPageState(1);
      return;
    }

    if (value >= lastPage) {
      setPageState(lastPage);
    } else {
      if (event?.target?.value === "") {
        setPageState(event?.target?.value);
        return;
      }
      if (event?.target?.value === "0") {
        setPageState(1);
        return;
      }
      setPageState(value);
    }
  };

  useEffect(() => {
    if (pageState) {
      setPage(pageState);
    } else {
      setPage((prev: number) => prev);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue, setPage]);

  useEffect(() => {
    page === 1 && setPageState(page);
  }, [page]);

  const { innerWidth } = window;

  const [inputing, setInputing] = useState<boolean>(false);
  const handleKeyboard = () => {
    // Telegram Desktop
    if (innerWidth === 400 || innerWidth === 420 || innerWidth === 384) {
      return;
    }
    setInputing(true);
  };

  const handleFormatInput = () => {
    setInputing(false);
  };

  return (
    <div
      className={clsx(
        "rounded-lg transition-all duration-300 ease-in-out pt-5 pb-5 flex items-center justify-center gap-3 bg-[#F2FFE0]",
      )}
      style={{
        // transform: inputing ? "translateY(-500%)" : "translateY(0)",
        top: inputing ? 0 : undefined,
        bottom: inputing ? undefined : 0,
        right: inputing ? 0 : undefined,
        left: inputing ? 0 : undefined,
        position: inputing ? "absolute" : undefined,
      }}
    >
      <div
        onClick={handlePrevPage}
        className={clsx(
          "btn-hover h-[36px] w-[36px] flex items-center justify-center cursor-pointer rounded-[4px] bg-gradient-to-r from-[#97C35B] to-[#61A700] border-b-[3px] border-b-[#4C7E0B]",
          page === 1 && "opacity-50 pointer-events-none",
        )}
      >
        <svg
          width="14"
          height="12"
          viewBox="0 0 14 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.75 11.7967L0.25 6.54672C0.0937505 6.39047 5.07134e-07 6.20297 5.26258e-07 5.98422C5.42649e-07 5.79672 0.0937506 5.60922 0.250001 5.45297L5.75 0.20297C6.03125 -0.0782802 6.53125 -0.0782801 6.8125 0.23422C7.09375 0.51547 7.09375 1.01547 6.78125 1.29672L2.625 5.23422L13.25 5.23422C13.6875 5.23422 14 5.57797 14 5.98422C14 6.42172 13.6875 6.73422 13.25 6.73422L2.625 6.73422L6.78125 10.703C7.09375 10.9842 7.09375 11.4842 6.8125 11.7655C6.53125 12.078 6.0625 12.078 5.75 11.7967Z"
            fill="white"
          />
        </svg>
      </div>
      <span className="text-sm font-[500]">Page</span>
      <div className="h-[36px] bg-[white] border rounded-[4px] border-[##9B9D99] flex items-center justify-center">
        <input
          value={pageState}
          onChange={handleChangePage}
          onBlur={(event) => {
            handleFormatInput();
            const value = Number(event?.target?.value);
            if (value > 0) {
              setPageState(value);
            } else setPageState(1);
          }}
          onFocus={handleKeyboard}
          inputMode="numeric"
          className="w-[60px] h-full text-center text-[16px] font-[500] pointer-events-none"
        />
      </div>
      <span className="text-sm font-[500]">of {lastPage}</span>
      <div
        onClick={handleNextPage}
        className={clsx(
          "btn-hover h-[36px] w-[36px] flex items-center justify-center cursor-pointer rounded-[4px] bg-gradient-to-r from-[#97C35B] to-[#61A700] border-b-[3px] border-b-[#4C7E0B]",
          page === lastPage && "opacity-50 pointer-events-none",
        )}
      >
        <svg
          width="14"
          height="12"
          viewBox="0 0 14 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.25 0.203279L13.75 5.45328C13.9062 5.60953 14 5.79703 14 6.01578C14 6.20328 13.9062 6.39078 13.75 6.54703L8.25 11.797C7.96875 12.0783 7.46875 12.0783 7.1875 11.7658C6.90625 11.4845 6.90625 10.9845 7.21875 10.7033L11.375 6.76578L0.75 6.76578C0.3125 6.76578 0 6.42203 0 6.01578C0 5.57828 0.3125 5.26578 0.75 5.26578L11.375 5.26578L7.21875 1.29703C6.90625 1.01578 6.90625 0.515779 7.1875 0.234529C7.46875 -0.0779707 7.9375 -0.0779707 8.25 0.203279Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};

export default Pagination;
