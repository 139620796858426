import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function hatchEgg(id: string) {
  return await api.post(`/api/v1/egg-hatch/complete`, { egg_id: id });
}

export default function useHatchEgg() {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["hatchEgg"],
    mutationFn: hatchEgg,
  });

  return { hatchEggAsync: mutateAsync, isHatchingEgg: isPending };
}
