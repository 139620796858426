import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerResponse } from "../../../types/api";

export const getPackages = async () => {
  const response = await fetchApi("GET", "/iap/boxes/packages");
  if (response.error) {
    throw new Error("network-error");
  }

  const packagesResponse: ServerResponse<any> = await response.data.json();
  if (packagesResponse.error) {
    return {
      error: packagesResponse.error,
    };
  }

  return packagesResponse.data;
};

export default function usePackages() {
  const { data, isPending, refetch } = useQuery({
    queryKey: ["packages"],
    queryFn: () => getPackages(),
  });

  return {
    packages: data,
    packagesLoading: isPending,
    refeshPackages: refetch,
  };
}
