import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../config/api";
import { ServerError, ServerResponse } from "../../types/api";
import { Account } from "../../types/user";
import { Result } from "../../types/utils";

export const requestAccountDetailsV2 = async (): Promise<
  Result<Account, ServerError>
> => {
  const response = await fetchApi("GET", "/profile2");
  if (!response?.data?.ok) {
    return {
      error: {
        code: "network",
        message: response?.data?.statusText || "",
      },
      status: response?.data?.status,
    };
  }

  const balanceResponse: ServerResponse<Account> = await response.data.json();
  if (balanceResponse.error) {
    return {
      error: balanceResponse.error,
    };
  }

  return {
    data: balanceResponse.data,
  };
};

export const useAccountV2 = (enable?: true) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["AccountV2"],
    queryFn: requestAccountDetailsV2,
    retry: 1,
    enabled: enable,
  });

  return { accountV2: data, isLoading, refetch };
};
