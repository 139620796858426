/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import { motion } from "framer-motion";
import { useEffect, useMemo, useRef, useState } from "react";
import useMergeEgg from "../Hooks/useMergeEgg";
import { toast } from "react-toastify";
import ConfirmMergeModal from "./MergeEggModal";
import { sleep } from "../../../utils/time";
import { useClickOutside } from "../../../Hooks/useClickOutside";
import FusionGuideModal from "./FusionGuideModal";
import useFusionFee from "../Hooks/useGetFusionFee";
import { formatDecimals } from "../../../utils/formatNumber";
import ItemLoading from "../../../components/common/ItemLoading";

const PiecesColected = ({
  pieces,
  triggerRefresh,
}: {
  pieces: any;
  triggerRefresh: () => void;
}) => {
  const { mergeEggAsync, isMergingEgg } = useMergeEgg();

  const [selectedMergeItem, setSelectedMergeItem] = useState<any>({
    imgUrl: "",
    quantity: 0,
    type: "",
  });

  const { FusionFee, FusionFeeLoading, refeshFusionFee, isRefetching } =
    useFusionFee({
      type: selectedMergeItem.type,
    });

  const [isOpenConfirmMergeModal, setIsOpenConfirmMergeModal] =
    useState<boolean>(false);
  const [isOpenGuideFusionModal, setIsOpenGuideFusionModal] =
    useState<boolean>(false);

  const fusionGuideModalRef = useRef<any>();

  useClickOutside(fusionGuideModalRef, () => {
    setIsOpenGuideFusionModal(false);
  });

  const eggs = ["common", "uncommon", "rare", "epic", "legendary"];

  const formatedFusionFee = formatDecimals(FusionFee);

  const EggPieces = useMemo(() => {
    let collectedPieces = pieces;
    if (!pieces?.length) {
      collectedPieces = [
        { type: "common" },
        { type: "uncommon" },
        { type: "rare" },
        { type: "epic" },
        { type: "legendary" },
      ];
    }
    const count: { [key: string]: number } = {};

    eggs.forEach((type) => {
      count[type] = collectedPieces.filter(
        (item: any) => item.type === type
      ).length;
    });

    const allPieces: any[] = eggs.map((item) => {
      return {
        imgUrl: `images/lucky_spin/${item}.png` || "",
        quantity: !pieces?.length ? 0 : count[item],
        type: item,
      };
    });

    return allPieces;
  }, [pieces]);

  useEffect(() => {
    refeshFusionFee();
  }, [selectedMergeItem]);

  const handleFusion = () => {
    const eggPieceIds = pieces
      .filter((item: any) => item.type === selectedMergeItem.type)
      .map((item: any) => item.id)
      .splice(0, 5);

    mergeEggAsync(eggPieceIds)
      .then(async (data) => {
        toast.error(`Fusion ${data?.data?.data?.type} egg succesfully`, {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
        });
        await sleep(500);
        triggerRefresh();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
        });
      })
      .finally(() => {
        setIsOpenConfirmMergeModal(false);
        setSelectedMergeItem({
          imgUrl: "",
          quantity: 0,
          type: "",
        });
      });
  };

  return (
    <div className="bg-[#E4F0D3] rounded-[12px] mt-4 p-4 flex flex-col gap-3 ">
      <div className="flex justify-between">
        <p className="font-medium">Select collected pieces for fusion</p>
        <button
          className="box-tooltip"
          onClick={() => setIsOpenGuideFusionModal(true)}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 0C15.5078 0 20 4.49219 20 10C20 15.5469 15.5078 20 10 20C4.45312 20 0 15.5469 0 10C0 4.49219 4.45312 0 10 0ZM10 18.125C14.4531 18.125 18.125 14.4922 18.125 10C18.125 5.54688 14.4531 1.875 10 1.875C5.50781 1.875 1.875 5.54688 1.875 10C1.875 14.4922 5.50781 18.125 10 18.125ZM11.5625 13.125C12.0703 13.125 12.5 13.5547 12.5 14.0625C12.5 14.6094 12.0703 15 11.5625 15H8.4375C7.89062 15 7.5 14.6094 7.5 14.0625C7.5 13.5547 7.89062 13.125 8.4375 13.125H9.0625V10.625H8.75C8.20312 10.625 7.8125 10.2344 7.8125 9.6875C7.8125 9.17969 8.20312 8.75 8.75 8.75H10C10.5078 8.75 10.9375 9.17969 10.9375 9.6875V13.125H11.5625ZM10 7.5C9.29688 7.5 8.75 6.95312 8.75 6.25C8.75 5.58594 9.29688 5 10 5C10.6641 5 11.25 5.58594 11.25 6.25C11.25 6.95312 10.6641 7.5 10 7.5Z"
              fill="#4C7E0B"
            />
          </svg>
        </button>
      </div>
      <div className="flex justify-between">
        {EggPieces?.map((item, index) => {
          return (
            <div
              onClick={() => {
                if (selectedMergeItem.type === item.type) {
                  setSelectedMergeItem({
                    imgUrl: "",
                    quantity: 0,
                    type: "",
                  });
                  return;
                }
                setSelectedMergeItem(item);
              }}
              key={index}
            >
              <motion.div
                className={clsx(
                  "size-[50px] flex items-center rounded-[6px] justify-center bg-[#F2FFE0]",
                  selectedMergeItem?.type === item.type
                    ? "border-[2px] border-[#4C7E0B]"
                    : "border-[1px] border-[#C4D0B4]"
                )}
              >
                <img className="w-8 h-[38px]" src={item.imgUrl} alt=""></img>
              </motion.div>
              <p className="text-sm text-center">{item.quantity}</p>
            </div>
          );
        })}
      </div>
      <button
        onClick={() => !FusionFeeLoading && setIsOpenConfirmMergeModal(true)}
        disabled={selectedMergeItem.quantity < 5 || FusionFeeLoading}
        className={clsx(
          "w-full max-h-[54px] border-[3px] border-[#97C35B] rounded-[8px] py-3 shadow-[0_3px_0_#4C7E0B] text-[#4C7E0B] font-extrabold",
          "disabled:border-[#ADADAD] disabled:shadow-[0_3px_0_#4E4E4E] disabled:text-[#8B8B8B]",
          "flex justify-center gap-2",
          selectedMergeItem.quantity >= 5 &&
            "btn-click-no-shadow cursor-pointer"
        )}
      >
        {formatedFusionFee > 0 ? (
          <>
            <div className="flex items-center gap-[2px]">
              <p className="mr-[6px]">{`Fusion`}</p>
              <img
                className="size-[21px]"
                src="/images/icons/token_icon.png?v=3"
                width={21}
                height={21}
                alt="token"
              ></img>
              <p
                className={clsx(
                  "font-extrabold",
                  selectedMergeItem.quantity < 5 || FusionFeeLoading
                    ? "text-[#8B8B8B]"
                    : "text-[#13121C]"
                )}
              >
                {formatedFusionFee ?? 0}
              </p>
            </div>
          </>
        ) : (
          <>
            {isRefetching ? (
              <ItemLoading />
            ) : (
              <>{selectedMergeItem.type ? `Fusion free` : "Fusion"}</>
            )}
          </>
        )}
      </button>

      <ConfirmMergeModal
        fee={formatedFusionFee}
        isLoading={isMergingEgg}
        isShowModal={isOpenConfirmMergeModal}
        showModal={setIsOpenConfirmMergeModal}
        handleStart={() => handleFusion()}
        type={selectedMergeItem.type}
      />
      <FusionGuideModal
        ref={fusionGuideModalRef}
        isShowModal={isOpenGuideFusionModal}
        showModal={setIsOpenGuideFusionModal}
      />
    </div>
  );
};

export default PiecesColected;
