/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  // inventoryItemBackgroundColor,
  inventoryTab,
  tabButtons,
} from "../../../constants/inventory.constants";
import ConfirmFeedBirdModal from "../Components/ConfirmFeedBirdModal";
import SellModal from "../Components/SellModal";
// import useGetMyBox from "../../MysteryBox/Hooks/useGetMyBox";
// import { Button } from "@mui/material";
import { Slide, ToastContainer, toast } from "react-toastify";
// import { useScreenSize } from "../../../Hooks/useScreenSize";
// import { navPaths } from "../../../constants/navbar.constants";
// import { EGG_STATUS } from "../../../utils/helper";
// import LoadingScreen from "../../Global/Pages/LoadingScreen";
// import { removeDuplicateItemsByType } from "../../Missions/Utils/missions";
// import { eggLevels } from "../../MysteryBox/constanst";
import useGetMyEggs from "../Hooks/useGetMyEggs";
import useGetMyWorms from "../Hooks/useGetMyWorms";
import useSellWorm from "../Hooks/useSellWorm";
// import List from "../../Market/Components/List";
import ItemListV2 from "../Components/ItemListV2";
import { motion } from "framer-motion";
import useGetMyBirds from "../Hooks/useGetMyBirds";
import { navPaths } from "../../../constants/navbar.constants";

const Inventory = () => {
  const tele = window.Telegram.WebApp;
  const navigate = useNavigate();
  const location = useLocation();
  // const { height } = useScreenSize();

  const [page, setPage] = useState<number>(1);
  // const { myBox, refreshMyBox, isLoadingMyBox } = useGetMyBox();
  const {
    myWorms,
    isLoadingMyWorms,
    refreshMyWorms,
    itemPerPage: wormPerPage,
  } = useGetMyWorms({ page });
  const { myEggs, isLoadingMyEggs, refreshMyEggs, itemPerPage } = useGetMyEggs({
    page,
  });
  const { myBirds, refreshMyBirds, isLoadingMyBirds, itemBirdPerPage } =
    useGetMyBirds({ page });

  const { sellAsync, isSellingWorm } = useSellWorm();

  const [tab, setTab] = useState(1);

  useEffect(() => {
    location?.state?.showEgg && setTab(inventoryTab.egg);
    location?.state?.showWorm && setTab(inventoryTab.worm);
    (location?.state?.showBird || location?.state?.from === navPaths.BIRD) &&
      setTab(inventoryTab.bird);
  }, [
    location?.state?.showEgg,
    location?.state?.showWorm,
    location?.state?.showBird,
  ]);

  const [selected, setSelected] = useState<any>({
    id: 0,
    level: 0,
    quantity: 0,
  });
  const [isOpenConfirmFeedBird, setIsOpenConfirmFeedBird] = useState({
    isOpen: false,
    worm: 1,
  });
  const [isOpenSellModal, setIsOpenSellModal] = useState<any>(false);

  tele.BackButton.show();
  tele.BackButton.onClick(() => handleBackBtn());

  // const inventoryWorms = myWorms?.data ?? [];

  // const inventoryEggs = myBox?.data?.eggs ?? [];
  const inventoryEggs = useMemo(() => {
    return myEggs?.data?.items || [];
  }, [myEggs?.data?.items]);

  const inventoryWorms = useMemo(() => {
    return myWorms?.data?.items || [];
  }, [myWorms?.data?.items]);

  const inventoryBirds = useMemo(() => {
    return myBirds?.data?.items || [];
  }, [myBirds?.data?.items]);

  const [eggDataState, setEggDataState] = useState<any>([]);
  const [wormDataState, setWormDataState] = useState<any>([]);
  const [birdDataState, setBirdDataState] = useState<any>([]);

  useEffect(() => {
    if (tab === inventoryTab.egg) {
      refreshMyEggs();
      return;
    }
    if (tab === inventoryTab.worm) {
      refreshMyWorms();
      return;
    }
    refreshMyBirds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    // if (page === 1) {
    //   inventoryEggs?.length > 0
    //     ? setEggDataState(inventoryEggs)
    //     : setEggDataState([]);
    // } else {
    //   inventoryEggs?.length > 0 &&
    //     setEggDataState((prev: any) =>
    //       removeDuplicate([...prev, ...inventoryEggs])
    //     );
    // }
    inventoryEggs?.length > 0
      ? setEggDataState(inventoryEggs)
      : setEggDataState([]);
  }, [inventoryEggs, page]);

  useEffect(() => {
    inventoryWorms?.length > 0
      ? setWormDataState(inventoryWorms)
      : setWormDataState([]);
  }, [inventoryWorms, page]);

  useEffect(() => {
    inventoryBirds?.length > 0
      ? setBirdDataState(inventoryBirds)
      : setBirdDataState([]);
  }, [inventoryBirds, page]);

  // const WormsItems = () => {
  //   if (!inventoryWorms.length) {
  //     return [];
  //   }

  //   const countItem = inventoryWorms?.reduce((count: any, obj: any) => {
  //     count[obj.type] = (count[obj.type] || 0) + 1;
  //     return count;
  //   }, {});

  //   const worms: any[] = removeDuplicateItemsByType(inventoryWorms)?.map(
  //     (item) => {
  //       return {
  //         ...item,
  //         imgUrl: `/images/inventory/worm/${item.type}.png?v=5`,
  //         quantity: countItem[item.type],
  //         price: item.reward,
  //       };
  //     }
  //   );

  //   return worms;
  // };

  // const inventoryItems =
  //   tab === inventoryTab.worm ? WormsItems() : inventoryEggs;

  // useEffect(() => {
  //   setWorms(WormsItems());
  // }, [myWorm, WormsItems()]);

  const handleBackBtn = () => {
    navigate("/");
  };

  const handleChangeTab = (tab: { id: number; title: string }) => {
    setTab(tab.id);
    setSelected({
      id: 0,
      level: 0,
      quantity: 0,
    });
  };

  // const handleShowDetailEgg = () => {
  //   navigate(navPaths.OPEN_BOX, { state: { from: navPaths.INVENTORY } });
  // };

  const handleSellWorms = (quantity: number, type: string) => {
    if (myWorms?.data) {
      // get list id of selling worms
      const wormList = myWorms?.data
        ?.filter((item: any) => item.type === type)
        .splice(0, quantity)
        .map((item: any) => item.id);

      sellAsync(wormList)
        .then(() => {
          toast.error("Sell worm successfully", {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          });
          setIsOpenSellModal(false);
          setSelected({
            id: 0,
            level: 0,
            quantity: 0,
          });
          refreshMyWorms();
          // refreshMyBox();
          refreshMyEggs();
        })
        .catch(() => {
          toast.error("Fail to sell worm", {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          });
        });
    }
  };

  const triggerReload = () => {
    if (tab === inventoryTab.egg) {
      refreshMyEggs();
      return;
    }
    if (tab === inventoryTab.worm) {
      refreshMyWorms();
      return;
    }
    refreshMyBirds();
  };

  const isShowPagination = useMemo(() => {
    if (!itemPerPage) return false;
    if (!myEggs?.data?.total) return false;
    return itemPerPage <= myEggs?.data?.total;
  }, [itemPerPage, myEggs?.data?.total]);

  const isShowWormPagination = useMemo(() => {
    if (!wormPerPage) return false;
    if (!myWorms?.data?.total) return false;
    return wormPerPage <= myWorms?.data?.total;
  }, [wormPerPage, myWorms?.data?.total]);

  const isShowBirdPagination = useMemo(() => {
    if (!itemBirdPerPage) return false;
    if (!myWorms?.data?.total) return false;
    return itemBirdPerPage <= myBirds?.data?.total;
  }, [itemBirdPerPage, myWorms?.data?.total]);

  return (
    <div className="relative z-10">
      {/* {isLoadingMyWorm || isLoadingMyEggs ? (
        // <LoadingScreen />
        <></>
      ) : ( */}
      <div className="h-screen">
        <ToastContainer
          position="top-left"
          closeOnClick
          transition={Slide}
          hideProgressBar
          limit={1}
          stacked
          className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
        />
        <div
          className={clsx(
            "h-full pt-3 pb-6  bg-cover relative z-40 bg-[#F2FFE0]"
          )}
        >
          <p className="text-center text-[20px] font-extrabold text-[#325900]">
            INVENTORY
          </p>
          <div className="text-[#325900] mx-4 flex items-center overflow-hidden font-extrabold mt-3 bg-[#F6F6F6] rounded-[38px] border-[1px] border-[#1B3000]">
            {tabButtons.map((btn) => (
              <div
                key={btn.id}
                onClick={() => handleChangeTab(btn)}
                className={clsx(
                  "px-5 w-[33.33%] h-[48px] relative cursor-pointer flex items-center justify-center"
                )}
              >
                <span className="z-10">{btn.title}</span>
                {tab === btn.id && (
                  <motion.div
                    className="flex items-center justify-center rounded-[38px] border-[2px] border-[#1B3000] absolute inset-0 bg-[#FFFFFF]"
                    layoutId="tabButton"
                  />
                )}
              </div>
            ))}
          </div>
          {tab === inventoryTab.worm && (
            <>
              <ItemListV2
                items={wormDataState}
                triggerReload={triggerReload}
                setPage={setPage}
                total={myWorms?.data?.total}
                loading={isLoadingMyWorms}
                page={page}
                isShowPagination={isShowWormPagination}
                itemsPerPage={wormPerPage}
                tab={"worm"}
              />
            </>
          )}

          {tab === inventoryTab.egg && (
            <ItemListV2
              items={eggDataState}
              triggerReload={triggerReload}
              setPage={setPage}
              total={myEggs?.data?.total}
              loading={isLoadingMyEggs}
              page={page}
              isShowPagination={isShowPagination}
              itemsPerPage={itemPerPage}
              tab={"egg"}
            />
          )}
          {tab === inventoryTab.bird && (
            <ItemListV2
              tab={"bird"}
              items={birdDataState}
              triggerReload={triggerReload}
              setPage={setPage}
              total={myBirds?.data?.total}
              loading={isLoadingMyBirds}
              page={page}
              isShowPagination={isShowBirdPagination}
              itemsPerPage={itemBirdPerPage}
            />
          )}
        </div>

        <ConfirmFeedBirdModal
          wormLevel={isOpenConfirmFeedBird.worm}
          isOpen={isOpenConfirmFeedBird.isOpen}
          handleClose={() =>
            setIsOpenConfirmFeedBird({ isOpen: false, worm: 0 })
          }
        />

        <SellModal
          wormLevel={selected.type}
          isOpen={isOpenSellModal}
          handleClose={() => setIsOpenSellModal(false)}
          handleSell={(quantity: number, type: string) =>
            handleSellWorms(quantity, type)
          }
          quantity={selected.quantity}
          price={selected.price}
          isLoading={isSellingWorm}
        />
      </div>
      {/* )} */}
    </div>
  );
};

export default Inventory;
