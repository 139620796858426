import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function joinGuild({ guild_id }: { guild_id: string }) {
  return await api.post("/api/v1/guild/join", { guild_id });
}

export default function useJoinGuild() {
  return useMutation({
    mutationKey: ["joinGuild"],
    mutationFn: joinGuild,
  });
}
