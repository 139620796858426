import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerResponse } from "../../../types/api";

export const getGuildDetailMaster = async ({
  sort_by,
}: {
  sort_by?: string;
}) => {
  const response = await fetchApi(
    "GET",
    `/guild/detail/master?sort_by=${sort_by}`
  );
  if (response.error) {
    throw new Error("network-error");
  }

  const getGuildDetailMasterResponse: ServerResponse<any> =
    await response.data.json();
  if (getGuildDetailMasterResponse.error) {
    return {
      error: getGuildDetailMasterResponse.error,
    };
  }

  return getGuildDetailMasterResponse.data;
};

export default function useGuildDetailMaster({
  sort_by,
  enable,
}: {
  sort_by?: string;
  enable: boolean;
}) {
  const { data, isPending, refetch, isRefetching } = useQuery({
    queryKey: ["GuildDetailMaster"],
    queryFn: () => enable && getGuildDetailMaster({ sort_by }),
    retry: 1,
  });

  return {
    GuildDetailMaster: data,
    GuildDetailMasterLoading: isPending,
    refeshGuildDetailMaster: refetch,
    isRefetching,
  };
}
