import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function claimFriendBadge() {
  return await api.post("/api/v1/event-friend-badge");
}

export default function useClaimFriendBadge() {
  return useMutation({
    mutationKey: ["claimFriendBadge"],
    mutationFn: claimFriendBadge,
  });
}
