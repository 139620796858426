import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { Bird } from "../types/bird";

export const useBoostStore = create(
  persist(
    (set) => ({
      boostSpeedLevel: null,
      setBoostSpeed: (boostSpeedLevel: any) =>
        set({ boostSpeedLevel: boostSpeedLevel }),
      boostStorageLevel: null,
      setBoostStorage: (boostStorageLevel: Bird) =>
        set({ boostStorageLevel: boostStorageLevel }),
      boostWaterLevel: null,
      setBoostWater: (boostWaterLevel: Bird) =>
        set({ boostWaterLevel: boostWaterLevel }),
    }),
    {
      name: "boost-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
