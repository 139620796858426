import clsx from "clsx";
import { birdBackgroundColors, birdBorderColors } from "../constant";
import { formatDecimals } from "../../../utils/formatNumber";

const GuildMemberItem = ({ item, rank }: { item?: any; rank: number }) => {
  return (
    <div
      className={clsx(
        "py-[6px] w-full border-b-[1px]  border-[#0000001A] mb-3",
        "grid grid-cols-12"
      )}
    >
      <div className="col-span-1 flex items-center justify-center">
        {rank > 3 ? (
          <p className="text-sm font-bold">{rank}</p>
        ) : (
          <img
            className="size-[32px]"
            src={`/images/guild/guild_rank/${rank}.png`}
            alt=""
          ></img>
        )}
      </div>
      <div className="col-span-8 flex items-center gap-2 text-[12px] font-medium  px-2 py-[10px]">
        <div>
          <div className="size-[34px] rounded-[50%] overflow-hidden">
            <img src="/images/trees/main.png?v=4" alt=""></img>
          </div>
        </div>
        <div className="flex flex-col justify-start items-start gap-[2px] col-span-2">
          <p className="font-semibold">{item?.name || "------"}</p>
          <div className="flex items-center gap-1">
            <img
              src="/images/icons/token_icon.png?v=3"
              width={13}
              height={13}
              alt="token"
            ></img>
            <p className="text-[12px] font-semibold">
              {formatDecimals(item?.hunted).toFixed(0) ?? 0}
            </p>
          </div>
        </div>
      </div>
      <div className={clsx(" flex items-center ", " col-span-3 justify-end")}>
        <div
          className={clsx(
            "size-[35px] rounded-[50%] overflow-hidden border-[2px]",
            "flex items-center justify-center"
          )}
          style={{
            backgroundColor: birdBackgroundColors[item?.bird_type],
            borderColor: birdBorderColors[item?.bird_type],
          }}
        >
          <img
            className={item?.bird_type ? "size-[21px]" : "size-full"}
            src={
              item?.bird_type
                ? `images/bird/${item?.bird_type}.png`
                : "images/guild/no-bird.png"
            }
            alt=""
          ></img>
        </div>
      </div>
    </div>
  );
};

export default GuildMemberItem;
