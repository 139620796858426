import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerResponse } from "../../../types/api";

export const getMessage = async ({
  guild_id,
  page,
}: {
  guild_id: string;
  page: number;
}) => {
  const response = await fetchApi(
    "GET",
    `/guild/notification/list?guild_id=${guild_id}&page=${page}`
  );
  if (response.error) {
    throw new Error("network-error");
  }

  const getMessageResponse: ServerResponse<any> = await response.data.json();
  if (getMessageResponse.error) {
    return {
      error: getMessageResponse.error,
    };
  }

  return getMessageResponse.data;
};

export default function useGetMessages({
  guild_id,
  page,
}: {
  guild_id: string;
  page: number;
}) {
  const { data, isPending, refetch, isRefetching } = useQuery({
    queryKey: ["Messages"],
    queryFn: () => !!guild_id && getMessage({ guild_id, page }),
    retry: 1,
  });

  return {
    MessageList: data,
    isLoadingMessages: isPending,
    refreshMessages: refetch,
    isRefreshingMessages: isRefetching,
  };
}
