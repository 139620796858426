import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerResponse } from "../../../types/api";

export const getGuildDetail = async ({
  id,
  sort,
}: {
  id: string;
  sort?: string;
}) => {
  const response = await fetchApi(
    "GET",
    `/guild/detail?guild_id=${id}&sort_by=${sort}`
  );
  if (response.error) {
    throw new Error("network-error");
  }

  const getGuildDetailResponse: ServerResponse<any> =
    await response.data.json();
  if (getGuildDetailResponse.error) {
    return {
      error: getGuildDetailResponse.error,
    };
  }

  return getGuildDetailResponse.data;
};

export default function useGuildDetail({
  id,
  sort,
}: {
  id: string;
  sort?: string;
}) {
  const { data, isPending, refetch, isRefetching } = useQuery({
    queryKey: ["GuildDetail"],
    queryFn: () => !!id && getGuildDetail({ id, sort }),
    retry: 1,
  });

  return {
    GuildDetail: data,
    GuildDetailLoading: isPending,
    refeshGuildDetail: refetch,
    isRefetching,
  };
}
