import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerError, ServerResponse } from "../../../types/api";
import { Result } from "../../../types/utils";

export const getHistoryData = async ({
  page,
  marketType,
  history_type,
}: {
  page: number;
  marketType: string;
  history_type: string;
}): Promise<Result<any, ServerError>> => {
  const response = await fetchApi(
    "GET",
    marketType === "egg"
      ? `/history-log-market/me?market_type=egg&page=${page}&history_type=${history_type}`
      : `/history-log-market/me?market_type=worm&page=${page}&history_type=${history_type}`,
  );
  if (response.error) {
    // Throw to retry
    throw new Error("network-error");
  }

  const historyDataResponse: ServerResponse<any> = await response.data.json();
  if (historyDataResponse.error) {
    return {
      error: historyDataResponse.error,
    };
  }

  return {
    data: historyDataResponse.data,
  };
};

export default function useGetHistoryData({
  history_type,
  marketType,
  loadHistory,
  page,
}: {
  history_type: string;
  marketType: string;
  loadHistory: boolean;
  page: number;
}) {
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["historyData"],
    queryFn: () => getHistoryData({ page, marketType, history_type }),
    retry: 1,
    enabled: loadHistory,
  });

  return {
    historyData: data,
    isLoadingHistoryData: isFetching,
    refreshHistoryData: refetch,
  };
}
