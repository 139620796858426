import { CircularProgress } from "@mui/material";
import clsx from "clsx";
import { useEffect, useMemo, useRef, useState } from "react";
import { Location, useNavigate } from "react-router-dom";
import { useClickOutside } from "../../../Hooks/useClickOutside";
import ModalContainer from "../../../components/common/ModalContainer";
import { navPaths } from "../../../constants/navbar.constants";
import {
  formatDecimals,
  formatNumberFloatFix,
} from "../../../utils/formatNumber";
import { useAccountBalance } from "../../Home/Hooks/useGetAcountBalance";
import Filter from "./Filter";
import HistoryModal from "./HistoryModal";
import List from "./List";
import Pagination from "./Pagination";
import PriceSelecter from "./PriceSelecter";
// import VolumnAndFloorPrice from "./VolumnAndFloorPrice";
import useGetMarketData from "../Hooks/useMarketWormData";
import useGetMineEggData from "../Hooks/useMineEgg";

const FilterBtn = ({
  onClick,
  showFilter,
}: {
  onClick: any;
  showFilter: boolean;
}) => {
  return (
    <div
      onClick={onClick}
      className={clsx(
        "rounded-lg flex items-center justify-center h-full aspect-square",
        showFilter
          ? "bg-gradient-to-r from-[#97C35B] to-[#61A700] border-b-[3px] border-b-[#4C7E0B] text-white"
          : "bg-white border-2 border-[#A4BC82]",
      )}
    >
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 1.85156C0 1.04297 0.632812 0.375 1.44141 0.375H16.5234C17.332 0.375 18 1.04297 18 1.85156C18 2.20312 17.8594 2.51953 17.6484 2.76562L11.8125 9.97266V15C11.8125 15.6328 11.2852 16.125 10.6523 16.125C10.4062 16.125 10.1602 16.0547 9.94922 15.8789L6.71484 13.3125C6.36328 13.0312 6.1875 12.6445 6.1875 12.2227V9.97266L0.316406 2.76562C0.105469 2.51953 0 2.20312 0 1.85156ZM1.89844 2.0625L7.66406 9.12891C7.80469 9.30469 7.875 9.48047 7.875 9.65625V12.082L10.125 13.875V9.65625C10.125 9.48047 10.1602 9.30469 10.3008 9.12891L16.0664 2.0625H1.89844Z"
          fill={showFilter ? "#ffffff" : "#203900"}
        />
      </svg>
    </div>
  );
};

const WormMarket = ({
  userID,
  location,
}: {
  userID: string;
  location: Location<any>;
}) => {
  const navigate = useNavigate();

  const tele = window.Telegram.WebApp;
  tele.BackButton.show();
  tele.BackButton.onClick(() => handleBackBtn());

  const handleBackBtn = () => {
    navigate("/");
  };

  const [showOwnedItems, setShowOwnedItems] = useState<any>(
    location?.state?.showOwnedItems,
  );
  const [typeFilter, setTypeFilter] = useState<any>(null);

  const handleTypeFilter = (value: string) => {
    setPage(1);
    if (value === typeFilter) {
      setTypeFilter("");
      return;
    }
    setTypeFilter(value);
  };

  const { balance, refetch } = useAccountBalance();

  const [showFilter, setShowFilter] = useState<boolean>(false);

  const [isShowModal, setIsShowModal] = useState<boolean>(false);

  const handleShowHistory = () => {
    setIsShowModal(!isShowModal);
  };

  const historyModalRef = useRef<HTMLDivElement>(null);
  useClickOutside(historyModalRef, () => {
    setIsShowModal(false);
  });

  const [priceSort, setPriceSort] = useState<any>();

  const changePriceSort = (value: any) => {
    setPage(1);
    setPriceSort(value);
  };

  const [page, setPage] = useState<number>(1);
  const onSetPage = (value: number) => {
    setPage(value);
  };

  const {
    marketData,
    isLoadingMarketData,
    refreshMarketData,
    itemPerPageMarket,
  } = useGetMarketData({
    market_type: "worm",
    egg_type: typeFilter || "",
    sort_by_price: priceSort !== "LATEST" ? priceSort : "",
    sort_by_updated_at: priceSort === "LATEST" || !priceSort ? "DESC" : "",
    page: page,
  });

  const {
    mineEggData,
    isLoadingMineEggData,
    refreshMineEggData,
    itemPerPageMine,
  } = useGetMineEggData({
    market_type: "worm",
    egg_type: typeFilter || "",
    sort_by_price: priceSort !== "LATEST" ? priceSort : "",
    sort_by_updated_at: priceSort === "LATEST" || !priceSort ? "DESC" : "",
    page: page,
  });

  useEffect(() => {
    document
      .getElementById("market-list")
      ?.scrollTo({ top: 0, behavior: "smooth" });

    if (showOwnedItems) {
      refreshMineEggData();
    } else {
      refreshMarketData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, showOwnedItems]);

  const firstLoad = useRef(true);
  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      return;
    }
    if (typeFilter == null || typeFilter === "mythic") {
      return;
    }
    if (showOwnedItems) {
      refreshMineEggData();
    } else {
      refreshMarketData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOwnedItems, typeFilter]);

  useEffect(() => {
    if (!priceSort) return;
    if (showOwnedItems) {
      refreshMineEggData();
    } else {
      refreshMarketData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceSort]);

  const triggerReload = () => {
    setPage(1);
    refetch();
    refreshMarketData();
    refreshMineEggData();
  };

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
    if (showFilter) {
      setTypeFilter("");
      setPage(1);
    }
  };

  const handleShowOwnedItem = () => {
    setPage(1);
    setShowOwnedItems(!showOwnedItems);
    if (typeFilter == null) {
      setTypeFilter("");
    }
  };

  const [disableScroll, setDisableScroll] = useState<boolean>(false);

  useEffect(() => {
    if (showOwnedItems) {
      isLoadingMineEggData ? setDisableScroll(true) : setDisableScroll(false);
    } else {
      isLoadingMarketData ? setDisableScroll(true) : setDisableScroll(false);
    }
  }, [isLoadingMarketData, isLoadingMineEggData, showOwnedItems]);

  const total = useMemo(() => {
    if (showOwnedItems) {
      return mineEggData?.data?.total;
    } else {
      return marketData?.data?.total;
    }
  }, [marketData?.data?.total, mineEggData?.data?.total, showOwnedItems]);

  const itemsPerPage = useMemo(() => {
    if (!showOwnedItems) {
      return itemPerPageMarket;
    } else {
      return itemPerPageMine;
    }
  }, [itemPerPageMarket, itemPerPageMine, showOwnedItems]);

  const isShowPagination = useMemo(() => {
    if (!showOwnedItems) {
      if (!itemPerPageMarket) return false;
      if (!marketData?.data?.total) return false;
      return itemPerPageMarket <= marketData?.data?.total;
    } else {
      if (!itemPerPageMine) return false;
      if (!mineEggData?.data?.total) return false;
      return itemPerPageMine <= mineEggData?.data?.total;
    }
  }, [
    itemPerPageMarket,
    itemPerPageMine,
    marketData?.data?.total,
    mineEggData?.data?.total,
    showOwnedItems,
  ]);

  return (
    <div className="flex flex-col px-4 h-[calc(100%-80px)] bg-[#F2FFE0] dark:bg-transparent z-10">
      <div className="flex justify-between gap-3">
        <div
          className={clsx(
            "w-[120px] h-[43px] font-[500] flex items-center justify-center cursor-pointer capitalize rounded-lg text-xs lsm:text-sm text-[#149712] dark:bg-white/90",
            showOwnedItems
              ? "bg-gradient-to-r from-[#97C35B] to-[#61A700] border-b-[3px] border-b-[#4C7E0B] text-white"
              : "dashed-border",
          )}
          onClick={handleShowOwnedItem}
        >
          Owned items
        </div>
        <div className="flex gap-2 items-center flex-1 h-[44px]">
          <PriceSelecter changePriceSort={changePriceSort} />
          <FilterBtn onClick={handleShowFilter} showFilter={showFilter} />
        </div>
      </div>

      <Filter
        showFilter={showFilter}
        typeFilter={typeFilter}
        handleTypeFilter={handleTypeFilter}
      />

      {/*<VolumnAndFloorPrice typeFilter={typeFilter} marketType={"worm"} />*/}

      <div className="mt-[14px] flex justify-between items-center">
        <div className="flex items-center gap-1 pl-1">
          {/* <IconWallet /> */}
          <img
            src="/images/icons/token_icon.png"
            width={24}
            height={24}
            alt="token"
          ></img>
          <p className="dark:text-white text-[20px] font-[800] text-black">
            {formatNumberFloatFix(Number(formatDecimals(balance)) ?? 0, 2)}{" "}
          </p>
        </div>
        <div className="flex items-center gap-3">
          <div onClick={handleShowHistory} className="dark:hidden">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 0C18.6094 0 24 5.39062 24 12C24 18.6562 18.6094 24 12 24C9.51562 24 7.26562 23.2969 5.34375 22.0312C4.82812 21.7031 4.6875 21 5.01562 20.4844C5.39062 19.9688 6.09375 19.8281 6.60938 20.1562C8.15625 21.1875 9.98438 21.75 12 21.75C17.3438 21.75 21.75 17.3906 21.75 12C21.75 6.65625 17.3438 2.25 12 2.25C8.53125 2.25 5.48438 4.07812 3.75 6.75H6.375C6.98438 6.75 7.5 7.26562 7.5 7.875C7.5 8.53125 6.98438 9 6.375 9H1.125C0.46875 9 0 8.53125 0 7.875V2.625C0 2.01562 0.46875 1.5 1.125 1.5C1.73438 1.5 2.25 2.01562 2.25 2.625V5.01562C4.40625 2.01562 7.96875 0 12 0ZM12 6C12.6094 6 13.125 6.51562 13.125 7.125V11.5781L16.125 14.5781C16.5938 15.0469 16.5938 15.75 16.125 16.1719C15.7031 16.6406 15 16.6406 14.5781 16.1719L11.2031 12.7969C10.9688 12.6094 10.875 12.3281 10.875 12V7.125C10.875 6.51562 11.3438 6 12 6Z"
                fill="#203900"
              />
            </svg>
          </div>
          <div onClick={handleShowHistory} className="hidden dark:block">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 0C18.6094 0 24 5.39062 24 12C24 18.6562 18.6094 24 12 24C9.51562 24 7.26562 23.2969 5.34375 22.0312C4.82812 21.7031 4.6875 21 5.01562 20.4844C5.39062 19.9688 6.09375 19.8281 6.60938 20.1562C8.15625 21.1875 9.98438 21.75 12 21.75C17.3438 21.75 21.75 17.3906 21.75 12C21.75 6.65625 17.3438 2.25 12 2.25C8.53125 2.25 5.48438 4.07812 3.75 6.75H6.375C6.98438 6.75 7.5 7.26562 7.5 7.875C7.5 8.53125 6.98438 9 6.375 9H1.125C0.46875 9 0 8.53125 0 7.875V2.625C0 2.01562 0.46875 1.5 1.125 1.5C1.73438 1.5 2.25 2.01562 2.25 2.625V5.01562C4.40625 2.01562 7.96875 0 12 0ZM12 6C12.6094 6 13.125 6.51562 13.125 7.125V11.5781L16.125 14.5781C16.5938 15.0469 16.5938 15.75 16.125 16.1719C15.7031 16.6406 15 16.6406 14.5781 16.1719L11.2031 12.7969C10.9688 12.6094 10.875 12.3281 10.875 12V7.125C10.875 6.51562 11.3438 6 12 6Z"
                fill="white"
              />
            </svg>
          </div>
          <div
            onClick={() =>
              navigate(navPaths.INVENTORY, { state: { showWorm: true } })
            }
            className="btn-hover border-black text-white font-bold h-[28px] w-[64px] flex items-center justify-center cursor-pointer capitalize rounded-[4px] bg-gradient-to-r from-[#97C35B] to-[#61A700] border-b-[3px] border-b-[#4C7E0B]"
          >
            Sell
          </div>
        </div>
      </div>

      {/* {isLoadingMarketData || isLoadingMineEggData ? (
        <div className="w-full flex-1 flex items-center justify-center">
          <CircularProgress color="success" />
        </div>
      ) : ( */}

      <div
        id="market-list"
        className="relative pb-7 mt-4 overflow-scroll"
        // onScroll={handleScroll}
      >
        <List
          marketType={"worm"}
          marketData={marketData?.data}
          mineData={mineEggData?.data}
          showOwnedItems={showOwnedItems}
          userID={userID}
          triggerReload={triggerReload}
          typeFilter={typeFilter}
          page={page}
          // setPage={setPage}
          // loading={disableScroll}
        />
        {disableScroll && (
          <div className=" backdrop-blur-sm fixed inset-0 top-[65px] flex items-center justify-center z-50">
            <CircularProgress style={{ color: "#F2FFE0" }} />
          </div>
        )}
        {isShowPagination && typeFilter !== "mythic" && (
          <Pagination
            total={total}
            page={page}
            setPage={onSetPage}
            itemsPerPage={itemsPerPage}
          />
        )}
      </div>

      {/* )} */}

      <ModalContainer>
        {isShowModal && (
          <HistoryModal
            ref={historyModalRef}
            marketType={"worm"}
            isShowModal={isShowModal}
            setIsShowModal={setIsShowModal}
            userID={userID}
          />
        )}
      </ModalContainer>
    </div>
  );
};

export default WormMarket;
