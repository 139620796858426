import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function sellWorm(id: string[]) {
  return await api.post(`/api/v1/worms/exchange`, { ids: id });
}

// export default function useSellWorm() {
//   return useMutation({
//     mutationKey: ["sellWorm"],
//     mutationFn: sellWorm,
//   });
// }


// export const sellWorm = async (id: string[]) => {
//   const response = await fetchApi(
//     "POST",
//     "/worms/exchange",
//     JSON.stringify({ids: id})
//   );
//   if (response.error) {
//     return {
//       error: response.error.message,
//     };
//   }

//   const sellWormResponse: ServerResponse<null> = await response.data.json();
//   if (sellWormResponse.error) {
//     return {
//       error: sellWormResponse.error.message,
//     };
//   }

//   return {
//     data: true,
//   };
// };

export default function useSellWorm() {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["sellWorm"],
    mutationFn: sellWorm,
  });

  return { sellAsync: mutateAsync, isSellingWorm: isPending };
}
