import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerResponse } from "../../../types/api";

export const getMemberDetail = async ({ sort_by }: { sort_by?: string }) => {
  const response = await fetchApi("GET", `/guild/member/detail`);
  if (response.error) {
    throw new Error("network-error");
  }

  const getMemberDetailResponse: ServerResponse<any> =
    await response.data.json();
  if (getMemberDetailResponse.error) {
    return {
      error: getMemberDetailResponse.error,
    };
  }

  return getMemberDetailResponse.data;
};

export default function useMemberDetails({ sort_by }: { sort_by?: string }) {
  const { data, isPending, refetch, isRefetching } = useQuery({
    queryKey: ["MemberDetails"],
    queryFn: () => getMemberDetail({ sort_by }),
    retry: 1,
  });

  return {
    MemberDetails: data,
    MemberDetailsLoading: isPending,
    refeshMemberDetails: refetch,
    isRefetching,
  };
}
