import clsx from "clsx";
import { forwardRef } from "react";
import { CircularProgress } from "@mui/material";
import Backdrop from "../../../components/common/Backdrop";
import { motion } from "framer-motion";
import { dropIn } from "../../../components/common/ModalContainer";

const HistoryModal = forwardRef(
  (
    {
      purchaseHistoryLoading,
      setIsShowModal,
      data,
    }: {
      purchaseHistoryLoading: boolean;
      data: any;
      isShowModal: boolean;
      setIsShowModal: any;
    },
    ref: any
  ) => {
    return (
      <Backdrop onClick={() => setIsShowModal(false)} className=" items-end">
        <motion.div
          onClick={(e) => e.stopPropagation()}
          variants={dropIn}
          initial="hiddenHistory"
          animate="visible"
          exit="exit"
          ref={ref}
          id="historyContainer"
          className={clsx(
            "bg-[#F2FFE0] rounded-t-[20px] p-[24px] w-full h-[80%] pb-5 flex flex-col gap-3 items-center relative"
          )}
        >
          <div className="flex items-center justify-between w-full font-[600]">
            <div className="flex-1">Item</div>
            <div className="flex-1 text-center -ml-8 mr-2">ID</div>
            <div className="flex-1 text-center">Num</div>
            <div className="flex-1 text-center">Price</div>
            <div className="flex-1 text-center mr-2">Time</div>
            <div className="flex-1 text-end">Status</div>
          </div>
          <hr className="border-dotted border-t-2 border-black text-white bg-white h-[1px] w-full" />
          {purchaseHistoryLoading ? (
            <div className="mt-4 w-full h-full flex items-center justify-center">
              <CircularProgress color="success" />
            </div>
          ) : (
            <div
              id="historyList"
              className={clsx("flex flex-col gap-3 w-full overflow-auto")}
            >
              {data?.map((item: any, index: number) => {
                const eggId = item?.id?.slice(
                  item?.egg_id?.length - 9,
                  item?.egg_id?.length
                );
                const wormId = item?.id?.slice(
                  item?.id?.length - 9,
                  item?.id?.length
                );
                const id = item.type === "worm" ? wormId : eggId;

                const time = new Date(item?.created_at);
                const timeString = time.getMonth() + 1 + "/" + time.getDate();

                const quantity = item.worm_items?.[0]?.amount ?? 0;
                return (
                  <div
                    key={index}
                    className="flex items-center justify-between w-full"
                  >
                    <div className="flex-1 ">
                      <div className="w-fit relative">
                        <img
                          src={
                            item.type === "worm"
                              ? `/images/inventory/worm/${item.name.toLowerCase()}.png`
                              : item.package_id === "iap_box__multi__0" ||
                                item.package_id === "iap_box__single__0"
                              ? "images/purchase/box.png"
                              : `images/purchase/${item.package_id}.png`
                          }
                          alt={"market-item"}
                          className="h-[25px] aspect-square object-contain "
                        />
                        {item.type === "worm" && (
                          <div className="absolute bg-[#00000080] text-white rounded-[10px] text-[6px] px-[2px] bottom-0 right-0">
                            {`x${quantity}`}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex-1 te`xt-center -ml-8 mr-2 uppercase">
                      {id?.slice(0, 2) +
                        "..." +
                        id?.slice(id?.length - 2, id?.length)}
                    </div>
                    <div className="flex-1 text-center capitalize">
                      {item.quantity}
                    </div>
                    <div className="flex-1 text-center text-ellipsis max-w-[80px] overflow-clip">
                      {item?.price}
                    </div>
                    <div className="flex-1 text-center">{timeString}</div>
                    <div className="flex-1 min-w-[73px]">
                      {item && item.transaction_id != null ? (
                        <div className="flex-1 text-end font-[600] capitalize text-[#4F7815]">
                          Done
                        </div>
                      ) : (
                        <div className="flex-1 text-end font-[600] capitalize text-[#FF8515]">
                          Pending
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </motion.div>
      </Backdrop>
    );
  }
);

export default HistoryModal;
