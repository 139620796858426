import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { Result } from "../../../types/utils";
import { ServerError, ServerResponse } from "../../../types/api";

export const getCurrentSeason = async (): Promise<Result<any, ServerError>> => {
  const response = await fetchApi("GET", `/guild/season`);
  if (response.error) {
    // Throw to retry
    throw new Error("network-error");
  }

  const currentSeasonResponse: ServerResponse<any> = await response.data.json();
  if (currentSeasonResponse.error) {
    return {
      error: currentSeasonResponse.error,
    };
  }

  return {
    data: currentSeasonResponse.data,
  };
};

export default function useGetCurrentSeason() {
  const { data, refetch, isFetching, isRefetching } = useQuery({
    queryKey: ["CurrentSeason"],
    queryFn: () => getCurrentSeason(),
    retry: 1,
  });

  return {
    currentSeason: data,
    isLoadingCurrentSeason: isFetching,
    refreshCurrentSeason: refetch,
    isRefreshingCurrentSeason: isRefetching,
  };
}
