import clsx from "clsx";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Slide, toast, ToastContainer } from "react-toastify";
import ModalContainer from "../../../components/common/ModalContainer";
import HistoryModal from "../Components/HistoryModal";
import { useClickOutside } from "../../../Hooks/useClickOutside";
import { navPaths } from "../../../constants/navbar.constants";
import { Box, CircularProgress, circularProgressClasses } from "@mui/material";
import usePackages from "../Hooks/usePackages";
import LoadingScreen from "../../Global/Pages/LoadingScreen";
import useBuyBox from "../Hooks/useBuyBox";
import usePurchaseHistory from "../Hooks/usePurchaseHistory";
import { sleep } from "../../../utils/time";
import OpeningPackages from "../Components/OpeningPackages";
import ClosedPackages from "../Components/ClosedPackages";
import { motion } from "framer-motion";
import SpecialItems from "../Components/SpecialItems";

const Purchase = () => {
  const navigate = useNavigate();
  const tele = window.Telegram.WebApp;

  tele.BackButton.show();
  tele.BackButton.onClick(() => handleBackBtn());

  const { packages, packagesLoading } = usePackages();
  const { buyBoxAsync, isBuyingBox } = useBuyBox();
  const { purchaseHistory, purchaseHistoryLoading, refeshPurchaseHistory } =
    usePurchaseHistory();

  const [isOpenHistoryModal, setisOpenHistoryModal] = useState<boolean>(false);
  const [isBuyingPackage, setIsBuyingPackage] = useState<boolean>(false);

  const historyModalRef = useRef<HTMLDivElement>(null);
  useClickOutside(historyModalRef, () => {
    setisOpenHistoryModal(false);
  });

  const hasUnopenedBox = useMemo(() => {
    return purchaseHistory?.some(
      (item: any) => item.is_opened === false && item.transaction_id != null
    );
  }, [purchaseHistory]);

  const [boxPackages, wormPackages] = useMemo(() => {
    if (!packages) {
      return [[], []];
    }
    const worms = packages.filter((item: any) => item.type === "worm");
    const boxes = packages.filter((item: any) => item.type !== "worm");

    return [boxes, worms];
  }, [packages]);

  const tabButtons = [
    { id: "special_item", title: "Special Items" },
    { id: "egg_box", title: "Egg Box" },
    { id: "closed", title: "Ended" },
  ];

  const handleBackBtn = () => {
    navigate("/");
  };
  const [tab, setTab] = useState("special_item");
  const handleChangeTab = (tab: { id: string; title: string }) => {
    setTab(tab.id);
  };

  useEffect(() => {
    if (hasUnopenedBox && tab !== "special_item") {
      navigate(navPaths.OPEN_PURCHASE_BOX);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUnopenedBox]);

  const handleBuy = (id: string) => {
    buyBoxAsync(id)
      .then((data) => {
        tele.openInvoice(data?.data?.data, async (status: string) => {
          if (status === "paid") {
            setIsBuyingPackage(true);
            for (let i = 0; i <= 5; i++) {
              const refresedData = (await refeshPurchaseHistory()).data;

              const hasUnOpenedBox =
                refresedData?.length > 0 &&
                !refresedData?.[0]?.is_opened &&
                refresedData?.[0]?.transaction_id != null;

              if (hasUnOpenedBox) {
                setIsBuyingPackage(false);
                navigate(navPaths.OPEN_PURCHASE_BOX);
                return;
              }
              await sleep(2000);
            }
            toast.error("Egg Box might be delayed. Contact us if needed.", {
              style: { maxWidth: 337, height: 40, borderRadius: 8 },
              autoClose: 2000,
            });
          }
        });
      })
      .catch((error) => {
        setIsBuyingPackage(false);
        toast.error(error?.response?.data?.message, {
          style: { maxWidth: 337, height: 50, borderRadius: 8 },
          autoClose: 2000,
        });
      })
      .finally(() => {
        setIsBuyingPackage(false);
      });
  };

  if (packagesLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="pt-[60px] px-4 pb-[50px] bg-[#F2FFE0] dark:bg-transparent h-screen relative z-30">
      <ToastContainer
        position="top-left"
        closeOnClick
        transition={Slide}
        hideProgressBar
        limit={1}
        className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
      />
      {(isBuyingBox || isBuyingPackage) && (
        <div
          className={clsx(
            "flex pointer-events-none justify-center items-center h-screen w-screen bg-[#00000099] absolute left-0 right-0 top-0 z-40"
          )}
        >
          <Box sx={{ position: "relative" }}>
            <CircularProgress
              variant="determinate"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light" ? "#9BD449" : "#9BD449",
                borderRadius: "20px",
              }}
              size={56}
              thickness={6}
              value={100}
            />
            <CircularProgress
              variant="indeterminate"
              disableShrink
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light" ? "#F2FFE0" : "#F2FFE0",
                animationDuration: "550ms",
                position: "absolute",
                left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: "round",
                },
              }}
              size={56}
              thickness={6}
            />
          </Box>
        </div>
      )}

      <>
        <div className="text-[#1B3000] fixed top-0 left-0 right-0 flex text-sm font-extrabold mb-4 bg-[#FFFFFF] border-b border-[#D0D8C6]">
          {tabButtons.map((btn) => (
            <button
              key={btn.id}
              onClick={() => handleChangeTab(btn)}
              className={clsx(
                "px-3 w-[50%] h-[48px] relative",
                tab === btn.id && "text-[#149712]"
              )}
            >
              {btn.title}
              {tab === btn.id && (
                <motion.div
                  className="h-[3px] bg-[#149712] absolute right-0 left-0 -bottom-[1px]"
                  layoutId="underline"
                />
              )}
            </button>
          ))}
        </div>
        <div className="flex justify-end mb-5">
          <button
            onClick={() => {
              setisOpenHistoryModal(true);
            }}
            className={clsx(
              "py-[6px] px-[21px] w-fit bg-[#00000099] rounded-[16px] flex gap-2 text-[#fff] items-center justify-center",
              "dark:border-[#4C7E0B] border-[1px]"
            )}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 0C18.6094 0 24 5.39062 24 12C24 18.6562 18.6094 24 12 24C9.51562 24 7.26562 23.2969 5.34375 22.0312C4.82812 21.7031 4.6875 21 5.01562 20.4844C5.39062 19.9688 6.09375 19.8281 6.60938 20.1562C8.15625 21.1875 9.98438 21.75 12 21.75C17.3438 21.75 21.75 17.3906 21.75 12C21.75 6.65625 17.3438 2.25 12 2.25C8.53125 2.25 5.48438 4.07812 3.75 6.75H6.375C6.98438 6.75 7.5 7.26562 7.5 7.875C7.5 8.53125 6.98438 9 6.375 9H1.125C0.46875 9 0 8.53125 0 7.875V2.625C0 2.01562 0.46875 1.5 1.125 1.5C1.73438 1.5 2.25 2.01562 2.25 2.625V5.01562C4.40625 2.01562 7.96875 0 12 0ZM12 6C12.6094 6 13.125 6.51562 13.125 7.125V11.5781L16.125 14.5781C16.5938 15.0469 16.5938 15.75 16.125 16.1719C15.7031 16.6406 15 16.6406 14.5781 16.1719L11.2031 12.7969C10.9688 12.6094 10.875 12.3281 10.875 12V7.125C10.875 6.51562 11.3438 6 12 6Z"
                fill="white"
              />
            </svg>
            <p className="text-sm">History</p>
          </button>
        </div>
        <div className="h-[calc(100%-40px)] overflow-auto">
          {tab === "egg_box" && (
            <OpeningPackages
              packages={boxPackages}
              handleBuy={(id: string) => handleBuy(id)}
              isBuyingBox={isBuyingBox}
            />
          )}
          {tab === "special_item" && <SpecialItems packages={wormPackages} />}
          {tab === "closed" && (
            <ClosedPackages
              packages={packages}
              handleBuy={(id: string) => handleBuy(id)}
              isBuyingBox={isBuyingBox}
            />
          )}
        </div>
      </>

      <ModalContainer>
        {isOpenHistoryModal && (
          <HistoryModal
            ref={historyModalRef}
            isShowModal={isOpenHistoryModal}
            setIsShowModal={setisOpenHistoryModal}
            purchaseHistoryLoading={purchaseHistoryLoading}
            data={purchaseHistory}
          />
        )}
      </ModalContainer>
    </div>
  );
};

export default Purchase;
