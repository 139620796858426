import { Button } from "@mui/material";
import clsx from "clsx";
import { forwardRef, useState } from "react";
import { ITEM_TYPES } from "../../Market/Pages/Market";
import useHatchEgg from "../Hooks/useHatchEgg";
import { LoadingButton } from "@mui/lab";
import { navPaths } from "../../../constants/navbar.constants";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const HatchEggModal = forwardRef(
  (
    {
      isShowModal,
      showModal,
      egg,
    }: {
      isShowModal: boolean;
      showModal: any;
      egg: any;
    },
    ref: any
  ) => {
    const navigate = useNavigate();
    const { hatchEggAsync, isHatchingEgg } = useHatchEgg();
    const [birdData, setBirdData] = useState<any>(null);

    const color = ITEM_TYPES.find((item) => item.type === egg?.type)?.color;

    const handleHatchEgg = () => {
      hatchEggAsync(egg?.id)
        .then((data) => {
          setBirdData(data?.data?.data);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            style: { maxWidth: 337, height: 50, borderRadius: 8 },
            autoClose: 2000,
          });
        });
    };

    return (
      <div
        className={clsx(
          "bg-black/70 absolute z-50 inset-0 flex items-center justify-center px-7",
          isShowModal ? "visible" : " invisible"
        )}
      >
        <div
          ref={ref}
          className={clsx(
            "bg-[#F2FFE0] rounded-[20px] p-[24px] flex flex-col gap-4 items-center justify-center relative",
            isShowModal ? "translate-y-0" : "translate-y-[400%]",
            "transition-all duration-300 ease-in-out"
          )}
        >
          {/* close btn  */}
          <div
            onClick={() => showModal(false)}
            className=" absolute -right-3 -top-5 btn-hover bg-white rounded-full drop-shadow-[0_4px_0px_#4C7E0B]"
          >
            <svg
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23 46C10.2422 46 0 35.7578 0 23C0 10.332 10.2422 0 23 0C35.668 0 46 10.332 46 23C46 35.7578 35.668 46 23 46ZM15.7227 15.7227C14.8242 16.6211 14.8242 17.9688 15.7227 18.7773L19.9453 23L15.7227 27.2227C14.8242 28.1211 14.8242 29.4688 15.7227 30.2773C16.5312 31.1758 17.8789 31.1758 18.6875 30.2773L22.9102 26.0547L27.1328 30.2773C28.0312 31.1758 29.3789 31.1758 30.1875 30.2773C31.0859 29.4688 31.0859 28.1211 30.1875 27.2227L25.9648 23L30.1875 18.7773C31.0859 17.9688 31.0859 16.6211 30.1875 15.7227C29.3789 14.9141 28.0312 14.9141 27.1328 15.7227L22.9102 19.9453L18.6875 15.7227C17.8789 14.9141 16.5312 14.9141 15.7227 15.7227Z"
                fill="url(#paint0_linear_3658_4024)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3658_4024"
                  x1="6.9"
                  y1="6.9"
                  x2="34.5"
                  y2="41.975"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#97C35B" />
                  <stop offset="1" stopColor="#63A803" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          {birdData ? (
            <>
              <div className="w-[250px] text-center capitalize text-[20px] font-[800]">
                Congratulations
              </div>
              <div className="w-[40%]">
                <img
                  src={`/images/bird/${birdData.type}.png?v=6`}
                  alt={"hatch-egg"}
                  className="w-full object-contain z-10 -mt-2"
                />
              </div>
              <div className="capitalize text-[24px] font-spicy-rice-regular text-center mb-2">{`${birdData.type} Bird`}</div>
              <div
                className={clsx(
                  "font-[600] text-[16px] text-white rounded-[20px] px-2 py-1 flex items-center gap-1 uppercase -mt-2"
                )}
                style={{ backgroundColor: color }}
              >
                <div className="bg-white rounded-full text-[#646464] w-[18px] h-[18px] flex items-center justify-center">
                  #
                </div>
                <span
                  className={
                    birdData?.type === "penguin"
                      ? "text-black/70"
                      : "text-white"
                  }
                >
                  {birdData?.id?.slice(egg?.id?.length - 9, egg?.id?.length)}
                </span>
              </div>
            </>
          ) : (
            <>
              <div className="w-[250px] text-center capitalize text-[20px] font-[800]">
                {`${egg?.type} Egg`}
              </div>
              <div className="w-[40%]">
                <img
                  src={`/images/egg/${egg?.type}.png`}
                  alt={"hatch-egg"}
                  className="w-full object-contain z-10 -mt-2"
                />
              </div>
              <div
                className={clsx(
                  "font-[600] text-[16px] text-white rounded-[20px] px-2 py-1 flex items-center gap-1 uppercase -mt-2"
                )}
                style={{ backgroundColor: color }}
              >
                <div className="bg-white rounded-full text-[#646464] w-[18px] h-[18px] flex items-center justify-center">
                  #
                </div>
                <span
                  className={
                    egg?.type === "common" ? "text-black/70" : "text-white"
                  }
                >
                  {egg?.id?.slice(egg?.id?.length - 9, egg?.id?.length)}
                </span>
              </div>
            </>
          )}
          {birdData ? (
            <div className="flex items-center gap-3 w-full">
              <LoadingButton
                loading={isHatchingEgg}
                onClick={() =>
                  navigate(navPaths.INVENTORY, {
                    state: {
                      from: navPaths.BIRD,
                    },
                  })
                }
                sx={{ border: 1 }}
                className={clsx(
                  "font-bold capitalize text-[16px] text-white py-2 lsm:py-[18px] w-full h-[50px] rounded-xl ",
                  "btn-hover bg-gradient-to-r from-[#97C35B] to-[#61A700] drop-shadow-[0_4px_0px_#4C7E0B] border-none"
                )}
              >
                Check Inventory
              </LoadingButton>
            </div>
          ) : (
            <div className="flex items-center gap-3 w-full">
              <Button
                sx={{ border: 1 }}
                onClick={() => showModal(false)}
                className={clsx(
                  "font-bold capitalize text-[16px] py-2 lsm:py-[18px] w-full h-[50px] rounded-xl ",
                  "btn-hover bg-[#F2FFE0] drop-shadow-[0_3px_0px_#4C7E0B] text-[#4C7E0B] border-[2px]"
                )}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={isHatchingEgg}
                onClick={() => handleHatchEgg()}
                sx={{ border: 1 }}
                className={clsx(
                  "font-bold capitalize text-[16px] text-white py-2 lsm:py-[18px] w-full h-[50px] rounded-xl ",
                  "btn-hover bg-gradient-to-r from-[#97C35B] to-[#61A700] drop-shadow-[0_4px_0px_#4C7E0B] border-none"
                )}
              >
                Hatch
              </LoadingButton>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default HatchEggModal;
