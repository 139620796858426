import clsx from "clsx";

const LuckySpiner = () => {
  return (
    <div
      className={clsx(
        "px-[17px] mb-3 rounded-2xl bg-cover bg-no-repeat bg-center w-full h-[149px] relative flex items-center justify-center"
      )}
      style={{ backgroundImage: "url('/images/games/lucky_spin_bg.png')" }}
    >
      <div className="absolute top-[50%] -translate-y-[50%] flex flex-col items-center justify-center">
        <svg
          width="20"
          height="22"
          viewBox="0 0 20 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 2.75C7.99107 2.75 6.42857 4.29688 6.42857 6.1875V8.25H13.5714V6.1875C13.5714 4.29688 11.9643 2.75 10 2.75ZM3.57143 6.1875C3.57143 2.79297 6.42857 0 10 0C13.5268 0 16.4286 2.79297 16.4286 6.1875V8.25H17.1429C18.7054 8.25 20 9.49609 20 11V19.25C20 20.7969 18.7054 22 17.1429 22H2.85714C1.25 22 0 20.7969 0 19.25V11C0 9.49609 1.25 8.25 2.85714 8.25H3.57143V6.1875ZM11.4286 13.75C11.4286 13.0195 10.7589 12.375 10 12.375C9.19643 12.375 8.57143 13.0195 8.57143 13.75V16.5C8.57143 17.2734 9.19643 17.875 10 17.875C10.7589 17.875 11.4286 17.2734 11.4286 16.5V13.75Z"
            fill="white"
          />
        </svg>

        <p className="game_lucky_text_border mt-[2px]">Lucky Wheel</p>
        <div className="bg-[#FFFFFF33] rounded-[20px] px-[6px] py-[1.5px] poppins-semibold text-[#fff] text-sm">
          Comming Soon
        </div>
      </div>
    </div>
  );
};

export default LuckySpiner;
