import { forwardRef } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { navPaths } from "../../../constants/navbar.constants";

const HowToColectModal = forwardRef(
  (
    {
      isShowModal,
      showModal,
    }: {
      isShowModal: boolean;
      showModal: any;
    },
    ref: any
  ) => {
    const navigate = useNavigate();
    return (
      <div
        className={clsx(
          "bg-black/70 absolute z-50 inset-0 flex items-center justify-center px-7 ",
          isShowModal ? "visible" : " invisible"
        )}
      >
        <div
          ref={ref}
          className={clsx(
            "bg-[#F2FFE0] w-[100%] rounded-[20px] p-[24px] flex flex-col gap-4 items-center justify-center relative",
            isShowModal ? "translate-y-0" : "translate-y-[400%]",
            "transition-all duration-300 ease-in-out"
          )}
        >
          {/* close btn  */}
          <div
            onClick={() => showModal(false)}
            className=" absolute -right-2 -top-5 btn-hover bg-white rounded-full drop-shadow-[0_4px_0px_#4C7E0B]"
          >
            <svg
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23 46C10.2422 46 0 35.7578 0 23C0 10.332 10.2422 0 23 0C35.668 0 46 10.332 46 23C46 35.7578 35.668 46 23 46ZM15.7227 15.7227C14.8242 16.6211 14.8242 17.9688 15.7227 18.7773L19.9453 23L15.7227 27.2227C14.8242 28.1211 14.8242 29.4688 15.7227 30.2773C16.5312 31.1758 17.8789 31.1758 18.6875 30.2773L22.9102 26.0547L27.1328 30.2773C28.0312 31.1758 29.3789 31.1758 30.1875 30.2773C31.0859 29.4688 31.0859 28.1211 30.1875 27.2227L25.9648 23L30.1875 18.7773C31.0859 17.9688 31.0859 16.6211 30.1875 15.7227C29.3789 14.9141 28.0312 14.9141 27.1328 15.7227L22.9102 19.9453L18.6875 15.7227C17.8789 14.9141 16.5312 14.9141 15.7227 15.7227Z"
                fill="url(#paint0_linear_3658_4024)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3658_4024"
                  x1="6.9"
                  y1="6.9"
                  x2="34.5"
                  y2="41.975"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#97C35B" />
                  <stop offset="1" stopColor="#63A803" />
                </linearGradient>
              </defs>
            </svg>
          </div>

          <div className={clsx("font-bold text-center text-[20px] w-full")}>
            How to Collect Tickets
          </div>

          <div className="w-full grid grid-cols-7 gap-2 bg-[#E4F0D3] rounded-[12px] p-2">
            <div className="flex items-center justify-center">
              <div className="w-[40px] h-[40px] flex items-center justify-center  ">
                <div className="absolute z-10">
                  <img
                    src="images/lucky_spin/fusion/fire.png"
                    className="w-[40px] h-[40px]"
                    alt=""
                  ></img>
                </div>
              </div>
            </div>
            <div
              onClick={() => navigate(navPaths.STREAK)}
              className="col-span-6 text-sm"
            >
              <p className="font-bold">Daily Streak</p>
              <p className="">Play daily to earn tickets</p>
            </div>
          </div>
          <div className="w-full grid grid-cols-7 gap-2 bg-[#E4F0D3] rounded-[12px] p-2">
            <div className="flex items-center justify-center">
              <div className="w-[40px] h-[40px] flex items-center justify-center ">
                <div className="absolute z-10">
                  <img
                    src="images/navbar/friends.png"
                    className="w-[40px] h-[40px]s"
                    alt=""
                  ></img>
                </div>
              </div>
            </div>
            <div
              onClick={() => navigate(navPaths.FRIENDS)}
              className="col-span-6 text-sm"
            >
              <p className="font-bold">Invite Friends</p>
              <p className="">Get tickets by inviting friends</p>
            </div>
          </div>
          <div className="w-full grid grid-cols-7 gap-2 bg-[#E4F0D3] rounded-[12px] p-2">
            <div className="w-[40px] h-[40px] flex items-center col-span-1">
              <div className="absolute z-10">
                <img
                  src="images/lucky_spin/fusion/puzzle.png"
                  className="w-[40px] h-[40px]"
                  alt=""
                ></img>
              </div>
            </div>
            <div
              // onClick={() => {
              //   toast.error("Comming soon", {
              //     style: { maxWidth: 337, height: 40, borderRadius: 8 },
              //     autoClose: 2000,
              //   });
              // }}
              className="col-span-6 text-sm"
            >
              <p className="font-bold">Bounty Quests</p>
              <p className="">Coming soon</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default HowToColectModal;
