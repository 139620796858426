import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerError, ServerResponse } from "../../../types/api";
import { Worm } from "../../../types/worm";
import { Result } from "../../../types/utils";

export const getAllBird = async (): Promise<Result<any, ServerError>> => {
  const response = await fetchApi("GET", "/bird/me-all");
  if (response.error) {
    // Throw to retry
    throw new Error("network-error");
  }

  const birdResponse: ServerResponse<Worm> = await response.data.json();
  if (birdResponse.error) {
    return {
      error: birdResponse.error,
    };
  }

  return {
    data: birdResponse.data,
  };
};

export default function useAllBird() {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["AllBird"],
    queryFn: getAllBird,
    retry: 1,
  });

  return {
    AllBirds: data,
    isGettingAllBirds: isLoading,
    refreshAllBirds: refetch,
  };
}
