import {
  TonProofItemReplySuccess,
  toUserFriendlyAddress,
  useTonConnectUI,
  useTonWallet,
} from "@tonconnect/ui-react";
import clsx from "clsx";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Slide, toast, ToastContainer } from "react-toastify";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ModalContainer from "../../../components/common/ModalContainer";
import { useAccountV2 } from "../../../components/Hooks/useRegisterV2";
import { useClickOutside } from "../../../Hooks/useClickOutside";
import { useChangeMode } from "../../../store/modeStore";
import {
  formatDecimals,
  formatNumberFloatFix,
} from "../../../utils/formatNumber";
import { sleep } from "../../../utils/time";
import LoadingScreen from "../../Global/Pages/LoadingScreen";
import { useAccountBalance } from "../../Home/Hooks/useGetAcountBalance";
import AchieveModal from "../Components/AchieveModal";
import ConfirmConnectModal from "../Components/ConfirmConnectModal";
import ConfirmDisconnectModal from "../Components/ConfirmDisconnectWalletModal";
import useConectWallet from "../Hooks/useConnectWallet";
import useInitConect from "../Hooks/useInitConectWallet";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { navPaths } from "../../../constants/navbar.constants";
// import ClaimedTicket from "../../Streak/components/SVG/ClaimedTicket";

const CustomChip = ({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick?: () => void;
}) => {
  return (
    <div
      onClick={onClick && onClick}
      className={clsx(
        "py-[6px] px-2 bg-[#0000001A] rounded-[4px] flex gap-1 items-center justify-center dark:bg-[#313131] cursor-pointer",
      )}
    >
      {children}
    </div>
  );
};
const Profile = () => {
  const tele = window.Telegram.WebApp;
  const navigate = useNavigate();
  const [tonConnectUI] = useTonConnectUI();
  const tonWallet = useTonWallet();
  // const location = useLocation();

  const {
    accountV2,
    isLoading: isLoadingProfile,
    refetch: refeshAcountV2,
  } = useAccountV2();
  const mode = localStorage.getItem("mode");
  const changeMode = useChangeMode((state: any) => state.updateMode);
  const { balance } = useAccountBalance();
  // const { mutateAsync: claimInitBonusAsync, isPending: isClaiming } =
  //   useClaimInitBonus();
  const { mutateAsync: connectInitAsync } = useInitConect();
  const { mutateAsync: connectWalletAsync } = useConectWallet();

  const [isOpenConfirmConnectModal, setIsOpenConfirmConnectModal] =
    useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [isOpenConfirmDisconnectModal, setIsOpenConfirmDisconnectModal] =
    useState<boolean>(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const confirmConnectModalRef = useRef<any>();
  const confirmDisconnectModalRef = useRef<any>();

  const UserAge = accountV2?.data?.age;
  // const userID = tele.initDataUnsafe?.user?.id;
  // const seedAmount = formatNumberFloatFix(
  //   Number(
  //     formatDecimals(
  //       (accountV2?.data?.bonus_og || 0) + (accountV2?.data?.bonus_premium || 0)
  //     )
  //   ),
  //   0
  // );
  tele.BackButton.show();
  tele.BackButton.onClick(() => navigate("/"));

  useClickOutside(confirmConnectModalRef, () => {
    setIsOpenConfirmConnectModal(false);
    if (tonConnectUI && tonConnectUI.connected) {
      tonConnectUI.disconnect();
    }
  });

  const [theme, setTheme] = useState<string>(
    mode === "dark" ? "dark" : "light",
  );

  const handleOpenLink = () => {
    tele.showPopup(
      {
        message: `Do you want to open link`,
        buttons: [
          { id: "link", type: "default", text: "Open" },
          { type: "cancel" },
        ],
      },
      async function (btn: any) {
        if (btn === "link") {
          tele.openLink("https://twitter.com/seedcombinator");
        } else {
        }
      },
    );
  };

  const countAchive = useMemo(() => {
    if (!accountV2?.data) {
      return 0;
    }

    const achives = [
      accountV2?.data.achieve,
      accountV2?.data?.achieve_friend_badge,
      accountV2?.data?.achieve_web3_badge,
    ].filter((item: any) => item);

    return achives.length;
  }, [accountV2?.data]);

  useEffect(() => {
    if (
      tonConnectUI &&
      tonConnectUI.connected &&
      !tonWallet?.connectItems?.tonProof
    ) {
      tonConnectUI.disconnect();
    }
  }, [tonConnectUI, tonWallet]);

  useEffect(() => {
    !isLoadingProfile &&
      !accountV2?.data?.wallet_address_ton &&
      connectInitAsync()
        .then((data) => {
          setData(data?.data?.data);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountV2, isLoadingProfile]);

  useEffect(() => {
    if (!tonConnectUI) {
      return;
    }
    tonConnectUI.setConnectRequestParameters({
      state: "ready",
      value: { tonProof: data },
    });
  }, [data, tonConnectUI]);

  useEffect(() => {
    if (
      tonWallet?.connectItems?.tonProof &&
      !accountV2?.data?.wallet_address_ton
    ) {
      setIsOpenConfirmConnectModal(true);
      return;
    }
  }, [accountV2?.data?.wallet_address_ton, tonWallet]);

  const handleConnectWallet = () => {
    tonConnectUI.openModal();
  };

  const body = useMemo(() => {
    const proof = tonWallet?.connectItems?.tonProof as TonProofItemReplySuccess;

    return {
      address: tonWallet?.account?.address,
      network: tonWallet?.account?.chain,
      proof: {
        ...proof?.proof,
        stateInit: tonWallet?.account?.walletStateInit,
      },
    };
  }, [tonWallet]);

  // const handleClaim = () => {
  //   const customText = `I've%20just%20successfully%20claim%20${seedAmount}%20%24SEED%20in%20my%20Telegram%20for%20being%20in%20the%20Top%20${accountV2?.data?.top_rate}%25!%20%F0%9F%8C%9F%0A%0ADon't%20miss%20out%20%E2%80%93%20get%20your%20treasure%20here%20before%20it's%20too%20late%3A%20t.me%2Fseed_coin_bot%2Fapp%3Fstartapp%3D${userID}%0A%0A`;

  //   claimInitBonusAsync()
  //     .then(async () => {
  //       // toast.error("claim successfully", {
  //       //   style: { maxWidth: 337, height: 40, borderRadius: 8 },
  //       //   autoClose: 2000,
  //       // });
  //     })
  //     .catch((error) => {
  //       toast.error(error?.response?.data?.message, {
  //         style: { maxWidth: 337, height: 40, borderRadius: 8 },
  //         autoClose: 2000,
  //       });
  //     })
  //     .finally(async () => {
  //       await sleep(500);
  //       refreshBalance();
  //       refeshAcountV2();
  //     });
  //   tele.showPopup(
  //     {
  //       message: `Do you want to open link`,
  //       buttons: [
  //         { id: "link", type: "default", text: "Open" },
  //         { type: "cancel" },
  //       ],
  //     },
  //     async function (btn: any) {
  //       if (btn === "link") {
  //         tele.openLink(
  //           `https://twitter.com/intent/tweet?text=${customText}&hashtags=seedapp%2Cseedcoin%2CSeedizens`
  //         );
  //       } else {
  //       }
  //     }
  //   );
  // };

  const handleSaveWallet = () => {
    connectWalletAsync({
      address: body.address || "",
      network: body.network || "",
      proof: body.proof,
    })
      .then(() => {
        toast.error("Connect wallet succes fully", {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
        });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
        });
      })
      .finally(async () => {
        await sleep(500);
        refeshAcountV2();
      });
  };

  const handleSwitchMode = () => {
    setTheme(mode === "light" ? "dark" : "light");
    localStorage.setItem("mode", mode === "light" ? "dark" : "light");
    changeMode(mode === "light" ? "dark" : "light");
    if (mode === "light") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  };

  const [showAchieve, setShowAchieve] = useState<boolean>(false);
  const handleShowAchieve = () => setShowAchieve((prev) => !prev);
  const [showGuardian, setShowGuardian] = useState<boolean>(false);
  const handleShowGuardian = () => setShowGuardian((prev) => !prev);

  if (isLoadingProfile) {
    return <LoadingScreen />;
  }

  return (
    <>
      <ToastContainer
        position="top-left"
        closeOnClick
        transition={Slide}
        hideProgressBar
        limit={1}
        stacked
        className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
      />
      <div
        className={clsx(
          "px-4 pb-[40px] h-screen overflow-auto relative z-30 dark:bg-transparent bg-[#F2FFE0]",
          "pt-4",
        )}
      >
        <div className="flex gap-2 items-center mt-4 cursor-pointer relative w-full">
          <div
            className="size-[44px] rounded-[50%] overflow-hidden"
            style={{
              background:
                "radial-gradient(circle, rgba(247,255,235,1) 0%, rgba(171,225,94,1) 100%)",
            }}
          >
            <img
              // src={
              //   accountV2?.data?.achieve
              //     ? "/images/profile/achieve-avatar.png"
              //     : "/images/trees/8.png?v=4"
              // }
              src="/images/trees/main.png"
              alt=""
              className={accountV2?.data?.achieve ? "object-contain p-1" : ""}
            ></img>
          </div>
          <div className="dark:text-white">
            <p className="text-sm font-semibold">
              {tele.initDataUnsafe?.user?.first_name ||
              tele.initDataUnsafe?.user?.last_name
                ? `${tele.initDataUnsafe?.user?.first_name} ${tele.initDataUnsafe?.user?.last_name}`
                : "------"}
            </p>
            <p className="text-sm ">
              {tele.initDataUnsafe?.user?.username
                ? `@${tele.initDataUnsafe?.user?.username}`
                : "------"}
            </p>
          </div>
          <button
            onClick={handleSwitchMode}
            className={clsx(
              "btn-hover z-20 rounded-lg w-[44px] h-[44px] flex justify-center items-center",
              "absolute right-0",
            )}
          >
            <img
              className="w-[44px] h-[44px]"
              src={
                theme === "light"
                  ? "/images/light.png?v=3"
                  : "/images/dark.png?v=3"
              }
              alt=""
            />
          </button>
        </div>

        <div className="flex gap-1 flex-wrap mt-4">
          <CustomChip>
            <div className="size-4 rounded-[50%] text-[12px] text-white flex items-center justify-center bg-[#5A6847]">
              {UserAge}
            </div>
            <p className="text-sm dark:text-white">
              {(UserAge || 0) > 1 ? "Ages" : "Age"}
            </p>
          </CustomChip>

          {accountV2?.data?.is_premium && (
            <CustomChip>
              <svg
                width="19"
                height="15"
                viewBox="0 0 19 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 3.75C19 4.46875 18.4375 5 17.75 5C17.7188 5 17.7188 5 17.7188 5L16.125 13.6875C16.0625 14.1562 15.625 14.5 15.1562 14.5H4.8125C4.34375 14.5 3.90625 14.1562 3.84375 13.6875L2.25 5C2.25 5 2.25 5 2.21875 5C1.53125 5 0.96875 4.46875 0.96875 3.75C0.96875 3.0625 1.53125 2.5 2.21875 2.5C2.875 2.5 3.46875 3.0625 3.46875 3.75C3.46875 4.03125 3.34375 4.28125 3.1875 4.5L6 6.75C6.5 7.125 7.21875 6.96875 7.5 6.40625L9.3125 2.8125C8.96875 2.59375 8.71875 2.21875 8.71875 1.75C8.71875 1.0625 9.28125 0.5 10 0.5C10.6875 0.5 11.2188 1.0625 11.2188 1.75C11.2188 2.21875 10.9688 2.59375 10.5938 2.8125L12.4062 6.40625C12.6875 6.96875 13.4375 7.125 13.9375 6.75L16.7188 4.5C16.5938 4.28125 16.5 4.03125 16.5 3.75C16.5 3.0625 17.0312 2.5 17.75 2.5C18.4375 2.5 19 3.0625 19 3.75Z"
                  fill="#FFA800"
                />
              </svg>
              <p className="text-sm dark:text-white">Premium</p>
            </CustomChip>
          )}
          {/* 
          {accountV2?.data?.achieve && (
            <CustomChip onClick={handleShowAchieve}>
              <img
                alt=""
                className={clsx("h-[16px] w-auto aspect-square object-contain")}
                src="/images/bird-cage/achieve.png"
              />
              <p className="text-sm dark:text-white">Wings of Freedom</p>
            </CustomChip>
          )} */}
          {/* {accountV2?.data?.achieve_friend_badge && (
            <div
              className={clsx(
                !!location?.state?.friendBadge && "badge-shadow rounded-[4px]"
              )}
            >
              <CustomChip onClick={handleShowGuardian}>
                <img
                  alt=""
                  className="h-[16px] w-auto aspect-square object-contain"
                  src="/images/medal.png"
                />
                <p className="text-sm dark:text-white">Growth Guardian</p>
              </CustomChip>
            </div>
          )} */}
        </div>

        <div className="flex items-center justify-center gap-2 w-full mt-4 dark:text-white">
          <img
            src="/images/icons/token_icon.png?v=3"
            width={34}
            height={34}
            alt="token"
          ></img>
          <p className="text-[32px] font-black">
            {formatNumberFloatFix(Number(formatDecimals(balance)) ?? 0, 2)} SEED
          </p>
        </div>
        <div className="w-full flex items-center gap-3 mt-3">
          <button
            onClick={() =>
              !accountV2?.data?.wallet_address_ton && handleConnectWallet()
            }
            id="ton-connect-button"
            className={clsx(
              "btn-hover rounded-[8px] gap-[10px] bg-[#2DA7DB] w-full text-white px-4 py-3 shadow-[0_4px_0_#00628C]",
              "grid grid-cols-10 items-center justify-center",
            )}
          >
            <div>
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.25 0C14.6562 0 15 0.34375 15 0.75C15 1.1875 14.6562 1.5 14.25 1.5H2.75C2.03125 1.5 1.5 2.0625 1.5 2.75V11.25C1.5 11.9688 2.03125 12.5 2.75 12.5H13.25C13.9375 12.5 14.5 11.9688 14.5 11.25V5.75C14.5 5.0625 13.9375 4.5 13.25 4.5H3.75C3.3125 4.5 3 4.1875 3 3.75C3 3.34375 3.3125 3 3.75 3H13.25C14.75 3 16 4.25 16 5.75V11.25C16 12.7812 14.75 14 13.25 14H2.75C1.21875 14 0 12.7812 0 11.25V2.75C0 1.25 1.21875 0 2.75 0H14.25ZM11 8.5C11 7.96875 11.4375 7.5 12 7.5C12.5312 7.5 13 7.96875 13 8.5C13 9.0625 12.5312 9.5 12 9.5C11.4375 9.5 11 9.0625 11 8.5Z"
                  fill="white"
                />
              </svg>
            </div>
            <p className="text-ellipsis overflow-hidden col-span-9">
              {accountV2?.data?.wallet_address_ton
                ? `${toUserFriendlyAddress(
                    accountV2?.data?.wallet_address_ton ?? "",
                  ).slice(0, 9)}...${toUserFriendlyAddress(
                    accountV2?.data?.wallet_address_ton ?? "",
                  ).slice(-9)}`
                : "Connect your TON wallet"}
            </p>
          </button>
          {accountV2?.data?.wallet_address_ton && (
            <button
              onClick={() => setIsOpenConfirmDisconnectModal(true)}
              className="btn-click-no-shadow p-3 border-[3px] border-[#E45F5F] flex items-center justify-center rounded-lg shadow-[0_4px_0_#BF3030]"
            >
              <svg
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.4902 3.30859L19.0547 7.87305C19.3145 8.13281 19.5 8.50391 19.5 8.875C19.5 9.2832 19.3145 9.6543 19.0547 9.91406L14.4902 14.4785C14.2676 14.7012 13.9336 14.8125 13.5996 14.8125C12.9316 14.8125 12.375 14.2559 12.375 13.5879V11.25H7.625C6.95703 11.25 6.4375 10.7305 6.4375 10.0625V7.6875C6.4375 7.05664 6.95703 6.5 7.625 6.5H12.375V4.19922C12.375 3.53125 12.9316 2.9375 13.5996 2.9375C13.9336 2.9375 14.2676 3.08594 14.4902 3.30859ZM6.4375 2.9375H4.0625C3.39453 2.9375 2.875 3.49414 2.875 4.125V13.625C2.875 14.293 3.39453 14.8125 4.0625 14.8125H6.4375C7.06836 14.8125 7.625 15.3691 7.625 16C7.625 16.668 7.06836 17.1875 6.4375 17.1875H4.0625C2.0957 17.1875 0.5 15.5918 0.5 13.625V4.125C0.5 2.1582 2.0957 0.5625 4.0625 0.5625H6.4375C7.06836 0.5625 7.625 1.11914 7.625 1.75C7.625 2.41797 7.06836 2.9375 6.4375 2.9375Z"
                  fill="#BF3030"
                />
              </svg>
            </button>
          )}
        </div>

        <div className="mt-4">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={16}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper h-[100px]"
          >
            <SwiperSlide>
              <div className="bg-[#0000001A] h-[70px] rounded-lg p-4 flex items-center justify-between dark:bg-[#313131]">
                <div className="text-sm text-left dark:text-white">
                  <p className="font-semibold">SEED Community</p>
                  <p>Join SEED update for airdrop details</p>
                </div>
                <button
                  onClick={() => {
                    tele.openTelegramLink("https://t.me/seedupdates");
                  }}
                  className={clsx(
                    "cursor-pointer bg-[#000000] text-[#fff] px-4 py-2 rounded-[24px] text-sm font-semibold",
                    "dark:bg-[#428129]",
                  )}
                >
                  Join
                </button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="bg-[#0000001A] h-[70px] rounded-lg p-4 flex items-center justify-between dark:bg-[#313131]">
                <div className="text-sm text-left dark:text-white">
                  <p className="font-semibold">SEED Combinator</p>
                  <p>Follow our Twitter</p>
                </div>
                <button
                  onClick={() => {
                    handleOpenLink();
                  }}
                  className={clsx(
                    "cursor-pointer bg-[#000000] text-[#fff] px-4 py-2 rounded-[24px] text-sm font-semibold",
                    "dark:bg-[#428129]",
                  )}
                >
                  Follow
                </button>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        <div>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <p className="font-semibold dark:text-white">Achievement</p>
              <div
                className={clsx(
                  "py-[4px] px-2 bg-[#0000001A] rounded-[14px] flex gap-1 items-center justify-center dark:bg-[#313131] cursor-pointer",
                )}
              >
                <img
                  alt=""
                  className={clsx(
                    "h-[16px] w-auto aspect-square object-contain",
                  )}
                  src="/images/bird-cage/achieve.png"
                />
                <p className="text-sm dark:text-white">{countAchive}</p>
              </div>
            </div>
            <div>
              <>
                <ClickAwayListener
                  onClickAway={() => {
                    setIsTooltipOpen(false);
                  }}
                >
                  <Tooltip
                    placement="left"
                    PopperProps={{
                      disablePortal: true,
                    }}
                    arrow
                    onClose={() => {
                      setIsTooltipOpen(false);
                    }}
                    open={isTooltipOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <p>
                        Badge Collection is your golden ticket to unlock massive
                        rewards in the upcoming <b>airdrop</b>.
                      </p>
                    }
                    className="text-sm z-90"
                  >
                    <button
                      onClick={() => setIsTooltipOpen(!isTooltipOpen)}
                      className="flex justify-center w-full mb-2"
                    >
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0.388672C13.957 0.388672 18 4.43164 18 9.38867C18 14.3809 13.957 18.3887 9 18.3887C4.00781 18.3887 0 14.3809 0 9.38867C0 4.43164 4.00781 0.388672 9 0.388672ZM9 16.7012C13.0078 16.7012 16.3125 13.4316 16.3125 9.38867C16.3125 5.38086 13.0078 2.07617 9 2.07617C4.95703 2.07617 1.6875 5.38086 1.6875 9.38867C1.6875 13.4316 4.95703 16.7012 9 16.7012ZM10.4062 12.2012C10.8633 12.2012 11.25 12.5879 11.25 13.0449C11.25 13.5371 10.8633 13.8887 10.4062 13.8887H7.59375C7.10156 13.8887 6.75 13.5371 6.75 13.0449C6.75 12.5879 7.10156 12.2012 7.59375 12.2012H8.15625V9.95117H7.875C7.38281 9.95117 7.03125 9.59961 7.03125 9.10742C7.03125 8.65039 7.38281 8.26367 7.875 8.26367H9C9.45703 8.26367 9.84375 8.65039 9.84375 9.10742V12.2012H10.4062ZM9 7.13867C8.36719 7.13867 7.875 6.64648 7.875 6.01367C7.875 5.41602 8.36719 4.88867 9 4.88867C9.59766 4.88867 10.125 5.41602 10.125 6.01367C10.125 6.64648 9.59766 7.13867 9 7.13867Z"
                          fill="#1F2418"
                        />
                      </svg>
                    </button>
                  </Tooltip>
                </ClickAwayListener>
              </>
            </div>
          </div>
          <div className="mt-3">
            <div
              onClick={() => {
                if (accountV2?.data?.achieve_friend_badge) {
                  handleShowGuardian();
                  return;
                }
                navigate(navPaths.SEED_MEDAL);
              }}
              className={clsx(
                "grid grid-cols-12 relative mb-3",
                "border-[1px] border-[#6DAD14] bg-[#E2F5C5] py-4 px-3 rounded-[12px]",
              )}
            >
              <div className="col-span-2">
                <img
                  alt=""
                  className={clsx(
                    "h-[53px] w-[35px] aspect-square object-contain",
                  )}
                  src="/images/medal.png"
                />
              </div>
              <div className="col-span-10 text-sm">
                <p className="font-semibold mb-1">Growth Guardian</p>
                <p>
                  Badge is the key to our airdrop. Limited & on-chain tradable.
                </p>
              </div>
              <div className="absolute right-3 top-3">
                {accountV2?.data?.achieve_friend_badge ? (
                  <img
                    src="/images/holy/check_mark.png"
                    className="size-[27px]"
                    alt=""
                  ></img>
                ) : (
                  <div
                    className={clsx(
                      "py-[4px] px-2 bg-[#0000001A] rounded-[14px] flex gap-1 items-center justify-center dark:bg-[#313131] cursor-pointer",
                      "text-[#12B740] text-xs",
                    )}
                  >
                    Opening
                  </div>
                )}
              </div>
            </div>

            {/* wing freedom  */}
            <div
              onClick={() => accountV2?.data?.achieve && handleShowAchieve()}
              className={clsx(
                "grid grid-cols-12 relative",
                " py-4 px-3 rounded-[12px]",
                accountV2?.data?.achieve
                  ? "border-[1px] border-[#6DAD14] bg-[#E2F5C5]"
                  : "bg-[#E7EEDE] pointer-events-none"
              )}
            >
              <div
                className={clsx(
                  "col-span-2",
                  !accountV2?.data?.achieve && "opacity-45",
                )}
              >
                <img
                  alt=""
                  className={clsx(
                    "h-[53px] w-[35px] aspect-square object-contain",
                  )}
                  src="/images/bird-cage/achieve.png"
                />
              </div>
              <div
                className={clsx(
                  "col-span-10 text-sm",
                  !accountV2?.data?.achieve && "opacity-45",
                )}
              >
                <p className="font-semibold mb-1">Wings of Freedom</p>
                <p>
                  Badge is the key to our airdrop. Limited & on-chain tradable.
                </p>
              </div>
              <div className={clsx("absolute right-3 top-3 z-100")}>
                {accountV2?.data?.achieve ? (
                  <img
                    src="/images/holy/check_mark.png"
                    className="size-[27px]"
                    alt=""
                  ></img>
                ) : (
                  <div
                    className={clsx(
                      "py-[4px] px-2 bg-[#0000001A] rounded-[14px] flex gap-1 items-center justify-center dark:bg-[#313131] cursor-pointer",
                    )}
                  >
                    <p className="text-xs dark:text-white">Ended</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <ModalContainer>
          {(showAchieve || showGuardian) && (
            <AchieveModal
              showAchieve={showAchieve}
              showGuardian={showGuardian}
              handleShowAchieve={handleShowAchieve}
              handleShowGuardian={handleShowGuardian}
            />
          )}
        </ModalContainer>
      </div>

      <ConfirmConnectModal
        ref={confirmConnectModalRef}
        isShowModal={isOpenConfirmConnectModal}
        showModal={setIsOpenConfirmConnectModal}
        handleStart={() => handleSaveWallet()}
        value={tonWallet?.account}
        disconectWallet={() => {
          setIsOpenConfirmConnectModal(false);
          if (tonConnectUI && tonConnectUI.connected) {
            tonConnectUI.disconnect();
          }
        }}
      />

      <ConfirmDisconnectModal
        ref={confirmDisconnectModalRef}
        isShowModal={isOpenConfirmDisconnectModal}
        showModal={setIsOpenConfirmDisconnectModal}
        triggerRefresh={() => refeshAcountV2()}
      />
    </>
  );
};

export default Profile;
