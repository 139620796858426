import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { Result } from "../../../types/utils";
import { ServerError, ServerResponse } from "../../../types/api";

export const getMineEggData = async ({
  market_type = "",
  egg_type = "",
  sort_by_price = "",
  sort_by_updated_at = "DESC",
  page = 1,
}): Promise<Result<any, ServerError>> => {
  const response = await fetchApi(
    "GET",
    market_type === "egg"
      ? `/market/me?market_type=${market_type}&egg_type=${egg_type}&sort_by_price=${sort_by_price}&sort_by_updated_at=${sort_by_updated_at}&page=${page}`
      : `/market/me?market_type=${market_type}&worm_type=${egg_type}&sort_by_price=${sort_by_price}&sort_by_updated_at=${sort_by_updated_at}&page=${page}`
  );
  if (response.error) {
    // Throw to retry
    throw new Error("network-error");
  }

  const marketDataResponse: ServerResponse<any> = await response.data.json();
  if (marketDataResponse.error) {
    return {
      error: marketDataResponse.error,
    };
  }

  return {
    data: marketDataResponse.data,
  };
};

export default function useGetMineEggData(params: any) {
  const { data, refetch, isFetching } = useQuery({
    queryKey: ["MineEggData"],
    queryFn: () => getMineEggData(params),
    retry: 1,
  });

  return {
    mineEggData: data,
    isLoadingMineEggData: isFetching,
    refreshMineEggData: refetch,
    itemPerPageMine: data?.data?.page_size,
  };
}
