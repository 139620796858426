import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const useViewGuildStore = create(
  persist(
    (set) => ({
      isViewedGuild: false,
      setIsViewedGuild: (isViewedGuild: any) =>
        set({ isViewedGuild: isViewedGuild }),
    }),
    {
      name: "isViewedGuild-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
