import { Button, Modal } from "@mui/material";
import clsx from "clsx";

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  isX4Speed: boolean;
}

const NotifiModal = ({ isOpen, handleClose, isX4Speed }: ModalProps) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div className="w-[277px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#F2FFE0] px-2 py-[25px] rounded-3xl">
        <h2 className="text-2xl font-extrabold text-center mb-2">
          Congrats 🥳
        </h2>
        {isX4Speed ? (
          <p className="text-center font-normal mb-6">
            You got x4 in the first 100 hours. <br />
            Hurry up! Only in June.
          </p>
        ) : (
          <p className="text-center font-normal mb-6">
            You got x2 Speed for Newcomers in the first 100 hours!
            <br /> Enjoy now!
          </p>
        )}

        <div className="flex justify-center ">
          <Button
            onClick={handleClose}
            className={clsx(
              "capitalize text-white py-3 px-8",
              "bg-gradient-to-r from-[#97C35B] to-[#61A700]     drop-shadow-[0_4px_0px_#4C7E0B]",
              "hover:drop-shadow-none"
            )}
          >
            Got it
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default NotifiModal;
