import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function claimStreakReward(id: string[]) {
  return await api.post(`/api/v1/streak-reward`, {
    streak_reward_ids: id,
  });
}

export default function useClaimStreakReward() {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["claimStreakReward"],
    mutationFn: claimStreakReward,
  });

  return {
    claimStreakRewardAsync: mutateAsync,
    isClaimingStreakReward: isPending,
  };
}
