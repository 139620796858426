/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import useGetAccountRecentReferees from "../Hooks/useGetAccountRecentReferees";
import { Slide, ToastContainer, toast } from "react-toastify";
import clsx from "clsx";
import { navPaths } from "../../../constants/navbar.constants";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LoadingScreen from "../../Global/Pages/LoadingScreen";
import RuleSection from "../Components/RuleSection";
import FriendItem from "../Components/FriendItem";
import {
  formatDecimals,
  formatNumberFloatFix,
} from "../../../utils/formatNumber";

const Friends = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const tele = window.Telegram.WebApp;

  const isFromUpgradeBox = location?.state?.fromUpgradeBox ?? false;
  const isFromSpinEgg = location?.state?.fromSpinEgg ?? false;

  const userID = tele.initDataUnsafe?.user?.id;
  const isSmallScreen = window.innerHeight <= 520;

  const { recentReferees, isLoadingReferees } = useGetAccountRecentReferees();

  tele.BackButton.show();
  tele.BackButton.onClick(() => handleBackBtn());
  const handleBackBtn = () => {
    if (isFromUpgradeBox) {
      navigate(navPaths.UPGRADE_BOX);
      return;
    }
    if (isFromSpinEgg) {
      navigate(navPaths.SPIN_EGG);
      return;
    }
    navigate("/");
  };

  const defaultInviteTextEncoded = `SEED%20App%20-%20where%20every%20SEED%20is%20the%20genesis%20of%20everything.%0AJoin%20now%20with%20me%20as%20an%20early%20bird%20%E2%80%94%20SEED%20just%20launched%20with%20huge%20potential%21%0APlant-to-earn%20from%20thousands%20of%20Web3%20projects%20in%20SEED%20ecosystem%20with%20me.%0A%F0%9F%A5%B3%20Get%20Welcome%20Gift%20Pack%20including%20in-game%20items%20and%20SEED%0A%F0%9F%92%B2Earn%2020%25%20cashback%20from%20frens`;

  const handleCopyLink = () => {
    !toast.isActive("copy_link") &&
      toast.success("Link copied to clipboard", {
        toastId: "copy_link",
        autoClose: 2000,
        style: { maxWidth: 337, height: 40, borderRadius: 8 },
      });
  };

  const handleShareLink = () => {
    tele.openTelegramLink(
      `https://t.me/share/url?url=${
        process.env.REACT_APP_BOT_URL
      }startapp=${String(userID)}&text=${defaultInviteTextEncoded}`
    );
  };

  return (
    <>
      {isLoadingReferees && !recentReferees?.data?.referees?.length ? (
        <LoadingScreen />
      ) : (
        <div className="pt-5 px-4 pb-[170px] bg-[#fff] h-screen relative z-30 ">
          <ToastContainer
            position="top-left"
            closeOnClick
            transition={Slide}
            hideProgressBar
            limit={1}
            className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
          />
          <div className="h-full overflow-auto">
            <div className="flex flex-col items-center mb-5">
              <p className="text-[24px] poppins-semibold text-center">
                Invite friends!
              </p>
              <p className="text-xs poppins-regular text-center">
                Tip 3: Invite friends to earn more.
              </p>
            </div>

            {/* rule  */}
            <RuleSection />
            {/* friends list */}
            {recentReferees?.data && (
              <>
                {recentReferees?.data?.total > 0 && (
                  <>
                    <div className="font-semibold  flex items-center justify-between gap-1">
                      <div className="flex items-center gap-1">
                        <div>
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.87725 0.982442C7.07762 0.339185 7.92238 0.339187 8.12275 0.982441L9.10026 4.12059C9.3167 4.81545 9.94621 5.30564 10.6778 5.30564H13.841C14.1468 5.30564 14.3744 5.50101 14.4638 5.78808C14.5536 6.07647 14.4808 6.39146 14.219 6.58991L11.6598 8.5294C11.0812 8.9679 10.8487 9.73374 11.0642 10.4256L12.0417 13.5638C12.1472 13.9025 12.0177 14.1982 11.7969 14.3656C11.5792 14.5306 11.2953 14.5583 11.0411 14.3656L8.48192 12.4261C7.89791 11.9835 7.1021 11.9835 6.51808 12.4261L3.95893 14.3656C3.70472 14.5583 3.42083 14.5306 3.20311 14.3656C2.98227 14.1982 2.85277 13.9025 2.95827 13.5638L3.93578 10.4256C4.15129 9.73374 3.9188 8.9679 3.34019 8.5294L0.781044 6.58991C0.519179 6.39146 0.446377 6.07647 0.536209 5.78808C0.625629 5.50101 0.853247 5.30564 1.15896 5.30564H4.32224C5.05379 5.30564 5.6833 4.81545 5.89974 4.12059L6.87725 0.982442Z"
                              stroke="black"
                            />
                          </svg>
                        </div>

                        <p
                          className={clsx(
                            "font-semibold text-[18px]",
                            isSmallScreen ? "my-2" : "mb-2 mt-2",
                            ""
                          )}
                        >
                          {`Friend list (${recentReferees?.data?.total})`}
                        </p>
                      </div>
                      <div className="flex items-center justify-center gap-1">
                        <p className="text-[#7D7D7D] font-medium text-sm">
                          Received:{" "}
                        </p>
                        <div className="flex items-center gap-1">
                          <img
                            className="size-4"
                            src="/images/icons/token_icon.png?v=3"
                            alt="token"
                          ></img>
                          <p className=" text-[14px] font-semibold">
                            {formatNumberFloatFix(
                              Number(
                                formatDecimals(
                                  recentReferees.data?.referee_balance ?? 0
                                )
                              ) ?? 0,
                              2
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {recentReferees?.data?.total > 0 && (
                  <div className="relative">
                    <div
                      className={clsx(
                        "overflow-auto bg-white p-3 rounded-xl",
                        "max-h-[400px]",
                        "border-[1px] border-[#F3F3F3] border-solid "
                      )}
                    >
                      {recentReferees?.data &&
                        recentReferees?.data.referees &&
                        recentReferees?.data.referees
                          .sort((a: any, b: any) => b.is_premium - a.is_premium)
                          .map((item: any) => (
                            <>
                              <FriendItem item={item} />
                            </>
                          ))}
                      <div className="w-full absolute left-0 right-0 bottom-0 h-10 bg-gradient-to-t from-[#FFFFFF] to-[#FFFFFF00]"></div>
                    </div>
                  </div>
                )}
              </>
            )}

            <div
              className={clsx(
                "absolute pb-[100px] max-w-[448px] bottom-0 right-4 left-4 z-40",
                "bg-gradient-to-t from-white via-white to-transparent from-10% via-30% to-80%"
              )}
            >
              <div className="grid grid-cols-5 gap-1">
                <div className="col-span-4">
                  <button
                    onClick={handleShareLink}
                    className={clsx(
                      "font-bold text-white py-[18px] w-full rounded-[38px] flex items-center justify-center gap-[6px]",
                      "bg-[#61A700]"
                    )}
                  >
                    Invite a friend
                    <img
                      className="w-5 h-[22px]"
                      src="images/icons/invite.svg"
                      alt=""
                    ></img>
                  </button>
                </div>
                <div className="col-span-1 flex justify-center">
                  <CopyToClipboard
                    text={`${process.env.REACT_APP_BOT_URL}startapp=${String(
                      userID
                    )}`}
                  >
                    <Button
                      onClick={() => handleCopyLink()}
                      className={clsx(
                        "font-bold text-white p-[10px] rounded-[50%] flex items-center justify-center",
                        "bg-[#61A700]"
                      )}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3 21.75H13.5C13.875 21.75 14.25 21.4219 14.25 21V18H16.5V21C16.5 22.6875 15.1406 24 13.5 24H3C1.3125 24 0 22.6875 0 21V10.5C0 8.85938 1.3125 7.5 3 7.5H6V9.75H3C2.57812 9.75 2.25 10.125 2.25 10.5V21C2.25 21.4219 2.57812 21.75 3 21.75ZM7.5 3C7.5 1.35938 8.8125 0 10.5 0H21C22.6406 0 24 1.35938 24 3V13.5C24 15.1875 22.6406 16.5 21 16.5H10.5C8.8125 16.5 7.5 15.1875 7.5 13.5V3ZM10.5 14.25H21C21.375 14.25 21.75 13.9219 21.75 13.5V3C21.75 2.625 21.375 2.25 21 2.25H10.5C10.0781 2.25 9.75 2.625 9.75 3V13.5C9.75 13.9219 10.0781 14.25 10.5 14.25Z"
                          fill="white"
                        />
                      </svg>
                    </Button>
                  </CopyToClipboard>
                </div>
                {/* <div className="col-span-1 flex justify-center">
                <Button
                  onClick={() => setIsOpenQR(true)}
                  className={clsx(
                    "font-bold text-[16px] text-white p-0 w-full rounded-xl h-full flex items-center justify-center",
                    "btn-hover bg-gradient-to-r from-[#97C35B] to-[#61A700] drop-shadow-[0_4px_0px_#4C7E0B]"
                  )}
                >
                  <img
                    className="w-[30px]"
                    src="images/icons/qr_code.svg"
                    alt=""
                  ></img>
                </Button>
              </div> */}
              </div>
            </div>
          </div>

          {/* {isOpenQR && (
            <QRcodeModal
              inviteLink={`${process.env.REACT_APP_BOT_URL}startapp=${String(
                userID
              )}`}
              handleClose={() => setIsOpenQR(false)}
            />
          )} */}
        </div>
      )}
    </>
  );
};

export default Friends;
