import { Modal } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import { formatDecimals } from "../../../utils/formatNumber";
import { LoadingButton } from "@mui/lab";

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  wormLevel: string;
  handleSell: (quantity: number, type: string) => void;
  quantity: number;
  price: number;
  isLoading: boolean;
}

const SellModal = ({
  isOpen,
  handleClose,
  wormLevel,
  handleSell,
  quantity,
  price,
  isLoading,
}: ModalProps) => {
  const [sellQuantity, setSellQuantity] = useState<number>(1);
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        handleClose();
        setSellQuantity(1);
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div className="w-[343px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#F2FFE0] p-[25px] rounded-3xl">
        <div className="flex flex-col items-center justify-center">
          <img
            src={`/images/inventory/worm/${wormLevel}.png?v=5`}
            className="w-[168px] h-[168px]"
            alt=""
          ></img>
          <p className="my-6 text-center">
            Confirm amount of <span className="uppercase">{wormLevel}</span>{" "}
            worms to sell.
          </p>
          <div className="w-full flex justify-between">
            <div className="flex gap-4">
              <button
                onClick={() => setSellQuantity(1)}
                className="border-[1px] w-[65px] h-[36px] border-[#97C35B]  rounded"
              >
                Min
              </button>
              <button
                onClick={() =>
                  sellQuantity > 1 && setSellQuantity(sellQuantity - 1)
                }
                className="border-[1px] w-[36px] h-[36px] border-[#97C35B] flex items-center justify-center rounded"
              >
                <svg
                  width="17"
                  height="4"
                  viewBox="0 0 17 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.875 2C16.875 2.70312 16.2891 3.25 15.625 3.25H1.875C1.17188 3.25 0.625 2.70312 0.625 2C0.625 1.33594 1.17188 0.75 1.875 0.75H15.625C16.2891 0.75 16.875 1.33594 16.875 2Z"
                    fill="#4C7E0B"
                  />
                </svg>
              </button>
            </div>
            <p className="w-[30px] flex items-center justify-center text-[#4C7E0B] text-[20px] font-semibold">
              {sellQuantity}
            </p>
            <div className="flex gap-4">
              <button
                onClick={() =>
                  sellQuantity < quantity && setSellQuantity(sellQuantity + 1)
                }
                className="w-[36px] h-[36px] bg-gradient-to-r from-[#97C35B] to-[#61A700] flex items-center justify-center rounded"
              >
                <svg
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 2.125V7.75H15.625C16.2891 7.75 16.875 8.33594 16.875 9C16.875 9.70312 16.2891 10.25 15.625 10.25H10V15.875C10 16.5781 9.41406 17.125 8.75 17.125C8.04688 17.125 7.5 16.5781 7.5 15.875V10.25H1.875C1.17188 10.25 0.625 9.70312 0.625 9C0.625 8.33594 1.17188 7.75 1.875 7.75H7.5V2.125C7.5 1.46094 8.04688 0.875 8.75 0.875C9.41406 0.875 10 1.46094 10 2.125Z"
                    fill="white"
                  />
                </svg>
              </button>
              <button
                onClick={() => setSellQuantity(quantity)}
                className="bg-gradient-to-r from-[#97C35B] to-[#61A700] w-[65px] h-[36px] rounded text-[#fff]"
              >
                Max
              </button>
            </div>
          </div>
          <p className="my-6 ">
            You will receive:{" "}
            <div className="flex gap-1 justify-center">
              <img
                src="/images/icons/token_icon.png?v=3"
                width={26}
                height={26}
                alt="token"
              ></img>
              <b className="font-extrabold">
                {formatDecimals(price * sellQuantity)}
              </b>
            </div>
          </p>
        </div>

        <div className="flex justify-center">
          <LoadingButton
            loading={isLoading}
            onClick={() => {
              handleSell(sellQuantity, wormLevel);
              setSellQuantity(1);
            }}
            className={clsx(
              "normal-case text-white py-3 px-8 w-full",
              "bg-gradient-to-r from-[#97C35B] to-[#61A700]     drop-shadow-[0_4px_0px_#4C7E0B]",
              "hover:drop-shadow-none"
            )}
          >
            Sell
          </LoadingButton>
        </div>
      </div>
    </Modal>
  );
};

export default SellModal;
