import clsx from "clsx";
import { formatDecimals } from "../../../utils/formatNumber";
import {
  useCheckDailyMission,
  useDailyMissions,
} from "../../Missions/Hooks/useGetDaily";
import { toast } from "react-toastify";
import { sleep } from "../../../utils/time";
import useGetSetting from "../../../Hooks/useGetSetting";
import { useMemo } from "react";
import ItemLoading from "../../../components/common/ItemLoading";

const DailyClaim = ({ TriggerRefresh }: { TriggerRefresh: () => void }) => {
  const { dailyMissions, refetch: refreshDailyMissions } = useDailyMissions();
  const { checkDailyMission } = useCheckDailyMission();

  const { data: Settings, isLoading: LoadingSettings } = useGetSetting();

  const dailyBonusValue = useMemo(() => {
    if (!Settings?.data?.data) {
      return [];
    }
    return Settings?.data?.data?.["login-bonuses"];
  }, [Settings]);

  const claimed = (no: number) => {
    if (!dailyMissions || !dailyMissions?.length) {
      return false;
    }

    return dailyMissions?.[0]?.no >= no;
  };

  const unlocked = (no: number) => {
    if (!dailyMissions || !dailyMissions?.length) {
      return no === 1;
    }

    // dailyMissions.length is the number of days claimed.
    // claimed means the reward is already unlocked;
    if (no <= dailyMissions?.[0].no) {
      return true;
    }

    // suppose the last claim days is n. then n+2 should be locked
    if (no > dailyMissions?.[0].no + 1) {
      return false;
    }

    // now compare the last claim; if last claim on yesterday or before that, today reward is unlocked;
    const lastClaim = new Date(dailyMissions[0].timestamp);
    const truncatedLastClaim = new Date(
      lastClaim.getUTCFullYear(),
      lastClaim.getUTCMonth(),
      lastClaim.getUTCDate()
    );

    const now = new Date();
    const truncatedNow = new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate()
    );

    return truncatedLastClaim.getTime() < truncatedNow.getTime();
  };

  const getWeekDays = (weekNumber: number) => {
    const startDayOfWeek = (weekNumber - 1) * 7 + 1;
    return Array.from({ length: 7 }, (_, i) => startDayOfWeek + i);
  };

  const updateDaysOfWeek = () => {
    const curentDay =
      dailyMissions?.reduce(
        (prev: any, current: any) => (prev.no > current.no ? prev : current),
        0
      ).no ?? 0;

    let weekNumber = Math.floor(curentDay / 7) + 1;
    const curentWeekDays = getWeekDays(weekNumber);
    const maxDayOfWeek = Math.max(...curentWeekDays);

    // if claim the last day of this week, but day hasnt passed yet, render current week
    // maxDayOfWeek - 6 to get first day of next week, so make the func unlocked always compare lastclaim vs now
    if (curentDay === maxDayOfWeek - 7 && !unlocked(maxDayOfWeek - 6)) {
      weekNumber--;
      return getWeekDays(weekNumber);
    }

    return curentWeekDays;
  };

  const handleDoDailyMission = async () => {
    const reward = await checkDailyMission();
    if (reward.error) {
      toast.error(reward.error.message, {
        style: { maxWidth: 337, height: 40, borderRadius: 8 },
        autoClose: 2000,
      });
      return;
    }

    toast.success("Mission completed", {
      style: { maxWidth: 337, height: 40, borderRadius: 8 },
      autoClose: 2000,
    });
    await sleep(500);
    TriggerRefresh();
    refreshDailyMissions();
  };

  return (
    <>
      {LoadingSettings ? (
        <div className="h-[calc(100%-220px)] flex items-center justify-center">
          <ItemLoading />
        </div>
      ) : (
        <div className="grid grid-cols-3 gap-x-4 gap-y-0 pt-10 flex-1 px-2 overflow-auto">
          {updateDaysOfWeek().map((day: any, index: number) => {
            return (
              <button
                key={index}
                onClick={() => handleDoDailyMission()}
                className={clsx(
                  "btn-hover dark:btn-click w-full h-[120px] pt-[22px] relative cursor-pointer bg-white rounded-[16px] mb-[18px] flex items-center justify-center",
                  "border-[1px] border-solid drop-shadow-[0_4px_0px_#4D7F0C]",
                  unlocked(day) ? "border-[#4D7F0C]" : "border-[#00000080]",
                  claimed(day) || !unlocked(day) ? "pointer-events-none" : "",
                  index === 6 ? "col-span-3" : ""
                )}
              >
                {claimed(day) && (
                  <div
                    className={clsx(
                      "w-[30px] h-[30px] rounded-[50%] flex items-center justify-center absolute -right-2 -top-4 z-30"
                    )}
                  >
                    <img
                      src="/images/holy/check_mark.png"
                      className="w-[31px] h-[31px]"
                      alt=""
                    ></img>
                  </div>
                )}
                {!unlocked(day) && (
                  <div className="absolute z-40 w-full h-full top-0 ">
                    <div className="absolute z-10 bg-black bg-opacity-50 w-full h-full rounded-[13px]"></div>
                    <img
                      src="/images/daily/lock.svg"
                      className="z-50 absolute left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2"
                      alt=""
                    ></img>
                  </div>
                )}
                <div className="absolute text-[10px] font-semibold left-0 top-0  text-white w-[60px] flex items-center h-[19px] bg-[#4E800D] px-[10px] rounded-br-xl rounded-tl-[12px]">
                  {`Day ${day}`}
                </div>
                <div>
                  <img
                    src={`/images/daily/${index + 1}.png`}
                    className="w-[55%] h-[80%] mx-auto"
                    alt=""
                  ></img>
                  <p className="font-semibold text-center">
                    {formatDecimals(dailyBonusValue[index])}
                  </p>
                </div>
              </button>
            );
          })}
        </div>
      )}
    </>
  );
};

export default DailyClaim;
