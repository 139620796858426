import { Button, CircularProgress } from "@mui/material";
import clsx from "clsx";
import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { useClickOutside } from "../../../Hooks/useClickOutside";
import { navPaths } from "../../../constants/navbar.constants";
import { getNumberFormatUs } from "../../../utils/formatNumber";
import { ITEM_TYPES } from "../Pages/Market";
import BuyModal from "./BuyModal";
import ModalContainer from "../../../components/common/ModalContainer";
import useGetDetailItem from "../Hooks/useGetDetailItem";

const ItemDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const tele = window.Telegram.WebApp;
  tele.BackButton.show();
  tele.BackButton.onClick(() => handleBackBtn());

  const handleBackBtn = () => {
    navigate(navPaths.MARKET);
  };

  const { detailItem, isLoadingDetailItem } = useGetDetailItem({
    id: location?.state?.id,
  });

  const item = detailItem?.data;

  const [isShowModal, setIsShowModal] = useState<boolean>(false);

  const handleBuy = () => {
    setIsShowModal(!isShowModal);
  };

  const buyModalRef = useRef<HTMLDivElement>(null);
  useClickOutside(buyModalRef, () => {
    setIsShowModal(false);
  });

  const userID = location?.state?.userID;

  // const price =
  //   location?.state?.created_by === userID
  //     ? getNumberFormatUs(location?.state?.price_net / 10 ** 9, 4)
  //     : getNumberFormatUs(location?.state?.price_gross / 10 ** 9, 4);

  const price = getNumberFormatUs(item?.price_gross / 10 ** 9, 4);

  return (
    <div className="z-10 dark:bg-transparent h-[100vh] flex flex-col pb-5 relative bg-[#F2FFE0] px-[10%]">
      <ToastContainer
        position="top-left"
        closeOnClick
        transition={Slide}
        hideProgressBar
        limit={1}
        stacked
        className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
      />
      {!isLoadingDetailItem && (
        <div className="flex flex-col w-full h-full gap-5 items-center">
          <div
            className={clsx(
              "font-[800] text-[20px] text-[#325900] dark:text-white mt-[5%] mb-[3%]"
            )}
          >
            {"MARKET"}
          </div>

          <div
            style={{
              height: "calc(100% - 100px)",
              maxHeight: 500,
            }}
            className="flex flex-col w-full pb-[21px] items-center justify-between px-[18px] rounded-[16px] border-2 border-[#8F8F8F] border-b-[5px] border-b-[#424242] dark:bg-[#F2FFE0]"
          >
            <div
              className="rounded-b-[4px] w-[65%] px-2 mx-[10px] pt-[2px] pb-1 flex items-center justify-between"
              style={{
                color:
                  item?.egg_type === "common" || item?.worm_type === "common"
                    ? "#000"
                    : "#fff",
                backgroundColor: ITEM_TYPES.find(
                  (des) =>
                    des.type === item?.egg_type || des.type === item?.worm_type
                )?.color,
              }}
            >
              <div className="text-[14px] font-[800] capitalize">
                {item?.egg_type || item?.worm_type}
              </div>
              <div className="text-[14px] font-[400]">
                {
                  ITEM_TYPES.find(
                    (des) =>
                      des.type === item?.egg_type ||
                      des.type === item?.worm_type
                  )?.description
                }
              </div>
            </div>
            <div className={`relative h-[60%] w-full`}>
              <img
                src={
                  location?.state?.marketType === "egg"
                    ? `/images/egg/${item?.egg_type}.png`
                    : `/images/inventory/worm/${item?.worm_type}.png`
                }
                alt={"market-item"}
                className="h-full w-full object-contain relative z-10"
              />
              <div className="absolute inset-0 z-0 item-bg" />
            </div>
            <div className="px-[4px] py-[3px] w-fit flex items-center gap-2 text-[16px] pr-3 uppercase">
              <div
                style={{
                  color:
                    item?.egg_type === "common" || item?.worm_type === "common"
                      ? "#000"
                      : "#fff",
                  backgroundColor: ITEM_TYPES.find(
                    (des) =>
                      des.type === item?.egg_type ||
                      des.type === item?.worm_type
                  )?.color,
                }}
                className="w-[20px] h-[20px] rounded-full  flex items-center justify-center text-white"
              >
                #
              </div>
              {location?.state?.marketType === "egg"
                ? item?.egg_id?.slice(
                    item?.egg_id?.length - 9,
                    item?.egg_id?.length
                  )
                : item?.worm_id?.slice(
                    item?.worm_id?.length - 9,
                    item?.worm_id?.length
                  )}
            </div>

            <div className="flex items-center gap-3 text-[16px]">
              {"Price: "}
              <div className="flex items-center gap-1">
                <img
                  src="/images/icons/token_icon.png"
                  width={21}
                  height={21}
                  alt="token"
                ></img>
                <span className="font-[800] text-[20px]">{price}</span>
              </div>
            </div>

            <div className="w-full">
              {location?.state?.created_by !== userID && (
                <Button
                  onClick={handleBuy}
                  className={clsx(
                    "w-full normal-case h-[46px] py-3 rounded-lg font-extrabold text-base bg-gradient-to-r from-[#97C35B] to-[#61A700] text-[#FFFFFF] shadow-[0_4px_0_#4C7E0B]",
                    "disabled:shadow-none disabled:border-none"
                  )}
                >
                  Buy
                </Button>
              )}
              {location?.state?.created_by === userID && (
                <Button
                  onClick={handleBuy}
                  className={clsx(
                    " w-full normal-case h-[46px] btn-hover py-3 rounded-lg font-extrabold text-base bg-gradient-to-r from-[#97C35B] to-[#61A700] text-[#FFFFFF] shadow-[0_4px_0_#4C7E0B]",
                    "disabled:shadow-none disabled:border-none"
                  )}
                >
                  Unlist
                </Button>
              )}
            </div>
          </div>
          <ModalContainer>
            {isShowModal && (
              <BuyModal
                ref={buyModalRef}
                showModal={setIsShowModal}
                type={item?.egg_type || item?.worm_type}
                price={price}
                cancelSell={location?.state?.created_by === userID}
                id={item?.id}
                marketId={item?.egg_id || item?.worm_id}
                ownerId={userID || ""}
              />
            )}
          </ModalContainer>
        </div>
      )}
      {isLoadingDetailItem && (
        <div className=" backdrop-blur-sm fixed inset-0 flex items-center justify-center z-50 bg-black/50">
          <CircularProgress style={{ color: "#F2FFE0" }} />
        </div>
      )}
    </div>
  );
};

export default ItemDetail;
