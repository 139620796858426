import { LoadingButton } from "@mui/lab";
import { Modal } from "@mui/material";
import clsx from "clsx";

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  wormLevel: string;
  handleSellWorm: () => void;
  wormPrice: number;
  isLoading: boolean;
  handleSavetoInventory: () => void;
}
const GetWormModal = ({
  isOpen,
  handleClose,
  wormLevel,
}: // handleSellWorm,
// wormPrice,
// isLoading,
// handleSavetoInventory,
ModalProps) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div className="w-[343px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#F2FFE0] p-[25px] rounded-3xl">
        <div className="flex flex-col items-center justify-center">
          <img
            src={`/images/inventory/worm/${wormLevel}.png`}
            className="w-[168px] h-[168px]"
            alt=""
          ></img>
          <p className="mt-4 text-[20px] font-extrabold">Congratulations!</p>
          <p className="mt-2 mb-6 text-center">
            You have caught a <span className="uppercase">{wormLevel}</span>{" "}
            worm!
          </p>
        </div>

        <div className="flex justify-center gap-2">
          <LoadingButton
            loading={false}
            // onClick={handleSavetoInventory}
            onClick={handleClose}
            className={clsx(
              "w-full normal-case h-[60px] btn-hover py-3 rounded-lg font-extrabold text-base text-white shadow-[0_3px_0_#4C7E0B]",
              "bg-gradient-to-r from-[#97C35B] to-[#61A700]"
            )}
            style={{
              border: "3px solid #97C35B",
              backgroundColor: "transparent",
            }}
          >
            Yep
          </LoadingButton>
          {/* <LoadingButton
            loading={isLoading}
            onClick={handleSellWorm}
            className={clsx(
              "normal-case text-white text-base py-3 rounded-lg w-full h-[60px] font-extrabold",
              "bg-gradient-to-r from-[#97C35B] to-[#61A700] drop-shadow-[0_3px_0px_#4C7E0B]",
              "hover:drop-shadow-none"
            )}
          >
            <div>
              <p>Sell now</p>
              <div className="flex gap-1">
                <img
                  src="/images/icons/token_icon.png?v=3"
                  width={26}
                  height={26}
                  alt="token"
                ></img>
                {formatDecimals(wormPrice)}
              </div>
            </div>
          </LoadingButton> */}
        </div>
      </div>
    </Modal>
  );
};

export default GetWormModal;
