/* eslint-disable react-hooks/exhaustive-deps */

import { Button } from "@mui/material";
import clsx from "clsx";
import { useEffect, useMemo, useRef, useState } from "react";
import Cd from "react-countdown";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { Slide, toast, ToastContainer } from "react-toastify";
import Countdown from "../../components/common/Countdown";
import Loading from "../../components/common/Loading";
import NotifiModal from "../../components/common/NotifiModal";
import { useAccountV2 } from "../../components/Hooks/useRegisterV2";
import { navPaths } from "../../constants/navbar.constants";
import useGetSetting from "../../Hooks/useGetSetting";
import { useAppTime } from "../../store/appTimeStore";
import { useBirdStore } from "../../store/birdStore";
import { useBoostStore } from "../../store/boostStore";
import { useViewGuildStore } from "../../store/useCheckViewGuild";
import { useGuildID } from "../../store/useGuildId";
import { useUserStore } from "../../store/userStore";
import {
  formatDecimals,
  formatNumberFloatFix,
  getNumberFormatUs,
} from "../../utils/formatNumber";
import {
  boardingBoosts,
  calculateMinedSeedsV3,
  calculateMiningMultiplier,
  calculateMiningSpeed,
  getMiningSpeedByLevel,
  getStorageUpgradesLevel,
} from "../../utils/minedSeed";
import { sleep } from "../../utils/time";
import useMemberDetails from "../Guild/Hooks/useMemberDetails";
import useGetLeaderBird from "../Inventory/Hooks/getLeaderBird";
import useCatchWorm from "../Inventory/Hooks/useCatchWorm";
import useAvailableWorm from "../Inventory/Hooks/useGetAvailableWorm";
import useSellWorm from "../Inventory/Hooks/useSellWorm";
import DailyMissonModal from "../Missions/Components/DailyMissonModal";
import {
  useCheckDailyMission,
  useDailyMissions,
} from "../Missions/Hooks/useGetDaily";
import GetWormModal from "./Components/GetWormModal";
import ProfileButton from "./Components/ProfileButton";
import WormAppearNotifi from "./Components/WormAppearNotifi";
import useClaimFirstEgg from "./Hooks/useClaimFirstEgg";
import { useAccountBalance, useClaimSeedV2 } from "./Hooks/useGetAcountBalance";
import useGetAcountBoosts from "./Hooks/useGetAcountBoosts";
import { useLatestMessage } from "./Hooks/useGetLatestMessage";

const Home = () => {
  const tele = window.Telegram.WebApp;
  const navigate = useNavigate();
  const setStoreBird = useBirdStore((state: any) => state.setBirddItem);
  const setUser = useUserStore((state: any) => state.setUser);
  const { now } = useAppTime();
  const setGuildId = useGuildID((state: any) => state.setGuildId);
  const setIsviewedGuild = useViewGuildStore(
    (state: any) => state.setIsViewedGuild,
  );
  const isViewedGuild = useViewGuildStore((state: any) => state.isViewedGuild);

  const setBoostSpeed = useBoostStore((state: any) => state.setBoostSpeed);
  const setBoostStorage = useBoostStore((state: any) => state.setBoostStorage);
  const setBoostWater = useBoostStore((state: any) => state.setBoostWater);

  const { data } = useGetSetting();
  const Settings = data?.data?.data;

  const {
    accountV2,
    isLoading: isLoadingProfile,
    refetch: refeshAcountV2,
  } = useAccountV2();
  const { balance, refetch: refreshBalance } = useAccountBalance();
  const { claimAsync } = useClaimSeedV2();
  const { hasRead: hasReadLatestMessage, checkLatestMessage } =
    useLatestMessage();
  const {
    dailyMissions,
    // hasDaily,
    refetch: refreshDailyMissions,
  } = useDailyMissions();
  const { checkDailyMission, isPending: checkDailyLoading } =
    useCheckDailyMission();
  const { boosts, boostsLoading } = useGetAcountBoosts();
  const { worm, isGettingWorm, refreshWorm } = useAvailableWorm();
  const { catchWormAsync, isCatchingWorm } = useCatchWorm();
  const { sellAsync, isSellingWorm } = useSellWorm();
  const { leaderBird } = useGetLeaderBird();
  const { mutateAsync: claimFirstEggAsync, isPending: isClaimingFirstEgg } =
    useClaimFirstEgg();
  const { MemberDetails } = useMemberDetails({ sort_by: "" });

  const [isOpenNotifi, setIsOpenNotifi] = useState<boolean>(false);
  const [isOpenDailyMission, setIsOpenDailyMission] = useState<boolean>(false);
  const [isOpenGetWormModal, setIsOpenGetWormModal] = useState<any>({
    isOpen: false,
    worm: 0,
  });
  const [isShowSmoke, setIsShowSmoke] = useState<boolean>(false);
  const [minedSeed, setMinedSeed] = useState(0);
  const [isShowToolTip, setIsShowToolTip] = useState(true);
  const [isOpenWormNotifi, setIsOpenWormNotifi] = useState<boolean>(false);

  // const userName = () => {
  //   if (
  //     tele.initDataUnsafe?.user?.first_name ||
  //     tele.initDataUnsafe?.user?.last_name
  //   ) {
  //     return `${tele.initDataUnsafe?.user?.first_name} ${tele.initDataUnsafe?.user?.last_name}`;
  //   }
  //   return "------";
  // };

  useEffect(() => {
    const telegram = window.Telegram.WebApp;
    telegram.BackButton.hide();
  }, []);
  const isSmallScreen = window.innerHeight <= 590;
  const current = useMemo(() => now.getTime(), [now]);
  const [canCaughtWorm, countDownTime]: any = useMemo(() => {
    if (!worm?.data) {
      return [false, ""];
    }

    const timeWormAppear = new Date(worm?.data?.created_at).getTime();
    const timeWormDisappear = new Date(worm?.data?.ended_at).getTime();
    const timeForNextWorm = new Date(worm?.data?.next_worm).getTime();

    if (worm.data.is_caught) {
      return [false, worm?.data?.next_worm];
    } else {
      if (current < timeWormAppear) {
        return [false, worm?.data?.created_at];
      }
      if (current >= timeWormAppear && current < timeWormDisappear) {
        return [true, worm?.data?.created_at];
      }
      if (current >= timeWormDisappear && current <= timeForNextWorm) {
        return [false, worm?.data?.next_worm];
      }
    }

    return [false, ""];
  }, [worm?.data, current]);

  const [boostSpeedLevel, speedValues] = useMemo(() => {
    if (!Settings) {
      return [[], []];
    }
    const speedBoost = Settings["mining-speed"];
    const speedBoostCost = [0, ...Settings["mining-speed-costs"]];

    const boostSpeedLevel = speedBoost?.reduce(
      (acc: any, item: any, index: number) => {
        acc[String(index)] = {
          speed: formatDecimals(item),
          price: formatDecimals(speedBoostCost[index]),
        };
        return acc;
      },
      {},
    );

    return [boostSpeedLevel, speedBoost];
  }, [Settings]);

  const [boostStorageLevel, storageValues] = useMemo(() => {
    if (!Settings) {
      return [[], []];
    }
    const storageBoost = Settings["storage-size"];
    const storageBoostCost = [0, ...Settings["storage-size-costs"]];

    const boostStorageLevel = storageBoost?.reduce(
      (acc: any, item: any, index: number) => {
        acc[String(index)] = {
          duration: item / 1000,
          price: formatDecimals(storageBoostCost[index]),
        };
        return acc;
      },
      {},
    );

    return [boostStorageLevel, storageBoost];
  }, [Settings]);

  const [boostWaterLevel, watervalues] = useMemo(() => {
    if (!Settings) {
      return [[], []];
    }
    const waterBoost = Settings["holy-water"];
    const waterBoostCost = [0, ...Settings["holy-water-costs"]];

    const boostWaterLevel = waterBoost?.reduce(
      (acc: any, item: any, index: number) => {
        acc[String(index)] = {
          speed: (item + 100) / 100,
          missions: waterBoostCost[index],
        };
        return acc;
      },
      {},
    );

    return [boostWaterLevel, waterBoost];
  }, [Settings]);

  useEffect(() => {
    setBoostSpeed(boostSpeedLevel);
    setBoostStorage(boostStorageLevel);
    setBoostWater(boostWaterLevel);
  }, [data?.data]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [startTime, endTime, storageLevel] = useMemo(() => {
    const startTime =
      new Date(accountV2?.data?.last_claim ?? "").getTime() / 1000;
    const storageLevel = getStorageUpgradesLevel(accountV2?.data);

    const storageBonus = boostStorageLevel[storageLevel]?.duration;
    const endTime = startTime + storageBonus;

    return [startTime, endTime, storageLevel];
  }, [accountV2?.data]);

  const formatedTimestampBoosts = useMemo(() => {
    if (!boosts) {
      return [];
    }

    const boostsData = boosts?.map(
      (item: { timestamp: string; type: string; val: number }) => {
        return {
          timestamp: new Date(item.timestamp).getTime(),
          type: item.type,
          val: item.val,
        };
      },
    );

    return boostsData;
  }, [boosts]);

  // const hasFriendBadge = useMemo(() => {
  //   if (!accountV2) {
  //     return false;
  //   }

  //   return accountV2.data?.achieve_friend_badge;
  // }, [accountV2]);

  const treeRef = useRef<any>();
  const wormRef = useRef<any>();
  const auraRef = useRef<any>();

  const speedValue = Object.values(speedValues);
  const storageValue = Object.values(storageValues);
  const waterValue = Object.values(watervalues);

  const [isX2, isX4] = useMemo(() => {
    if (!formatedTimestampBoosts.length) {
      return [false, false, 0];
    }

    const isX2 =
      calculateMiningMultiplier(
        getMiningSpeedByLevel(speedValue, 0),
        accountV2?.data?.upgrades ?? [],
        formatedTimestampBoosts ?? boardingBoosts(),
        Date.now(),
        speedValue,
        storageValue,
        waterValue,
      ) === 2;

    const isX4 =
      calculateMiningMultiplier(
        getMiningSpeedByLevel(speedValue, 0),
        accountV2?.data?.upgrades ?? [],
        formatedTimestampBoosts ?? boardingBoosts(),
        Date.now(),
        speedValue,
        storageValue,
        waterValue,
      ) === 4;

    const miningSpeed = calculateMiningSpeed(
      getMiningSpeedByLevel(speedValue, 0),
      accountV2?.data?.upgrades ?? [],
      formatedTimestampBoosts ?? boardingBoosts(),
      Date.now(),
      speedValue,
      storageValue,
      waterValue,
    );
    return [isX2, isX4, miningSpeed];
  }, [formatedTimestampBoosts, current]);

  useEffect(() => {
    const param = tele.initDataUnsafe?.start_param;
    const guildId = param?.split("_")?.[1]?.replace("g=", "");

    if (!!guildId && !isViewedGuild) {
      setIsviewedGuild(true);
      navigate(navPaths.GUILD_DETAILS);
      setGuildId(guildId);
    }
  }, [tele]);

  useEffect(() => {
    if (!accountV2?.data || isLoadingProfile) {
      return;
    }
    setUser(accountV2.data);
  }, [accountV2]);

  // Recalculate seed mined every 100ms
  useEffect(() => {
    if (!accountV2?.data || isLoadingProfile) {
      return;
    }

    const timerId = setInterval(() => {
      setMinedSeed(
        formatDecimals(
          calculateMinedSeedsV3(
            accountV2.data.last_claim,
            accountV2.data.upgrades ?? [],
            formatedTimestampBoosts ?? boardingBoosts(),
            storageValue,
            speedValue,
            waterValue,
          ),
        ),
      );
    }, 100);

    return () => {
      clearInterval(timerId);
    };
  }, [accountV2, formatedTimestampBoosts]);

  const handleClaim = async () => {
    const claimed = await claimAsync();
    if (claimed.error) {
      toast.error(claimed.error, {
        style: { maxWidth: 337, height: 40, borderRadius: 8 },
        autoClose: 2000,
      });
      return;
    }

    refreshBalance();
    refeshAcountV2();
  };

  const handleCheckClaim = () => {
    if (hasReadLatestMessage) {
      return;
    }
    if (timePassed >= 1800) {
      handleClaim();
      return;
    }

    if (!toast.isActive("cant_claim") && !isFill) {
      const timeRemain = Math.floor((1800 - timePassed) / 60);
      toast.error(
        `Claim after ${timeRemain <= 1 ? `1 minute` : `${timeRemain} minutes`}`,
        {
          toastId: "cant_claim",
          autoClose: 2000,
          style: { borderRadius: 8 },
        },
      );
    }
  };

  const handleDoDailyMission = async () => {
    const reward = await checkDailyMission();
    if (reward.error) {
      toast.error(reward.error.message, {
        style: { maxWidth: 337, height: 40, borderRadius: 8 },
        autoClose: 2000,
      });
      return;
    }

    toast.success("Mission completed", {
      style: { maxWidth: 337, height: 40, borderRadius: 8 },
      autoClose: 2000,
    });

    refreshBalance();
    refreshDailyMissions();
  };

  const progressRef = useRef<HTMLDivElement>(null);
  const [isFill, setIsFill] = useState<boolean>(false);
  const [timePassed, setTimePassed] = useState(0);

  // const isFull = timePassed >= 1800;

  // Update progress bar and countdown
  useEffect(() => {
    if (!progressRef.current) {
      return;
    }

    const now = Date.now() / 1000;
    const distanceFromStart = now - startTime;
    const distanceFromEnd = endTime - startTime;
    const percentEnd = (distanceFromStart / distanceFromEnd) * 100;

    setIsFill(percentEnd >= 100);
    setTimePassed(distanceFromStart);

    progressRef.current.style.width =
      (percentEnd >= 100 ? 100 : Math.floor(percentEnd)) + "%";
  }, [minedSeed, startTime, endTime]);

  if (isLoadingProfile) {
    return <Loading />;
  }
  const handleClickWorm = () => {
    // worm glow animation
    wormRef.current.style.animation = "scale-up 0.2s linear forwards";
    setTimeout(() => {
      wormRef.current.style.animation = "scale-up-down 1s linear infinite";
      auraRef.current.style.animation = "scale-up-down 1s linear infinite";
    }, 250);
  };

  const handleCatchWorm = () => {
    catchWormAsync()
      .then(async (data) => {
        // worm disappear animation
        setIsShowSmoke(true);
        setIsShowToolTip(false);
        if (auraRef.current && wormRef.current) {
          auraRef.current.style.display = "none";
          auraRef.current.style.animation = "none";
          wormRef.current.style.animation = "fade 0.5s linear";
          wormRef.current.style.display = "none";
        }
        await sleep(1000);

        if (data?.data?.status === "failed") {
          toast.error("Opps, bad luck, the worm ran away", {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          });
          refreshWorm();
          return;
        }

        if (data?.error) {
          !toast.isActive("catchFail") &&
            toast.error(data?.error, {
              style: { maxWidth: 337, height: 40, borderRadius: 8 },
              autoClose: 2000,
              toastId: "catchFail",
            });
          refreshWorm();
          return;
        }

        setIsOpenGetWormModal({
          isOpen: true,
          worm: data.data,
        });
        refreshWorm();
      })
      .catch((err) => {
        !toast.isActive("catchFail") &&
          toast.error(err?.response?.data?.message, {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
            toastId: "catchFail",
          });
        refreshWorm();
      })
      .finally(() => {
        setIsShowSmoke(false);
        setIsShowToolTip(false);
        auraRef.current.style.display = "inline-block";
        auraRef.current.style.animation = "inline-block";
        wormRef.current.style.animation = "fade 0.5s linear";
        wormRef.current.style.display = "inline-block";
      });
  };

  const handleSellWorm = () => {
    sellAsync([isOpenGetWormModal.worm?.id])
      .then(() => {
        toast.error("Sell worm succesfully", {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
        });
        setIsOpenGetWormModal({
          isOpen: false,
          worm: null,
        });
        refreshWorm();
        refreshBalance();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
        });
      });
  };

  const handleClaimFirstEgg = () => {
    claimFirstEggAsync()
      .then((data) => {
        navigate(navPaths.INVENTORY_DETAIL, {
          state: { ...data?.data?.data, receiveFirstEgg: true },
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
        });
      });
  };

  // const handleGoToCage = () => {
  //   navigate(navPaths.BIRD_CAGE);
  // };

  return (
    <>
      <ToastContainer
        containerId={"homeToastContainer"}
        position="top-left"
        closeOnClick
        transition={Slide}
        hideProgressBar
        limit={1}
        stacked
        className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
      />
      <div
        className={clsx(
          "h-screen overflow-hidden flex flex-col flex-1 px-4 relative ",
          "dark:bg-transparent dark:bg-gradient-to-b from-transparent via-transparent to-transparent ",
          "bg-gradient-to-b from-[#F7FFEB] via-[#E4FFBE] to-[#79B22A]",
          "pb-[110px]",
        )}
      >
        <ProfileButton achieve={!!accountV2?.data?.achieve} />
        <div className="mt-[20px]">
          <div
            className={clsx(
              "flex flex-col items-center flex-1 relative",
              isMobile && "lsm:mb-[10px]",
            )}
          >
            <div className="flex items-center justify-center gap-2">
              <div className="w-[34px] aspect-square">
                <img src="/images/icons/token_icon.png?v=3" alt="token"></img>
              </div>
              <p className="dark:text-white text-[32px] font-bold">
                {getNumberFormatUs(
                  formatNumberFloatFix(Number(formatDecimals(balance)) ?? 0, 2),
                )}
              </p>
            </div>
          </div>
          <div className="flex justify-center relative ">
            {false && (
              <>
                {boostsLoading ? null : (
                  <>
                    {isX4 ? (
                      <img
                        src="/images/x4.png"
                        className="w-[143px] h-[38px]"
                        alt=""
                      ></img>
                    ) : isX2 ? (
                      <img
                        src="/images/x2.png"
                        className="w-[143px] h-[38px]"
                        alt=""
                      ></img>
                    ) : null}
                  </>
                )}
              </>
            )}

            {/* iap btn */}
            {/* <button
              onClick={() => {
                navigate(navPaths.PURCHASE);
              }}
              className={clsx(
                "btn-hover z-20  rounded-lg w-[44px] h-[44px] flex justify-center items-center",
                "absolute left-0 -bottom-[190px]",
              )}
            >
              <img
                className="w-[48px] h-[44px]"
                src={"/images/purchase/btn_icon.png?v=3"}
                alt=""
              />
            </button> */}

            {/* daily mission btn  */}
            {/* <button
              onClick={() => {
                navigate(navPaths.STREAK);
              }}
              className={clsx(
                "btn-hover z-20  rounded-lg w-[44px] h-[44px] flex justify-center items-center",
                "absolute right-0 -bottom-[10px]"
              )}
            >
              {hasDaily && (
                <div className="w-[10px] h-[10px] absolute z-10 -right-0 top-0">
                  <div className="animate-blink w-full h-full absolute  rounded-[50%] "></div>
                  <div className="w-full h-full bg-[#FFA928] absolute  rounded-[50%] "></div>
                </div>
              )}
              <img
                className="w-[42px] h-[42px]"
                src={"/images/lucky_spin/fusion/fire.png?v=3"}
                alt=""
              />
            </button> */}

            {/* inventory btn  */}
            <button
              onClick={() => navigate(navPaths.INVENTORY)}
              className={clsx(
                "btn-hover z-20  rounded-lg w-[44px] h-[44px] flex justify-center items-center",
                "absolute left-0 -bottom-[125px]",
              )}
            >
              <img
                className="w-[44px] h-[44px]"
                // src={"/images/prize_spin_ic.png?v=3"}
                src="/images/home/inventory.png"
                alt=""
              />
            </button>

            {/* spin egg btn  */}
            {/* <button
              onClick={() => navigate(navPaths.GAME_LIST)}
              className={clsx(
                "btn-hover z-20  rounded-lg w-[44px] h-[44px] flex justify-center items-center",
                "absolute right-0 -bottom-[70px]",
              )}
            >
              <img
                className="w-[44px] h-[44px]"
                // src={"/images/prize_spin_ic.png?v=3"}
                src="/images/home/game.png"
                alt=""
              />
            </button> */}

            {/* market btn  */}
            <button
              onClick={() => navigate(navPaths.MARKET)}
              className={clsx(
                "btn-hover z-20 rounded-lg w-[44px] h-[44px] flex justify-center items-center",
                "absolute left-0 -bottom-[185px]",
              )}
            >
              <img
                className="w-[44px] h-[44px]"
                // src={"/images/market/marketplace_ic.png?v=3"}
                src="/images/home/market.png"
                alt=""
              />
            </button>

            {/* buy box btn  */}
            <button
              onClick={() => navigate(navPaths.PURCHASE)}
              className={clsx(
                "btn-hover z-20 rounded-lg w-[50px] h-[50px] -ml-[2px] flex justify-center items-center",
                "absolute left-0 -bottom-[70px]",
              )}
            >
              <img
                className="w-[44px] h-[44px]"
                // src={"/images/market/marketplace_ic.png?v=3"}
                src={`/images/home/box.png`}
                alt=""
              />
            </button>

            {/* guild btn  */}
            <button
              onClick={() =>
                navigate(
                  !!MemberDetails?.guild_rank
                    ? navPaths.GUILD_LIST
                    : navPaths.GUILD_WELCOME,
                  { state: { rank: MemberDetails?.guild_rank } },
                )
              }
              className={clsx(
                "btn-hover z-20 rounded-lg w-[50px] h-[50px] flex justify-center items-center",
                "absolute right-0 -bottom-[70px]",
              )}
            >
              <img
                className="w-[50px] h-[50px]"
                // src={"/images/market/marketplace_ic.png?v=3"}
                src={`/images/guild/guild_level/${
                  !!MemberDetails?.guild_rank
                    ? MemberDetails?.guild_rank
                    : "village"
                }.png`}
                alt=""
              />
            </button>

            {/* bird */}
            {!!leaderBird?.data && (
              <button
                onClick={() => {
                  navigate(navPaths.BIRD);
                  setStoreBird(leaderBird?.data);
                }}
                className={clsx(
                  "btn-hover z-20 rounded-lg w-[50px] h-[48px] flex justify-center items-center ",
                  "absolute right-0 -bottom-[130px]",
                )}
              >
                <img
                  className="w-[48px] h-[48px]"
                  src={`/images/bird/${leaderBird?.data?.type}.png?v=7`}
                  alt=""
                />
              </button>
            )}
          </div>
        </div>
        <div
          // onClick={() => {
          //   getHappyDay() && !checkHistoryClaimHappyDays() && handleTapTree();
          // }}
          ref={treeRef}
          className={clsx(
            "flex flex-1 max-h-[510px] relative justify-center bg-no-repeat bg-contain bg-bottom z-10 w-[110%] -ml-[7%]",
            isMobile ? "-mb-[8%]" : "-mb-[5%]",
          )}
          style={{
            backgroundImage: `url('/images/trees/main.png?v=4')`,
          }}
        >
          {canCaughtWorm ? (
            <div
              className={clsx(
                "relative w-[35%] h-[40%] z-10",
                isSmallScreen
                  ? "right-10 top-10"
                  : "top-[55%] -right-0 -translate-y-[50%] -translate-x-[20%]",
              )}
            >
              {isCatchingWorm && isShowToolTip && (
                <div className="worm-tool-tip flex justify-center">
                  {isCatchingWorm && <div className="worm-loader"></div>}
                </div>
              )}

              <div>
                <img
                  ref={wormRef}
                  onClick={() => {
                    !isCatchingWorm && !isGettingWorm && handleCatchWorm();
                    handleClickWorm();
                  }}
                  src={`/images/inventory/worm/${worm?.data?.type}.png?v=5`}
                  className={clsx(
                    "absolute top-0 right-0 z-30 scale-worm",
                    isSmallScreen ? "w-[50px] h-[50px]" : "w-[60px] h-[60px]",
                  )}
                  alt=""
                ></img>
                {isShowSmoke && (
                  <img
                    src={`/images/inventory/smoke.gif`}
                    className={clsx(
                      "absolute -top-8 -right-9 z-30 ",
                      isSmallScreen
                        ? "w-[130px] h-[130px]"
                        : "w-[150px] h-[150px]",
                    )}
                    alt=""
                  ></img>
                )}
              </div>
              <img
                ref={auraRef}
                src={`/images/inventory/worm/${worm?.data?.type}_aura.png`}
                className={clsx(
                  "absolute -top-1 -right-1 z-20 blink-worm",
                  isSmallScreen ? "w-[52px] h-[52px]" : "w-[70px] h-[70px]",
                )}
                alt=""
              ></img>
            </div>
          ) : (
            <div
              className={clsx(
                "relative w-[35%] h-[40%] z-10",
                isSmallScreen
                  ? "right-5 top-10"
                  : "top-[40%] -translate-y-[10%] -ml-[10%]",
              )}
            >
              {worm?.data && (
                <div className="hidden-worm-tool-tip flex justify-center">
                  {isGettingWorm ? (
                    <div className="worm-loader"></div>
                  ) : (
                    <Cd
                      date={countDownTime}
                      onComplete={() => {
                        refreshWorm();
                      }}
                      renderer={({ hours, minutes, seconds }: any) => {
                        return (
                          <div className="flex gap-1 justify-center text-sm">
                            <p className="text-[13px] text-left">Next in:</p>
                            <div className="font-semibold flex justify-center">
                              {<span>{String(hours).padStart(2, "0")}:</span>}
                              {<span>{String(minutes).padStart(2, "0")}:</span>}
                              {<span>{String(seconds).padStart(2, "0")}</span>}
                            </div>
                          </div>
                        );
                      }}
                    ></Cd>
                  )}
                </div>
              )}

              <div>
                <img
                  onClick={() => {
                    setIsOpenWormNotifi(true);
                  }}
                  src={`/images/inventory/mystery_worm.png?v=5`}
                  className={clsx(
                    "absolute top-0 right-0 z-30 mr-[10%]",
                    isSmallScreen ? "w-[50px] h-[50px]" : "w-[50px] h-[50px]",
                  )}
                  alt=""
                ></img>
              </div>
            </div>
          )}

          {/* <div
            onClick={handleGoToCage}
            className={clsx(
              "absolute inset-0 mr-auto my-auto cursor-pointer z-30 cage-swing",
              isMobile
                ? "ml-[25%] w-[54px] h-[68px]"
                : "ml-[33%] w-[50px] h-[55px]"
            )}
          >
            <img
              src="/images/bird-cage/bird_cage_home.png"
              alt=""
              className="w-full h-full object-contain mt-[70%]"
            />
          </div> */}

          {!accountV2?.data?.give_first_egg && (
            <div onClick={() => !isClaimingFirstEgg && handleClaimFirstEgg()}>
              <img
                src={`/images/basic_bird.png?v=5`}
                className={clsx(
                  "z-30 shake_egg",
                  isSmallScreen ? "w-[44px] h-[44px]" : "w-[44px] h-[44px]",
                )}
                alt=""
              ></img>
            </div>
          )}
        </div>

        {/* storage button */}
        <div className="dark:rounded-[40px] z-10">
          <div className={clsx("max-h-[90px]", isSmallScreen ? "mt-1" : "")}>
            <div className="dark:rounded-[40px]">
              <div
                onClick={handleCheckClaim}
                className={clsx(
                  "rounded-[40px] w-full relative overflow-hidden ",
                  "bg-white",
                  isSmallScreen ? "p-2" : "p-4",
                )}
              >
                {/* progess bar */}
                <div
                  ref={progressRef}
                  className={clsx(
                    "h-full top-0 left-0 absolute z-10",
                    // "bg-[#D6FF9D]",
                    timePassed >= 1800 ? "bg-[#FF9900]" : "bg-[#D6FF9D]",
                  )}
                ></div>

                {/* blur when has news */}
                {hasReadLatestMessage && (
                  <div className="bg-[#000] opacity-60  w-full h-full top-0 left-0 absolute z-30 flex justify-center items-center">
                    <Button
                      onClick={checkLatestMessage}
                      className=" font-extrabold text-white py-3 px-[14px] border-solid border-[1px] border-white rounded-[40px]"
                    >
                      CHECK NEWS
                    </Button>
                  </div>
                )}

                <div
                  className={clsx(
                    "relative z-10 flex items-center",
                    hasReadLatestMessage ? "blur-sm" : "",
                    isFill ? "justify-center" : "justify-between",
                  )}
                >
                  <div
                    className={clsx(
                      "flex items-center gap-1",
                      // isFill && "text-white",
                    )}
                  >
                    <p className=" font-medium text-lg">
                      {timePassed >= 1800 ? "Claim" : "Seeding"}
                    </p>
                    <div className="w-4 aspect-square ml-1">
                      <img
                        src="/images/icons/token_icon.png?v=3"
                        alt="token"
                      ></img>
                    </div>
                    {!Number.isNaN(minedSeed) && (
                      <p className="font-medium text-lg">
                        {minedSeed < 0
                          ? Number(0).toFixed(6)
                          : minedSeed.toFixed(6)}
                      </p>
                    )}
                  </div>

                  {isFill ? (
                    ""
                  ) : (
                    <Countdown
                      isShowDay={false}
                      date={endTime * 1000}
                    ></Countdown>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <NotifiModal
        isX4Speed={isX4}
        isOpen={isOpenNotifi}
        handleClose={() => setIsOpenNotifi(false)}
      />

      {isOpenDailyMission && (
        <DailyMissonModal
          isLoading={checkDailyLoading}
          handleDoMission={handleDoDailyMission}
          data={dailyMissions}
          closeModal={() => setIsOpenDailyMission(false)}
        />
      )}

      <GetWormModal
        isOpen={isOpenGetWormModal.isOpen}
        handleClose={() => {
          setIsOpenGetWormModal({ isOpen: false, worm: 0 });
          refreshWorm();
        }}
        wormLevel={isOpenGetWormModal.worm?.type}
        handleSellWorm={() => handleSellWorm()}
        wormPrice={isOpenGetWormModal.worm?.reward}
        isLoading={isSellingWorm}
        handleSavetoInventory={() => {
          setIsOpenGetWormModal({ isOpen: false, worm: 0 });
          navigate(navPaths.INVENTORY);
        }}
      />

      <WormAppearNotifi
        isOpen={isOpenWormNotifi}
        handleClose={() => {
          setIsOpenWormNotifi(false);
        }}
      />
    </>
  );
};

export default Home;
