import clsx from "clsx";
import { useScreenSize } from "../../../Hooks/useScreenSize";
import useGetMyBox from "../Hooks/useGetMyBox";
import Loading from "../../../components/common/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import {
  eggBackgroundColors,
  eggBorderColors,
  eggButtonShadowColors,
  eggButtonTextColors,
  eggLevels,
  eggTextShadowColors,
} from "../constanst";
import { navPaths } from "../../../constants/navbar.constants";
import { useMemo } from "react";

const OpenBox = () => {
  const tele = window.Telegram.WebApp;
  const location = useLocation();
  const navigate = useNavigate();
  const { height } = useScreenSize();
  const { myBox, isLoadingMyBox } = useGetMyBox();

  tele.BackButton.show();
  tele.BackButton.onClick(() => handleBackBtn());

  const eggLevel = useMemo(() => {
    if (myBox?.data) {
      return location.state?.data?.type ?? myBox?.data?.eggs?.[0]?.type;
    }
  }, [location.state?.data?.type, myBox?.data]);

  const handleBackBtn = () => {
    if (location.state?.from === navPaths.INVENTORY) {
      navigate(navPaths.INVENTORY);
      return;
    }
    navigate("/");
  };

  const handleCrackEgg = () => {
    toast.error("The egg is hatching!", {
      style: { maxWidth: 337, height: 40, borderRadius: 8 },
      toastId: "egg",
      autoClose: 2000,
    });
  };

  return (
    <>
      {isLoadingMyBox ? (
        <Loading />
      ) : (
        <div
          className={clsx(
            "px-4 pb-[100px] h-screen relative z-30",
            "flex flex-col justify-center flex-1 item",
            "pt-[40px]"
          )}
          style={{ backgroundColor: eggBackgroundColors[eggLevel] }}
        >
          <ToastContainer
            position="top-left"
            closeOnClick
            transition={Slide}
            hideProgressBar
            limit={1}
            stacked
            className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
          />
          <p
            style={{
              color:
                eggLevel === eggLevels.common
                  ? eggButtonTextColors["common"]
                  : "#fff",
            }}
            className={clsx(
              "font-spicy-rice-regular text-center text-[40px]",
              eggLevel === eggLevels.common
                ? ""
                : "drop-shadow-[0_2px_2px_#00000073]"
            )}
          >
            Congrats!
          </p>
          <div
            className={clsx(
              "flex flex-1 max-h-[450px] relative justify-center",
              height > 650 ? "items-center" : "items-start"
            )}
          >
            <div
              className={clsx(
                "rounded-[50%] bg-[#fff] aura",
                height > 650 ? "w-[298px] h-[298px]" : "w-[180px] h-[180px]"
              )}
            ></div>
            <img
              className={clsx(
                "shaking-box absolute",
                height > 650 ? "w-[240px] h-[293px]" : "w-[130px] h-[160px]"
              )}
              src={`/images/egg/${eggLevel}.png?v=4`}
              alt=""
            ></img>
          </div>
          <div className="w-full mb-7">
            <p
              style={{
                textShadow: `0 3px 2px ${eggTextShadowColors[eggLevel]}`,
                color:
                  eggLevel === eggLevels.common
                    ? eggButtonTextColors["common"]
                    : "#fff",
              }}
              className="text-center capitalize font-spicy-rice-regular font-medium text-2xl mt-2 "
            >
              {`${eggLevel} egg`}
            </p>
            <div
              className={clsx(
                " w-full max-h-[110px] rounded-lg p-4 text-sm ",
                eggLevel === eggLevels.common
                  ? "text-[#305500] bg-[#0000001A]"
                  : "text-white bg-[#00000033]",
                height > 590 ? "mt-6 p-4" : "mt-1 p-2"
              )}
            >
              <p className="font-extrabold">Contains:</p>
              <ul className="list-disc pl-7">
                <li>Special boost for inactive</li>
                <li>Exclusive Genesis NFTs</li>
                <li>SEED Treasure</li>
              </ul>
            </div>
          </div>
          <div className="absolute bottom-[35px] right-4 left-4 flex flex-col items-center justify-center">
            <button
              onClick={() => !toast.isActive("egg") && handleCrackEgg()}
              className={clsx(
                "py-[18px] normal-case w-full rounded-xl flex items-center justify-center gap-2",
                "btn-hover font-extrabold",
                "bg-white border-2"
              )}
              style={{
                color: eggButtonTextColors[eggLevel],
                borderColor: eggBorderColors[eggLevel],
                boxShadow: `0 4px 0 ${eggButtonShadowColors[eggLevel]}`,
              }}
            >
              Hatch
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default OpenBox;
