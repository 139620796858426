import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerError, ServerResponse } from "../../../types/api";
import { Result } from "../../../types/utils";

export const getDetailItem = async ({
  id,
}: {
  id: string;
}): Promise<Result<any, ServerError>> => {
  const response = await fetchApi("GET", `/market-item/${id}`);
  if (response.error) {
    // Throw to retry
    throw new Error("network-error");
  }

  const detailItemResponse: ServerResponse<any[]> = await response.data.json();
  if (detailItemResponse.error) {
    return {
      error: detailItemResponse.error,
    };
  }

  return {
    data: detailItemResponse.data,
  };
};

export default function useGetDetailItem({ id }: { id: string }) {
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["DetailItem"],
    queryFn: () => getDetailItem({ id }),
    retry: 1,
  });

  return {
    detailItem: data,
    isLoadingDetailItem: isFetching,
    refreshDetailItem: refetch,
  };
}
