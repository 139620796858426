import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { useClickOutside } from "../../../Hooks/useClickOutside";
// import { navPaths } from "../../../constants/navbar.constants";
import { MARKET_ITEM_STATUS } from "../../../utils/helper";
import { ITEM_TYPES } from "../Pages/Market";
import BuyModal from "./BuyModal";
import ModalContainer from "../../../components/common/ModalContainer";
import { useMarketStore } from "../../../store/marketStore";
import { getNumberFormatUs } from "../../../utils/formatNumber";
import { isMobile } from "react-device-detect";

const List = ({
  marketType,
  marketData,
  mineData,
  showOwnedItems,
  userID,
  triggerReload,
  typeFilter,
  // onSetPage,
  page,
}: // setPage,
// loading,
{
  marketType: string;
  marketData: any;
  mineData: any;
  showOwnedItems: boolean;
  userID: string | undefined;
  triggerReload: any;
  typeFilter: string;
  // onSetPage: any;
  page: number;
  // setPage: any;
  // loading: boolean;
}) => {
  // const navigate = useNavigate();

  const [items, setItems] = useState<any>([]);

  // const showLoadMore = () => {
  //   if (showOwnedItems) {
  //     if (mineData?.items?.length >= mineData?.total) return false;
  //     return true;
  //   } else {
  //     if (marketData?.items?.length >= marketData?.total) return false;
  //     return true;
  //   }
  // };

  // useEffect(() => {
  //   if (showOwnedItems) {
  //     if (page === 1) {
  //       mineData?.items?.length > 0 ? setItems(mineData?.items) : setItems([]);
  //     } else {
  //       mineData?.items?.length > 0 &&
  //         setItems((prevState: any) =>
  //           removeDuplicate([...prevState, ...mineData?.items])
  //         );
  //     }
  //   } else {
  //     if (page === 1) {
  //       marketData?.items?.length > 0
  //         ? setItems(marketData?.items)
  //         : setItems([]);
  //     } else {
  //       marketData?.items?.length > 0 &&
  //         setItems((prevState: any) =>
  //           removeDuplicate([...prevState, ...marketData?.items])
  //         );
  //     }
  //   }
  // }, [marketData, mineData, showOwnedItems, page]);
  useEffect(() => {
    if (showOwnedItems) {
      mineData?.items?.length > 0 ? setItems(mineData?.items) : setItems([]);
    } else {
      marketData?.items?.length > 0
        ? setItems(marketData?.items)
        : setItems([]);
    }
  }, [marketData, mineData, showOwnedItems, page]);

  const [item, setItem] = useState<any>();
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  // const [reachBottom, setReachBottom] = useState<boolean>(false);

  // useEffect(() => {
  //   !loading && setReachBottom(false);
  // }, [loading]);
  // eslint-disable-next-line react-hooks/rules-of-hooks

  const handleBuy = (item: any) => {
    setItem(item);
    setIsShowModal(!isShowModal);
  };

  const buyModalRef = useRef<HTMLDivElement>(null);
  useClickOutside(buyModalRef, () => {
    setIsShowModal(false);
  });
  const listRef = useRef<any>(null);

  const floorPrice = useMarketStore((state: any) => state.floorPrice);

  if (typeFilter === "mythic") {
    return (
      <div
        style={{ height: "calc(100vh - 250px)" }}
        className="w-full flex flex-col items-center justify-center gap-4 text-[#345C00] dark:text-white"
      >
        <img
          src={
            marketType === "egg"
              ? "/images/market/no_egg.png"
              : "/images/inventory/no_data.png"
          }
          className="w-[50%] h-auto"
          alt="no-egg"
        ></img>
        <span className="text-[20px]">Not yet appeared in the world!</span>
      </div>
    );
  }

  if (items?.length === 0) {
    return (
      <div
        style={{ height: "calc(100vh - 250px)" }}
        className="w-full flex flex-col items-center justify-center gap-4 text-[#345C00] dark:text-white"
      >
        {typeFilter || showOwnedItems ? (
          <span className="text-[20px]">The list is empty</span>
        ) : (
          <span className="text-[20px]">Market cleared out!</span>
        )}

        <img
          src={
            marketType === "egg"
              ? "/images/market/no_egg.png"
              : "/images/inventory/no_data.png"
          }
          className="w-[50%] h-auto"
          alt="no-egg"
        ></img>
        {typeFilter && (
          <span className="text-[16px] font-bold text-center px-5 flex items-center">
            Floor price:
            <img
              src="/images/icons/token_icon.png"
              width={20}
              height={20}
              alt="token"
              className="ml-1 mr-[3px]"
            ></img>
            {getNumberFormatUs(floorPrice / 10 ** 9, 2)}
          </span>
        )}
        {(typeFilter || showOwnedItems) && (
          <span className="text-[16px] text-center px-6">
            There are currently no items that match your filter.
          </span>
        )}
        {!typeFilter && !showOwnedItems && (
          <span className="text-[16px] text-center px-6">
            Other players have taken everything! Items from other sellers will
            appear soon, so stay alert!
          </span>
        )}
      </div>
    );
  }

  // const getPrice = (item: any) => {
  //   return item?.created_by === userID
  //     ? getNumberFormatUs(item?.price_net / 10 ** 9, 4)
  //     : getNumberFormatUs(item?.price_gross / 10 ** 9, 4);
  // };

  const getPrice = (item: any) => item?.price_gross / 10 ** 9;

  // const handleScroll = (e: React.UIEvent<HTMLElement>) => {
  //   const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
  //   const bottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 1;
  //   if (bottom) {
  //     setReachBottom(true);
  //     setPage((prev: number) => prev + 1);
  //   }
  // };
  const getImageSource = (item: any) => {
    if (marketType === "egg") {
      if (!item?.egg_type) {
        return "/images/market/no_egg.png";
      } else {
        return `/images/egg/${item?.egg_type}.png`;
      }
    }
    if (marketType === "worm") {
      if (!item?.worm_type) {
        return "/images/inventory/no_data.png";
      } else {
        return `/images/inventory/worm/${item?.worm_type}.png`;
      }
    }
  };

  return (
    <div ref={listRef} className="grid grid-cols-2 gap-3 lsm:gap-4">
      {items?.map((item: any, index: number) => {
        if (item?.status !== MARKET_ITEM_STATUS.ON_SALE) return null;
        return (
          <div key={index} className=" relative pb-2">
            <div
              // onClick={() =>
              //   navigate(navPaths.MARKET_ITEM, {
              //     state: {
              //       ...item,
              //       userID: userID,
              //       marketType,
              //     },
              //   })
              // }
              className={clsx(
                "border-[2px] border-[#8F8F8F] rounded-[12px] px-[7px] lsm:px-[11px] pb-[11px] bg-gradient-to-b from-[#F8FFEF] to-[#E5FFC0] flex flex-col gap-0 drop-shadow-[0_4px_0px_#424242]",
                // index === items?.length - 1 && "mb-14"
              )}
            >
              <div
                className="rounded-b-[4px] px-2 mx-[5px] pt-[2px] pb-1 flex items-center justify-between"
                style={{
                  color:
                    item?.egg_type === "common" || item?.worm_type === "common"
                      ? "#000"
                      : "#fff",
                  backgroundColor: ITEM_TYPES.find(
                    (des) =>
                      des.type === item?.egg_type ||
                      des.type === item?.worm_type,
                  )?.color,
                }}
              >
                {/* <div className="lsm:hidden text-[12px] font-bold capitalize">
                  {item?.egg_type === "legendary" ? "legend" : item?.egg_type}
                </div> */}
                <div className="text-[12px] font-bold capitalize">
                  {item?.egg_type === "legendary" ||
                  item?.worm_type === "legendary"
                    ? "legend"
                    : item?.egg_type || item?.worm_type}
                </div>
                <div className="text-[12px] font-[400]">
                  {
                    ITEM_TYPES.find(
                      (des) =>
                        des.type === item?.egg_type ||
                        des.type === item?.worm_type,
                    )?.description
                  }
                </div>
              </div>
              <div className="flex flex-col items-center justify-center relative">
                <div className="flex justify-center mt-3 -mb-1">
                  <div className=" w-fit flex items-center gap-1 text-sm pr-2 uppercase">
                    <div
                      style={{
                        color: "#fff",
                        backgroundColor:
                          item?.egg_type === "common" ||
                          item?.worm_type === "common"
                            ? "#13121C"
                            : ITEM_TYPES.find(
                                (des) =>
                                  des.type === item?.egg_type ||
                                  des.type === item?.worm_type,
                              )?.color,
                      }}
                      className="w-[16px] h-[16px] rounded-full  flex items-center justify-center"
                    >
                      #
                    </div>
                    <p
                      className="font-medium"
                      style={{
                        color:
                          item?.egg_type === "common" ||
                          item?.worm_type === "common"
                            ? "#000"
                            : ITEM_TYPES.find(
                                (des) =>
                                  des.type === item?.egg_type ||
                                  des.type === item?.worm_type,
                              )?.color,
                      }}
                    >
                      {marketType === "egg"
                        ? item?.egg_id?.slice(
                            item?.egg_id?.length - 9,
                            item?.egg_id?.length,
                          )
                        : item?.worm_id?.slice(
                            item?.worm_id?.length - 9,
                            item?.worm_id?.length,
                          )}
                    </p>
                  </div>
                </div>
                <img
                  src={getImageSource(item)}
                  alt={"market-item"}
                  className={clsx(
                    "w-[60%] object-contain",
                    isMobile
                      ? "h-[90px] mt-1 sm:mt-0 sm:h-[130px]"
                      : "h-[90px] my-1",
                  )}
                />
              </div>
              <div className="flex items-center justify-between mt-1">
                <div className="flex items-center gap-[2px]">
                  <img
                    src="/images/icons/token_icon.png"
                    width={20}
                    height={20}
                    alt="token"
                  ></img>
                  <span
                    className={clsx(
                      "font-[600] text-[14px] text-ellipsis overflow-clip",
                      isMobile
                        ? "max-w-[50px] sm:max-w-[75px]"
                        : "max-w-[60px]",
                    )}
                  >
                    {getPrice(item)}
                  </span>
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleBuy(item);
                  }}
                  className="border-black text-sm text-white font-bold h-[32px] w-[70px] flex items-center justify-center cursor-pointer capitalize rounded-[4px] bg-gradient-to-r from-[#97C35B] to-[#61A700] border-b-[3px] border-b-[#4C7E0B]"
                >
                  {item?.created_by === userID ? "Unlist" : "Buy"}
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {/* {reachBottom && showLoadMore() && (
          <div className=" fixed bottom-3 right-0 left-0 flex items-center justify-center">
            <div className="w-[200px] h-14 px-3 flex items-center justify-center">
              <CircularProgress color="success" />
            </div>
          </div>
        )} */}
      {/* {isFetching && <div>Loading...</div>} */}
      <ModalContainer>
        {isShowModal && (
          <BuyModal
            ref={buyModalRef}
            showModal={setIsShowModal}
            type={item?.egg_type || item?.worm_type}
            price={getPrice(item)}
            cancelSell={item?.created_by === userID}
            id={item?.id}
            marketId={item?.egg_id || item?.worm_id}
            ownerId={userID || ""}
            triggerReload={triggerReload}
          />
        )}
      </ModalContainer>
    </div>
  );
};

export default List;
