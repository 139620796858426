import clsx from "clsx";
import React, { ReactNode } from "react";
import ItemLoading from "../../../components/common/ItemLoading";

const StartBtn = ({
  children,
  onClick,
  isLoading,
}: {
  children: ReactNode;
  onClick: () => void;
  isLoading?: boolean;
}) => {
  return (
    <button
      disabled={isLoading}
      onClick={() => onClick()}
      className={clsx(
        "bg-[#61A700] w-[80px] rounded-[38px] py-2 px-[22px] capitalize text-[#fff] text-sm font-semibold flex items-center justify-center"
      )}
    >
      {isLoading ? <ItemLoading /> : children}
    </button>
  );
};

export default StartBtn;
