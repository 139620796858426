import clsx from "clsx";
import { motion } from "framer-motion";
import Backdrop from "../../../components/common/Backdrop";
import { dropIn } from "../../../components/common/ModalContainer";
import { wormBg, wormBorderColor, wormShadowColor } from "../constants";

const ConfirmBuyModal = ({
  item,
  handleCloseModal,
  handleBuy,
}: {
  item: any;
  handleCloseModal: () => void;
  handleBuy: (id: string) => void;
}) => {
  return (
    <Backdrop onClick={() => handleCloseModal()} className="!bg-[#000000E5]">
      <motion.div
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="release-modal"
      >
        <div
          className={clsx(
            "bg-[#FFF] rounded-[20px] p-[24px] flex flex-col items-center justify-center relative backdrop-blur"
          )}
        >
          <span className="text-[24px] capitalize font-semibold">{`Get ${item.name.toLowerCase()} Worm`}</span>
          <>
            <div className="w-[135px] relative">
              <motion.div
                className={clsx(
                  "max-h-[230px] w-[135px] rounded-[8px] px-[4px] pb-[5px] flex flex-col"
                )}
                style={{
                  border: `2px solid ${
                    wormBorderColor[item.name.toLowerCase()]
                  }`,
                  boxShadow: `0px 4px 0px ${
                    wormShadowColor[item.name.toLowerCase()]
                  }`,
                  background: `radial-gradient(circle, rgba(242,242,242,1) 0%, ${
                    wormBg[item.name.toLowerCase()]
                  } 40%)`,
                }}
              >
                <div className="rounded-b-[4px] px-2 mx-[18px] lsm:mx-[10px] flex items-center justify-center bg-[#00000080] text-white">
                  <div className="text-[11px] font-bold capitalize">
                    {item.name.toLowerCase()}
                  </div>
                </div>

                <div className="flex flex-col items-center justify-center relative">
                  <img
                    src={`/images/inventory/worm/${item.name.toLowerCase()}.png`}
                    alt={"market-item"}
                    className="h-[76px] w-[100px] object-contain"
                  />
                </div>
                <div className="flex justify-center py-1 items-center gap-[2px] bg-[#fff] shadow-[0_2px_0_#4F4F4F] rounded-[5px] mt-2">
                  <div>
                    <img
                      src={`/images/inventory/worm/${item.name.toLowerCase()}.png`}
                      alt={"market-item"}
                      className="h-4 w-5 object-contain"
                    />
                  </div>
                  <p className="font-bold text-[14px]">{`x${item.worm_items[0].amount}`}</p>
                </div>
              </motion.div>
            </div>
          </>
          <div className="mt-4 w-[200px]">
            <div
              onClick={() => {
                handleBuy(item.id);
              }}
              className={clsx(
                "flex items-center justify-center gap-1 text-[20px] w-full text-[#fff] h-[48px] px-5 rounded-[38px] normal-case mx-auto",
                " bg-[#000000] select-none"
              )}
            >
              <svg
                width="26"
                height="25"
                viewBox="0 0 26 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.0288 0.72445C12.3427 0.0413427 13.3115 0.0358875 13.6331 0.715415L16.5935 6.97079C16.8042 7.41604 17.2281 7.72248 17.717 7.78295L24.9136 8.67306C25.6913 8.76925 25.9696 9.75406 25.3573 10.2431L20.0516 14.4805C19.6043 14.8378 19.4114 15.4264 19.5606 15.9792L21.4302 22.905C21.6369 23.6708 20.8096 24.3009 20.1263 23.8982L13.5516 20.0233C13.1009 19.7577 12.5416 19.7577 12.091 20.0233L5.7238 23.7759C5.014 24.1942 4.16785 23.501 4.43835 22.7228L6.02364 18.162C6.37222 17.1591 7.08729 16.3253 8.02526 15.828L12.7713 13.3113C12.9884 13.1962 12.8842 12.8659 12.6404 12.8962L7.55551 13.5285C6.53427 13.6555 5.50191 13.4035 4.65402 12.8203L0.912569 10.2467C0.242702 9.78592 0.496351 8.74155 1.30295 8.63937L8.03723 7.78624C8.53014 7.7238 8.95591 7.41139 9.16337 6.95993L12.0288 0.72445Z"
                  fill="url(#paint0_linear_9164_1472)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_9164_1472"
                    x1="19.125"
                    y1="3.0625"
                    x2="7.37975"
                    y2="24.1437"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FCFF4C" />
                    <stop offset="1" stop-color="#FE6D04" />
                  </linearGradient>
                </defs>
              </svg>
              <p className="font-semibold">{item.price}</p>
            </div>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default ConfirmBuyModal;
