import clsx from "clsx";
import DisableTicket from "../components/SVG/DisableTicket";
import { LoadingButton } from "@mui/lab";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import StreakGuide from "./StreakGuide";
import useClaimStreakReward from "../hooks/useClaimStreakReward";
import { getProgessPercentage, getStreakDays } from "../utils";
import EnabletTicket from "./SVG/EnabletTicket";
import { toast } from "react-toastify";
import { sleep } from "../../../utils/time";
import ClaimedTicket from "./SVG/ClaimedTicket";

const StreakClaim = ({
  Streak,
  StreakReward,
  TriggerRefresh,
}: {
  Streak: any;
  StreakReward: any;
  TriggerRefresh: () => void;
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  const { claimStreakRewardAsync, isClaimingStreakReward } =
    useClaimStreakReward();

  const progressRef = useRef<any>();

  const [StreakCount, lostStreak] = useMemo(() => {
    if (!Streak) {
      return [1, true];
    }
    if (!Streak.length) {
      return [1, true];
    }

    const lastStreak = new Date(Streak?.[0]?.created_at).getTime();
    const now = new Date().getTime();
    if (now - lastStreak > 86400000) {
      return [1, now - lastStreak > 86400000];
    }

    return [Streak?.[0]?.no, now - lastStreak > 86400000];
  }, [Streak]);

  const totalDay =
    getStreakDays(StreakCount)?.[3] - getStreakDays(StreakCount)?.[0] + 1;

  const [canClaimReward] = useMemo(() => {
    if (!StreakReward) {
      return [false];
    }

    const canClaim = !!StreakReward?.[0]?.no;

    return [canClaim];
  }, [StreakReward]);

  useEffect(() => {
    if (progressRef.current) {
      progressRef.current.style.width = getProgessPercentage(StreakCount) + "%";
      return;
    }
  }, [StreakCount, totalDay]);

  const handleClaimStreakReward = () => {
    const streakIds = StreakReward?.filter(
      (item: any) => item.status === "created"
    )
      .splice(0, StreakReward?.[0]?.no)
      .map((item: any) => item.id);

    claimStreakRewardAsync(streakIds)
      .then(async () => {
        toast.error("Claim streak reward succesfully", {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
        });
        await sleep(500);
        TriggerRefresh();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
        });
      });
  };

  const renderTicket = (day: number) => {
    const hasReward =
      StreakReward?.filter((item: any) => item.no === day)?.length > 0;

    if (!Streak?.length) {
      return <DisableTicket />;
    }
    if (StreakCount >= day && hasReward) {
      return <EnabletTicket />;
    }

    if (StreakCount >= day && !hasReward) {
      return <ClaimedTicket />;
    }

    return <DisableTicket />;
  };

  const checkIsClaimed = (day: number) => {
    if (Streak?.length === 0 && StreakCount === day && !StreakReward?.[0]?.no) {
      return false;
    }
    if (StreakCount >= day && !!StreakReward?.[0]?.no) {
      return true;
    }
    if (StreakCount >= day && !StreakReward?.[0]?.no) {
      return true;
    }
    return false;
  };

  return (
    <div className="bg-[#0000000A] p-4 rounded-[12px]">
      <div className="flex items-center justify-between">
        <div className="flex gap-1 items-center">
          <p className="text-[24px] font-extrabold">Streak Bonus</p>
          {Streak?.[0]?.no > 0 && !lostStreak && (
            <div className="bg-[#D7E7C2] py-[3px] px-[6px] rounded h-[23px] flex items-center gap-[2px]">
              <img
                src="images/lucky_spin/fusion/fire.png"
                className="size-[15px]"
                alt=""
              ></img>
              <p className="text-[#4C7E0B] text-sm font-medium">{`${
                Streak?.[0]?.no
              } ${StreakCount > 1 ? "days" : "day"}`}</p>
            </div>
          )}
        </div>
        <div>
          <ClickAwayListener
            onClickAway={() => {
              setIsTooltipOpen(false);
            }}
          >
            <Tooltip
              placement="left-start"
              PopperProps={{
                disablePortal: true,
              }}
              arrow
              onClose={() => {
                setIsTooltipOpen(false);
              }}
              open={isTooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={<StreakGuide />}
              className="text-sm m-0 z-90"
            >
              <button onClick={() => setIsTooltipOpen(!isTooltipOpen)}>
                <svg
                  width="22"
                  height="23"
                  viewBox="0 0 22 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 0.25C17.0586 0.25 22 5.19141 22 11.25C22 17.3516 17.0586 22.25 11 22.25C4.89844 22.25 0 17.3516 0 11.25C0 5.19141 4.89844 0.25 11 0.25ZM11 5.75C10.2266 5.75 9.625 6.39453 9.625 7.125C9.625 7.89844 10.2266 8.5 11 8.5C11.7305 8.5 12.375 7.89844 12.375 7.125C12.375 6.39453 11.7305 5.75 11 5.75ZM12.7188 16.75C13.2773 16.75 13.75 16.3203 13.75 15.7188C13.75 15.1602 13.2773 14.6875 12.7188 14.6875H12.0312V10.9062C12.0312 10.3477 11.5586 9.875 11 9.875H9.625C9.02344 9.875 8.59375 10.3477 8.59375 10.9062C8.59375 11.5078 9.02344 11.9375 9.625 11.9375H9.96875V14.6875H9.28125C8.67969 14.6875 8.25 15.1602 8.25 15.7188C8.25 16.3203 8.67969 16.75 9.28125 16.75H12.7188Z"
                    fill="#4C7E0B"
                  />
                </svg>
              </button>
            </Tooltip>
          </ClickAwayListener>
        </div>
      </div>

      <div>
        <div className="mt-5 h-[60px] flex flex-col justify-between">
          <div className="w-full relative">
            <div className="absolute left-0">
              {renderTicket(getStreakDays(StreakCount)?.[0])}
            </div>
            <div
              style={{
                left: `30%`,
              }}
              className="absolute "
            >
              {renderTicket(getStreakDays(StreakCount)?.[1])}
            </div>
            <div
              style={{
                left: `60%`,
              }}
              className="absolute"
            >
              {renderTicket(getStreakDays(StreakCount)?.[2])}
            </div>
            <div className="absolute right-0">
              {renderTicket(getStreakDays(StreakCount)?.[3])}
            </div>
          </div>
          <div
            className={clsx(
              " h-[10px] bg-[#D7E7C2] border-[1px] border-[#AFBE9C] rounded-[20px] relative "
            )}
          >
            <div
              ref={progressRef}
              className="absolute h-[8px] bg-[#4C7E0B]"
            ></div>
            <div
              style={{ left: "-1px" }}
              className={clsx(
                "size-6 text-[12px] border-[1px] rounded-[50%] flex items-center justify-center",
                "absolute top-[50%] -translate-y-[50%]",
                checkIsClaimed(getStreakDays(StreakCount)?.[0])
                  ? "bg-[#4C7E0B] text-[#fff] border-[#F2FFE0]"
                  : "bg-[#F2FFE0] text-[#000] border-[#AFBE9C]"
              )}
            >
              {getStreakDays(StreakCount)?.[0]}
            </div>
            <div
              style={{
                left: `30%`,
              }}
              className={clsx(
                "size-6 text-[12px] border-[1px] border-[#AFBE9C] rounded-[50%] flex items-center justify-center",
                "absolute top-[50%] -translate-y-[50%]",
                checkIsClaimed(getStreakDays(StreakCount)?.[1])
                  ? "bg-[#4C7E0B] text-[#fff] border-[#F2FFE0]"
                  : "bg-[#F2FFE0] text-[#000] border-[#AFBE9C]"
              )}
            >
              {getStreakDays(StreakCount)?.[1]}
            </div>
            <div
              style={{
                left: `60%`,
              }}
              className={clsx(
                "size-6 text-[12px] border-[1px] border-[#AFBE9C] rounded-[50%] flex items-center justify-center",
                "absolute top-[50%] -translate-y-[50%]",
                checkIsClaimed(getStreakDays(StreakCount)?.[2])
                  ? "bg-[#4C7E0B] text-[#fff] border-[#F2FFE0]"
                  : "bg-[#F2FFE0] text-[#000] border-[#AFBE9C]"
              )}
            >
              {getStreakDays(StreakCount)?.[2]}
            </div>
            <div
              className={clsx(
                "size-6 text-[12px] border-[1px] border-[#AFBE9C] rounded-[50%] flex items-center justify-center",
                "absolute top-[50%] -translate-y-[50%] -right-[1px]",
                checkIsClaimed(getStreakDays(StreakCount)?.[3])
                  ? "bg-[#4C7E0B] text-[#fff] border-[#F2FFE0]"
                  : "bg-[#F2FFE0] text-[#000] border-[#AFBE9C]"
              )}
            >
              {getStreakDays(StreakCount)?.[3]}
            </div>
          </div>
        </div>
      </div>

      <LoadingButton
        onClick={() => handleClaimStreakReward()}
        disabled={!canClaimReward || isClaimingStreakReward}
        loading={isClaimingStreakReward}
        className={clsx(
          "mt-5 text-[16px] w-full font-bold text-white py-[12px] rounded-xl normal-case",
          "btn-click-no-shadow bg-gradient-to-r from-[#97C35B] to-[#61A700] shadow-[0_4px_0px_#4C7E0B]",
          "disabled:shadow-[0_4px_0px_#4E4E4E] disabled:text-[#F3F3F3]"
        )}
      >
        {canClaimReward
          ? `Claim ${StreakReward?.length} ticket`
          : "Claim ticket"}
      </LoadingButton>
    </div>
  );
};

export default StreakClaim;
