import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const usePurchaseHistoryStore = create(
  persist(
    (set) => ({
      hasUnopenedBox: false,
      setHasUnopenBox: (hasUnopenedBox: boolean) =>
        set({ hasUnopenedBox: hasUnopenedBox }),
    }),
    {
      name: "purchased-history-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
