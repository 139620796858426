import React from "react";
import BaseBtn from "../BaseBtn";
import { navPaths } from "../../../../constants/navbar.constants";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

const Spiner = () => {
  const navigate = useNavigate();
  return (
    <div
      className={clsx(
        "px-[17px] mb-3 rounded-2xl bg-cover bg-no-repeat bg-center w-full h-[149px] relative"
      )}
      style={{ backgroundImage: "url('/images/games/spin_bg.png')" }}
    >
      <div className="absolute top-[50%] -translate-y-[50%] flex items-center justify-between gap-[11px]">
        <img
          className="size-[111px]"
          src="/images/games/spin_logo.png"
          alt=""
        ></img>
        <div className="flex flex-col justify-center items-start">
          <p className="game_spin_text_border">Spinner Eggs</p>
          <div className="flex items-start mt-[14px]">
            <BaseBtn
              className="border-[#FE8B87] bg-[#F2586833] size-9 mr-[6px]"
              onClick={() => navigate(navPaths.SPIN_EGG)}
            >
              <img
                className="w-5 h-[25px]"
                src="/images/games/unkown_egg_piece.png"
                alt=""
              ></img>
            </BaseBtn>
            <BaseBtn
              className="border-[#FE8B87] bg-[#F2586833] size-9 mr-[10px]"
              onClick={() => navigate(navPaths.SPIN_EGG)}
            >
              <img
                className="w-[22px] h-[26px]"
                src="/images/games/unkown_egg.png"
                alt=""
              ></img>
            </BaseBtn>
            <BaseBtn
              className="border-[#FE8B87] bg-[#CB2F2A] py-1 px-[29px] h-9 game_spin_text_border shadow-[0_3px_0_#B20600] text-base"
              onClick={() => navigate(navPaths.SPIN_EGG)}
            >
              Play
            </BaseBtn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spiner;
