import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { Result } from "../../../types/utils";
import { ServerError, ServerResponse } from "../../../types/api";

export const getUserBenefit = async (): Promise<Result<any, ServerError>> => {
  const response = await fetchApi("GET", `/guild/user-benefit`);
  if (response.error) {
    // Throw to retry
    throw new Error("network-error");
  }

  const userBenefitResponse: ServerResponse<any> = await response.data.json();
  if (userBenefitResponse.error) {
    return {
      error: userBenefitResponse.error,
    };
  }

  return {
    data: userBenefitResponse.data,
  };
};

export default function useGetUserBenefit() {
  const { data, refetch, isFetching, isRefetching } = useQuery({
    queryKey: ["UserBenefit"],
    queryFn: () => getUserBenefit(),
    retry: 1,
  });

  return {
    userBenefit: data,
    isLoadingUserBenefit: isFetching,
    refreshUserBenefit: refetch,
    isRefreshingUserBenefit: isRefetching,
  };
}
