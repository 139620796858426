import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerResponse } from "../../../types/api";

export const getGuildDetailMembersMembers = async ({
  sort_by,
}: {
  sort_by?: string;
}) => {
  const response = await fetchApi(
    "GET",
    `/guild/detail/member?sort_by=${sort_by}`
  );
  if (response.error) {
    throw new Error("network-error");
  }

  const getGuildDetailMembersResponse: ServerResponse<any> =
    await response.data.json();
  if (getGuildDetailMembersResponse.error) {
    return {
      error: getGuildDetailMembersResponse.error,
    };
  }

  return getGuildDetailMembersResponse.data;
};

export default function useGuildDetailMembers({
  sort_by,
  enable,
}: {
  sort_by?: string;
  enable: boolean;
}) {
  const { data, isPending, refetch, isRefetching } = useQuery({
    queryKey: ["GuildDetailMembers"],
    queryFn: () =>
      enable &&
      getGuildDetailMembersMembers({
        sort_by,
      }),
    retry: 1,
  });

  return {
    GuildDetailMembers: data,
    GuildDetailMembersLoading: isPending,
    refeshGuildDetailMembers: refetch,
    isRefetching,
  };
}
