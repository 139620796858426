/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import { motion } from "framer-motion";
import { useMemo } from "react";

const EggsCollected = ({ eggs }: { eggs: any }) => {
  const eggType = ["common", "uncommon", "rare", "epic", "legendary"];

  const Eggs = useMemo(() => {
    let collectedEggs = eggs;
    if (!eggs?.length) {
      collectedEggs = [
        { type: "common" },
        { type: "uncommon" },
        { type: "rare" },
        { type: "epic" },
        { type: "legendary" },
      ];
    }
    const count: { [key: string]: number } = {};

    eggType.forEach((type: any) => {
      count[type] = collectedEggs.filter(
        (item: any) => item.type === type
      ).length;
    });

    const allPieces: any[] = eggType.map((item: any) => {
      return {
        imgUrl: `images/egg/${item}.png` || "",
        quantity: !eggs?.length ? 0 : count[item],
        type: item,
      };
    });

    return allPieces;
  }, [eggs]);

  return (
    <div className="bg-[#E4F0D3] rounded-[12px] mt-4 p-4 flex flex-col gap-3 ">
      <div className="flex justify-between">
        <p className="font-medium">Eggs collected</p>
      </div>
      <div className="flex justify-between">
        {Eggs?.map((item, index) => {
          return (
            <div key={index}>
              <motion.div
                className={clsx(
                  "size-[50px] flex items-center rounded-[6px] justify-center bg-[#F2FFE0]"
                )}
              >
                <img className="w-8 h-[38px]" src={item.imgUrl} alt=""></img>
              </motion.div>
              <p className="text-sm text-center">{item.quantity}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EggsCollected;
