/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { Result } from "../../../types/utils";
import { TelegramBotMessage } from "../../../types/message";
import { ServerError, ServerResponse } from "../../../types/api";
import { useMemo, useState } from "react";

// V2
export const requestLatestMessage = async (): Promise<
  Result<TelegramBotMessage, ServerError>
> => {
  const response = await fetchApi("GET", "/latest-message");
  if (response.error) {
    // Throw to retry
    throw new Error("network-error");
  }

  const messageResponse: ServerResponse<TelegramBotMessage> =
    await response.data.json();
  if (messageResponse.error) {
    return {
      error: messageResponse.error,
    };
  }

  return {
    data: messageResponse.data,
  };
};

export const useLatestMessage = () => {
  const localStorageKey = "messageReadAt";

  const { data, refetch, isPending } = useQuery({
    queryKey: ["LatestMessage"],
    queryFn: requestLatestMessage,
    retry: (failureCount, error) => {
      return failureCount < 3 && error.message === "network-error";
    },
  });

  const [isRefreshLatestMessge, setIsRefreshLatestMessage] = useState(false)

  const [hasMessage, hasRead] = useMemo(() => {
    if (!data?.data) {
      return [false, false];
    }

    const hasMessage = data.data != null;

    const messageTime = data.data.date;
    const lastReadAt = Number(localStorage.getItem(localStorageKey));
    if (Number.isNaN(lastReadAt)) {
      return [hasMessage, false];
    }

    setIsRefreshLatestMessage(false)
    const hasRead = messageTime > lastReadAt;
    return [hasMessage, hasRead];
  }, [data, isRefreshLatestMessge]);

  const checkLatestMessage = () => {
    const telegram = window.Telegram.WebApp;
    if (!telegram) {
      return;
    }

    // Timestamp when user check the news
    localStorage.setItem(localStorageKey, (Date.now() / 1000).toString());
    setIsRefreshLatestMessage(true)
    refetch()
    telegram.openTelegramLink(process.env.REACT_APP_GROUP_URL);
  };

  return {
    latestMessage: data,
    hasMessage,
    hasRead,
    isPending,
    refetch,
    checkLatestMessage,
  };
};
