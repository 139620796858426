import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function connectWallet({
  address,
  network,
  proof,
}: {
  address: string;
  network: string;
  proof: {
    timestamp: number;
    domain: {
      lengthBytes: number;
      value: string;
    };
    payload: string;
    signature: string;
  };
}) {
  return await api.post("/api/v1/profile/wallets/ton", {
    address: address,
    network: network,
    proof: proof,
  });
}

export default function useConectWallet() {
  return useMutation({
    mutationKey: ["connectWallet"],
    mutationFn: connectWallet,
  });
}
