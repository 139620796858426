import clsx from "clsx";
import { bootTypeEnum } from "../../../constants/boots.constants";
import { useBoostStore } from "../../../store/boostStore";

interface MissionsItemPropType {
  type: number;
  level: number;
  secondItem?: boolean;
}

const MissionsItem = ({
  level,
  type,
  secondItem = false,
}: MissionsItemPropType) => {
  const isSmallScreen = window.innerHeight <= 520;

  const boostSpeedLevel = useBoostStore((state: any) => state.boostSpeedLevel);
  const boostStorageLevel = useBoostStore(
    (state: any) => state.boostStorageLevel
  );
  const boostWaterLevel = useBoostStore((state: any) => state.boostWaterLevel);

  const renderStorageItems = () => {
    return (
      <div
        className={clsx(
          "grid grid-cols-7 gap-0 rounded-2xl w-full",
          isSmallScreen ? "p-2" : " p-4",
          secondItem
            ? "border-[1px] border-[#BCE780] border-solid bg-[#EFFFD8]"
            : ""
        )}
      >
        <div className="col-span-2 flex ">
          <img
            src={`/images/storage/${level + 1}.png?v=3`}
            width={60}
            alt="storage"
          ></img>
        </div>
        <div className="col-span-5 flex flex-col justify-center items-start">
          <p className="font-semibold text-base mb-2">{`Level ${level + 1}`}</p>
          <div className="flex gap-[7px] ">
            <p className="text-sm ">{`Claim every ${
              boostStorageLevel[level]?.duration / 3600
            }h`}</p>
          </div>
        </div>
      </div>
    );
  };

  const renderTreeItems = () => {
    return (
      <div
        className={clsx(
          "grid grid-cols-7 gap-3 rounded-2xl w-full",
          isSmallScreen ? "p-2" : " p-4",
          secondItem
            ? "border-[1px] border-[#BCE780] border-solid bg-[#EFFFD8]"
            : ""
        )}
      >
        <div className="col-span-2 flex ">
          <img
            src={`/images/trees/${level + 1}.png?v=3`}
            width={60}
            alt=""
          ></img>
        </div>
        <div className="col-span-5 flex flex-col justify-center items-start">
          <p className="font-semibold text-base mb-2">{`Level ${level + 1}`}</p>
          <div className="flex gap-[7px] ">
            <p className="text-sm">{`Mine ${
              boostSpeedLevel[level]?.speed * 2
            } SEED/hour`}</p>
          </div>
        </div>
      </div>
    );
  };

  const renderWaterItems = () => {
    return (
      <div
        className={clsx(
          "grid grid-cols-9 gap-3 rounded-2xl  w-full",
          isSmallScreen ? "p-2" : " p-4",
          secondItem
            ? "border-[1px] border-[#BCE780] border-solid bg-[#EFFFD8]"
            : ""
        )}
      >
        <div className="col-span-2 flex ">
          <img
            src={`/images/holy/${level + 1}.png?v=3`}
            className="h-[60px] w-[50px]"
            width={60}
            alt=""
          ></img>
        </div>
        <div className="col-span-7 flex items-start flex-col justify-center">
          <p className="font-semibold text-base mb-2">{`Level ${level + 1}`}</p>
          <div className="flex gap-[7px] ">
            <p className="text-sm">{`x${boostWaterLevel[level]?.speed} speed`}</p>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (type === bootTypeEnum.STORAGE) {
      return renderStorageItems();
    }
    if (type === bootTypeEnum.SPEED) {
      return renderTreeItems();
    }
    if (type === bootTypeEnum.WATER) {
      return renderWaterItems();
    }
  };
  return <div>{renderContent()}</div>;
};

export default MissionsItem;
