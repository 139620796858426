import { eggLevels } from "../modules/MysteryBox/constanst";

export enum inventoryTab {
  worm = 1,
  egg = 2,
  bird = 3,
}

export const tabButtons = [
  { id: inventoryTab.worm, title: "Worms" },
  { id: inventoryTab.egg, title: "Eggs" },
  { id: inventoryTab.bird, title: "Birds" },
];

export const inventoryBtns = {
  [inventoryTab.worm]: {
    btns: [
      { id: 1, title: "Feed your bird" },
      { id: 2, title: "Sell for SEED" },
    ],
  },
  [inventoryTab.egg]: {
    btns: [
      { id: 1, title: "Hatch" },
      { id: 2, title: "List on the market" },
    ],
  },
};

export const inventoryItemBackgroundColor = {
  [eggLevels.common]: "#D7D7D7CC",
  [eggLevels.uncommon]: "#2ADC51",
  [eggLevels.rare]: "#005BC6",
  [eggLevels.epic]: "#810DB7",
  [eggLevels.legendary]: "#E49600",
};

export const WormModalText: { [key: string]: string } = {
  "1": "Clearing all missions boosts the chance of higher-level worms appearing.",
  "2": "Just inviting a single friend will also help increase the success rate of catching worms.",
};

export const wormsType = ["common", "uncommon", "rare", "epic", "legendary"];

export const BirdText: { [key: string]: string } = {
  sparrow: "Find worms: Common, Uncommon, Rare",
  penguin: "Find worms: Common, Uncommon, Rare",
  parrot: "Find worms: Uncommon, Rare",
  owl: "Find worms: Uncommon, Rare, Epic",
  hawk: "Find worms: Uncommon, Rare, Epic",
  phoenix: "Find worms: Uncommon, Rare, Epic, Legend",
};
