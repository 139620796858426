import { Slide, ToastContainer } from "react-toastify";
import StreakClaim from "../components/StreakClaim";
import DailyClaim from "../components/DailyClaim";
import useGetStreak from "../hooks/useGetStreak";
import useStreakReward from "../hooks/useGetStreakReward";
import LoadingScreen from "../../Global/Pages/LoadingScreen";
import { useNavigate } from "react-router-dom";
import { navPaths } from "../../../constants/navbar.constants";

const DailyStreak = () => {
  const tele = window.Telegram.WebApp;
  const navigate = useNavigate();

  const { Streak, StreakLoading, refeshStreak } = useGetStreak();
  const { StreakReward, StreakRewardLoading, refeshStreakReward } =
    useStreakReward();

  tele.BackButton.show();
  tele.BackButton.onClick(() => {
    navigate(navPaths.MISSIONS);
  });

  if (StreakLoading || StreakRewardLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="py-4 px-4 pb-[50px] bg-[#F2FFE0] overflow-auto h-screen relative z-30">
      <ToastContainer
        position="top-left"
        closeOnClick
        transition={Slide}
        hideProgressBar
        limit={1}
        className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
      />

      <StreakClaim
        Streak={Streak}
        StreakReward={StreakReward}
        TriggerRefresh={() => {
          refeshStreak();
          refeshStreakReward();
        }}
      />
      <DailyClaim
        TriggerRefresh={() => {
          refeshStreak();
          refeshStreakReward();
        }}
      />
    </div>
  );
};

export default DailyStreak;
