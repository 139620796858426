import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function cancelSell(params: any) {
  return await api.post(
    `/api/v1/market-item/${params?.id}/cancel`,
    { id: params?.id },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export default function useCancelSell() {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["cancelSell"],
    mutationFn: cancelSell,
  });

  return { cancelSellAsync: mutateAsync, isCancelingSell: isPending };
}
