const Emotion = ({
  percentage,
  width,
  height,
}: {
  percentage: number;
  width?: string;
  height?: string;
}) => {
  if (percentage < 33.33) {
    return (
      <svg
        width={width ?? "26"}
        height={height ?? "21"}
        viewBox="0 0 26 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.61844 8.8486C-0.231822 6.99834 -0.231822 4.00945 1.61844 2.15918C3.4687 0.308918 6.45759 0.308918 8.30786 2.15918C10.1581 4.00945 10.1581 6.99834 8.30786 8.8486C6.45759 10.6989 3.4687 10.6989 1.61844 8.8486Z"
          fill="white"
        />
        <path
          d="M4.80141 5.6663L6.28939 2.5092C5.08607 1.97871 3.63691 2.19867 2.65355 3.18202C1.3726 4.46298 1.3726 6.5332 2.65355 7.81416C3.93451 9.09511 6.00473 9.09511 7.28568 7.81416C8.26904 6.8308 8.489 5.38164 7.95851 4.17832L4.80141 5.6663Z"
          fill="#231F20"
        />
        <path
          d="M17.7921 8.84762C15.9419 6.99736 15.9419 4.00847 17.7921 2.15821C19.6424 0.307942 22.6313 0.307942 24.4815 2.15821C26.3318 4.00847 26.3318 6.99736 24.4815 8.84762C22.6313 10.6979 19.6424 10.6979 17.7921 8.84762Z"
          fill="white"
        />
        <path
          d="M20.9622 5.66532L22.4502 2.50822C21.2469 1.97773 19.7977 2.19769 18.8144 3.18105C17.5334 4.462 17.5334 6.53223 18.8144 7.81318C20.0953 9.09413 22.1655 9.09413 23.4465 7.81318C24.4298 6.82982 24.6498 5.38066 24.1193 4.17734L20.9622 5.66532Z"
          fill="#231F20"
        />
        <path
          d="M17.598 20.2212C17.3133 20.2212 17.0546 20.0271 16.9769 19.7424C16.757 18.9402 16.2912 18.2156 15.6313 17.6722C14.1562 16.456 11.9696 16.456 10.4945 17.6722C9.83464 18.2156 9.36884 18.9273 9.14888 19.7424C9.05831 20.0788 8.69602 20.2859 8.34667 20.1953C7.99732 20.1047 7.80324 19.7424 7.89381 19.3931C8.1914 18.3321 8.79953 17.3876 9.66644 16.6759C11.6073 15.0585 14.5185 15.0585 16.4594 16.6759C17.3263 17.3876 17.9344 18.3321 18.232 19.3931C18.3226 19.7424 18.1285 20.0918 17.7791 20.1953C17.7274 20.2082 17.6627 20.2212 17.6109 20.2212H17.598Z"
          fill="#231F20"
        />
      </svg>
    );
  }
  if (percentage >= 66.66) {
    return (
      <svg
        width={width ?? "27"}
        height={height ?? "22"}
        viewBox="0 0 27 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.81328 8.92405C-0.0914052 7.01937 -0.0914052 3.94257 1.81328 2.03789C3.71796 0.133204 6.79476 0.133204 8.69944 2.03789C10.6041 3.94257 10.6041 7.01937 8.69944 8.92405C6.79476 10.8287 3.71796 10.8287 1.81328 8.92405Z"
          fill="white"
        />
        <path
          d="M5.08989 5.64924L6.62163 2.39929C5.38292 1.85319 3.89114 2.07962 2.87886 3.0919C1.56024 4.41053 1.56024 6.54164 2.87886 7.86027C4.19749 9.1789 6.3286 9.1789 7.64723 7.86027C8.65951 6.84799 8.88594 5.35621 8.33984 4.1175L5.08989 5.64924Z"
          fill="#231F20"
        />
        <path
          d="M18.4624 8.92405C16.5578 7.01937 16.5578 3.94257 18.4624 2.03789C20.3671 0.133204 23.4439 0.133204 25.3486 2.03789C27.2533 3.94257 27.2533 7.01937 25.3486 8.92405C23.4439 10.8287 20.3671 10.8287 18.4624 8.92405Z"
          fill="white"
        />
        <path
          d="M21.7258 5.64924L23.2575 2.39929C22.0188 1.85319 20.527 2.07962 19.5147 3.0919C18.1961 4.41053 18.1961 6.54164 19.5147 7.86027C20.8334 9.1789 22.9645 9.1789 24.2831 7.86027C25.2954 6.84799 25.5218 5.35621 24.9757 4.1175L21.7258 5.64924Z"
          fill="#231F20"
        />
        <path
          d="M8.92576 19.5475C10.1911 18.4952 11.8161 17.8559 13.5876 17.8559C15.3591 17.8559 16.984 18.4952 18.2494 19.5475C19.4082 18.5885 20.2739 17.2965 20.6868 15.8047H6.47498C6.88788 17.2965 7.75364 18.5885 8.91244 19.5475H8.92576Z"
          fill="#231F20"
        />
        <path
          d="M20.6868 15.8033C20.8599 15.1773 20.9798 14.5246 20.9798 13.832H6.16858C6.16858 14.5113 6.28845 15.164 6.46161 15.8033H20.6735H20.6868Z"
          fill="white"
        />
        <path
          d="M18.2494 19.547C16.9841 18.4948 15.3591 17.8555 13.5876 17.8555C11.8161 17.8555 10.1911 18.4948 8.92578 19.547C10.1911 20.5993 11.8161 21.2386 13.5876 21.2386C15.3591 21.2386 16.9841 20.5993 18.2494 19.547Z"
          fill="#F60000"
        />
      </svg>
    );
  }
  return (
    <svg
      width={width ?? "30"}
      height={height ?? "21"}
      viewBox="0 0 30 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.82587 11.0064C8.82696 11.0064 11.2598 8.63679 11.2598 5.71367C11.2598 2.79055 8.82696 0.420898 5.82587 0.420898C2.82478 0.420898 0.391918 2.79055 0.391918 5.71367C0.391918 8.63679 2.82478 11.0064 5.82587 11.0064Z"
        fill="#FFF5E3"
      />
      <path
        d="M5.63283 5.90179L7.34022 2.37328C5.95946 1.78037 4.29661 2.02621 3.16825 3.12526C1.69841 4.55691 1.69841 6.87069 3.16825 8.30234C4.63809 9.73399 7.01359 9.73399 8.48343 8.30234C9.61179 7.2033 9.86419 5.58365 9.25547 4.23876L5.63283 5.90179Z"
        fill="#231F20"
      />
      <path
        d="M20.5391 9.44532C18.416 7.37738 18.416 4.03687 20.5391 1.96892C22.6622 -0.0990164 26.0918 -0.0990164 28.2149 1.96892C30.338 4.03687 30.338 7.37738 28.2149 9.44532C26.0918 11.5133 22.6622 11.5133 20.5391 9.44532Z"
        fill="#FFF5E3"
      />
      <path
        d="M24.1766 5.90179L25.884 2.37328C24.5032 1.78037 22.8404 2.02621 21.712 3.12526C20.2422 4.55691 20.2422 6.87069 21.712 8.30234C23.1818 9.73399 25.5573 9.73399 27.0272 8.30234C28.1555 7.2033 28.4079 5.58365 27.7992 4.23876L24.1766 5.90179Z"
        fill="#231F20"
      />
      <path
        d="M21.5636 18.5566H8.63195C8.09077 18.5566 7.65205 18.984 7.65205 19.5111V19.5255C7.65205 20.0527 8.09077 20.48 8.63195 20.48H21.5636C22.1048 20.48 22.5435 20.0527 22.5435 19.5255V19.5111C22.5435 18.984 22.1048 18.5566 21.5636 18.5566Z"
        fill="#231F20"
      />
    </svg>
  );
};

export default Emotion;
