import React from "react";

const EnabletTicket = () => {
  return (
    <img
      className="size-[30px]"
      src="images/lucky_spin/enable_ticket.png"
      alt=""
    ></img>
  );
};

export default EnabletTicket;
