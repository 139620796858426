import { RouteObject } from "react-router-dom";
import { navPaths } from "../../constants/navbar.constants";
import Market from "./Pages/Market";
import ItemDetail from "./Components/ItemDetail";

export const marketRoute: RouteObject = {
  path: navPaths.MARKET,
  Component: Market,
};

export const marketItemRoute: RouteObject = {
  path: navPaths.MARKET_ITEM,
  Component: ItemDetail,
};
