import clsx from "clsx";
import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { useClickOutside } from "../../../Hooks/useClickOutside";
import ModalContainer from "../../../components/common/ModalContainer";
import { navPaths } from "../../../constants/navbar.constants";
import { ITEM_TYPES } from "../../Market/Pages/Market";
import SellEggModal from "../Components/SellEggModal";
import useGetEggDetail from "../Hooks/useGetEggDetail";
import HatchEggModal from "../Components/HatchEggModal";
import DisableSellIc from "../Components/SVG/DisableSellIc";
import DisableTransferIc from "../Components/SVG/DisableTransferIc";

const SellIc = () => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 7.5001V10.5251C28 12.5001 26.75 13.7501 24.775 13.7501H20.5V5.0126C20.5 3.6251 21.6375 2.4876 23.025 2.5001C24.3875 2.5126 25.6375 3.0626 26.5375 3.9626C27.4375 4.8751 28 6.1251 28 7.5001Z"
      fill="#4B7E08"
    />
    <path
      d="M3 8.75V26.25C3 27.2875 4.175 27.875 5 27.25L7.1375 25.65C7.6375 25.275 8.3375 25.325 8.7875 25.775L10.8625 27.8625C11.35 28.35 12.15 28.35 12.6375 27.8625L14.7375 25.7625C15.175 25.325 15.875 25.275 16.3625 25.65L18.5 27.25C19.325 27.8625 20.5 27.275 20.5 26.25V5C20.5 3.625 21.625 2.5 23 2.5H9.25H8C4.25 2.5 3 4.7375 3 7.5V8.75Z"
      fill="#9AD054"
    />
    <path
      d="M15.5 15.3252H11.75C11.2375 15.3252 10.8125 15.7502 10.8125 16.2627C10.8125 16.7752 11.2375 17.2002 11.75 17.2002H15.5C16.0125 17.2002 16.4375 16.7752 16.4375 16.2627C16.4375 15.7502 16.0125 15.3252 15.5 15.3252Z"
      fill="#4B7E08"
    />
    <path
      d="M11.75 12.2002H15.5C16.0125 12.2002 16.4375 11.7752 16.4375 11.2627C16.4375 10.7502 16.0125 10.3252 15.5 10.3252H11.75C11.2375 10.3252 10.8125 10.7502 10.8125 11.2627C10.8125 11.7752 11.2375 12.2002 11.75 12.2002Z"
      fill="#4B7E08"
    />
    <path
      d="M7.9624 10.0127C7.2624 10.0127 6.7124 10.5752 6.7124 11.2627C6.7124 11.9502 7.2749 12.5127 7.9624 12.5127C8.6499 12.5127 9.2124 11.9502 9.2124 11.2627C9.2124 10.5752 8.6499 10.0127 7.9624 10.0127Z"
      fill="#4B7E08"
    />
    <path
      d="M7.9624 15.0127C7.2624 15.0127 6.7124 15.5752 6.7124 16.2627C6.7124 16.9502 7.2749 17.5127 7.9624 17.5127C8.6499 17.5127 9.2124 16.9502 9.2124 16.2627C9.2124 15.5752 8.6499 15.0127 7.9624 15.0127Z"
      fill="#4B7E08"
    />
  </svg>
);

const HatchIc = () => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5467_1621)">
      <path
        d="M26.8872 10.9114C27.5033 13.04 27.8329 15.0566 27.8329 16.7341C27.8329 23.4946 22.4512 29 15.8367 29C9.22214 29 3.8335 23.4946 3.8335 16.7341C3.8335 15.4934 4.01251 14.0835 4.35661 12.5397C4.39267 12.3848 4.5002 12.2579 4.65011 12.2012C4.80066 12.1378 4.97271 12.1589 5.10871 12.2436C5.4098 12.434 5.66028 12.5957 5.88294 12.737C7.59587 6.8016 11.4658 1 15.8373 1C20.2088 1 23.548 6.33618 25.2679 11.1298L26.1705 10.622C26.2996 10.5443 26.4571 10.5374 26.6007 10.594C26.7367 10.6507 26.8442 10.7633 26.8872 10.9114ZM26.8518 16.7341C26.8518 15.303 26.5937 13.5969 26.1067 11.7713L23.7346 13.1035C23.7131 13.1178 23.6916 13.1246 23.6701 13.1315L20.1443 14.4848C19.9507 14.5626 19.7287 14.506 19.5927 14.3579L18.5534 13.1875L17.5142 13.6031L16.4249 15.7106C16.3604 15.8375 16.2314 15.929 16.0884 15.9644C16.0599 15.9713 16.0239 15.9713 15.9878 15.9713C15.9594 15.9713 15.9379 15.9644 15.9088 15.957C15.8942 15.957 15.8727 15.957 15.8588 15.9501C15.7943 15.9358 15.7298 15.9078 15.6798 15.8655L13.1787 13.9198L11.7599 12.8907L10.9287 13.511C10.8857 13.539 10.8427 13.5676 10.7997 13.5813L9.09437 14.1737C9.07286 14.1805 9.0444 14.188 9.0153 14.1948C7.17333 14.519 7.10881 14.519 7.05188 14.519C6.9298 14.519 6.89438 14.519 6.52181 14.2863C6.26373 14.1314 5.8627 13.8775 5.17449 13.4401C4.93792 14.6316 4.81647 15.7591 4.81647 16.7323C4.81647 22.957 9.76107 28.0256 15.8379 28.0256C21.9148 28.0256 26.8524 22.957 26.8524 16.7323L26.8518 16.7341ZM23.2754 12.2436L24.4007 11.6164C22.6953 6.75244 19.4346 1.97253 15.8373 1.97253C11.932 1.97253 8.27712 7.73867 6.7432 13.2796C6.95131 13.4133 7.07276 13.4911 7.15182 13.5334C7.45292 13.4843 8.162 13.3642 8.80719 13.2516L10.398 12.6947L11.4588 11.9051C11.6378 11.7713 11.8744 11.7713 12.0534 11.8982L13.7803 13.1532L15.8227 14.7461L16.7254 13.0052C16.7754 12.8994 16.869 12.8216 16.9765 12.7793L18.5028 12.1589C18.6964 12.0812 18.9184 12.1378 19.0544 12.2927L20.1076 13.4631L23.2754 12.2436Z"
        fill="#2C2C2C"
      />
      <path
        d="M26.1053 11.7725C26.5923 13.5981 26.8504 15.3042 26.8504 16.7353C26.8504 22.96 21.9058 28.0286 15.8359 28.0286C9.76601 28.0286 4.81445 22.96 4.81445 16.7353C4.81445 15.7628 4.93653 14.6347 5.17247 13.4431C5.86068 13.8799 6.26171 14.1338 6.51979 14.2893C6.89236 14.5221 6.92842 14.5221 7.04987 14.5221C7.10743 14.5221 7.17195 14.5221 9.01328 14.1979C9.04175 14.191 9.07085 14.1836 9.09235 14.1767L10.7977 13.5844C10.8407 13.5701 10.8837 13.5421 10.9267 13.5141L11.7579 12.8937L13.1767 13.9229L15.6778 15.8686C15.7277 15.9109 15.7923 15.9389 15.8568 15.9532C15.8713 15.96 15.8928 15.96 15.9068 15.96C15.9352 15.9669 15.9567 15.9743 15.9858 15.9743C16.0219 15.9743 16.0573 15.9743 16.0864 15.9675C16.23 15.932 16.359 15.8405 16.4229 15.7136L17.5121 13.6062L18.5514 13.1905L19.5907 14.3609C19.7267 14.509 19.9487 14.5656 20.1423 14.4878L23.6681 13.1345C23.6896 13.1277 23.7111 13.1202 23.7326 13.1065L26.1046 11.7743L26.1053 11.7725ZM25.4822 17.9194C25.4892 17.6512 25.2741 17.4328 25.0021 17.426C24.7371 17.4191 24.5075 17.6307 24.5005 17.8982C24.4505 19.9777 23.3613 21.9589 21.5193 23.3265C21.3043 23.4889 21.2612 23.7919 21.4263 24.0103C21.5193 24.1304 21.6699 24.2007 21.8135 24.2007C21.921 24.2007 22.0216 24.1653 22.1145 24.1018C24.2 22.5512 25.4253 20.3025 25.4828 17.9194H25.4822Z"
        fill="#4B7E08"
      />
      <path
        d="M25.0027 17.425C25.2753 17.4318 25.4898 17.6508 25.4828 17.9184C25.4252 20.3009 24.2 22.5496 22.1145 24.1008C22.0215 24.1643 21.9209 24.1997 21.8134 24.1997C21.6698 24.1997 21.5193 24.1294 21.4263 24.0093C21.2612 23.7909 21.3042 23.4879 21.5193 23.3255C23.3612 21.9579 24.4505 19.9773 24.5005 17.8972C24.5074 17.6291 24.737 17.4181 25.0021 17.425H25.0027Z"
        fill="#9AD054"
      />
      <path
        d="M24.4006 11.6165L23.2753 12.2437L20.1076 13.4632L19.0544 12.2928C18.9184 12.1379 18.6964 12.0813 18.5028 12.1591L16.9765 12.7794C16.8689 12.8217 16.776 12.8995 16.7253 13.0053L15.8227 14.7463L13.7802 13.1534L12.0534 11.8983C11.8744 11.7714 11.6378 11.7714 11.4588 11.9052L10.398 12.6948L8.80716 13.2517C8.16196 13.3643 7.45288 13.4844 7.15179 13.5335C7.07272 13.4912 6.95127 13.4135 6.74316 13.2797C8.27645 7.73941 11.9313 1.97266 15.8373 1.97266C19.4345 1.97266 22.6953 6.75195 24.4013 11.6159L24.4006 11.6165ZM13.2286 5.63132C13.4222 5.44092 13.4222 5.1379 13.2356 4.9475C13.042 4.7571 12.734 4.75026 12.5404 4.94066C11.2722 6.16706 10.3904 7.83772 9.87426 9.02243C9.76673 9.26946 9.88122 9.55132 10.1323 9.6571C10.1969 9.6851 10.2614 9.69257 10.3259 9.69257C10.5195 9.69257 10.6985 9.58679 10.7775 9.40386C11.4657 7.82466 12.3184 6.51363 13.2286 5.63257V5.63132Z"
        fill="#F6F6F6"
      />
      <path
        d="M13.2357 4.94732C13.4223 5.13772 13.4223 5.44074 13.2287 5.63114C12.3185 6.51221 11.4658 7.82323 10.7776 9.40243C10.6986 9.58598 10.5195 9.69114 10.326 9.69114C10.2615 9.69114 10.1969 9.68429 10.1324 9.65567C9.88131 9.54989 9.76682 9.26803 9.87435 9.02101C10.3905 7.83692 11.2716 6.16625 12.5405 4.93923C12.7341 4.74883 13.0421 4.75567 13.2357 4.94607V4.94732Z"
        fill="#2C2C2C"
      />
    </g>
    <defs>
      <clipPath id="clip0_5467_1621">
        <rect
          width="24"
          height="28"
          fill="white"
          transform="translate(3.8335 1)"
        />
      </clipPath>
    </defs>
  </svg>
);

const TransferIc = () => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M19.4167 28.4375C19.0792 28.4375 18.7667 28.25 18.6042 27.9625C18.4417 27.675 18.4417 27.3125 18.6167 27.025L19.9292 24.8375C20.1917 24.3875 20.7667 24.25 21.2167 24.5125C21.6667 24.775 21.8042 25.35 21.5417 25.8L21.2042 26.3625C24.6542 25.55 27.2417 22.45 27.2417 18.75C27.2417 18.2375 27.6667 17.8125 28.1792 17.8125C28.6917 17.8125 29.1167 18.2375 29.1167 18.75C29.1042 24.0875 24.7542 28.4375 19.4167 28.4375Z"
      fill="#4B7E08"
    />
    <path
      opacity="0.4"
      d="M3.1665 12.1875C2.654 12.1875 2.229 11.7625 2.229 11.25C2.229 5.9125 6.579 1.5625 11.9165 1.5625C12.254 1.5625 12.5665 1.75001 12.729 2.03751C12.8915 2.32501 12.8915 2.68751 12.7165 2.97501L11.404 5.16251C11.1415 5.61251 10.5665 5.75002 10.1165 5.48752C9.6665 5.22502 9.52901 4.64998 9.79151 4.19998L10.129 3.63747C6.67901 4.44997 4.09151 7.55 4.09151 11.25C4.10401 11.7625 3.679 12.1875 3.1665 12.1875Z"
      fill="#4B7E08"
    />
    <path
      d="M14.0037 17.3125L10.0787 15.2C9.6662 14.975 9.1662 14.975 8.7537 15.2L4.8287 17.3125C4.5412 17.4625 4.36621 17.775 4.36621 18.1125C4.36621 18.45 4.5412 18.7625 4.8287 18.9125L8.7537 21.025C8.9662 21.1375 9.1912 21.1875 9.4162 21.1875C9.6412 21.1875 9.8662 21.1375 10.0787 21.025L14.0037 18.9125C14.2912 18.7625 14.4662 18.45 14.4662 18.1125C14.4662 17.775 14.2787 17.475 14.0037 17.3125Z"
      fill="#9AD054"
    />
    <path
      d="M8.1038 21.7623L4.4538 19.9373C4.1788 19.7998 3.8538 19.8123 3.5788 19.9748C3.3163 20.1373 3.15381 20.4248 3.15381 20.7373V24.1873C3.15381 24.7873 3.47879 25.3248 4.01629 25.5873L7.66631 27.4123C7.79131 27.4748 7.9288 27.5123 8.0663 27.5123C8.2288 27.5123 8.39131 27.4623 8.54131 27.3748C8.80381 27.2123 8.9663 26.9248 8.9663 26.6123V23.1623C8.9788 22.5623 8.6413 22.0248 8.1038 21.7623Z"
      fill="#4B7E08"
    />
    <path
      d="M15.2415 19.9748C14.979 19.8123 14.654 19.7998 14.3665 19.9373L10.7165 21.7623C10.179 22.0248 9.854 22.5623 9.854 23.1623V26.6123C9.854 26.9248 10.0165 27.2123 10.279 27.3748C10.429 27.4623 10.5915 27.5123 10.754 27.5123C10.8915 27.5123 11.029 27.4748 11.154 27.4123L14.804 25.5873C15.3415 25.3248 15.6665 24.7873 15.6665 24.1873V20.7373C15.6665 20.4248 15.504 20.1373 15.2415 19.9748Z"
      fill="#4B7E08"
    />
    <path
      d="M26.5037 4.78711L22.5787 2.67461C22.1662 2.44961 21.6662 2.44961 21.2537 2.67461L17.3287 4.78711C17.0412 4.93711 16.8662 5.24955 16.8662 5.58705C16.8662 5.92455 17.0412 6.23707 17.3287 6.38707L21.2537 8.49958C21.4662 8.61208 21.6912 8.66208 21.9162 8.66208C22.1412 8.66208 22.3662 8.61208 22.5787 8.49958L26.5037 6.38707C26.7912 6.23707 26.9662 5.92455 26.9662 5.58705C26.9662 5.23705 26.7787 4.93711 26.5037 4.78711Z"
      fill="#9AD054"
    />
    <path
      d="M20.6038 9.22423L16.9538 7.3992C16.6788 7.2617 16.3538 7.27424 16.0788 7.43674C15.8163 7.59924 15.6538 7.88672 15.6538 8.19922V11.6492C15.6538 12.2492 15.9788 12.7867 16.5163 13.0492L20.1663 14.8743C20.2913 14.9368 20.4288 14.9742 20.5663 14.9742C20.7288 14.9742 20.8913 14.9242 21.0413 14.8367C21.3038 14.6742 21.4663 14.3867 21.4663 14.0742V10.6242C21.4788 10.0242 21.1413 9.48673 20.6038 9.22423Z"
      fill="#4B7E08"
    />
    <path
      d="M27.7415 7.43674C27.479 7.27424 27.154 7.2617 26.8665 7.3992L23.2165 9.22423C22.679 9.48673 22.354 10.0242 22.354 10.6242V14.0742C22.354 14.3867 22.5165 14.6742 22.779 14.8367C22.929 14.9242 23.0915 14.9742 23.254 14.9742C23.3915 14.9742 23.529 14.9368 23.654 14.8743L27.304 13.0492C27.8415 12.7867 28.1665 12.2492 28.1665 11.6492V8.19922C28.1665 7.88672 28.004 7.59924 27.7415 7.43674Z"
      fill="#4B7E08"
    />
  </svg>
);

const MintIc = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M26.25 10.6596C26.25 10.8485 26.25 11.0375 26.2367 11.2138C24.2022 10.4959 21.8085 10.9367 20.2128 12.2969C19.1357 11.3775 17.7394 10.8611 16.25 10.8611C12.9787 10.8611 10.3192 13.3926 10.3192 16.5161C10.3192 20.0803 12.2075 22.6873 14.016 24.375C13.8697 24.3624 13.75 24.3372 13.6436 24.2994C10.1995 23.1785 2.5 18.5438 2.5 10.6596C2.5 7.18355 5.45212 4.375 9.09574 4.375C11.2633 4.375 13.1782 5.35736 14.375 6.88129C15.5851 5.35736 17.5 4.375 19.6543 4.375C23.2979 4.375 26.25 7.18355 26.25 10.6596Z"
      fill="#292D32"
    />
    <path
      d="M23.3333 11.875C21.9823 11.875 20.7576 12.5519 20 13.5934C19.2424 12.5519 18.0303 11.875 16.6667 11.875C14.3687 11.875 12.5 13.8016 12.5 16.1969C12.5 17.1212 12.6389 17.9673 12.8914 18.7484C14.0783 22.6146 17.7273 24.9188 19.5328 25.5567C19.7853 25.6478 20.202 25.6478 20.4672 25.5567C22.2727 24.9188 25.9217 22.6146 27.1086 18.7484C27.3611 17.9543 27.5 17.1081 27.5 16.1969C27.5 13.8016 25.6313 11.875 23.3333 11.875Z"
      fill="#292D32"
    />
  </svg>
);

const CustomBtn = ({
  type,
  onClick,
  eggType,
}: {
  type: string;
  onClick?: any;
  eggType: string;
}) => {
  return (
    <div
      onClick={onClick}
      className={clsx(
        "flex flex-col flex-1 gap-[2px] cursor-pointer items-center",
        type !== "sell" &&
          type !== "transfer" &&
          type !== "hatch" &&
          "pointer-events-none"
      )}
    >
      {type === "sell" &&
        (eggType === "basic" ? <DisableSellIc /> : <SellIc />)}
      {type === "hatch" && <HatchIc />}
      {type === "transfer" &&
        (eggType === "basic" ? <DisableTransferIc /> : <TransferIc />)}
      {type === "mint" && <MintIc />}

      {type === "sell" && (
        <span className="text-sm font-[500] capitalize text-[#000000]">
          {type}
        </span>
      )}
      {type === "hatch" && (
        <span className="text-sm font-[500] capitalize text-[#000000]">
          {type}
        </span>
      )}
      {type === "transfer" && (
        <span className="text-sm font-[500] capitalize text-[#000000]">
          {type}
        </span>
      )}
      {type === "mint" && (
        <span className="text-sm font-[500] capitalize text-[#00000060]">
          {type}
        </span>
      )}
    </div>
  );
};

const InventoryDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const tele = window.Telegram.WebApp;
  tele.BackButton.show();
  tele.BackButton.onClick(() => handleBackBtn());

  const handleBackBtn = () => {
    navigate(navPaths.INVENTORY, { state: { showEgg: true } });
  };

  const color = ITEM_TYPES.find(
    (item) => item.type === location?.state?.type
  )?.color;

  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isShowHatchModal, setIsShowHatchModal] = useState<boolean>(false);

  const sellModalRef = useRef<HTMLDivElement>(null);
  const hatchEggModalRef = useRef<HTMLDivElement>(null);

  useClickOutside(sellModalRef, () => {
    setIsShowModal(false);
  });

  useClickOutside(hatchEggModalRef, () => {
    setIsShowHatchModal(false);
  });

  const { eggDetail } = useGetEggDetail({ id: location?.state?.id });

  const [modalType, setModalType] = useState<string>();

  const handleShowEggModal = (type: string) => {
    setModalType(type);
    setIsShowModal(true);
  };

  return (
    <div className="h-[100vh] flex flex-col pt-12 pb-5 items-center relative bg-[#F2FFE0] z-10 dark:bg-transparent">
      <ToastContainer
        position="top-left"
        closeOnClick
        transition={Slide}
        hideProgressBar
        limit={1}
        stacked
        className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
      />
      {location?.state?.receiveFirstEgg ? (
        <div
          style={{ color: color }}
          className="text-[40px] font-spicy-rice-regular"
        >
          Congrats!
        </div>
      ) : (
        <div
          className={clsx(
            "font-[600] text-[16px] text-white rounded-[20px] px-2 py-1 flex items-center gap-1 uppercase"
          )}
          style={{ backgroundColor: color }}
        >
          <div className="bg-white rounded-full text-[#646464] w-[18px] h-[18px] flex items-center justify-center">
            #
          </div>
          <span
            className={
              location?.state?.type === "common"
                ? "text-black/70"
                : "text-white"
            }
          >
            {location?.state?.id?.slice(
              location?.state?.id?.length - 9,
              location?.state?.id?.length
            )}
          </span>
        </div>
      )}

      <div
        className="flex flex-col gap-4 items-center w-full px-4 mt-4"
        style={{
          height: "calc(100% - 120px)",
        }}
      >
        <div className={`relative h-[55%] flex flex-col items-center`}>
          <img
            src={`/images/egg/${location?.state?.type}.png`}
            alt={"market-item"}
            className="w-full h-full object-contain"
          />
          <div
            className={clsx(
              "capitalize text-[24px] font-bold [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]",
              location?.state?.type === "common"
                ? "text-black"
                : `text-[${color}]`,
              "font-spicy-rice-regular tracking-wide"
            )}
            style={{
              color: location?.state?.type === "common" ? "#00000050" : color,
            }}
          >
            {location?.state?.type} Egg
          </div>
          {/* <div className="absolute inset-0 item-bg" /> */}
        </div>
        <div className="bg-[#FFFFFF90] dark:bg-white rounded-lg p-4 mt-6 w-full flex flex-col gap-[10px] text-[#305500] shadow">
          <span className="text-[14px] font-[800]">Egg Utilities:</span>
          <ul className="text-sm list-disc ml-4">
            <li>Special boost for inactive income</li>
            {location?.state?.type !== "basic" && (
              <li>Exclusive Genesis NFTs</li>
            )}
            <li>SEED Treasure</li>
          </ul>
        </div>

        {/* <div className="flex items-center justify-between w-full gap-[18px] mt-5">
          <span className="text-sm font-[500] text-[#203900] dark:text-white">
            Price
          </span>
          <div className="h-[38px] rounded-lg border border-[#A4BC82] flex-1 bg-white relative overflow-hidden">
            <input
              value={price}
              onChange={handleInputPrice}
              // inputMode="decimal"
              placeholder="Input here"
              className="w-full h-full pl-[10px] pr-[40px]"
            />
            <img
              src="/images/icons/token_icon.png"
              width={21}
              height={21}
              alt="token"
              className="absolute my-auto top-0 bottom-0 right-[10px]"
            ></img>
          </div>
        </div> */}
      </div>

      {location?.state?.receiveFirstEgg ? (
        <button
          onClick={() =>
            navigate(navPaths.INVENTORY, { state: { showEgg: true } })
          }
          className={clsx(
            "bg-[#fff] btn-click-no-shadow shadow-[0_3px_0_#4B7E08] fixed bottom-4 left-4 right-4 py-4 font-extrabold border-2 border-[#4B7E08] rounded-lg",
            location?.state?.type === "common"
              ? "text-black"
              : `text-[${color}]`
          )}
        >
          Check inventory
        </button>
      ) : (
        <div className="max-w-md fixed bottom-4 left-0 right-0 mx-auto">
          <div className="mx-4 bg-white py-[12px] rounded-[12px] border-b-[3px] border-[#4B7E08] flex items-center shadow">
            <CustomBtn
              eggType={location?.state?.type}
              type="sell"
              onClick={() => {
                if (location?.state?.type === "basic") {
                  return;
                }
                setIsShowModal(true);
                handleShowEggModal("sell");
              }}
            />
            <CustomBtn
              eggType={location?.state?.type}
              type="hatch"
              onClick={() => setIsShowHatchModal(true)}
            />
            <CustomBtn
              eggType={location?.state?.type}
              type="transfer"
              onClick={() =>
                location?.state?.type !== "basic" &&
                handleShowEggModal("transfer")
              }
            />
            <CustomBtn eggType={location?.state?.type} type="mint" />
          </div>
        </div>
      )}
      {/* <div className="px-6 w-full">
        <Button
          onClick={handleSell}
          className={clsx(
            " w-full normal-case h-[60px] btn-hover py-3 rounded-lg font-extrabold text-base bg-gradient-to-r from-[#97C35B] to-[#61A700] text-[#FFFFFF] shadow-[0_3px_0_#4C7E0B]",
            "disabled:shadow-none disabled:border-none"
          )}
          style={{ border: "3px solid #97C35B" }}
        >
          List on the market
        </Button>
      </div> */}

      <ModalContainer>
        {isShowModal && (
          <SellEggModal
            ref={sellModalRef}
            modalType={modalType}
            showModal={setIsShowModal}
            egg={eggDetail?.data}
          />
        )}
      </ModalContainer>

      <HatchEggModal
        ref={hatchEggModalRef}
        isShowModal={isShowHatchModal}
        showModal={setIsShowHatchModal}
        egg={eggDetail?.data}
      />
    </div>
  );
};

export default InventoryDetail;
