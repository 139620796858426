/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { forwardRef, useMemo, useState } from "react";
import clsx from "clsx";
import { LoadingButton } from "@mui/lab";
import { Checkbox, TextField } from "@mui/material";
import GuildTypeSelector from "./GuildTypeSelector";
import RequirementSelector from "./RequirementSelector";
import useCreateGuild from "../Hooks/useCreateGuild";
import { toast } from "react-toastify";
import { sleep } from "../../../utils/time";
import { birdLevel } from "../../MysteryBox/constanst";
import { useGuildPricetore } from "../../../store/guildPriceStore";
import { formatDecimals } from "../../../utils/formatNumber";
import DistributeRateSelector from "./DistributeRateSelector";

const CreateGuildModal = forwardRef(
  (
    {
      isShowModal,
      showModal,
      triggerRefresh,
    }: {
      isShowModal: boolean;
      showModal: any;
      triggerRefresh: () => void;
    },
    ref: any
  ) => {
    const birds = Object.values(birdLevel);
    const storedGuildPrice = useGuildPricetore(
      (state: any) => state.guildPrice
    );

    const [guildType, setGuildType] = useState<string>("type_1");
    const [guildName, setGuideName] = useState<string>("");
    const [rate, setRate] = useState<string>("1");
    const [isCheckRequirement, setIsCheckRequirement] =
      useState<boolean>(false);
    const [isCheckDistribute, setIsCheckDistribute] = useState<boolean>(false);
    const [requirement, setRequirement] = useState<string>(birds[0]);

    const { mutateAsync: CreateGuildAsync, isPending: isCreatingGuild } =
      useCreateGuild();

    const handleCloseModal = () => {
      showModal(false);
      setGuideName("");
      setGuildType("type_1");
      setRate("1");
      setRequirement(birds[0]);
      setIsCheckDistribute(false);
      setIsCheckRequirement(false);
    };

    const handleCreateGuild = () => {
      const urlRegex =
        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

      if (guildName.length > 80) {
        toast.error("Guild name must be 80 characters or shorter", {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
        });
        return;
      }
      if (guildName === "" || guildName.match(urlRegex)) {
        toast.error("Invalid guild name", {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
        });
        return;
      }

      CreateGuildAsync({
        name: guildName.trim(),
        type: guildType,
        bird_type_required: isCheckRequirement ? requirement : "not-required",
        distribution_rate: isCheckDistribute ? Number(rate) * 100 : 0,
        top_member: 0,
      })
        .then(() => {
          toast.error("Create guild successfully", {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          });
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message, {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          });
        })
        .finally(async () => {
          await sleep(500);
          handleCloseModal();
          triggerRefresh();
        });
    };

    const [createPrice, createSize] = useMemo(() => {
      if (!storedGuildPrice?.length) {
        return [0, 0];
      }
      const selected = storedGuildPrice?.find(
        (item: any) => item.guild_type === guildType
      );

      return [formatDecimals(selected?.create_fee ?? 0), selected?.create_size];
    }, [guildType, storedGuildPrice]);

    return (
      <div
        className={clsx(
          "bg-black/70 absolute z-50 inset-0 flex items-center justify-center px-7 ",
          isShowModal ? "visible" : " invisible"
        )}
      >
        <div
          ref={ref}
          className={clsx(
            "bg-[#F2FFE0] w-[100%] max-h-[calc(100%-100px)] rounded-[20px] p-[24px] flex flex-col gap-4 items-center justify-center relative",
            isShowModal ? "translate-y-0" : "translate-y-[400%]",
            "transition-all duration-300 ease-in-out"
          )}
        >
          {/* close btn  */}
          <div
            onClick={() => {
              handleCloseModal();
            }}
            className=" absolute -right-2 -top-5 btn-hover bg-white rounded-full drop-shadow-[0_4px_0px_#4C7E0B]"
          >
            <svg
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23 46C10.2422 46 0 35.7578 0 23C0 10.332 10.2422 0 23 0C35.668 0 46 10.332 46 23C46 35.7578 35.668 46 23 46ZM15.7227 15.7227C14.8242 16.6211 14.8242 17.9688 15.7227 18.7773L19.9453 23L15.7227 27.2227C14.8242 28.1211 14.8242 29.4688 15.7227 30.2773C16.5312 31.1758 17.8789 31.1758 18.6875 30.2773L22.9102 26.0547L27.1328 30.2773C28.0312 31.1758 29.3789 31.1758 30.1875 30.2773C31.0859 29.4688 31.0859 28.1211 30.1875 27.2227L25.9648 23L30.1875 18.7773C31.0859 17.9688 31.0859 16.6211 30.1875 15.7227C29.3789 14.9141 28.0312 14.9141 27.1328 15.7227L22.9102 19.9453L18.6875 15.7227C17.8789 14.9141 16.5312 14.9141 15.7227 15.7227Z"
                fill="url(#paint0_linear_3658_4024)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3658_4024"
                  x1="6.9"
                  y1="6.9"
                  x2="34.5"
                  y2="41.975"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#97C35B" />
                  <stop offset="1" stopColor="#63A803" />
                </linearGradient>
              </defs>
            </svg>
          </div>

          <div className={clsx("font-bold text-center text-[20px] w-full")}>
            Create Guild
          </div>
          <div className="overflow-auto h-[90%] w-full">
            <div className="mt-6 w-full flex flex-col items-center justify-between gap-3">
              <div className="w-full">
                <p className="text-sm">Guild name</p>
                <TextField
                  value={guildName}
                  onChange={(e) => {
                    if (e.target.value.length > 40) {
                      return;
                    }
                    setGuideName(e.target.value);
                  }}
                  className="w-full outline-none border-[1px] border-[#00000033] bg-[#fff] rounded-[5px]"
                  placeholder="Enter your guild name"
                  size="small"
                ></TextField>
              </div>

              <div className="w-full">
                <p className="text-sm">Guild size</p>
                <GuildTypeSelector
                  prices={storedGuildPrice}
                  changeType={(value: string) => setGuildType(value)}
                />
              </div>
            </div>

            {/* <div className="bg-[#E4F0D3] w-full p-3 mt-3 rounded-[8px]">
              <div className="flex w-full justify-between gap-2 font-medium text-sm mt-2">
                Guild Fee:
                <span className="flex justify-end items-center gap-[2px] col-span-4">
                  <img
                    src="/images/icons/token_icon.png?v=3"
                    width={13}
                    height={13}
                    alt="token"
                  ></img>
                  <p className="font-bold">{createPrice}</p>
                </span>
              </div>

              <div className="flex w-full justify-between gap-2 font-medium text-sm mt-2">
                Guild size:
                <span className="flex justify-end items-center gap-[2px] col-span-4">
                  <svg
                    width="13"
                    height="15"
                    viewBox="0 0 13 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.8125 8.5625C9.17969 8.59896 10.3099 9.07292 11.2031 9.98438C12.1146 10.8776 12.5885 12.0078 12.625 13.375C12.625 13.6302 12.543 13.8398 12.3789 14.0039C12.2148 14.168 12.0052 14.25 11.75 14.25H1.25C0.994792 14.25 0.785156 14.168 0.621094 14.0039C0.457031 13.8398 0.375 13.6302 0.375 13.375C0.411458 12.0078 0.885417 10.8776 1.79688 9.98438C2.6901 9.07292 3.82031 8.59896 5.1875 8.5625H7.8125ZM1.71484 12.9375H11.2852C11.1576 12.0625 10.7747 11.3333 10.1367 10.75C9.4987 10.1849 8.72396 9.89323 7.8125 9.875H5.1875C4.27604 9.89323 3.5013 10.1849 2.86328 10.75C2.22526 11.3333 1.84245 12.0625 1.71484 12.9375ZM6.5 7.25C5.51562 7.23177 4.6862 6.89453 4.01172 6.23828C3.35547 5.5638 3.01823 4.73438 3 3.75C3.01823 2.76562 3.35547 1.9362 4.01172 1.26172C4.6862 0.605469 5.51562 0.268229 6.5 0.25C7.48438 0.268229 8.3138 0.605469 8.98828 1.26172C9.64453 1.9362 9.98177 2.76562 10 3.75C9.98177 4.73438 9.64453 5.5638 8.98828 6.23828C8.3138 6.89453 7.48438 7.23177 6.5 7.25ZM6.5 1.5625C5.88021 1.58073 5.36068 1.79036 4.94141 2.19141C4.54036 2.61068 4.33073 3.13021 4.3125 3.75C4.33073 4.36979 4.54036 4.88932 4.94141 5.30859C5.36068 5.70964 5.88021 5.91927 6.5 5.9375C7.11979 5.91927 7.63932 5.70964 8.05859 5.30859C8.45964 4.88932 8.66927 4.36979 8.6875 3.75C8.66927 3.13021 8.45964 2.61068 8.05859 2.19141C7.63932 1.79036 7.11979 1.58073 6.5 1.5625Z"
                      fill="black"
                    />
                  </svg>

                  <p className="font-bold">{`+${createSize} ${createSize > 1 ? "members" : "member"
                    }`}</p>
                </span>
              </div>
            </div> */}

            <div className="w-full">
              <div className="flex items-center">
                <Checkbox
                  onChange={(e) => setIsCheckRequirement(e.target.checked)}
                  aria-label="Membership requirements "
                  defaultChecked={isCheckRequirement}
                  color="success"
                />
                <p className="text-sm">Membership requirements </p>
              </div>
              {isCheckRequirement && (
                <div className="pl-[42px]">
                  <p className="text-[12px] text-[#7D7D7D]">
                    Members need a bird of the same type or higher rarity than
                    the selected one
                  </p>
                  <RequirementSelector
                    changeType={(value: string) => setRequirement(value)}
                  />
                </div>
              )}
            </div>

            <div className="w-full">
              <div className="flex items-center">
                <Checkbox
                  onChange={(e) => setIsCheckDistribute(e.target.checked)}
                  aria-label="Distribute SEED"
                  defaultChecked={isCheckRequirement}
                  color="success"
                />
                <p className="text-sm">Distribute SEED</p>
              </div>
              {isCheckDistribute && (
                <div className="pl-[42px]">
                  <p className="text-[12px] text-[#7D7D7D]">
                    Distribute a portion of your Seed earnings to your guild
                    members
                  </p>

                  <p className="text-[12px] mt-2">Seed distribution rate</p>
                  <DistributeRateSelector
                    isDisabled={false}
                    changeRate={(value: string) => setRate(value)}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="w-full">
            <LoadingButton
              loading={isCreatingGuild}
              onClick={() => handleCreateGuild()}
              disabled={isCreatingGuild}
              className={clsx(
                "font-extrabold normal-case text-base text-white py-3 w-full rounded-xl flex items-center justify-center gap-2",
                "btn-hover bg-gradient-to-r from-[#97C35B] to-[#61A700] shadow-[0_4px_0px_#4C7E0B]",
                "disabled:bg-[#4E4E4E] disabled:text-[#F3F3F3] disabled:shadow-[0_3px_0_#4E4E4E]"
              )}
            >
              Create
              <div className="flex justify-center items-center gap-[2px] col-span-2 ">
                <img
                  src="/images/icons/token_icon.png?v=3"
                  width={13}
                  height={13}
                  alt="token"
                ></img>
                <p>{createPrice}</p>
              </div>
            </LoadingButton>
          </div>
        </div>
      </div>
    );
  }
);

export default CreateGuildModal;
