import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerError, ServerResponse } from "../../../types/api";
import { Result } from "../../../types/utils";

export const getLeaderBird = async (): Promise<Result<any, ServerError>> => {
  const response = await fetchApi("GET", `/bird/is-leader`);
  if (response.error) {
    // Throw to retry
    throw new Error("network-error");
  }

  const LeaderBirdResponse: ServerResponse<any[]> = await response.data.json();
  if (LeaderBirdResponse.error) {
    return {
      error: LeaderBirdResponse.error,
    };
  }

  return {
    data: LeaderBirdResponse.data,
  };
};

export default function useGetLeaderBird() {
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["getLeaderBird"],
    queryFn: () => getLeaderBird(),
    retry: 1,
  });

  return {
    leaderBird: data,
    isLoadingLeaderBird: isFetching,
    refreshLeaderBird: refetch,
  };
}
