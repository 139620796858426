import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerResponse } from "../../../types/api";

export const getStreakReward = async () => {
  const response = await fetchApi("GET", "/streak-reward");
  if (response.error) {
    throw new Error("network-error");
  }

  const streaRewardkResponse: ServerResponse<any> = await response.data.json();
  if (streaRewardkResponse.error) {
    return {
      error: streaRewardkResponse.error,
    };
  }

  return streaRewardkResponse.data;
};

export default function useStreakReward() {
  const { data, isPending, refetch } = useQuery({
    queryKey: ["StreakReward"],
    queryFn: () => getStreakReward(),
  });

  return {
    StreakReward: data,
    StreakRewardLoading: isPending,
    refeshStreakReward: refetch,
  };
}
