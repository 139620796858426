import clsx from "clsx";
import { motion } from "framer-motion";
import { useMemo, useRef, useState } from "react";
import Ticket from "../Components/Ticket";
import { useNavigate } from "react-router-dom";
import { Slide, toast, ToastContainer } from "react-toastify";
import PiecesColected from "../Components/PiecesColected";
import SpinHistory from "../Components/SpinHistory";
import useAlltickets from "../Hooks/useGetAllTickets";
import useEggPieces from "../Hooks/useGetEggPieces";
import useSpinReward from "../Hooks/useSpinReward";
import { sleep } from "../../../utils/time";
import LoadingScreen from "../../Global/Pages/LoadingScreen";
import useHistoryMergeEgg from "../Hooks/useHistoryMergeEgg";
import { navPaths } from "../../../constants/navbar.constants";
import { useClickOutside } from "../../../Hooks/useClickOutside";
import HowToColectModal from "../Components/HowToColectModal";
import { Button } from "@mui/material";
import EggsCollected from "../Components/EggCollected";
import MissionModal from "../Components/MissionModal";

const SpinEgg = () => {
  const tele = window.Telegram.WebApp;
  const navigate = useNavigate();

  const { HistoryMergeEgg, HistoryMergeEggLoading, refeshHistoryMergeEgg } =
    useHistoryMergeEgg();
  const { Alltickets, AllticketsLoading, refeshAlltickets } = useAlltickets();
  const { EggPieces, EggPiecesLoading, refeshEggPieces } = useEggPieces();
  const { spinRewardAsync, isSpiningReward } = useSpinReward();

  tele.BackButton.show();
  tele.BackButton.onClick(() => {
    navigate(navPaths.GAME_LIST);
    sessionStorage.removeItem("SpinPrize-storage");
  });

  const [isShowMissionModal, setIsShowMissionModal] = useState<boolean>(false);
  const [isOpenHowtoColectModal, setIsOpenHowtoColectModal] =
    useState<boolean>(false);
  const [isSpining, setIsSpining] = useState<boolean>(false);
  const [receivedPrize, setReceivedPrize] = useState<any>();
  const [switchHanlde, setSwitchHandle] = useState<boolean>(false);

  const howtoColectModalRef = useRef<any>();

  useClickOutside(howtoColectModalRef, () => {
    setIsOpenHowtoColectModal(false);
  });

  const TotalTickets = useMemo(() => {
    if (!Alltickets) {
      return 0;
    }
    return Alltickets.length;
  }, [Alltickets]);

  const handleSpin = () => {
    const ticketId = Alltickets?.[0]?.id;
    setIsSpining(true);
    setSwitchHandle(false);

    spinRewardAsync(ticketId)
      .then((data) => {
        // SetReceiVedPrize(data?.data?.data);
        setReceivedPrize(data?.data?.data);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
        });
      })
      .finally(() => {
        setTimeout(async () => {
          setIsSpining(false);
          await sleep(500);
          refeshAlltickets();
          refeshEggPieces();
        }, 2000);
      });
  };

  if (AllticketsLoading || HistoryMergeEggLoading || EggPiecesLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div
        className={clsx(
          "px-4 pt-4 pb-[40px] h-screen relative z-30 bg-[#F2FFE0]"
        )}
      >
        <div className="h-full overflow-auto">
          <ToastContainer
            position="top-left"
            closeOnClick
            transition={Slide}
            hideProgressBar
            limit={1}
            stacked
            className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
          />
          <p className="text-center text-[24px] font-extrabold text-[#000000]">
            Eggs Spinner
          </p>
          <div
            onClick={() => setIsShowMissionModal(true)}
            className="w-full h-[73px] mt-5 relative cursor-pointer"
          >
            <img
              className=" w-full h-full"
              src="/images/lucky_spin/mission.png"
              alt=""
            ></img>
            <p
              className={clsx(
                "w-full text-center absolute top-[45%] -translate-y-[50%]",
                "text-[26px] leading-[33px] font-squada-one-regular"
              )}
            >
              Treasure Hunt Board
            </p>
          </div>
          <div
            className={clsx(
              "w-full  px-[18px] py-4 relative",
              "border-[3px] border-[#97C35B] rounded-[12px] mt-4"
            )}
          >
            {HistoryMergeEgg?.length >= 100 && (
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center z-40">
                <div className="bg-[#00000099] w-[88px] text-white py-1 text-center rounded-[14px]">
                  Ended
                </div>
              </div>
            )}

            <div
              className={clsx(
                "relative z-0",
                HistoryMergeEgg?.length >= 100 && "blur-lg"
              )}
            >
              <div className="flex justify-end ">
                <button
                  className="box-tooltip"
                  onClick={() => setIsOpenHowtoColectModal(true)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 0C15.5078 0 20 4.49219 20 10C20 15.5469 15.5078 20 10 20C4.45312 20 0 15.5469 0 10C0 4.49219 4.45312 0 10 0ZM10 18.125C14.4531 18.125 18.125 14.4922 18.125 10C18.125 5.54688 14.4531 1.875 10 1.875C5.50781 1.875 1.875 5.54688 1.875 10C1.875 14.4922 5.50781 18.125 10 18.125ZM11.5625 13.125C12.0703 13.125 12.5 13.5547 12.5 14.0625C12.5 14.6094 12.0703 15 11.5625 15H8.4375C7.89062 15 7.5 14.6094 7.5 14.0625C7.5 13.5547 7.89062 13.125 8.4375 13.125H9.0625V10.625H8.75C8.20312 10.625 7.8125 10.2344 7.8125 9.6875C7.8125 9.17969 8.20312 8.75 8.75 8.75H10C10.5078 8.75 10.9375 9.17969 10.9375 9.6875V13.125H11.5625ZM10 7.5C9.29688 7.5 8.75 6.95312 8.75 6.25C8.75 5.58594 9.29688 5 10 5C10.6641 5 11.25 5.58594 11.25 6.25C11.25 6.95312 10.6641 7.5 10 7.5Z"
                      fill="#4C7E0B"
                    />
                  </svg>
                </button>
              </div>

              <div className="relative flex justify-center">
                <img
                  className="w-[247px] h-[217px] "
                  src={
                    switchHanlde
                      ? "/images/lucky_spin/slot_machine_down.png"
                      : "/images/lucky_spin/slot_machine.png"
                  }
                  alt=""
                ></img>
                <div
                  className={clsx(
                    "w-[104px] h-[104px] absolute top-[50%] -translate-y-[40%] left-[50%] -translate-x-[50%] ",
                    "border-[2px] border-[#97C35B] rounded-[12px] bg-[#F2FFE0] overflow-hidden",
                    "flex items-center justify-center"
                  )}
                >
                  {isSpining || isSpiningReward ? (
                    <div className="slide_img_bg w-[66px] absolute "></div>
                  ) : (
                    <>
                      {receivedPrize ? (
                        <motion.div initial={{ y: -100 }} animate={{ y: 0 }}>
                          <img
                            className="w-[64px] h-[80px]"
                            src={`images/lucky_spin/${receivedPrize?.type}.png`}
                            alt=""
                          ></img>
                        </motion.div>
                      ) : (
                        <img
                          className="w-[64px] h-[80px]"
                          src={`images/lucky_spin/mystery_piece.png`}
                          alt=""
                        ></img>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="w-full h-[52px] bg-[#E4F0D3] p-4 rounded-[12px] mt-4 ">
                {!isSpining && receivedPrize && (
                  <p className="text-center text-sm font-bold capitalize">{`${receivedPrize?.type} egg piece`}</p>
                )}
              </div>

              <div className="w-full grid grid-cols-2 mt-4">
                <div className="flex items-center gap-1">
                  <Ticket />
                  <p className="text-sm font-semibold">{`${TotalTickets} ${
                    TotalTickets > 1 ? "Tickets" : "Ticket"
                  }`}</p>
                </div>
                <div className="w-full">
                  {TotalTickets === 0 ? (
                    <Button
                      onClick={async () => {
                        navigate(navPaths.FRIENDS);
                      }}
                      className={clsx(
                        "normal-case text-[16px] w-full font-bold text-white py-[12px] rounded-xl ",
                        "btn-hover bg-gradient-to-r from-[#97C35B] to-[#61A700] shadow-[0_4px_0px_#4C7E0B]"
                      )}
                    >
                      Get free tickets
                    </Button>
                  ) : (
                    <Button
                      disabled={isSpining}
                      onClick={async () => {
                        setSwitchHandle(true);
                        await sleep(200);
                        handleSpin();
                      }}
                      className={clsx(
                        "capitalize text-[16px] w-full font-bold text-white py-[12px] rounded-xl ",
                        "btn-hover bg-gradient-to-r from-[#97C35B] to-[#61A700] shadow-[0_4px_0px_#4C7E0B]",
                        "disabled:bg-[#ADADAD] disabled:shadow-[0_4px_0px_#4E4E4E]"
                      )}
                    >
                      Spin
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <PiecesColected
            pieces={EggPieces}
            triggerRefresh={() => {
              refeshEggPieces();
              refeshHistoryMergeEgg();
            }}
          />
          <EggsCollected eggs={HistoryMergeEgg} />
          {HistoryMergeEgg?.length > 0 && (
            <SpinHistory items={HistoryMergeEgg} />
          )}
        </div>
      </div>

      <MissionModal
        isShowModal={isShowMissionModal}
        handleClose={() => setIsShowMissionModal(false)}
        triggerRefresh={refeshAlltickets}
      />
      <HowToColectModal
        ref={howtoColectModalRef}
        isShowModal={isOpenHowtoColectModal}
        showModal={setIsOpenHowtoColectModal}
      />
    </>
  );
};

export default SpinEgg;
