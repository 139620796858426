import { forwardRef } from "react";
import clsx from "clsx";

const ReceivedModal = forwardRef(
  (
    {
      isShowModal,
      showModal,
    }: {
      isShowModal: boolean;
      showModal: any;
    },
    ref: any
  ) => {
    return (
      <div
        className={clsx(
          "bg-black/70 absolute z-50 inset-0 flex items-center justify-center px-7 ",
          isShowModal ? "visible" : " invisible"
        )}
      >
        <div
          ref={ref}
          className={clsx(
            "bg-[#F2FFE0] w-[100%] rounded-[20px] p-[24px] pt-[80px] flex flex-col gap-4 items-center justify-center relative",
            isShowModal ? "translate-y-0" : "translate-y-[400%]",
            "transition-all duration-300 ease-in-out"
          )}
        >
          <div className="absolute -top-[35%]">
            <img src="/images/profile_gift.png" alt=""></img>
          </div>

          <p className="text-[24px] font-extrabold">Congratulations!</p>

          <div className="w-full bg-[#E4F0D3] rounded-[12px] p-2">
            <p className="text-[14px] font-medium">
              Your guild was top 10 last session
            </p>
            <div className="flex justify-start items-center gap-2 col-span-3">
              <img
                src="/images/icons/token_icon.png?v=3"
                width={20}
                height={20}
                alt="token"
              ></img>
              <p className="text-[14px] font-semibold">+1000</p>
            </div>
          </div>

          <div className="w-full bg-[#E4F0D3] rounded-[12px] p-2">
            <p className="text-[14px] font-medium">
              SEED distributed by your guild master
            </p>
            <div className="flex justify-start items-center gap-2 col-span-3">
              <img
                src="/images/icons/token_icon.png?v=3"
                width={20}
                height={20}
                alt="token"
              ></img>
              <p className="text-[14px] font-semibold">+1000</p>
            </div>
          </div>
          <div className="w-full">
            <button
              onClick={() => showModal(false)}
              className={clsx(
                "font-bold normal-case text-white py-3 w-full rounded-xl flex items-center justify-center gap-2",
                "btn-hover bg-gradient-to-r from-[#97C35B] to-[#61A700] shadow-[0_4px_0px_#4C7E0B]",
                "disabled:bg-[#4E4E4E] disabled:text-[#F3F3F3] disabled:shadow-[0_3px_0_#4E4E4E]"
              )}
            >
              Claim
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export default ReceivedModal;
