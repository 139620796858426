import React from "react";

const ClaimedTicket = () => {
  return (
    <img
      className="h-[33px] w-[34px]"
      src="images/lucky_spin/claimed_ticket.png"
      alt=""
    ></img>
  );
};

export default ClaimedTicket;
