import { RouteObject } from "react-router-dom";
import { navPaths } from "../../constants/navbar.constants";
import GuildList from "./Pages/GuildList";
import GuildDetails from "./Pages/GuildDetails";
import GuildMessage from "./Pages/GuildMessage";
import GuildWelcome from "./Pages/GuildWelcome";

export const guildRoute: RouteObject = {
  path: "",
  children: [
    { path: navPaths.GUILD_WELCOME, Component: GuildWelcome },
    { path: navPaths.GUILD_LIST, Component: GuildList },
    {
      path: navPaths.GUILD_DETAILS,
      Component: GuildDetails,
    },
    { path: navPaths.GUILD_MESSAGE, Component: GuildMessage },
  ],
};
