import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerResponse } from "../../../types/api";

export const getGuildMembers = async ({
  page,
  guild_id,
}: {
  page: number;
  guild_id: string;
}) => {
  const response = await fetchApi(
    "GET",
    `/guild/member?guild_id=${guild_id}&page=${page}`
  );
  if (response.error) {
    throw new Error("network-error");
  }

  const getGuildMembersResponse: ServerResponse<any> =
    await response.data.json();
  if (getGuildMembersResponse.error) {
    return {
      error: getGuildMembersResponse.error,
    };
  }

  return getGuildMembersResponse.data;
};

export default function useGuildMembers({
  page,
  guild_id,
}: {
  page: number;
  guild_id: string;
}) {
  const { data, isPending, refetch, isRefetching } = useQuery({
    queryKey: ["GuildMembers"],
    queryFn: () => !!guild_id && getGuildMembers({ guild_id, page }),
    retry: 1,
  });

  return {
    GuildMembers: data,
    GuildMembersLoading: isPending,
    refeshGuildMembers: refetch,
    isRefetching,
  };
}
