import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerResponse } from "../../../types/api";

export const getStreak = async () => {
  const response = await fetchApi("GET", "/daily-login-streak");
  if (response.error) {
    throw new Error("network-error");
  }

  const streakResponse: ServerResponse<any> = await response.data.json();
  if (streakResponse.error) {
    return {
      error: streakResponse.error,
    };
  }

  return streakResponse.data;
};

export default function useGetStreak() {
  const { data, isPending, refetch } = useQuery({
    queryKey: ["Streak"],
    queryFn: () => getStreak(),
  });

  return {
    Streak: data,
    StreakLoading: isPending,
    refeshStreak: refetch,
  };
}
