import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function upgradeGuild({ guild_id }: { guild_id: string }) {
  return await api.post("/api/v1/guild/upgrade", { guild_id });
}

export default function useUpgradeGuild() {
  return useMutation({
    mutationKey: ["upgradeGuild"],
    mutationFn: upgradeGuild,
  });
}
