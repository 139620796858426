import { Button } from "@mui/material";
import clsx from "clsx";
import { forwardRef } from "react";
import { toast } from "react-toastify";
import { useAccount } from "../../../components/Hooks/useRegister";
import useCancelSell from "../../Market/Hooks/useCancelSell";
import { ITEM_TYPES } from "../../Market/Pages/Market";
import { motion } from "framer-motion";
import Backdrop from "../../../components/common/Backdrop";
import { dropIn } from "../../../components/common/ModalContainer";
import { LoadingButton } from "@mui/lab";

const UnlistModal = forwardRef(
  (
    {
      showModal,
      egg,
      triggerReload,
      tab,
    }: {
      showModal: any;
      egg: any;
      triggerReload: any;
      tab: string;
    },
    ref: any
  ) => {
    const { account } = useAccount();
    const userID = account?.data?.id;

    const color = ITEM_TYPES.find((item) => item.type === egg?.type)?.color;

    const { cancelSellAsync, isCancelingSell } = useCancelSell();

    const handleCancelSell = () => {
      cancelSellAsync({ ownerId: userID, id: egg?.market_id, market: tab })
        .then(() => {
          toast.error("Unlist successfully", {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          });

          showModal(false);
          triggerReload && triggerReload();
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message, {
            style: {
              maxWidth: 337,
              height: 40,
              borderRadius: 8,
            },
            autoClose: 2000,
          });
          showModal(false);
          triggerReload && triggerReload();
        });
    };

    return (
      <Backdrop onClick={() => showModal(false)}>
        <motion.div
          onClick={(e) => e.stopPropagation()}
          variants={dropIn}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="motion-modal"
        >
          <div
            ref={ref}
            className={clsx(
              "bg-[#F2FFE0] rounded-[20px] p-[24px] flex flex-col gap-4 items-center justify-center relative"
            )}
          >
            <div className="w-[250px] text-center text-[20px] font-[800]">
              Market
            </div>
            <div className="w-[50%]">
              <img
                src={
                  tab === "worm"
                    ? `/images/inventory/worm/${egg?.type}.png`
                    : `/images/egg/${egg?.type}.png`
                }
                alt={"sell-egg"}
                className="w-full object-contain z-10 -mt-2"
              />
            </div>
            <div
              className={clsx(
                "font-[600] text-[16px] text-white rounded-[20px] px-2 py-1 flex items-center gap-1 uppercase -mt-2"
              )}
              style={{ backgroundColor: color }}
            >
              <div className="bg-white rounded-full text-[#646464] w-[18px] h-[18px] flex items-center justify-center">
                #
              </div>
              <span
                className={
                  egg?.type === "common" ? "text-black/70" : "text-white"
                }
              >
                {egg?.id?.slice(egg?.id?.length - 9, egg?.id?.length)}
              </span>
            </div>
            {egg?.price && (
              <div className="text-sm text-black font-[500] flex items-center gap-1 mt-3 mb-4">
                <span className="text-[16px]">Price:</span>
                <img
                  src="/images/icons/token_icon.png"
                  width={21}
                  height={21}
                  alt="token"
                  className="ml-[2px]"
                ></img>
                <span className="text-[20px] font-[800]">
                  {egg?.price / 10 ** 9}
                </span>
              </div>
            )}
            <div className="flex items-center gap-3 w-full">
              <Button
                sx={{ border: 1 }}
                onClick={() => showModal(false)}
                // startIcon={<img src="images/icons/copy.svg" alt="copy" />}
                className={clsx(
                  "font-bold capitalize text-[16px] py-2 lsm:py-[18px] w-full h-[50px] rounded-xl ",
                  "btn-hover bg-[#F2FFE0] drop-shadow-[0_3px_0px_#4C7E0B] text-[#4C7E0B] border-[2px]"
                )}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={isCancelingSell}
                sx={{ border: 1 }}
                onClick={handleCancelSell}
                // startIcon={<img src="images/icons/copy.svg" alt="copy" />}
                className={clsx(
                  "font-bold capitalize text-[16px] text-white py-2 lsm:py-[18px] w-full h-[50px] rounded-xl ",
                  "btn-hover bg-gradient-to-r from-[#97C35B] to-[#61A700] drop-shadow-[0_4px_0px_#4C7E0B] border-none"
                )}
              >
                Unlist
              </LoadingButton>
            </div>
          </div>
        </motion.div>
      </Backdrop>
    );
  }
);

export default UnlistModal;
