import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerError, ServerResponse } from "../../../types/api";
import { Result } from "../../../types/utils";

export const getMyEggs = async ({
  page,
}: {
  page: number;
}): Promise<Result<any, ServerError>> => {
  const response = await fetchApi("GET", `/egg/me?page=${page}`);
  if (response.error) {
    // Throw to retry
    throw new Error("network-error");
  }

  const myEggResponse: ServerResponse<any[]> = await response.data.json();
  if (myEggResponse.error) {
    return {
      error: myEggResponse.error,
    };
  }

  return {
    data: myEggResponse.data,
  };
};

export default function useGetMyEggs({ page }: { page: number }) {
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["MyEggs"],
    queryFn: () => getMyEggs({ page }),
    retry: 1,
  });

  return {
    myEggs: data,
    isLoadingMyEggs: isFetching,
    refreshMyEggs: refetch,
    itemPerPage: data?.data?.page_size,
  };
}
