import clsx from "clsx";
import { navPaths } from "../../../constants/navbar.constants";
import { useNavigate } from "react-router-dom";

const GuildWelcome = () => {
  const tele = window.Telegram.WebApp;
  const navigate = useNavigate();

  tele.BackButton.show();
  tele.BackButton.onClick(() => navigate("/"));

  const handleOpenGuilde = () => {
    tele.showPopup(
      {
        message: `Do you want to open guilelines`,
        buttons: [
          { id: "link", type: "default", text: "Open" },
          { type: "cancel" },
        ],
      },
      async function (btn: any) {
        if (btn === "link") {
          tele.openLink("https://seeddao.gitbook.io/seed-tutorial/guild");
        } else {
        }
      }
    );
  };
  return (
    <>
      <div
        className={clsx(
          "pt-[42px] px-4 pb-[100px] bg-[#F2FFE0] dark:bg-transparent h-screen relative z-30",
          "bg-contain bg-no-repeat bg-center dark:text-white"
        )}
        style={{ backgroundImage: `url("/images/guild/bg_tree.png")` }}
      >
        <div className="w-screen h-screen backdrop-blur-xl absolute z-0 top-0 left-0"></div>
        <div className="flex items-center flex-col text-center relative z-10">
          <img
            src="/images/guild/guild_bird.png"
            className="size-[90px]"
            alt=""
          ></img>
          <p className="text-[24px] font-semibold">Hunting Guild</p>
          <p className="text-sm">Guild Up, Hunt Big, Earn More</p>
          <p
            onClick={() => handleOpenGuilde()}
            className="mt-3 cursor-pointer text-[#4C7E0B] text-sm font-bold"
          >
            Full Guideline
          </p>
        </div>

        <div className="flex flex-col gap-3 mt-6 h-[calc(100%-235px)] overflow-auto relative z-10">
          <div className=" grid grid-cols-12 px-2 py-[10px] ">
            <div className="col-span-2 p-2 size-[64px] flex justify-center items-center bg-[#E4F0D3] rounded-[12px]">
              <img
                src="/images/guild/master_benefit.png"
                className="size-10"
                alt=""
              ></img>
            </div>
            <div className="col-span-10 text-sm px-3">
              <p className="font-semibold ">Leader's Benefit</p>
              <ul className="list-disc pl-5">
                <li>Gets a 5% bonus when member’s birds hunt</li>
              </ul>
            </div>
          </div>

          <div className=" grid grid-cols-12 px-2 py-[10px] ">
            <div className="col-span-2 p-2 size-[64px] flex justify-center items-center bg-[#E4F0D3] rounded-[12px]">
              <img
                src="/images/guild/member_benefit.png"
                className="size-[48px]"
                alt=""
              ></img>
            </div>
            <div className="col-span-10 text-sm px-3">
              <p className="font-semibold ">Member's Benefit</p>
              <ul className="list-disc pl-5">
                <li>5% SEED bonus when birds hunt</li>
                <li>Get SEED from your guild distribution</li>
              </ul>
            </div>
          </div>

          <div
            onClick={() => navigate(navPaths.GUILD_LIST)}
            className=" grid grid-cols-12 px-2 py-[10px] "
          >
            <div className="col-span-2 p-2 size-[64px] flex justify-center items-center bg-[#E4F0D3] rounded-[12px]">
              <img
                src="/images/leaderboard/mainicon.png"
                className="size-[48px]"
                alt=""
              ></img>
            </div>
            <div className="col-span-10 text-sm px-3 flex justify-between items-center">
              <div>
                <p className="font-semibold ">Leader Board</p>
                <ul className="list-disc pl-5">
                  <li>Top guild get rewards</li>
                </ul>
              </div>
              <div>
                <svg
                  width="16"
                  height="15"
                  viewBox="0 0 16 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.28125 0.746094L15.4688 6.65234C15.6445 6.82812 15.75 7.03906 15.75 7.28516C15.75 7.49609 15.6445 7.70703 15.4688 7.88281L9.28125 13.7891C8.96484 14.1055 8.40234 14.1055 8.08594 13.7539C7.76953 13.4375 7.76953 12.875 8.12109 12.5586L12.7969 8.12891H0.84375C0.351562 8.12891 0 7.74219 0 7.28516C0 6.79297 0.351562 6.44141 0.84375 6.44141H12.7969L8.12109 1.97656C7.76953 1.66016 7.76953 1.09766 8.08594 0.78125C8.40234 0.429688 8.92969 0.429688 9.28125 0.746094Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="text-center text-sm flex flex-col gap-2 mt-5">
            <p className="text-[24px] font-semibold ">Have your own guild?</p>
            <p>
              Create a guild and invite your team. Manage treasure and compete
              with others!
            </p>
            <p
              onClick={() =>
                navigate(navPaths.GUILD_LIST, {
                  state: { openCreateGuild: true },
                })
              }
              className="cursor-pointer text-[#4C7E0B] font-bold"
            >
              Create a guild
            </p>
          </div>
        </div>

        <div className="absolute z-10 bottom-[30px] right-4 left-4">
          <div className="w-full">
            <button
              onClick={() => navigate(navPaths.GUILD_LIST)}
              className={clsx(
                "font-bold text-white py-[18px] w-full rounded-xl flex items-center justify-center gap-2",
                "btn-hover bg-gradient-to-r from-[#97C35B] to-[#61A700] drop-shadow-[0_4px_0px_#4C7E0B]"
              )}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuildWelcome;
