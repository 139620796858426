import clsx from "clsx";
import ItemLoading from "../../../components/common/ItemLoading";
import { formatDecimals } from "../../../utils/formatNumber";
import { useCheckMission } from "../Hooks/useDoMissions";
import { toast } from "react-toastify";

interface TeleMissionItemType {
  item: any;
  reFetch: () => void;
}

const TeleMissionItem = ({ item, reFetch }: TeleMissionItemType) => {
  const tele = window.Telegram.WebApp;

  const { checkMission, isLoading } = useCheckMission();

  const handleOpenLink = () => {
    if (item?.task_user === null || !item?.task_user?.completed) {
      tele.openTelegramLink(item?.metadata?.url ?? "");
      checkMission(item)
        .then(async (data) => {
          if (data?.data === "Mission completed") {
            toast.success(data.data, {
              style: { maxWidth: 337, height: 40, borderRadius: 8 },
              autoClose: 2000,
            });
            reFetch();
            return;
          }

          if (data?.data === "incomplete task") {
            toast.error("Incomplete mission", {
              style: { maxWidth: 337, height: 40, borderRadius: 8 },
              autoClose: 2000,
            });
            return;
          }

          toast.error(data?.data, {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          });
          return;
        })
        .catch((err: any) => {
          toast.error(
            String(err?.response?.data?.message).replace("task", "mission"),
            {
              style: { maxWidth: 337, height: 40, borderRadius: 8 },
              autoClose: 2000,
            }
          );
        });
      return;
    }
    tele.openTelegramLink(item?.metadata?.url ?? "");
  };
  return (
    <>
      <button
        disabled={isLoading}
        onClick={() => {
          handleOpenLink();
        }}
        key={item?.id}
        rel="noreferrer"
        className={clsx("text-center relative w-full mt-1 ")}
      >
        {item.task_user != null &&
          (item.task_user.repeats || 0 >= item.repeats) && (
            <div
              className={clsx(
                "w-[30px] h-[30px] rounded-[50%] flex items-center justify-center absolute right-4 -top-4 z-20"
              )}
            >
              <img
                src="/images/holy/check_mark.png"
                className="w-[31px] h-[31px]"
                alt=""
              ></img>
            </div>
          )}
        <div
          key={item.id}
          className={clsx(
            "z-10 py-3 px-4 relative cursor-pointer grid grid-cols-12 gap-3 bg-white rounded-2xl p-4 w-full mb-[18px] ",
            item.task_user?.repeats || 0 >= item.repeats
              ? "border-[1px] border-solid border-[#000] drop-shadow-none brightness-50"
              : "border-[1px] border-[#F3F3F3] border-solid"
          )}
        >
          <div className="col-span-9 flex relative">
            {item?.metadata?.important && (
              <img
                src="images/important.png"
                width={13}
                height={13}
                alt=""
                className="absolute z-20 -top-1 -left-1"
              ></img>
            )}
            <div className=" flex items-center mr-4">
              <div className="rounded-lg drop-shadow-lg overflow-hidden w-8 h-8">
                <img
                  src={item.metadata.image_url}
                  className="w-8 h-8"
                  alt=""
                ></img>
              </div>
            </div>

            <div className=" flex items-center text-start justify-start text-[15px]">
              {item.name}
            </div>
          </div>
          <div className="col-span-3 flex items-center justify-start">
            {isLoading ? (
              <ItemLoading />
            ) : (
              <>
                <img
                  src="/images/icons/token_icon.png?v=3"
                  className="w-4 h-4"
                  alt=""
                ></img>
                <span className="font-semibold text-sm ml-1">{`+${formatDecimals(
                  item.reward_amount ?? 0
                )}`}</span>
              </>
            )}
          </div>
        </div>
      </button>
    </>
  );
};

export default TeleMissionItem;
