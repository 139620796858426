import React from "react";

const DoneTicket = () => {
  return (
    <img
      className="size-10"
      src="/images/lucky_spin/mission_done_ticket.png"
      alt=""
    ></img>
  );
};

export default DoneTicket;
