import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function transferEgg({
  id,
  telegramId,
}: {
  id: string;
  telegramId: number;
}) {
  return await api.post(`/api/v1/transfer/egg`, {
    telegram_id: telegramId,
    egg_id: id,
  });
}

export default function useTransferEgg() {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["transferEgg"],
    mutationFn: transferEgg,
  });

  return { transferAsync: mutateAsync, isTransferEgg: isPending };
}
