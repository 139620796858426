import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerError, ServerResponse } from "../../../types/api";
import { Worm } from "../../../types/worm";
import { Result } from "../../../types/utils";

export const getAvailableWorm = async (): Promise<
  Result<Worm, ServerError>
> => {
  const response = await fetchApi("GET", "/worms");
  if (response.error) {
    // Throw to retry
    throw new Error("network-error");
  }

  const wormResponse: ServerResponse<Worm> = await response.data.json();
  if (wormResponse.error) {
    return {
      error: wormResponse.error,
    };
  }

  return {
    data: wormResponse.data,
  };
};

export default function useAvailableWorm() {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["AvailableWorm"],
    queryFn: getAvailableWorm,
    retry: 1,
  });

  return { worm: data, isGettingWorm: isLoading, refreshWorm: refetch };
}
