const Food = () => {
  return (
    <svg
      width="25"
      height="23"
      viewBox="0 0 25 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.3408 2.15716C20.8365 3.3521 18.3912 5.84719 18.0259 5.49093C17.7383 5.2108 20.2187 2.72854 21.5561 1.45961C22.8238 0.256975 21.9395 0.401103 21.5842 0.669259C20.4549 1.52291 16.1356 4.44226 15.8559 5.96138C15.7351 6.61787 16.0148 7.6212 15.8282 8.07027C15.4106 9.07489 9.81007 13.7824 6.99112 16.005C4.17217 18.2277 0.834218 20.2827 2.52251 21.9275C4.21081 23.572 6.32107 20.3207 8.60301 17.575C10.885 14.8293 15.7176 9.37427 16.7494 8.96754C17.21 8.78578 18.2401 9.05821 18.9146 8.9406C20.4742 8.66817 23.471 4.46107 24.3479 3.36108C24.6236 3.01509 24.7711 2.15374 23.5369 3.38845C22.2341 4.69117 19.6856 7.10671 19.398 6.82657C19.0301 6.46818 21.6014 4.08514 22.8176 2.62119C23.8543 1.37322 23.6299 1.13415 22.3417 2.15716"
        fill="#005A53"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.7485 21.8875C24.4368 20.2431 21.0563 18.1462 18.1978 15.8854C15.3393 13.6243 9.65799 8.83812 9.229 7.82238C8.80045 6.80664 10.1379 5.04503 8.69726 2.89379C7.25617 0.742563 3.45894 -0.489156 1.61696 1.30496C-0.225014 3.09908 1.04 6.79766 3.24818 8.20131C5.45635 9.60495 7.26539 8.30181 8.30823 8.71966C9.35106 9.1375 14.2649 14.6713 16.5864 17.4555C18.9078 20.2396 21.0602 23.5324 22.7485 21.8879"
        fill="#005A53"
      />
    </svg>
  );
};

export default Food;
