import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { Result } from "../../../types/utils";
import { ServerError, ServerResponse } from "../../../types/api";

export const getMarketData = async ({
  market_type = "",
  egg_type = "",
  sort_by_price = "",
  sort_by_updated_at = "DESC",
  page,
}: {
  market_type: string;
  egg_type: string;
  sort_by_price: string;
  sort_by_updated_at: string;
  page: number;
}): Promise<Result<any, ServerError>> => {
  const response = await fetchApi(
    "GET",
    `/market/v2?market_type=${market_type}&worm_type=${egg_type}&sort_by_price=${sort_by_price}&sort_by_updated_at=${sort_by_updated_at}&page=${page}`
  );
  if (response.error) {
    // Throw to retry
    throw new Error("network-error");
  }

  const marketDataResponse: ServerResponse<any> = await response.data.json();
  if (marketDataResponse.error) {
    return {
      error: marketDataResponse.error,
    };
  }

  return {
    data: marketDataResponse.data,
  };
};

export default function useGetMarketData(params: any) {
  const { data, refetch, isFetching } = useQuery({
    queryKey: ["MarketData"],
    queryFn: () => getMarketData(params),
    retry: 1,
  });

  return {
    marketData: data,
    isLoadingMarketData: isFetching,
    refreshMarketData: refetch,
    itemPerPageMarket: data?.data?.page_size,
  };
}
