import clsx from "clsx";
import { motion } from "framer-motion";
import Backdrop from "../../../components/common/Backdrop";
import { dropIn } from "../../../components/common/ModalContainer";

const AchieveModal = ({
  showAchieve,
  showGuardian,
  handleShowAchieve,
  handleShowGuardian,
}: {
  showAchieve: boolean;
  showGuardian: boolean;
  handleShowAchieve: () => void;
  handleShowGuardian: () => void;
}) => {
  const hideModal = () => {
    if (showGuardian) {
      handleShowGuardian();
      return;
    }
    if (showAchieve) {
      handleShowAchieve();
      return;
    }
  };

  return (
    <Backdrop onClick={hideModal} className="!bg-[#000000E5]">
      <motion.div
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="release-modal"
      >
        <div
          className={clsx(
            "bg-[#FFFFFF1A] text-white rounded-[20px] py-[24px] flex flex-col items-center justify-center relative backdrop-blur",
          )}
        >
          <span className="font-spicy-rice-regular text-[32px]">
            {showAchieve ? "Wings of Freedom" : "Growth Guardian"}
          </span>
          <>
            <div className=" px-6 pt-4 flex flex-col items-center w-full">
              <img
                alt=""
                className="w-[35vw] aspect-square object-contain mb-1"
                src={
                  showAchieve
                    ? "/images/bird-cage/achieve.png"
                    : "images/medal.png"
                }
              />
              <span className="font-bold text-base mt-2">
                Achievement Badge
              </span>
              <div className="mt-1 bg-[#FFFFFF1F] py-[5.5px] rounded-lg text-xs text-center w-full text-[#FFFFFF99]">
                Badge is the key to our airdrop. Limited &<br />
                on-chain tradable
              </div>
            </div>
            <span className="text-sm w-[80%] text-center mt-3">
              {showAchieve
                ? "This simple act represents our shared hope for a world where leaders like Durov are free to innovate without restrictions."
                : "Your “spread-the-word” efforts powered SEED to No.1 Play2Earn Telegram App. We honor your key role in our growth."}
            </span>
          </>
          <div className="px-6 mt-4 w-full">
            <div
              onClick={hideModal}
              className={clsx(
                "flex items-center justify-center text-[16px] w-full font-[800] text-black h-[48px] px-5 rounded-xl normal-case mx-auto",
                "btn-click-no-shadow bg-white shadow-[0_4px_0px_#B0B0B0] select-none",
              )}
            >
              {"Close"}
            </div>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default AchieveModal;
