import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function createGuild({
  name,
  type,
  bird_type_required,
  distribution_rate,
  top_member,
}: {
  name: string;
  type: string;
  bird_type_required: string;
  distribution_rate: number;
  top_member: number;
}) {
  return await api.post("/api/v1/guild/create", {
    name,
    type,
    bird_type_required,
    distribution_rate,
    top_member,
  });
}

export default function useCreateGuild() {
  return useMutation({
    mutationKey: ["createGuild"],
    mutationFn: createGuild,
  });
}
