import { fetchApi } from "../../../config/api";
import { Nullable, Result } from "../../../types/utils";
import { ServerError, ServerResponse } from "../../../types/api";
import { Task } from "../../../types/task";
import { useState } from "react";
import { sleep } from "../../../utils/time";

const MISSION_CHECK_LIMIT = 3;
const MISSION_CHECK_INTERVAL = 1000;

export const requestCheckMission = async (
  missionId: string
): Promise<Result<string, ServerError>> => {
  const response = await fetchApi("POST", `/upgrades/tasks/${missionId}`);
  if (response.error) {
    return {
      error: {
        code: "network",
        message: response.error.message,
      },
    };
  }

  const messageResponse: ServerResponse<string> = await response.data.json();
  if (messageResponse.error) {
    return {
      error: messageResponse.error,
    };
  }

  return {
    data: messageResponse.data,
  };
};

export const requestCheckMissionProgress = async (
  missionMessageId: string
): Promise<Nullable<any>> => {
  const response = await fetchApi(
    "GET",
    `/tasks/notification/${missionMessageId}`
  );
  if (response.error || !response.data.ok) {
    // Just ignore network error ?
    return null;
  }

  const progressResponse: ServerResponse<any> = await response.data.json();
  if (progressResponse.error) {
    return null;
  }

  return progressResponse.data;
};

export const useCheckMission = () => {
  const [loading, setLoading] = useState(false);
  // TODO: Change to useMutation for optimistic UI later

  const checkMission = async (
    mission: Pick<Task, "id" | "repeats">
  ): Promise<
    Result<
      "Mission completed" | "incomplete task" | "Request timeout",
      ServerError
    >
  > => {
    // Start checking
    setLoading(true);

    // Failed to get mission progress check id
    const response = await requestCheckMission(mission.id);
    if (response.error) {
      return {
        error: response.error,
      };
    }

    // Check if mission is done
    const messageId = response.data;
    for (let i = 0; i < MISSION_CHECK_LIMIT; i++) {
      const missionStatus = await requestCheckMissionProgress(messageId);
      if (missionStatus == null) {
        await sleep(MISSION_CHECK_INTERVAL);
        continue;
      }

      if (missionStatus?.data?.repeats >= mission.repeats) {
        setLoading(false);
        return {
          data: "Mission completed",
        };
      }
      setLoading(false);
      return {
        data: missionStatus.error,
      };
    }

    // Failed to check mission progress
    setLoading(false);
    return { data: "Request timeout" };
  };

  return { isLoading: loading, checkMission };
};
