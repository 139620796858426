import { RouteObject } from "react-router-dom";
import { navPaths } from "../../constants/navbar.constants";
import Purchase from "./Pages/Purchase";
import OpenBox from "./Pages/OpenBox";
import OpenMultiBox from "./Pages/OpenMultiBox";

export const purchaseRoute: RouteObject = {
  path: "",
  children: [
    { path: navPaths.PURCHASE, Component: Purchase },
    { path: navPaths.OPEN_PURCHASE_BOX, Component: OpenBox },
    { path: navPaths.OPEN_PURCHASED_MULTI_BOX, Component: OpenMultiBox },
  ],
};
