import React from "react";

const DisableTransferIc = () => {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M19.4167 28.4375C19.0792 28.4375 18.7667 28.25 18.6042 27.9625C18.4417 27.675 18.4417 27.3125 18.6167 27.025L19.9292 24.8375C20.1917 24.3875 20.7667 24.25 21.2167 24.5125C21.6667 24.775 21.8042 25.35 21.5417 25.8L21.2042 26.3625C24.6542 25.55 27.2417 22.45 27.2417 18.75C27.2417 18.2375 27.6667 17.8125 28.1792 17.8125C28.6917 17.8125 29.1167 18.2375 29.1167 18.75C29.1042 24.0875 24.7542 28.4375 19.4167 28.4375Z"
        fill="#292D32"
      />
      <path
        opacity="0.4"
        d="M3.1665 12.1875C2.654 12.1875 2.229 11.7625 2.229 11.25C2.229 5.9125 6.579 1.5625 11.9165 1.5625C12.254 1.5625 12.5665 1.75001 12.729 2.03751C12.8915 2.32501 12.8915 2.68751 12.7165 2.97501L11.404 5.16251C11.1415 5.61251 10.5665 5.75002 10.1165 5.48752C9.6665 5.22502 9.52901 4.64998 9.79151 4.19998L10.129 3.63747C6.67901 4.44997 4.09151 7.55 4.09151 11.25C4.10401 11.7625 3.679 12.1875 3.1665 12.1875Z"
        fill="#292D32"
      />
      <path
        d="M14.0037 17.3125L10.0787 15.2C9.6662 14.975 9.1662 14.975 8.7537 15.2L4.8287 17.3125C4.5412 17.4625 4.36621 17.775 4.36621 18.1125C4.36621 18.45 4.5412 18.7625 4.8287 18.9125L8.7537 21.025C8.9662 21.1375 9.1912 21.1875 9.4162 21.1875C9.6412 21.1875 9.8662 21.1375 10.0787 21.025L14.0037 18.9125C14.2912 18.7625 14.4662 18.45 14.4662 18.1125C14.4662 17.775 14.2787 17.475 14.0037 17.3125Z"
        fill="#292D32"
      />
      <path
        d="M8.1038 21.7623L4.4538 19.9373C4.1788 19.7998 3.8538 19.8123 3.5788 19.9748C3.3163 20.1373 3.15381 20.4248 3.15381 20.7373V24.1873C3.15381 24.7873 3.47879 25.3248 4.01629 25.5873L7.66631 27.4123C7.79131 27.4748 7.9288 27.5123 8.0663 27.5123C8.2288 27.5123 8.39131 27.4623 8.54131 27.3748C8.80381 27.2123 8.9663 26.9248 8.9663 26.6123V23.1623C8.9788 22.5623 8.6413 22.0248 8.1038 21.7623Z"
        fill="#292D32"
      />
      <path
        d="M15.2415 19.9748C14.979 19.8123 14.654 19.7998 14.3665 19.9373L10.7165 21.7623C10.179 22.0248 9.854 22.5623 9.854 23.1623V26.6123C9.854 26.9248 10.0165 27.2123 10.279 27.3748C10.429 27.4623 10.5915 27.5123 10.754 27.5123C10.8915 27.5123 11.029 27.4748 11.154 27.4123L14.804 25.5873C15.3415 25.3248 15.6665 24.7873 15.6665 24.1873V20.7373C15.6665 20.4248 15.504 20.1373 15.2415 19.9748Z"
        fill="#292D32"
      />
      <path
        d="M26.5037 4.78711L22.5787 2.67461C22.1662 2.44961 21.6662 2.44961 21.2537 2.67461L17.3287 4.78711C17.0412 4.93711 16.8662 5.24955 16.8662 5.58705C16.8662 5.92455 17.0412 6.23707 17.3287 6.38707L21.2537 8.49958C21.4662 8.61208 21.6912 8.66208 21.9162 8.66208C22.1412 8.66208 22.3662 8.61208 22.5787 8.49958L26.5037 6.38707C26.7912 6.23707 26.9662 5.92455 26.9662 5.58705C26.9662 5.23705 26.7787 4.93711 26.5037 4.78711Z"
        fill="#292D32"
      />
      <path
        d="M20.6038 9.22423L16.9538 7.3992C16.6788 7.2617 16.3538 7.27424 16.0788 7.43674C15.8163 7.59924 15.6538 7.88672 15.6538 8.19922V11.6492C15.6538 12.2492 15.9788 12.7867 16.5163 13.0492L20.1663 14.8743C20.2913 14.9368 20.4288 14.9742 20.5663 14.9742C20.7288 14.9742 20.8913 14.9242 21.0413 14.8367C21.3038 14.6742 21.4663 14.3867 21.4663 14.0742V10.6242C21.4788 10.0242 21.1413 9.48673 20.6038 9.22423Z"
        fill="#292D32"
      />
      <path
        d="M27.7415 7.43674C27.479 7.27424 27.154 7.2617 26.8665 7.3992L23.2165 9.22423C22.679 9.48673 22.354 10.0242 22.354 10.6242V14.0742C22.354 14.3867 22.5165 14.6742 22.779 14.8367C22.929 14.9242 23.0915 14.9742 23.254 14.9742C23.3915 14.9742 23.529 14.9368 23.654 14.8743L27.304 13.0492C27.8415 12.7867 28.1665 12.2492 28.1665 11.6492V8.19922C28.1665 7.88672 28.004 7.59924 27.7415 7.43674Z"
        fill="#292D32"
      />
    </svg>
  );
};

export default DisableTransferIc;
