import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useState } from "react";
import { birdLevel } from "../../MysteryBox/constanst";

const RequirementSelector = ({ changeType }: { changeType: any }) => {
  const birds = Object.values(birdLevel);
  const [value, setValue] = useState(birds[0]);

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
    changeType(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={handleChange}
        sx={{
          border: "2px solid #A4BC82",
          borderRadius: "8px",
          "&:hover": {
            "&& fieldset": {
              border: "none",
            },
          },

          boxShadow: "none",
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: "none",
            },
        }}
        className="w-full h-[45px] bg-white text-[14px] font-semibold"
      >
        {birds.map((item: string) => {
          return (
            <MenuItem className="font-semibold text-sm" value={item}>
              <div className="flex items-center gap-1">
                <img
                  className="size-6"
                  src={`/images/bird/${item}.png`}
                  alt=""
                ></img>
                <p className="capitalize">{item}</p>
              </div>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default RequirementSelector;
