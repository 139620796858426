import clsx from "clsx";
import { motion } from "framer-motion";
import { wormBg, wormBorderColor, wormShadowColor } from "../constants";
import ModalContainer from "../../../components/common/ModalContainer";
import ConfirmBuyModal from "./ConfirmBuyModal";
import { useState } from "react";
import useBuyBox from "../Hooks/useBuyBox";
import { toast } from "react-toastify";
import { Box, CircularProgress, circularProgressClasses } from "@mui/material";
import usePurchaseHistory from "../Hooks/usePurchaseHistory";
import { sleep } from "../../../utils/time";
import { navPaths } from "../../../constants/navbar.constants";
import { useNavigate } from "react-router-dom";
import useOpenBox from "../Hooks/useOpenBox";

const SpecialItems = ({ packages }: { packages: any }) => {
  const navigate = useNavigate();
  const tele = window.Telegram.WebApp;
  const { buyBoxAsync, isBuyingBox } = useBuyBox();
  const { refeshPurchaseHistory } = usePurchaseHistory();
  const { openBoxAsync } = useOpenBox();

  const [showConfirmModal, setShowConfirmModal] = useState<{
    isOpen: boolean;
    item: any;
  }>({
    isOpen: false,
    item: null,
  });
  const [isBuyingPackage, setIsBuyingPackage] = useState<boolean>(false);

  const handleBuy = (id: string) => {
    buyBoxAsync(id)
      .then((data) => {
        setShowConfirmModal({ isOpen: false, item: null });
        tele.openInvoice(data?.data?.data, async (status: string) => {
          if (status === "paid") {
            setIsBuyingPackage(true);
            for (let i = 0; i <= 5; i++) {
              const refresedData = (await refeshPurchaseHistory()).data;

              const buyWormSuccess =
                refresedData?.length > 0 &&
                !refresedData?.[0]?.is_opened &&
                refresedData?.[0]?.transaction_id != null;

              if (buyWormSuccess) {
                openBoxAsync(refresedData?.[0]?.id)
                  .then(() => {
                    navigate(navPaths.INVENTORY, { state: { showWorm: true } });
                  })
                  .catch((error) => {
                    toast.error(error?.response?.data?.message, {
                      style: { maxWidth: 337, height: 40, borderRadius: 8 },
                      autoClose: 2000,
                    });
                  })
                  .finally(() => {
                    setIsBuyingPackage(false);
                  });
                return;
              }
              await sleep(2000);
            }

            toast.error(
              "Worm package might be delayed. Contact us if needed.",
              {
                style: { maxWidth: 337, height: 40, borderRadius: 8 },
                autoClose: 2000,
              }
            );
          }
        });
      })
      .catch((error) => {
        setIsBuyingPackage(false);
        toast.error(error?.response?.data?.message, {
          style: { maxWidth: 337, height: 50, borderRadius: 8 },
          autoClose: 2000,
        });
      })
      .finally(() => {
        setIsBuyingPackage(false);
      });
  };

  return (
    <div>
      {(isBuyingBox || isBuyingPackage) && (
        <div
          className={clsx(
            "flex pointer-events-none justify-center items-center h-screen w-screen bg-[#00000099] absolute left-0 right-0 top-0 z-40"
          )}
        >
          <Box sx={{ position: "relative" }}>
            <CircularProgress
              variant="determinate"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light" ? "#9BD449" : "#9BD449",
                borderRadius: "20px",
              }}
              size={56}
              thickness={6}
              value={100}
            />
            <CircularProgress
              variant="indeterminate"
              disableShrink
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light" ? "#F2FFE0" : "#F2FFE0",
                animationDuration: "550ms",
                position: "absolute",
                left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: "round",
                },
              }}
              size={56}
              thickness={6}
            />
          </Box>
        </div>
      )}
      <div className="bg-[#F3F3F3] w-full py-[10px] text-[12px] text-center rounded-lg poppins-regular mb-2">
        Tip for buying stars at the lowest price (via{" "}
        <span
          onClick={() =>
            tele.showPopup(
              {
                message: `Do you want to open link`,
                buttons: [
                  { id: "link", type: "default", text: "Open" },
                  { type: "cancel" },
                ],
              },
              async function (btn: any) {
                if (btn === "link") {
                  tele.openLink("https://fragment.com/stars");
                } else {
                }
              }
            )
          }
          className="text-[#2a9c28] poppins-regular"
        >
          Fragment
        </span>
        )
      </div>
      <div className="mb-3 font-semibold flex items-center justify-start gap-1">
        <div>
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.87725 0.982442C7.07762 0.339185 7.92238 0.339187 8.12275 0.982441L9.10026 4.12059C9.3167 4.81545 9.94621 5.30564 10.6778 5.30564H13.841C14.1468 5.30564 14.3744 5.50101 14.4638 5.78808C14.5536 6.07647 14.4808 6.39146 14.219 6.58991L11.6598 8.5294C11.0812 8.9679 10.8487 9.73374 11.0642 10.4256L12.0417 13.5638C12.1472 13.9025 12.0177 14.1982 11.7969 14.3656C11.5792 14.5306 11.2953 14.5583 11.0411 14.3656L8.48192 12.4261C7.89791 11.9835 7.1021 11.9835 6.51808 12.4261L3.95893 14.3656C3.70472 14.5583 3.42083 14.5306 3.20311 14.3656C2.98227 14.1982 2.85277 13.9025 2.95827 13.5638L3.93578 10.4256C4.15129 9.73374 3.9188 8.9679 3.34019 8.5294L0.781044 6.58991C0.519179 6.39146 0.446377 6.07647 0.536209 5.78808C0.625629 5.50101 0.853247 5.30564 1.15896 5.30564H4.32224C5.05379 5.30564 5.6833 4.81545 5.89974 4.12059L6.87725 0.982442Z"
              stroke="black"
            />
          </svg>
        </div>
        <p className="text-[18px]">Worm Package</p>
      </div>
      <div className="grid grid-cols-3 gap-4 gap-y-4">
        {packages?.map((item: any) => {
          const type = item.name.toLowerCase();
          const quantity = item.worm_items[0].amount;
          const price = item.price;

          return (
            <div className="w-full relative">
              <motion.div
                className={clsx(
                  "max-h-[230px]  rounded-[8px] px-[4px] pb-[5px] flex flex-col"
                )}
                style={{
                  border: `2px solid ${wormBorderColor[type]}`,
                  boxShadow: `0px 4px 0px ${wormShadowColor[type]}`,
                  background: `radial-gradient(circle, rgba(242,242,242,1) 0%, ${wormBg[type]} 40%)`,
                }}
              >
                <div className="rounded-b-[4px] px-2 mx-2 lsm:mx-[10px] flex items-center justify-center bg-[#00000080] text-white">
                  <div className="text-[11px] font-bold capitalize">{type}</div>
                </div>

                <div className="flex flex-col items-center justify-center relative">
                  <img
                    src={`/images/inventory/worm/${type}.png`}
                    alt={"market-item"}
                    className="h-[60px] w-[77px] object-contain"
                  />
                  <div className="absolute bg-[#00000080] text-white rounded-[10px] text-[10px] px-[14px] bottom-0">
                    {`x${quantity}`}
                  </div>
                </div>
                <div
                  onClick={() => {
                    setShowConfirmModal({ isOpen: true, item: item });
                  }}
                  className="flex justify-center items-center gap-[2px] bg-[#fff] shadow-[0_2px_0_#4F4F4F] rounded-[5px] mt-2"
                >
                  <div>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.20469 0.984153C6.51739 0.297151 7.49133 0.291697 7.8117 0.975154L9.01063 3.53286C9.22025 3.98005 9.64465 4.28842 10.1347 4.34961L13.0314 4.71134C13.8065 4.80813 14.0854 5.78832 13.4773 6.27864L11.4374 7.92346C10.9946 8.28053 10.8038 8.86506 10.9508 9.41462L11.6791 12.1385C11.8841 12.9055 11.0537 13.5337 10.3714 13.1278L7.73393 11.5584C7.2807 11.2887 6.71622 11.2887 6.26298 11.5584L3.83171 13.0051C3.12321 13.4266 2.27384 12.7358 2.54222 11.9563L2.88709 10.9546C3.23222 9.95211 3.94335 9.11706 4.87804 8.61666L6.52498 7.73495C6.7413 7.61915 6.63643 7.28976 6.39298 7.32032L4.68214 7.53511C3.65291 7.66433 2.61225 7.40849 1.76026 6.8168L0.991117 6.28265C0.325345 5.82029 0.579675 4.78055 1.3837 4.67771L3.92291 4.35294C4.41697 4.28975 4.84317 3.97544 5.04951 3.52211L6.20469 0.984153Z"
                        fill="url(#paint0_linear_9164_1170)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_9164_1170"
                          x1="10.6006"
                          y1="1.58398"
                          x2="3.7905"
                          y2="13.6908"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#FCFF4C" />
                          <stop offset="1" stop-color="#FE6D04" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <p className="font-semibold text-[12px]">{price}</p>
                </div>
              </motion.div>
            </div>
          );
        })}
      </div>
      <ModalContainer>
        {showConfirmModal.isOpen && (
          <ConfirmBuyModal
            handleBuy={(id: string) => handleBuy(id)}
            item={showConfirmModal.item}
            handleCloseModal={() =>
              setShowConfirmModal({ isOpen: false, item: null })
            }
          />
        )}
      </ModalContainer>
    </div>
  );
};

export default SpecialItems;
