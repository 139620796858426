import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerError, ServerResponse } from "../../../types/api";
import { Result } from "../../../types/utils";

export const getEggDetail = async ({
  id,
}: {
  id: string;
}): Promise<Result<any[], ServerError>> => {
  const response = await fetchApi("GET", `/egg/${id}`);
  if (response.error) {
    // Throw to retry
    throw new Error("network-error");
  }

  const eggDetailResponse: ServerResponse<any[]> = await response.data.json();
  if (eggDetailResponse.error) {
    return {
      error: eggDetailResponse.error,
    };
  }

  return {
    data: eggDetailResponse.data,
  };
};

export default function useGetEggDetail({ id }: { id: string }) {
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["getEggDetail"],
    queryFn: () => getEggDetail({ id }),
    retry: 1,
  });

  return {
    eggDetail: data,
    isLoadingEggDetail: isFetching,
    refreshEggDetail: refetch,
  };
}
