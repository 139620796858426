export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const isSameDay = (data: any) => {
  const now = new Date().getTime();
  const claimedDate =
    new Date(data[0]?.timestamp).getUTCFullYear() +
    "-" +
    (new Date(data[0]?.timestamp).getUTCMonth() + 1) +
    "-" +
    new Date(data[0]?.timestamp).getUTCDate();

  return new Date(now).getTime() > new Date(claimedDate).getTime() + 86400000;
};

export const checkSameDayV2 = (timestamp: string) => {
  if (!timestamp) {
    return false;
  }

  const today = new Date();
  const time = new Date(timestamp);

  return (
    today.getUTCFullYear() === time.getUTCFullYear() &&
    today.getUTCMonth() === time.getUTCMonth() &&
    today.getUTCDate() === time.getUTCDate()
  );
};

export const checkSameDay = (data: any) => {
  if (!data?.length || !data[0] || !data[0].timestamp) {
    return false;
  }

  const date = new Date();
  const d = new Date(data[0].timestamp);
  return (
    date.getUTCFullYear() === d.getUTCFullYear() &&
    date.getUTCMonth() === d.getUTCMonth() &&
    date.getUTCDate() === d.getUTCDate()
  );
};

export const getMobileOS = () => {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return "Android";
  } else if (
    /iPad|iPhone|iPod/.test(ua) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  ) {
    return "iOS";
  }
  return "Other";
};

export const renderErrMessage = (errMessage: string) => {
  if (!errMessage) {
    return "Something went wrong";
  }
  return errMessage === "incomplete task" ? "Incomplete mission" : errMessage;
};

export const EGG_STATUS = {
  ON_MARKET: "on-market",
  BOUGHT: "bought",
  REMOVED: "removed",
};

export const MARKET_ITEM_STATUS = {
  ON_SALE: "on-sale",
  BOUGHT: "bought",
  REMOVED: "removed",
};

export const INVENTORY_STATUS = {
  ON_MARKET: "on-market",
  IN_INVENTORY: "in-inventory",
};

export const MARKET_HISTORY_STATUS = {
  ON_SALE: "on-sale",
  BOUGHT: "bought",
  REMOVED: "removed",
};

export const MARKET_HISTORY_STATUS_DISPLAY = {
  LISTING: "listing",
  SOLD: "sold",
  UNLISTED: "unlisted",
  BOUGHT: "bought",
};

export const MARKET_HISTORY_ACTION = {
  BUY: "buy",
  SELL: "sell",
  GIFT: "gift",
};

export const areEqual = (arr1: any[], arr2: any[]) => {
  // let N = arr1.length;
  // let M = arr2.length;

  // // If lengths of array are not equal means
  // // array are not equal
  // if (N != M) return false;

  // // Sort both arrays
  // arr1.sort();
  // arr2.sort();

  // // Linearly compare elements
  // for (let i = 0; i < N; i++) if (arr1[i] != arr2[i]) return false;

  // // If all elements were same.
  // return true;
  let result;
  // eslint-disable-next-line array-callback-return
  arr1?.map((item) => {
    // eslint-disable-next-line array-callback-return
    arr2?.map((item2) => {
      if (item?.id === item2?.id) {
        result = false;
        // return item
      } else {
        result = true;
        // return item
      }
    });
  });

  return result;
};

export const removeDuplicate = (arr: any) => {
  const map: Record<string, boolean> = {};

  return arr?.filter((value: any) => {
    if (!map[value.id]) {
      map[value.id] = true;
      return true;
    }

    return false;
  });
};

export const debounce = (fn: (arg0: any) => void, timer: number) => {
  let timeout: any;
  return function (args?: any) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn(args);
    }, timer);
  };
};

export async function retry<T>(
  fn: () => Promise<T>,
  options: { retries: number; delay: number },
): Promise<T> {
  let lastError: Error | undefined;
  for (let i = 0; i < options.retries; i++) {
    try {
      return await fn();
    } catch (e) {
      if (e instanceof Error) {
        lastError = e;
      }
      await new Promise((resolve) => setTimeout(resolve, options.delay));
    }
  }
  throw lastError;
}
