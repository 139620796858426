import clsx from "clsx";
import { CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import { removeDuplicateItemsByType } from "../../Missions/Utils/missions";
import { useMemo } from "react";
import { formatDecimals } from "../../../utils/formatNumber";

const FeedingItemList = ({
  feedingRate,
  feedingItems,
  quantities,
  handleChangeQuantity,
}: {
  feedingRate: any;
  feedingItems: any;
  quantities: any;
  handleChangeQuantity: (item: any, action: string) => void;
}) => {
  const WormsItems = useMemo(() => {
    if (!feedingItems?.length) {
      return [];
    }

    const countItem = feedingItems?.reduce((count: any, obj: any) => {
      count[obj.type] = (count[obj.type] || 0) + 1;
      return count;
    }, {});

    const worms: any[] = removeDuplicateItemsByType(feedingItems)?.map(
      (item) => {
        return {
          ...item,
          imgUrl: `/images/inventory/worm/${item.type}.png?v=5`,
          quantity: countItem[item.type],
          price: item.reward,
        };
      }
    );

    return worms;
  }, [feedingItems]);

  return (
    <>
      {WormsItems?.length > 0 && (
        <div className=" h-full overflow-auto pb-2">
          <div
            className={clsx(
              "grid grid-cols-2 pt-4 px-4 gap-3 gap-y-4 lsm:gap-4 overflow-visible",
              false && "pointer-events-none"
            )}
          >
            {WormsItems?.map((item: any, index: number) => {
              return (
                <div key={index} className=" relative pb-2">
                  <motion.div
                    className={clsx(
                      "border-[2px] max-h-[250px] border-[#8F8F8F] rounded-[12px] px-[7px] lsm:px-[11px] pb-[11px] bg-gradient-to-b from-[#F8FFEF] to-[#E5FFC0] flex flex-col gap-2 drop-shadow-[0_4px_0px_#424242]"
                    )}
                  >
                    <div
                      className={clsx(
                        "rounded-[16px] px-1 py-[2px] flex items-center gap-1",
                        "bg-[#61665A] absolute top-1 right-1"
                      )}
                    >
                      <div className="border-[1px]  rounded-[50%] w-[18px] h-[18px] flex items-center justify-center">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_5674_2553)">
                            <g clipPath="url(#clip1_5674_2553)">
                              <path
                                d="M7.94862 15.7137C12.3228 15.7137 15.8687 12.2599 15.8687 7.99944C15.8687 3.73896 12.3228 0.285156 7.94862 0.285156C3.5745 0.285156 0.0285645 3.73896 0.0285645 7.99944C0.0285645 12.2599 3.5745 15.7137 7.94862 15.7137Z"
                                fill="#E3E3E3"
                              />
                              <path
                                d="M8.24723 6.67668C5.29662 7.13048 3.27778 3.65151 0.171875 6.5046V7.88669L8.55782 7.88671L8.24723 6.67668Z"
                                fill="#00C7B5"
                                fillOpacity="0.6"
                              />
                              <path
                                d="M8.24712 6.67793C5.00748 8.25577 1.46589 7.08136 0.171762 6.52674C-0.138813 7.58556 -0.138818 10.0057 1.25884 12.1234C2.67604 14.2706 4.93416 15.2494 5.9177 15.4511C7.31535 15.8545 10.7629 16.0562 13.3719 13.636C15.9808 11.2158 16.1672 8.03938 15.7013 6.3753C14.6142 5.82068 12.2848 4.71142 8.24712 6.67793Z"
                                fill="#00C7B5"
                              />
                            </g>
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M11.6424 4.68589C11.0783 5.13399 10.1613 6.06965 10.0243 5.93605C9.91649 5.831 10.8466 4.90016 11.3482 4.42431C11.8236 3.97332 11.4919 4.02737 11.3587 4.12793C10.9352 4.44804 9.31549 5.5428 9.2106 6.11247C9.16532 6.35865 9.27021 6.7349 9.20023 6.9033C9.04364 7.28004 6.94342 9.04534 5.88632 9.87884C4.82921 10.7123 3.57748 11.483 4.21059 12.0998C4.8437 12.7164 5.63505 11.4972 6.49078 10.4676C7.34651 9.43795 9.15874 7.3923 9.54568 7.23978C9.71841 7.17162 10.1047 7.27378 10.3576 7.22968C10.9425 7.12752 12.0663 5.54986 12.3951 5.13736C12.4985 5.00761 12.5538 4.6846 12.091 5.14762C11.6024 5.63614 10.6468 6.54197 10.5389 6.43692C10.4009 6.30252 11.3652 5.40888 11.8213 4.8599C12.21 4.39191 12.1259 4.30226 11.6428 4.68589"
                              fill="#005A53"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M11.7963 12.0872C12.4294 11.4705 11.1617 10.6842 10.0898 9.83644C9.01788 8.98851 6.88737 7.19369 6.7265 6.81279C6.56579 6.43189 7.06734 5.77128 6.5271 4.96457C5.98669 4.15786 4.56273 3.69596 3.87199 4.36876C3.18124 5.04155 3.65563 6.42852 4.48369 6.95489C5.31176 7.48125 5.99015 6.99257 6.38121 7.14927C6.77228 7.30596 8.61497 9.38112 9.48551 10.4252C10.3561 11.4693 11.1632 12.704 11.7963 12.0874"
                              fill="#005A53"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_5674_2553">
                              <rect
                                x="0.0285645"
                                y="0.285156"
                                width="15.8401"
                                height="15.4286"
                                rx="7.71429"
                                fill="white"
                              />
                            </clipPath>
                            <clipPath id="clip1_5674_2553">
                              <rect
                                width="15.8401"
                                height="15.4286"
                                fill="white"
                                transform="translate(0.0285645 0.285156)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <p className="text-[#fff]">
                        {formatDecimals(feedingRate[item?.type])}
                      </p>
                    </div>
                    <div className="flex flex-col items-center justify-center relative">
                      <img
                        src={`/images/inventory/worm/${item?.type}.png`}
                        alt={"market-item"}
                        className="h-[130px] w-[70%] object-contain"
                      />
                      <div className="flex justify-center -mt-1">
                        <div className="px-[3px] py-[3px] w-fit flex items-center gap-1 text-sm pr-2 uppercase">
                          <div className="w-[16px] h-[16px] rounded-full bg-black flex items-center justify-center text-white">
                            #
                          </div>
                          {item?.id?.slice(
                            item?.id?.length - 9,
                            item?.id?.length
                          )}
                        </div>
                      </div>

                      <div className="flex items-center relative w-[88px] h-[22px] mt-4">
                        <button
                          onClick={() => {
                            handleChangeQuantity(item, "minus");
                          }}
                          className="absolute z-10 -left-3 border-[2px] bg-[#fff] border-[#4B7E08CC] rounded-[50%] w-[30px] h-[30px] flex items-center justify-center"
                        >
                          <svg
                            width="14"
                            height="2"
                            viewBox="0 0 14 2"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.5 1C13.5 1.4375 13.1562 1.75 12.75 1.75L1.25 1.75C0.8125 1.75 0.5 1.4375 0.5 1.03125C0.5 0.59375 0.8125 0.25 1.25 0.25L12.75 0.25C13.1562 0.25 13.5 0.59375 13.5 1Z"
                              fill="#6D9833"
                            />
                          </svg>
                        </button>
                        <div className="absolute text-center w-full py-1 bg-[#fff] border-[1px] border-[#AAC18B] text-[12px]">
                          {`${quantities[item.type]} of ${item.quantity}`}
                        </div>
                        <button
                          onClick={() => handleChangeQuantity(item, "add")}
                          className="absolute -right-3 z-1 bg-[#4B7E08] rounded-[50%] w-[30px] h-[30px] flex items-center justify-center"
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.5 7C13.5 7.4375 13.1562 7.75 12.75 7.75L7.75 7.75L7.75 12.75C7.75 13.1875 7.40625 13.5313 7 13.5313C6.5625 13.5313 6.25 13.1875 6.25 12.75L6.25 7.75L1.25 7.75C0.8125 7.75 0.5 7.4375 0.5 7.03125C0.5 6.59375 0.8125 6.25 1.25 6.25L6.25 6.25L6.25 1.25C6.25 0.84375 6.5625 0.53125 7 0.53125C7.40625 0.53125 7.75 0.84375 7.75 1.25L7.75 6.25L12.75 6.25C13.1562 6.25 13.5 6.59375 13.5 7Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </motion.div>
                </div>
              );
            })}

            {false && (
              <div className=" backdrop-blur-sm fixed inset-0 flex items-center justify-center z-50 bg-black/50">
                <CircularProgress style={{ color: "#F2FFE0" }} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default FeedingItemList;
