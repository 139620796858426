import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import clsx from "clsx";
import { motion } from "framer-motion";
import { Dispatch, SetStateAction } from "react";
import Backdrop from "../../../components/common/Backdrop";
import { dropIn } from "../../../components/common/ModalContainer";
import { useBirdStore } from "../../../store/birdStore";

const ReleaseModal = ({
  setShowReleaseModal,
  handleReleaseBird,
  isLoading,
}: {
  setShowReleaseModal: Dispatch<SetStateAction<boolean>>;
  handleReleaseBird: () => void;
  isLoading: boolean;
}) => {
  const birdInCage = useBirdStore((state: any) => state.birdInCage);
  const hideModal = () => setShowReleaseModal(false);

  const handleRelease = () => {
    if (!birdInCage?.id) {
      return;
    }
    handleReleaseBird();
  };

  return (
    <Backdrop onClick={hideModal}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="release-modal"
      >
        <div
          className={clsx(
            "bg-[#F2FFE0] rounded-[20px] p-[24px] flex flex-col gap-4 items-center justify-center relative",
          )}
        >
          {/* close btn  */}
          <div
            onClick={hideModal}
            className=" absolute -right-3 -top-5 btn-hover bg-white rounded-full drop-shadow-[0_4px_0px_#4C7E0B]"
          >
            <svg
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23 46C10.2422 46 0 35.7578 0 23C0 10.332 10.2422 0 23 0C35.668 0 46 10.332 46 23C46 35.7578 35.668 46 23 46ZM15.7227 15.7227C14.8242 16.6211 14.8242 17.9688 15.7227 18.7773L19.9453 23L15.7227 27.2227C14.8242 28.1211 14.8242 29.4688 15.7227 30.2773C16.5312 31.1758 17.8789 31.1758 18.6875 30.2773L22.9102 26.0547L27.1328 30.2773C28.0312 31.1758 29.3789 31.1758 30.1875 30.2773C31.0859 29.4688 31.0859 28.1211 30.1875 27.2227L25.9648 23L30.1875 18.7773C31.0859 17.9688 31.0859 16.6211 30.1875 15.7227C29.3789 14.9141 28.0312 14.9141 27.1328 15.7227L22.9102 19.9453L18.6875 15.7227C17.8789 14.9141 16.5312 14.9141 15.7227 15.7227Z"
                fill="url(#paint0_linear_3658_4024)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3658_4024"
                  x1="6.9"
                  y1="6.9"
                  x2="34.5"
                  y2="41.975"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#97C35B" />
                  <stop offset="1" stopColor="#63A803" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <span className="font-bold text-[20px]">Stand with us</span>
          <span className="text-[16px] text-center">
            Are you sure you wanna join us in
            <br />
            #WingsofFreedom #FreeDurov campaign?
          </span>

          <div className="mt-2 flex items-center gap-3 w-full">
            <Button
              sx={{ border: 1 }}
              onClick={hideModal}
              className={clsx(
                "font-bold capitalize text-[16px] py-2 lsm:py-[18px] w-full h-[46px] rounded-xl ",
                "btn-hover bg-[#F2FFE0] drop-shadow-[0_3px_0px_#4C7E0B] text-[#4C7E0B] border-[2px]",
              )}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isLoading}
              onClick={handleRelease}
              sx={{ border: 1 }}
              className={clsx(
                "font-bold capitalize text-[16px] text-white py-2 lsm:py-[18px] w-full h-[46px] rounded-xl ",
                "btn-hover bg-gradient-to-r from-[#97C35B] to-[#61A700] drop-shadow-[0_4px_0px_#4C7E0B] border-none",
              )}
            >
              Release
            </LoadingButton>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default ReleaseModal;
