import clsx from "clsx";
import React, { ReactNode } from "react";

const BaseBtn = ({
  children,
  onClick,
  className,
}: {
  children: ReactNode;
  onClick?: () => void;
  className: string;
}) => {
  return (
    <button
      onClick={() => !!onClick && onClick()}
      className={clsx(
        "rounded-[4px] border-[1px] flex items-center justify-center poppins-regular",
        className
      )}
    >
      {children}
    </button>
  );
};

export default BaseBtn;
