import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function leaveGuild({ guild_id }: { guild_id: string }) {
  return await api.post("/api/v1/guild/leave", { guild_id });
}

export default function useLeaveGuild() {
  return useMutation({
    mutationKey: ["leaveGuild"],
    mutationFn: leaveGuild,
  });
}
