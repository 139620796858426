import clsx from "clsx";
import { formatHourMins } from "../../../utils/time";

const MessageItem = ({
  data,
  number,
  openMessage,
}: {
  data: any;
  number: number;
  openMessage: () => void;
}) => {
  return (
    <div
      onClick={() => openMessage()}
      className={clsx(
        "grid grid-cols-12 w-full border-b-[1px] border-[#CDDABC] cursor-pointer",
        "bg-transparent"
      )}
    >
      <div
        className={clsx(
          "col-span-3 flex flex-col items-center justify-center",
          "py-5 px-2 "
        )}
      >
        <svg
          width="16"
          height="20"
          viewBox="0 0 16 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.96559 19.0297C6.73102 19.0297 5.74336 18.0421 5.74336 16.7722H10.2584C10.2584 18.0421 9.23543 19.0297 7.96559 19.0297ZM15.5846 13.774C15.7963 13.9856 15.9021 14.2678 15.9021 14.5147C15.8668 15.1144 15.4436 15.6435 14.7381 15.6435H1.22836C0.558164 15.6435 0.0996094 15.1144 0.0996094 14.5147C0.0996094 14.2678 0.170156 13.9856 0.381797 13.774C1.05199 13.0332 2.35711 11.9398 2.35711 8.3066C2.35711 5.59055 4.26187 3.40359 6.87211 2.83922V2.09848C6.87211 1.49883 7.36594 0.969727 8.00086 0.969727C8.60051 0.969727 9.12961 1.49883 9.12961 2.09848V2.83922C11.7046 3.40359 13.6093 5.59055 13.6093 8.3066C13.6093 11.9398 14.9145 13.0332 15.5846 13.774Z"
            fill="#626262"
          />
        </svg>
        <p className={clsx("text-[12px]")}>
          {formatHourMins(data?.created_at)}
        </p>
      </div>

      <div
        className={clsx(
          "col-span-9 flex items-center w-full",
          "py-5 text-sm",
          "font-normal text-[#767676]"
        )}
      >
        <p className="overflow-hidden truncate">{data?.content ?? ""}</p>
      </div>
    </div>
  );
};

export default MessageItem;
