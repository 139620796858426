import clsx from "clsx";
import React from "react";

const RuleSection = () => {
  return (
    <div>
      <div className="pt-2 mb-3 font-semibold flex items-center justify-start gap-1">
        <div>
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.87725 0.982442C7.07762 0.339185 7.92238 0.339187 8.12275 0.982441L9.10026 4.12059C9.3167 4.81545 9.94621 5.30564 10.6778 5.30564H13.841C14.1468 5.30564 14.3744 5.50101 14.4638 5.78808C14.5536 6.07647 14.4808 6.39146 14.219 6.58991L11.6598 8.5294C11.0812 8.9679 10.8487 9.73374 11.0642 10.4256L12.0417 13.5638C12.1472 13.9025 12.0177 14.1982 11.7969 14.3656C11.5792 14.5306 11.2953 14.5583 11.0411 14.3656L8.48192 12.4261C7.89791 11.9835 7.1021 11.9835 6.51808 12.4261L3.95893 14.3656C3.70472 14.5583 3.42083 14.5306 3.20311 14.3656C2.98227 14.1982 2.85277 13.9025 2.95827 13.5638L3.93578 10.4256C4.15129 9.73374 3.9188 8.9679 3.34019 8.5294L0.781044 6.58991C0.519179 6.39146 0.446377 6.07647 0.536209 5.78808C0.625629 5.50101 0.853247 5.30564 1.15896 5.30564H4.32224C5.05379 5.30564 5.6833 4.81545 5.89974 4.12059L6.87725 0.982442Z"
              stroke="black"
            />
          </svg>
        </div>
        <p className="text-[18px] font-semibold">Referral Rules</p>
      </div>
      <div className={clsx("mb-3 rounded-xl")}>
        <div
          className={clsx(
            "relative z-20 border-[1px] border-[#F3F3F3] rounded-xl px-3 bg-[#fff] "
          )}
        >
          <div
            className={clsx(
              " z-10 relative cursor-pointer bg-white p-3 w-full ",
              "border-b-[1px] border-[#F3F3F3] border-solid"
            )}
          >
            <div className="text-[14px] font-medium mb-[6px] flex items-center gap-[6px]">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.08296 0.879807C7.38712 0.167017 8.39558 0.161608 8.70736 0.871095L10.2023 4.27299C10.4043 4.73268 10.8315 5.05374 11.3292 5.12004L15.0426 5.61466C15.8002 5.71557 16.0823 6.66467 15.5029 7.16306L12.7327 9.54567C12.3201 9.90055 12.1431 10.4579 12.2754 10.9858L13.2439 14.8494C13.4383 15.625 12.5861 16.2404 11.911 15.8119L8.65585 13.7458C8.1854 13.4472 7.58481 13.4472 7.11436 13.7458L4.05676 15.6865C3.35719 16.1305 2.48563 15.4558 2.74014 14.6673L3.36315 12.7371C3.68526 11.7391 4.36868 10.8975 5.27931 10.3775L7.55226 9.07956C7.76327 8.95906 7.654 8.63584 7.41316 8.66809L5.17112 8.96833C4.08716 9.11349 2.9893 8.83056 2.11052 8.17958L0.7472 7.16967C0.109364 6.69718 0.367916 5.68876 1.15441 5.58146L4.51086 5.12355C5.0122 5.05515 5.44073 4.72834 5.63932 4.26295L7.08296 0.879807Z"
                  fill="url(#paint0_linear_9052_1678)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_9052_1678"
                    x1="11.9375"
                    y1="1.8125"
                    x2="3.5"
                    y2="15.875"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FCFF4C" />
                    <stop offset="1" stop-color="#FE8B04" />
                  </linearGradient>
                </defs>
              </svg>
              Invite a friend
            </div>
            <p className="text-[12px] flex items-center gap-1 flex-wrap">
              You will both get
              <span className="flex items-center gap-1 font-semibold">
                2
                <img
                  src="/images/icons/token_icon.png?v=3"
                  width={12}
                  height={12}
                  alt="token"
                ></img>
              </span>
              & <b>10%</b> of your friend’s reward
            </p>
          </div>
          <div
            className={clsx(
              " z-10 relative cursor-pointer bg-white p-3 w-full "
            )}
          >
            <div className="text-[14px] font-medium mb-[6px] flex items-center gap-[6px]">
              <svg
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.44922 2.14844L10.0078 5.29297C10.2539 5.78516 10.9102 5.92188 11.3203 5.59375L13.7812 3.625C13.6445 3.46094 13.5625 3.21484 13.5625 2.96875C13.5625 2.36719 14.0273 1.875 14.6562 1.875C15.2578 1.875 15.75 2.36719 15.75 2.96875C15.75 3.59766 15.2578 4.0625 14.6562 4.0625C14.6289 4.0625 14.6289 4.0625 14.6289 4.0625L13.3711 10.9531C13.2344 11.7734 12.4961 12.375 11.6484 12.375H4.07422C3.22656 12.375 2.48828 11.7734 2.35156 10.9531L1.09375 4.0625C0.464844 4.0625 0 3.59766 0 2.96875C0 2.36719 0.464844 1.875 1.09375 1.875C1.69531 1.875 2.1875 2.36719 2.1875 2.96875C2.1875 3.21484 2.10547 3.46094 1.96875 3.625L4.40234 5.59375C4.83984 5.92188 5.46875 5.78516 5.71484 5.29297L7.30078 2.14844C6.97266 1.95703 6.78125 1.62891 6.78125 1.21875C6.78125 0.617188 7.24609 0.125 7.875 0.125C8.47656 0.125 8.96875 0.617188 8.96875 1.21875C8.96875 1.62891 8.75 1.95703 8.44922 2.14844Z"
                  fill="url(#paint0_linear_9052_1695)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_9052_1695"
                    x1="8"
                    y1="-0.5"
                    x2="8"
                    y2="13.5"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FF8F07" />
                    <stop offset="1" stop-color="#FDF55C" />
                  </linearGradient>
                </defs>
              </svg>
              Invite a friend with telegram premium
            </div>
            <p className="text-[12px] flex items-center gap-1 flex-wrap">
              You will both get{` `}
              <span className="flex items-center gap-1 font-semibold">
                10
                <img
                  src="/images/icons/token_icon.png?v=3"
                  width={12}
                  height={12}
                  alt="token"
                ></img>
              </span>{" "}
              & <span className="font-semibold">20%</span> of your friend’s
              reward
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RuleSection;
