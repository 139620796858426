import { Button, Modal } from "@mui/material";
import clsx from "clsx";
import { WormModalText } from "../../../constants/inventory.constants";
import { useEffect, useState } from "react";

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const WormAppearNotifi = ({ isOpen, handleClose }: ModalProps) => {
  const tele = window.Telegram.WebApp;

  const [randomNumber, setRandomNumber] = useState<string>("");

  const handleOpenLink = () => {
    tele.showPopup(
      {
        message: `Do you want to open guidelines`,
        buttons: [
          { id: "link", type: "default", text: "Open" },
          { type: "cancel" },
        ],
      },
      async function (btn: any) {
        if (btn === "link") {
          tele.openLink(
            "https://seeddao.gitbook.io/seed-tutorial/catch-worms-earn-seed"
          );
          handleClose();
        } else {
        }
      }
    );
  };

  useEffect(() => {
    const randomInt = Math.floor(Math.random() * 2) + 1;
    setRandomNumber(String(randomInt));
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div className="w-[343px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#F2FFE0] p-[25px] rounded-3xl">
        <div className="flex flex-col items-center justify-center">
          <div className="flex justify-between w-full mb-3">
            <div className="w-full">
              <img src={`/images/inventory/worm_group.png`} alt=""></img>
            </div>
          </div>
          <div className="mb-1">
            <p className="text-center text-base">
              {WormModalText[randomNumber]}
            </p>
          </div>
        </div>
        <div className="flex w-full justify-center mb-6">
          <button
            onClick={() => {
              handleOpenLink();
            }}
            className="font-semibold text-[15px] text-[#4C7E0B]"
          >
            Full guide
          </button>
        </div>

        <div className="flex ">
          <Button
            onClick={handleClose}
            className={clsx(
              "btn-hover normal-case text-white text-base py-3 rounded-lg w-full h-[46px] font-extrabold",
              "bg-gradient-to-r from-[#97C35B] to-[#61A700] drop-shadow-[0_3px_0px_#4C7E0B]"
            )}
          >
            I understand
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default WormAppearNotifi;
