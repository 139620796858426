import { CircularProgress } from "@mui/material";
import clsx from "clsx";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useScreenSize } from "../../../Hooks/useScreenSize";
import ModalContainer from "../../../components/common/ModalContainer";
import { navPaths } from "../../../constants/navbar.constants";
import { INVENTORY_STATUS } from "../../../utils/helper";
import Pagination from "../../Market/Components/Pagination";
import { ITEM_BIRD_TYPES, ITEM_TYPES } from "../../Market/Pages/Market";
import SellEggModal from "./SellEggModal";
import UnlistModal from "./UnlistModal";
import { useBirdStore } from "../../../store/birdStore";

const ItemListV2 = ({
  items,
  triggerReload,
  page,
  setPage,
  total,
  loading,
  isShowPagination,
  itemsPerPage,
  tab,
}: {
  items: any;
  triggerReload: any;
  page: number;
  setPage: any;
  total: number;
  loading: boolean;
  isShowPagination: boolean;
  itemsPerPage: number;
  tab: string;
}) => {
  const navigate = useNavigate();
  const setBirdStore = useBirdStore((state: any) => state.setBirddItem);
  // const [disableScroll, setDisableScroll] = useState<boolean>(false);

  const [item, setItem] = useState<any>();
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [showModalSell, setShowModalSell] = useState<boolean>(false);

  const onShowModalSell = (item: any) => {
    setItem(item);
    setShowModalSell(!showModalSell);
  };

  const showModalUnlist = (item: any) => {
    setItem(item);
    setIsShowModal(!isShowModal);
  };

  const { height } = useScreenSize();

  // const handleScroll = (e: React.UIEvent<HTMLElement>) => {
  //   const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
  //   const bottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 1;
  //   if (bottom) {
  //     // setReachBottom(true);
  //     // setDisableScroll(true);
  //     if (items?.length >= total) return;
  //     setPage((prev: number) => prev + 1);
  //   }
  // };

  // useEffect(() => {
  //   loading ? setDisableScroll(true) : setDisableScroll(false);
  // }, [loading]);

  // const showLoadMore = () => {
  //   if (items?.length >= total) return false;
  //   return true;
  // };

  // const [reachBottom, setReachBottom] = useState<boolean>(false);

  // useEffect(() => {
  //   !loading && setReachBottom(false);
  // }, [loading]);

  useEffect(() => {
    document
      .getElementById("inventory-list")
      ?.scrollTo({ top: 0, behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleClickItem = (item: any) => {
    if (tab === "worm") {
      if (item?.on_market) {
        showModalUnlist(item);
      } else {
        onShowModalSell(item);
      }
    }
    if (tab === "egg") {
      if (item?.status === INVENTORY_STATUS.IN_INVENTORY) {
        navigate(navPaths.INVENTORY_DETAIL, {
          state: {
            ...item,
          },
        });
      } else {
        showModalUnlist(item);
      }
    }
    if (tab === "bird") {
      if (!item?.on_market) {
        setBirdStore(item);
        navigate(navPaths.BIRD_DETAIL);
      } else {
        showModalUnlist(item);
      }
    }
  };

  if (!loading && items && items?.length === 0 && tab !== "bird") {
    return (
      <div
        className={clsx(
          "flex flex-col items-center h-[calc(100%-123px)]",
          height < 590 ? "justify-start" : "justify-center"
        )}
      >
        <img
          className="w-[163px] h-[206px] object-contain"
          src={
            tab === "worm"
              ? "/images/inventory/no_data.png?v=4"
              : "/images/inventory/egg_no_data.png?v=4"
          }
          alt=""
        ></img>
        <div className="text-center mt-5 mx-7">
          <p className="text-[#345C00]">
            {tab === "egg"
              ? "The eggs can be purchased from the marketplace or obtained through events."
              : "Explore the tree for worms!\n Check it out!"}
          </p>
        </div>
      </div>
    );
  }
  if (items && items?.length === 0 && tab === "bird") {
    return (
      <div
        className={clsx(
          "flex flex-col items-center h-[calc(100%-123px)]",
          height < 590 ? "justify-start" : "justify-center"
        )}
      >
        <img
          className="w-[219px] h-[219px]"
          src="/images/bird/empty.png?v=4"
          alt=""
        ></img>
        <div className="text-center mt-5">
          <p className="text-[#345C00]">
            You don’t have any birds right now! Head over to the market to buy
            one or try hatching an egg!
          </p>
        </div>
      </div>
    );
  }

  return (
    <div
      id="inventory-list"
      className="overflow-scroll h-[calc(100%-70px)] pb-2"
    >
      <div
        // onScroll={handleScroll}
        className={clsx(
          "grid grid-cols-2 pt-4 px-4 gap-3 gap-y-4 lsm:gap-4 overflow-visible",
          // disableScroll ? " overflow-hidden" : "overflow-scroll"
          loading && "pointer-events-none"
        )}
      >
        {items?.map((item: any, index: number) => {
          return (
            <div key={index} className=" relative pb-2">
              <motion.div
                whileHover={{ scale: 1.07 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => handleClickItem(item)}
                className={clsx(
                  "border-[2px] max-h-[230px] border-[#8F8F8F] rounded-[12px] px-[7px] lsm:px-[11px] pb-[11px] bg-gradient-to-b from-[#F8FFEF] to-[#E5FFC0] flex flex-col gap-2 drop-shadow-[0_4px_0px_#424242]"
                  // index === items?.length - 1 && "mb-14"
                )}
              >
                <div
                  className="rounded-b-[4px] px-2 mx-[5px] lsm:mx-[10px] pt-[2px] pb-1 flex items-center justify-between"
                  style={{
                    color:
                      item?.type === "common" || item?.type === "penguin"
                        ? "#000"
                        : "#fff",
                    backgroundColor:
                      tab === "bird"
                        ? ITEM_BIRD_TYPES.find((des) => des.type === item?.type)
                            ?.color
                        : ITEM_TYPES.find((des) => des.type === item?.type)
                            ?.color,
                  }}
                >
                  <div className="text-[12px] font-bold capitalize">
                    {item?.type === "legendary" ? "legend" : item?.type}
                  </div>
                  <div className="text-[12px] font-[400]">
                    {tab === "bird"
                      ? ITEM_BIRD_TYPES.find((des) => des.type === item?.type)
                          ?.description
                      : ITEM_TYPES.find((des) => des.type === item?.type)
                          ?.description}
                  </div>
                </div>
                <div className="flex justify-center -mt-1">
                  <div className="px-[3px] pt-[5px] w-fit flex items-center gap-1 text-sm pr-2 uppercase">
                    <div
                      style={{
                        backgroundColor:
                          tab === "bird"
                            ? item?.type === "penguin"
                              ? "#000"
                              : ITEM_BIRD_TYPES.find(
                                  (des) => des.type === item?.type
                                )?.color
                            : item?.type === "common"
                            ? "#000"
                            : ITEM_TYPES.find((des) => des.type === item?.type)
                                ?.color,
                      }}
                      className="w-[16px] h-[16px] rounded-full flex items-center justify-center text-white"
                    >
                      #
                    </div>
                    <p
                      style={{
                        color:
                          tab === "bird"
                            ? item?.type === "penguin"
                              ? "#000"
                              : ITEM_BIRD_TYPES.find(
                                  (des) => des.type === item?.type
                                )?.color
                            : item?.type === "common"
                            ? "#000"
                            : ITEM_TYPES.find((des) => des.type === item?.type)
                                ?.color,
                      }}
                    >
                      {item?.id?.slice(item?.id?.length - 9, item?.id?.length)}
                    </p>
                  </div>
                </div>
                {item?.is_leader && (
                  <div
                    className="absolute top-[56px] left-[6px] border-[2px] z-50 border-[#fff] rounded-[14px] shadow-[0px_2px_0px_#00000033] font-semibold px-[6px] py-1 text-[12px] text-[#fff]"
                    style={{
                      backgroundColor:
                        tab === "bird"
                          ? ITEM_BIRD_TYPES.find(
                              (des) => des.type === item?.type
                            )?.color
                          : ITEM_TYPES.find((des) => des.type === item?.type)
                              ?.color,
                    }}
                  >
                    Leader
                  </div>
                )}
                <div className="flex flex-col items-center justify-center relative">
                  <img
                    src={
                      tab === "bird"
                        ? `/images/bird/${item?.type}.png`
                        : tab === "worm"
                        ? `/images/inventory/worm/${item?.type}.png`
                        : `/images/egg/${item?.type}.png`
                    }
                    alt={"market-item"}
                    className="h-[130px] w-[70%] object-contain"
                  />

                  {tab === "egg" &&
                    item?.status === INVENTORY_STATUS.ON_MARKET && (
                      <div className="text-[10px] bg-[#4B7E08CC] rounded-[14px] h-[20px] w-[108px] flex items-center justify-center text-white mt-[10px] pb-[1.5px]">
                        Listing
                      </div>
                    )}
                  {tab === "egg" &&
                    item?.status === INVENTORY_STATUS.IN_INVENTORY && (
                      <div className="text-[10px] bg-[#00000099] rounded-[14px] h-[20px] w-[108px] flex items-center justify-center text-white mt-[10px] pb-[1.5px]">
                        Inventory
                      </div>
                    )}
                  {tab === "worm" && item?.on_market && (
                    <div className="text-[10px] bg-[#4B7E08CC] rounded-[14px] h-[20px] w-[108px] flex items-center justify-center text-white mt-[10px] pb-[1.5px]">
                      Listing
                    </div>
                  )}
                  {tab === "worm" && !item?.on_market && (
                    <div className="text-[10px] bg-[#00000099] rounded-[14px] h-[20px] w-[108px] flex items-center justify-center text-white mt-[10px] pb-[1.5px]">
                      Inventory
                    </div>
                  )}
                  {tab === "bird" && item?.on_market && (
                    <div className="text-[10px] bg-[#4B7E08CC] rounded-[14px] h-[20px] w-[108px] flex items-center justify-center text-white mt-[10px] pb-[1.5px]">
                      Listing
                    </div>
                  )}
                  {tab === "bird" &&
                    !item?.on_market &&
                    item?.status === "in-inventory" && (
                      <div className="text-[10px] bg-[#00000099] rounded-[14px] h-[20px] w-[108px] flex items-center justify-center text-white mt-[10px] pb-[1.5px]">
                        Inventory
                      </div>
                    )}
                  {tab === "bird" &&
                    !item?.on_market &&
                    item?.status === "hunting" && (
                      <div className="text-[10px] bg-[#4B7E08CC] rounded-[14px] h-[20px] w-[108px] flex items-center justify-center text-white mt-[10px] pb-[1.5px]">
                        Hunting
                      </div>
                    )}
                </div>
              </motion.div>
            </div>
          );
        })}

        {loading && (
          <div className=" backdrop-blur-sm fixed inset-0 flex items-center justify-center z-50 bg-black/50">
            <CircularProgress style={{ color: "#F2FFE0" }} />
          </div>
        )}
        {/* {loading && (
        <div className=" fixed bottom-3 right-0 left-0 flex items-center justify-center">
          <div className="w-[200px] h-14 px-3 flex items-center justify-center">
            <CircularProgress color="success" />
          </div>
        </div>
      )} */}
        <ModalContainer>
          {isShowModal && (
            <UnlistModal
              showModal={setIsShowModal}
              egg={item}
              triggerReload={triggerReload}
              tab={tab}
            />
          )}
        </ModalContainer>
        <ModalContainer>
          {showModalSell && (
            <SellEggModal
              showModal={setShowModalSell}
              egg={item}
              tab={tab}
              triggerReload={triggerReload}
            />
          )}
        </ModalContainer>
      </div>
      {isShowPagination && (
        <div className="">
          <Pagination
            total={total}
            page={page}
            setPage={setPage}
            itemsPerPage={itemsPerPage}
          />
        </div>
      )}
    </div>
  );
};

export default ItemListV2;
