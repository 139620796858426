import clsx from "clsx";
import React from "react";
import { formatDecimals } from "../../../utils/formatNumber";

const FriendItem = ({ item }: { item: any }) => {
  return (
    <div
      className={clsx(
        "grid grid-cols-10 gap-3 bg-white py-4 px-2 w-full  border-b-[1px] border-[#F3F3F3]"
      )}
    >
      <div className="col-span-2 flex">
        <div className="size-[48px] overflow-hidden rounded-[50%]">
          <img
            src="/images/icons/user.png?v=3"
            width={48}
            height={48}
            alt="avt"
          ></img>
        </div>
      </div>
      <div className="col-span-8">
        <div className="flex items-center gap-1 mb-1">
          <p className="text-sm font-medium">{item.name}</p>
          {item.is_premium && (
            <span className="h-[18px] w-full flex items-center">
              <svg
                width="17"
                height="13"
                viewBox="0 0 17 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.75 2.96875C16.75 3.59766 16.2578 4.0625 15.6562 4.0625C15.6289 4.0625 15.6289 4.0625 15.6289 4.0625L14.2344 11.6641C14.1797 12.0742 13.7969 12.375 13.3867 12.375H4.33594C3.92578 12.375 3.54297 12.0742 3.48828 11.6641L2.09375 4.0625C2.09375 4.0625 2.09375 4.0625 2.06641 4.0625C1.46484 4.0625 0.972656 3.59766 0.972656 2.96875C0.972656 2.36719 1.46484 1.875 2.06641 1.875C2.64062 1.875 3.16016 2.36719 3.16016 2.96875C3.16016 3.21484 3.05078 3.43359 2.91406 3.625L5.375 5.59375C5.8125 5.92188 6.44141 5.78516 6.6875 5.29297L8.27344 2.14844C7.97266 1.95703 7.75391 1.62891 7.75391 1.21875C7.75391 0.617188 8.24609 0.125 8.875 0.125C9.47656 0.125 9.94141 0.617188 9.94141 1.21875C9.94141 1.62891 9.72266 1.95703 9.39453 2.14844L10.9805 5.29297C11.2266 5.78516 11.8828 5.92188 12.3203 5.59375L14.7539 3.625C14.6445 3.43359 14.5625 3.21484 14.5625 2.96875C14.5625 2.36719 15.0273 1.875 15.6562 1.875C16.2578 1.875 16.75 2.36719 16.75 2.96875Z"
                  fill="black"
                />
              </svg>
            </span>
          )}
        </div>
        <div>
          <div className="flex items-center gap-1">
            <span className="text-[#7D7D7D] text-sm">You received:</span>
            <img
              src="/images/icons/token_icon.png?v=3"
              width={18}
              height={18}
              alt="token"
            ></img>
            <p className="text-sm">
              + {formatDecimals(item.received_amount).toFixed(2)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FriendItem;
