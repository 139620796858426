import clsx from "clsx";
import CopyToClipboard from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { Slide, toast, ToastContainer } from "react-toastify";
import { useAccountV2 } from "../../../components/Hooks/useRegisterV2";
import LoadingScreen from "../../Global/Pages/LoadingScreen";
import { useMemo, useState } from "react";
import useGetAccountRecentReferees from "../../Friends/Hooks/useGetAccountRecentReferees";
import { LoadingButton } from "@mui/lab";
import useClaimFriendBadge from "../Hooks/useClaimFreindBadge";
import { sleep } from "../../../utils/time";
import { navPaths } from "../../../constants/navbar.constants";
import { ClickAwayListener, Tooltip } from "@mui/material";

const BirdCage = () => {
  const navigate = useNavigate();
  const tele = window.Telegram.WebApp;

  tele.BackButton.show();
  tele.BackButton.onClick(() => navigate("/"));

  const userID = tele.initDataUnsafe?.user?.id;

  const [isTooltipOpen, setIsTooltipOpen] = useState(false); //

  const {
    accountV2,
    isLoading: isLoadingProfile,
    refetch: refeshAcountV2,
  } = useAccountV2();
  const { recentReferees, isLoadingReferees, refreshReferees } =
    useGetAccountRecentReferees();
  const { mutateAsync: claimBadgeAsync, isPending: isClaiming } =
    useClaimFriendBadge();

  const hasFriendBadge = useMemo(() => {
    if (!accountV2) {
      return false;
    }

    return accountV2.data?.achieve_friend_badge;
  }, [accountV2]);

  const isAbleToClaim = useMemo(() => {
    if (!recentReferees?.data) {
      return false;
    }

    return recentReferees?.data?.total >= 2;
  }, [recentReferees]);

  const handleShareLink = () => {
    const defaultInviteTextEncoded = `SEED%20App%20-%20No.1%20Play2Earn%20Telegram%20App%20%0AJoin%20now%20with%20me%20and%20ready%20to%20receive%20SEED%20airdrop%21%0A%0A%F0%9F%A5%B3%20Get%20Welcome%20Gift%20Pack%20including%20in-game%20items%20and%20SEED%0A%F0%9F%92%B2Earn%2020%25%20cashback%20from%20frens`;
    tele.openTelegramLink(
      `https://t.me/share/url?url=${
        process.env.REACT_APP_BOT_URL
      }startapp=${String(userID)}&text=${defaultInviteTextEncoded}`
    );
  };

  const handleCopyLink = () => {
    !toast.isActive("copy_link") &&
      toast.success("Link copied to clipboard", {
        toastId: "copy_link",
        autoClose: 2000,
        style: { maxWidth: 337, height: 40, borderRadius: 8 },
      });
  };

  const handleClaimBadge = async () => {
    claimBadgeAsync()
      .then(() => {
        toast.error("Claim friend badge successfully", {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
          toastId: "catchFail",
        });
        navigate(navPaths.PROFILE, { state: { friendBadge: true } });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
          toastId: "catchFail",
        });
      })
      .finally(async () => {
        await sleep(500);
        refeshAcountV2();
        refreshReferees();
      });
  };

  if (isLoadingProfile || isLoadingReferees) {
    return <LoadingScreen />;
  }

  return (
    <div
      className="bg-[#F2FFE0] h-screen  px-4 pt-8 pb-[120px] bg-contain bg-no-repeat bg-center relative z-50"
      style={{ backgroundImage: `url("/images/guild/bg_tree.png")` }}
    >
      <ToastContainer
        position="top-left"
        closeOnClick
        transition={Slide}
        hideProgressBar
        limit={1}
        stacked
        className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
      />
      <div className="w-screen h-screen backdrop-blur-2xl absolute z-0 top-0 left-0"></div>
      <div className="flex flex-col h-full relative">
        <p className="text-[24px] font-extrabold text-center mb-8">
          Growth Guardian
        </p>
        <ClickAwayListener
          onClickAway={() => {
            setIsTooltipOpen(false);
          }}
        >
          <Tooltip
            placement="left"
            PopperProps={{
              disablePortal: true,
            }}
            arrow
            onClose={() => {
              setIsTooltipOpen(false);
            }}
            open={isTooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Seedizen who actively contributes to the growth of the community"
            className="text-sm z-90"
          >
            <button
              onClick={() => setIsTooltipOpen(!isTooltipOpen)}
              className="absolute right-0 top-2 pl-3"
            >
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0.5C15.5078 0.5 20 4.99219 20 10.5C20 16.0469 15.5078 20.5 10 20.5C4.45312 20.5 0 16.0469 0 10.5C0 4.99219 4.45312 0.5 10 0.5ZM10 18.625C14.4531 18.625 18.125 14.9922 18.125 10.5C18.125 6.04688 14.4531 2.375 10 2.375C5.50781 2.375 1.875 6.04688 1.875 10.5C1.875 14.9922 5.50781 18.625 10 18.625ZM11.5625 13.625C12.0703 13.625 12.5 14.0547 12.5 14.5625C12.5 15.1094 12.0703 15.5 11.5625 15.5H8.4375C7.89062 15.5 7.5 15.1094 7.5 14.5625C7.5 14.0547 7.89062 13.625 8.4375 13.625H9.0625V11.125H8.75C8.20312 11.125 7.8125 10.7344 7.8125 10.1875C7.8125 9.67969 8.20312 9.25 8.75 9.25H10C10.5078 9.25 10.9375 9.67969 10.9375 10.1875V13.625H11.5625ZM10 8C9.29688 8 8.75 7.45312 8.75 6.75C8.75 6.08594 9.29688 5.5 10 5.5C10.6641 5.5 11.25 6.08594 11.25 6.75C11.25 7.45312 10.6641 8 10 8Z"
                  fill="#4C7E0B"
                />
              </svg>
            </button>
          </Tooltip>
        </ClickAwayListener>

        <div
          className="flex-1 max-h-[320px] bg-no-repeat bg-contain bg-center relative"
          style={{ backgroundImage: `url('/images/medal.png')` }}
        ></div>

        <div className="text-center mt-8 flex flex-col gap-2 items-center">
          <p className="text-[20px] font-semibold ">Achievement Badge</p>

          <p className="text-sm text-[#545454] w-[80%]">
            Badge is the key to our airdrop.{<br />}Limited & on-chain tradable.
          </p>
        </div>
      </div>
      <div className=" absolute bottom-[32px] right-4 left-4">
        {isAbleToClaim && !hasFriendBadge ? (
          <LoadingButton
            onClick={() => handleClaimBadge()}
            loading={isClaiming}
            disabled={isClaiming || hasFriendBadge}
            sx={{ border: 1 }}
            className={clsx(
              "font-bold capitalize text-[16px] text-white py-2 lsm:py-[18px] w-full h-[46px] rounded-xl ",
              "btn-hover bg-gradient-to-r from-[#97C35B] to-[#61A700] shadow-[0_3px_0px_#4C7E0B] border-none",
              "disabled:bg-[#ADADAD] disabled:text-[#F3F3F3] disabled:shadow-[0_3px_0px_#4E4E4E]"
            )}
          >
            Claim
          </LoadingButton>
        ) : (
          <>
            <div className="grid grid-cols-5 gap-4">
              <div className="col-span-4">
                <button
                  onClick={handleShareLink}
                  className={clsx(
                    "font-bold text-white py-[18px] w-full rounded-xl flex items-center justify-center gap-2",
                    "btn-hover bg-gradient-to-r from-[#97C35B] to-[#61A700] drop-shadow-[0_4px_0px_#4C7E0B]"
                  )}
                >
                  Invite 2+ friends to claim
                </button>
              </div>
              <div className="col-span-1 flex justify-center">
                <CopyToClipboard
                  text={`${process.env.REACT_APP_BOT_URL}startapp=${String(
                    userID
                  )}`}
                >
                  <button
                    onClick={() => handleCopyLink()}
                    className={clsx(
                      "font-bold text-white p-0 w-full rounded-xl h-full flex items-center justify-center",
                      "btn-click-no-shadow border-[3px] border-[#63A803] shadow-[0_3px_0px_#4C7E0B]"
                    )}
                  >
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.5 25.375H15.75C16.1875 25.375 16.625 24.9922 16.625 24.5V21H19.25V24.5C19.25 26.4688 17.6641 28 15.75 28H3.5C1.53125 28 0 26.4688 0 24.5V12.25C0 10.3359 1.53125 8.75 3.5 8.75H7V11.375H3.5C3.00781 11.375 2.625 11.8125 2.625 12.25V24.5C2.625 24.9922 3.00781 25.375 3.5 25.375ZM8.75 3.5C8.75 1.58594 10.2812 0 12.25 0H24.5C26.4141 0 28 1.58594 28 3.5V15.75C28 17.7188 26.4141 19.25 24.5 19.25H12.25C10.2812 19.25 8.75 17.7188 8.75 15.75V3.5ZM12.25 16.625H24.5C24.9375 16.625 25.375 16.2422 25.375 15.75V3.5C25.375 3.0625 24.9375 2.625 24.5 2.625H12.25C11.7578 2.625 11.375 3.0625 11.375 3.5V15.75C11.375 16.2422 11.7578 16.625 12.25 16.625Z"
                        fill="#63A803"
                      />
                    </svg>
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BirdCage;
