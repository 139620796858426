import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { Result } from "../../../types/utils";
import { ServerError, ServerResponse } from "../../../types/api";
import { RecentReferees } from "../../../types/referees";

export const getAccountRecentReferees = async (): Promise<
  Result<RecentReferees, ServerError>
> => {
  const response = await fetchApi("GET", "/profile/recent-referees");
  if (response.error) {
    // Throw to retry
    throw new Error("network-error");
  }

  const refereesResponse: ServerResponse<RecentReferees> = await response.data.json();
  if (refereesResponse.error) {
    return {
      error: refereesResponse.error,
    };
  }

  return {
    data: {
      total: refereesResponse.data?.total || 0,
      referee_balance: refereesResponse.data?.referee_balance || 0,
      referees: refereesResponse.data?.referees || []
    },
  };
};

export default function useGetAccountRecentReferees() {
  const { data, isPending, refetch } = useQuery({
    queryKey: ["AccountRecentReferees"],
    queryFn: () => getAccountRecentReferees(),
  });

  return {
    recentReferees: data,
    isLoadingReferees: isPending,
    refreshReferees: refetch,
  };
}
