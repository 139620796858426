export const bonusesWormBgColor: {
  [key: string]: string;
} = {
  uncommon: "#50923999",
  rare: "#0063C299",
  epic: "#8B6BFF99",
  legendary: "#FFCC4899",
};

export const bonusesWormBorderColor: {
  [key: string]: string;
} = {
  uncommon: "#509239",
  rare: "#0063C2",
  epic: "#897EB6",
  legendary: "#F6CE3D",
};

export const wormBg: {
  [key: string]: string;
} = {
  common: "#CCA45E",
  uncommon: "#139E00",
  rare: "#0063C2",
  epic: "#9D22EF",
  legendary: "#F07800",
};

export const wormBorderColor: {
  [key: string]: string;
} = {
  common: "#8F8F8F",
  uncommon: "#9EFC91",
  rare: "#81B2E1",
  epic: "#CB7BFF",
  legendary: "#FFC080",
};

export const wormShadowColor: {
  [key: string]: string;
} = {
  common: "#424242",
  uncommon: "#0B5B00",
  rare: "#00386F",
  epic: "#6000A0",
  legendary: "#924900",
};
