import clsx from "clsx";
import { navPaths } from "../../../constants/navbar.constants";
import { useNavigate } from "react-router-dom";
import { useGuildID } from "../../../store/useGuildId";
import {
  formatCompactNumber,
  formatDecimals,
} from "../../../utils/formatNumber";

const MyGuild = ({ myGuildData }: { myGuildData: any }) => {
  const navigate = useNavigate();
  const setGuildId = useGuildID((state: any) => state.setGuildId);
  return (
    <>
      {myGuildData && (
        <div
          onClick={() => {
            navigate(navPaths.GUILD_DETAILS, { state: { ...myGuildData } });
            setGuildId(myGuildData?.guild_id);
          }}
          className={clsx(
            "grid grid-cols-12 bg-[#FFFFFF] cursor-pointer rounded-[16px] shadow-[0_0_12px_#CCF0AF]",
            "px-4 py-[10px]"
          )}
        >
          <div className="max-w-[45px] flex items-center justify-center col-span-2">
            {myGuildData && (
              <>
                {myGuildData?.rank_index > 3 ? (
                  <p className="text-sm text-center font-bold">
                    {myGuildData?.rank_index}
                  </p>
                ) : (
                  <img
                    className="size-[45px]"
                    src={`/images/guild/guild_rank/${myGuildData?.rank_index}.png`}
                    alt=""
                  ></img>
                )}
              </>
            )}
          </div>
          <div className="size-[48px] rounded-[50%] overflow-hidden col-span-2">
            <img
              src={`/images/guild/guild_level/${myGuildData?.guild_rank}.png`}
              alt=""
            ></img>
          </div>
          <div className="flex flex-col justify-center items-start relative col-span-6 pl-2">
            <p className="font-semibold text-[14px] leading-4">
              {myGuildData?.name}
            </p>
            <div className="flex items-center gap-3">
              <div
                className={clsx(
                  "text-[12px] w-[50px] flex justify-start items-center font-medium"
                )}
              >
                <svg
                  width="10"
                  height="11"
                  viewBox="0 0 10 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.87305 6.1875C7.76758 6.1875 9.31055 7.73047 9.31055 9.625C9.31055 9.97656 9.01758 10.25 8.68555 10.25H1.18555C0.833984 10.25 0.560547 9.97656 0.560547 9.625C0.560547 7.73047 2.08398 6.1875 3.99805 6.1875H5.87305ZM1.49805 9.3125H8.35352C8.19727 8.08203 7.14258 7.125 5.87305 7.125H3.99805C2.70898 7.125 1.6543 8.08203 1.49805 9.3125ZM4.93555 5.25C3.54883 5.25 2.43555 4.13672 2.43555 2.75C2.43555 1.38281 3.54883 0.25 4.93555 0.25C6.30273 0.25 7.43555 1.38281 7.43555 2.75C7.43555 4.13672 6.30273 5.25 4.93555 5.25ZM4.93555 1.1875C4.05664 1.1875 3.37305 1.89062 3.37305 2.75C3.37305 3.62891 4.05664 4.3125 4.93555 4.3125C5.79492 4.3125 6.49805 3.62891 6.49805 2.75C6.49805 1.89062 5.79492 1.1875 4.93555 1.1875Z"
                    fill="black"
                  />
                </svg>
                {myGuildData?.number_member ?? 0}/
                {myGuildData?.size === -1
                  ? "Unlimited"
                  : formatCompactNumber(myGuildData?.size)}
              </div>
              <div className="bg-[#7D7D7D] size-1 rounded-[50%]"></div>
              <div className="flex justify-center items-center gap-[2px] col-span-2 text-[12px] font-semibold">
                <img
                  src="/images/icons/token_icon.png?v=3"
                  width={13}
                  height={13}
                  alt="token"
                ></img>
                <p>{formatDecimals(myGuildData?.hunted ?? 0).toFixed(0)}</p>
              </div>{" "}
              <div className="bg-[#7D7D7D] size-1 rounded-[50%]"></div>
              <div className="flex justify-center items-center gap-[2px] col-span-2 text-[12px] font-semibold">
                + <p>{5 + (myGuildData?.distribution_rate ?? 0) / 100}%</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyGuild;
