import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerResponse } from "../../../types/api";

export const getPurchaseHistory = async () => {
  const response = await fetchApi("GET", "/iap/boxes/history");
  if (response.error) {
    throw new Error("network-error");
  }

  const purchaseHistoryResponse: ServerResponse<any> =
    await response.data.json();
  if (purchaseHistoryResponse.error) {
    return {
      error: purchaseHistoryResponse.error,
    };
  }

  return purchaseHistoryResponse.data;
};

export default function usePurchaseHistory() {
  const { data, isPending, refetch } = useQuery({
    queryKey: ["purchaseHistory"],
    queryFn: () => getPurchaseHistory(),
  });

  return {
    purchaseHistory: data,
    purchaseHistoryLoading: isPending,
    refeshPurchaseHistory: refetch,
  };
}
