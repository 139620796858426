import { RefObject, useEffect } from "react";

const MOUSEDOWN = "mousedown";
const TOUCHSTART = "touchstart";

type HandledEvents = [typeof MOUSEDOWN, typeof TOUCHSTART];

type HandledEventsType = HandledEvents[number];

type PossibleEvent = {
  [Type in HandledEventsType]: HTMLElementEventMap[Type];
}[HandledEventsType];

type Handler = (event: PossibleEvent) => void;

/**
 * @see https://usehooks.com/useOnClickOutside
 * @see https://github.com/Andarist/use-onclickoutside
 * @see https://stackoverflow.com/a/37721906
 */
export function useClickOutside(ref: RefObject<HTMLElement>, handler: Handler) {
  useEffect(
    () => {
      const listener = (event: PossibleEvent) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target as Node)) {
          return;
        }

        handler(event);
      };

      document.addEventListener(MOUSEDOWN, listener);
      document.addEventListener(TOUCHSTART, listener, { passive: true });

      return () => {
        document.removeEventListener(MOUSEDOWN, listener);
        document.removeEventListener(TOUCHSTART, listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}
