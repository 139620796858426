import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function sellEgg({
  id,
  price,
  market,
}: {
  id: string;
  price: number;
  market: string;
}) {
  return await api.post(
    `/api/v1/market-item/add`,
    market === "worm"
      ? {
          worm_id: id,
          price: price,
        }
      : {
          egg_id: id,
          price: price,
        }
  );
}

export default function useSellEgg() {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["sellEgg"],
    mutationFn: sellEgg,
  });

  return { sellAsync: mutateAsync, isSellingEgg: isPending };
}
