import clsx from "clsx";
import { motion } from "framer-motion";
import { Dispatch, SetStateAction } from "react";
import Backdrop from "../../../components/common/Backdrop";
import { dropIn } from "../../../components/common/ModalContainer";

const ReleaseSuccessModal = ({
  setShowReleaseSuccessModal,
  releaseBirdData,
}: {
  setShowReleaseSuccessModal: Dispatch<SetStateAction<boolean>>;
  releaseBirdData: any;
}) => {
  const tele = window.Telegram.WebApp;
  const hideModal = () => setShowReleaseSuccessModal(false);
  const eggType = releaseBirdData?.data?.data?.egg_type;
  const achieve = releaseBirdData?.data?.data?.achieve;
  const birdType = releaseBirdData?.data?.data?.bird_type;

  const shareTwitter = () => {
    const baseUrl = "https://release-bird.seeddao.org/";
    const customText = `I%20just%20released%20a%20%23${birdType}.%0AFreedom%20isn%E2%80%99t%20just%20a%20word%3B%20it%E2%80%99s%20how%20we%20live.%0ARelease%20your%20birds%20and%20%23FreeDurov%20today%21%20%E2%9C%88%0A%23seedapp%20%23seedcoin%20%23Seedizens%0A`;

    tele.showPopup(
      {
        message: `Do you want to open link`,
        buttons: [
          { id: "link", type: "default", text: "Open" },
          { type: "cancel" },
        ],
      },
      async function (btn: any) {
        if (btn === "link") {
          tele.openLink(
            `https://twitter.com/intent/tweet?text=${customText}${baseUrl}`,
          );
        } else {
        }
      },
    );
  };

  return (
    <Backdrop onClick={hideModal}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="release-modal"
      >
        <div
          className={clsx(
            "bg-[#FFFFFF20] text-white rounded-[20px] py-[24px] flex flex-col items-center justify-center relative backdrop-blur",
          )}
        >
          <span className="font-spicy-rice-regular text-[32px]">
            {achieve && "Wings of Freedom"}
            {!achieve && eggType && "Congratulations!"}
            {!achieve && !eggType && "Thank you!"}
          </span>
          {achieve && eggType && (
            <>
              <div className="p-4 mt-4 w-full flex flex-col gap-4">
                <div className="rounded-2xl bg-[#FFFFFF10] p-4 flex flex-col items-center h-[25vh]">
                  <img
                    alt=""
                    className="h-[85%] w-auto aspect-square object-contain mb-1"
                    src="/images/bird-cage/achieve.png"
                  />
                  <span className="font-bold">Achievement Badge</span>
                </div>
                <div className="rounded-2xl bg-[#FFFFFF10] p-4 flex flex-col items-center h-[25vh]">
                  <img
                    alt=""
                    className="h-[85%] w-auto aspect-square object-contain mb-1"
                    src={`/images/egg/${eggType}.png`}
                  />
                  <span className="font-bold capitalize">{eggType} Egg</span>
                </div>
              </div>
              <span className="mt-4 text-sm w-[80%] text-center">
                This simple act represents our shared hope for a world where
                leaders like Durov are free to innovate without restrictions.
              </span>
            </>
          )}
          {!achieve && eggType && (
            <div className="p-4 flex flex-col items-center h-[25vh]">
              <img
                alt=""
                className="h-[85%] w-auto aspect-square object-contain mb-1"
                src={`/images/egg/${eggType}.png`}
              />
              <span className="text-[20px] font-bold capitalize mt-2">
                {eggType} Egg
              </span>
            </div>
          )}
          {achieve && !eggType && (
            <>
              <div className="px-4 pt-4 flex flex-col items-center h-[25vh]">
                <img
                  alt=""
                  className="h-[85%] w-auto aspect-square object-contain mb-1"
                  src="/images/bird-cage/achieve.png"
                />
                <span className="font-bold">Achievement Badge</span>
              </div>
              <span className="text-sm w-[80%] text-center mt-2">
                This simple act represents our shared hope for a world where
                leaders like Durov are free to innovate without restrictions.
              </span>
            </>
          )}
          {!achieve && !eggType && (
            <span className="mt-4 text-sm w-full lsm:w-[95%] text-center">
              Your involvement helps raise awareness for digital freedom and
              privacy. Together, we’re making a difference! Thank you for
              standing with us!
            </span>
          )}
          <div className="px-4 mt-4 w-full flex gap-3">
            <div
              onClick={hideModal}
              className={clsx(
                "flex items-center justify-center text-[16px] w-full font-[800] text-black h-[48px] px-5 rounded-xl normal-case mx-auto",
                "btn-click-no-shadow bg-white shadow-[0_4px_0px_#B0B0B0] select-none",
              )}
            >
              {!achieve && !eggType ? "Cheers" : "Claim"}
            </div>
            <div
              onClick={shareTwitter}
              className={clsx(
                "flex items-center justify-center text-[16px] w-full font-[800] text-white h-[48px] px-5 rounded-xl normal-case mx-auto",
                "btn-click-no-shadow bg-gradient-to-r from-[#97C35B] to-[#61A700] shadow-[0_4px_0px_#4C7E0B] select-none",
              )}
            >
              {"Share"}
            </div>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default ReleaseSuccessModal;
