import { Modal } from "@mui/material";
import clsx from "clsx";
import { forwardRef, useMemo } from "react";
import CloseBtn from "./SVG/CloseBtn";
import MissionItem from "./SVG/MissionItem";
import EnabletTicket from "../../Streak/components/SVG/EnabletTicket";
import { useMissions } from "../../Missions/Hooks/useGetMissions";
import ItemLoading from "../../../components/common/ItemLoading";
import { useCheckMission } from "../../Missions/Hooks/useDoMissions";
import { missionsTypes } from "../../../constants/missions.constants";
import { getMobileOS } from "../../../utils/helper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { navPaths } from "../../../constants/navbar.constants";
import DoneTicket from "./SVG/DoneTicket";
import { sleep } from "../../../utils/time";
import { twitterUrl } from "../../Missions/Utils/missions";

const MissionModal = forwardRef(
  ({
    isShowModal,
    handleClose,
    triggerRefresh,
  }: {
    isShowModal: boolean;
    handleClose: any;
    triggerRefresh: () => void;
  }) => {
    const tele = window.Telegram.WebApp;
    const navigate = useNavigate();

    const {
      missions: missionsDatas,
      refetch: refreshMissions,
      isLoading: isLoadingMissions,
    } = useMissions();
    const { checkMission, isLoading: isCheckingMission } = useCheckMission();

    const TicketMissions = useMemo(() => {
      if (!missionsDatas?.data?.length) {
        return [];
      }

      const isPremium = !!tele?.initDataUnsafe?.user?.is_premium;
      const missions = missionsDatas?.data
        ?.filter((item: any) => {
          if (isPremium) {
            return item?.type !== missionsTypes.SIGN_IN && item?.tickets !== 0;
          }
          return (
            item?.type !== missionsTypes.SIGN_IN &&
            item?.tickets !== 0 &&
            !item?.metadata?.premium_only
          );
        })
        ?.sort((a, b) => {
          const isACompleted = a?.task_user !== null && a?.task_user?.completed;

          const isBCompelted = b?.task_user !== null && b?.task_user?.completed;

          if (!isACompleted && isBCompelted) {
            return -1;
          }
          if (isACompleted && !isBCompelted) {
            return 1;
          }
          return 0;
        });

      return missions;
    }, [missionsDatas?.data, tele?.initDataUnsafe?.user?.is_premium]);

    const handleDoMission = (item: any, url?: string) => {
      if (item?.task_user === null || !item?.task_user?.completed) {
        if (getMobileOS() === "Other") {
          if (
            item.type === missionsTypes.PLAY_APP ||
            (item.type === missionsTypes.COLLABORATION &&
              item?.metadata?.is_tma) // if mission tpye is collab and is tma, open tele link
          ) {
            const isCLicked = localStorage.getItem(
              `isOpenOnDesktop_${item.id}`
            );

            if (!isCLicked) {
              localStorage.setItem(`isOpenOnDesktop_${item.id}`, "true");
              tele.openTelegramLink(item?.metadata?.url ?? "");
            } else {
              localStorage.removeItem(`isOpenOnDesktop_${item.id}`);
            }
          }

          if (
            item.type === missionsTypes.COLLABORATION &&
            !item?.metadata?.is_tma
          ) {
            tele.openLink(item?.metadata?.url ?? "");
          }
          if (item.type === missionsTypes.SHARE_STORIES) {
            toast.success("You need to open the mobile app", {
              style: { maxWidth: 337, height: 40, borderRadius: 8 },
              autoClose: 2000,
            });
            return;
          }
        } else if (item.type === missionsTypes.PLAY_APP) {
          tele.openTelegramLink(item?.metadata?.url ?? "");
        } else if (item.type === missionsTypes.COLLABORATION) {
          if (item?.metadata?.is_tma) {
            tele.openTelegramLink(item?.metadata?.url ?? "");
          } else {
            tele.openLink(item?.metadata?.url ?? "");
          }
        }

        if (
          item.type === missionsTypes.TELEGRAM_BOOST ||
          item.type === missionsTypes.TELEGRAM_JOIN_NO_BOT
        ) {
          tele.openTelegramLink(item?.metadata?.url ?? "");
        }
        if (item.type === missionsTypes.TWITTER_FOLLOW) {
          tele.showPopup(
            {
              message: `Do you want to open ${item.metadata.name}`,
              buttons: [
                { id: "link", type: "default", text: "Open" },
                { type: "cancel" },
              ],
            },
            async function (btn: any) {
              if (btn === "link") {
                if (item?.task_user === null || !item?.task_user?.completed) {
                  tele.openLink(
                    item.type === missionsTypes.TWITTER_FOLLOW
                      ? url
                      : item.metadata.url
                  );
                  handleCheckMission(item);
                  return;
                }
                tele.openLink(
                  item.type === missionsTypes.TWITTER_FOLLOW
                    ? url
                    : item.metadata.url
                );
              } else {
              }
            }
          );
          return;
        }
        if (item.type === missionsTypes.TELEGRAM__JOIN) {
          tele.openTelegramLink(item?.metadata?.url ?? "");
        }
        if (item.type === missionsTypes.SHARE_STORIES) {
          tele.shareToStory(item?.metadata?.story_media, {
            text: item?.metadata?.story_content,
          });
        }

        handleCheckMission(item);
        return;
      }
      if (item.type === missionsTypes.TELEGRAM__JOIN) {
        tele.openTelegramLink(item?.metadata?.url ?? "");
      }
      if (
        item.type === missionsTypes.TELEGRAM_BOOST ||
        item.type === missionsTypes.TELEGRAM_JOIN_NO_BOT ||
        item.type === missionsTypes.PLAY_APP
      ) {
        tele.openTelegramLink(item?.metadata?.url ?? "");
        return;
      }
      if (item.type === missionsTypes.COLLABORATION) {
        if (item?.metadata?.is_tma) {
          tele.openTelegramLink(item?.metadata?.url ?? "");
        } else {
          tele.openLink(item?.metadata?.url ?? "");
        }
      }
      if (item.type === missionsTypes.TWITTER_FOLLOW) {
        tele.openLink(
          item.type === missionsTypes.TWITTER_FOLLOW ? url : item.metadata.url
        );
      }
      if (item.type === missionsTypes.SHARE_STORIES) {
        tele.shareToStory(item?.metadata?.story_media, {
          text: item?.metadata?.story_content,
        });
      }
    };

    const handleCheckMission = (item: any) => {
      checkMission(item)
        .then(async (data) => {
          if (data?.data === "Mission completed") {
            await sleep(500);
            toast.success(data.data, {
              style: { maxWidth: 337, height: 40, borderRadius: 8 },
              autoClose: 2000,
            });
            triggerRefresh();
            refreshMissions();
            return;
          }

          if (data?.data === "incomplete task") {
            // redirect to refer page when refer-premium mission is not done yet
            if (item.type === missionsTypes.REFER_PREMIUM) {
              navigate(navPaths.FRIENDS);
              return;
            }
            toast.error("Incomplete mission", {
              style: { maxWidth: 337, height: 40, borderRadius: 8 },
              autoClose: 2000,
            });
            return;
          }

          toast.error(data?.data, {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          });
        })
        .catch((err) => {
          toast.error(
            String(err?.response?.data?.message).replace("task", "mission"),
            {
              style: { maxWidth: 337, height: 40, borderRadius: 8 },
              autoClose: 2000,
            }
          );
        });
    };

    return (
      <Modal
        open={isShowModal}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        disableAutoFocus={true}
      >
        <div
          style={{
            backgroundImage: `url('/images/lucky_spin/mission_modal.png`,
          }}
          className={clsx(
            "w-[95%] h-[80%] absolute flex flex-col items-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ",
            "bg-no-repeat bg-[length:100%_100%] bg-center"
          )}
        >
          <CloseBtn handleClose={handleClose} />
          <p className="w-full text-[26px] mt-[48px] text-center font-squada-one-regular">
            Treasure Hunt Board
          </p>

          <div className="w-full flex flex-col items-center h-[calc(100%-140px)] overflow-auto">
            {isLoadingMissions ? (
              <div className="h-[calc(100%-140px)] w-full flex justify-center items-center">
                <ItemLoading />
              </div>
            ) : (
              <>
                {TicketMissions?.map((item) => {
                  const Url = twitterUrl(item);
                  return (
                    <div
                      onClick={() =>
                        !isCheckingMission && handleDoMission(item, Url)
                      }
                      className={clsx(
                        "relative w-[90%] h-[120px] flex justify-center cursor-pointer",
                        (item?.task_user?.completed ||
                          item?.task_user?.repeats >= item?.repeats) &&
                          "opacity-30",
                        isCheckingMission && "pointer-events-none"
                      )}
                    >
                      <MissionItem />
                      <div
                        className={clsx(
                          "w-[81%] h-[50%] absolute top-[50%] -translate-y-[50%]",
                          "border-[1.5px] border-[#3F1104]"
                        )}
                      ></div>
                      <div
                        className={clsx(
                          "w-[80%] h-[45%] absolute top-[50%] px-2 -translate-y-[50%] grid grid-cols-12",
                          "border-[0.8px] border-[#3F1104]"
                        )}
                      >
                        <div className="flex items-center col-span-2 ">
                          <div className="size-9 rounded-[50%] overflow-hidden border-[2px] border-[#3F1104]">
                            <img
                              className="size-full"
                              src={item?.metadata?.image_url}
                              alt=""
                            ></img>
                          </div>
                        </div>
                        <div className="col-span-7 flex items-center">
                          <p className="font-squada-one-regular text-[16px]">
                            {item?.name}
                          </p>
                        </div>
                        <div className="col-span-3 flex justify-center items-center gap-[6px]">
                          {isCheckingMission ? (
                            <>
                              <ItemLoading />
                            </>
                          ) : (
                            <>
                              <p>{`+${item?.tickets}`}</p>
                              {item?.task_user?.completed ||
                              item?.task_user?.repeats >= item?.repeats ? (
                                <DoneTicket />
                              ) : (
                                <EnabletTicket />
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </Modal>
    );
  }
);

export default MissionModal;
