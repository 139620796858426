import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerResponse } from "../../../types/api";

export const getBoxDetails = async (id: string) => {
  const response = await fetchApi("GET", `/iap/boxes/${id}`);
  if (response.error) {
    throw new Error("network-error");
  }

  const boxDetailsResponse: ServerResponse<any> = await response.data.json();
  if (boxDetailsResponse.error) {
    return {
      error: boxDetailsResponse.error,
    };
  }

  return boxDetailsResponse.data;
};

export default function useBoxDetails(id: string) {
  const { data, isPending, refetch } = useQuery({
    queryKey: ["boxDetails"],
    queryFn: () => getBoxDetails(id),
    enabled: !!id,
  });

  return {
    boxDetails: data,
    boxDetailsLoading: isPending,
    refeshBoxDetail: refetch,
  };
}
