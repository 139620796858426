import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function buyItem(params: any) {
  return await api.post(
    `/api/v1/market-item/buy`,
    { market_id: params?.market_id },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export default function useBuyItem() {
  const { mutateAsync } = useMutation({
    mutationKey: ["buyItem"],
    mutationFn: buyItem,
  });

  return { buyAsync: mutateAsync };
}
