import clsx from "clsx";
import { useMemo, useState } from "react";
import { missionsTypes } from "../../../../constants/missions.constants";
import {
  formatDecimals,
  getNumberFormatUs,
} from "../../../../utils/formatNumber";
import { twitterUrl } from "../../Utils/missions";
import StartBtn from "../StartBtn";
import TonIcon from "../TonIc";
import { CircularProgress } from "@mui/material";

const TonMission = ({
  Mission,
  tonChecking,
  handleCheckTonStatus,
  isDoingMission,
  handleDoMission,
  showRecheck,
  handleRecheckTonMission,
}: {
  Mission: any;
  tonChecking: boolean;
  isDoingMission: boolean;
  handleCheckTonStatus: (arg0: any) => void;
  handleDoMission: (item: any) => void;
  showRecheck: boolean;
  handleRecheckTonMission: (arg0: any) => void;
}) => {
  const tele = window.Telegram.WebApp;
  const [loadingItem, setLoadingItem] = useState("");

  const tonMissions = useMemo(() => {
    if (!Mission.length) {
      return null;
    }
    return Mission.filter((item: any) => item.type === "ton-wallet-verify");
  }, [Mission]);

  const otherMissions = useMemo(() => {
    if (!Mission.length) {
      return [];
    }

    return Mission.filter((item: any) => item.type !== "ton-wallet-verify");
  }, [Mission]);

  const [tonMissionPos, setTonMissionPos] = useState<number>(0);

  return (
    <div className={clsx("mb-3 rounded-2xl")}>
      <div className="pb-[10px] font-semibold flex items-center justify-start gap-1">
        <div>
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.87725 0.982442C7.07762 0.339185 7.92238 0.339187 8.12275 0.982441L9.10026 4.12059C9.3167 4.81545 9.94621 5.30564 10.6778 5.30564H13.841C14.1468 5.30564 14.3744 5.50101 14.4638 5.78808C14.5536 6.07647 14.4808 6.39146 14.219 6.58991L11.6598 8.5294C11.0812 8.9679 10.8487 9.73374 11.0642 10.4256L12.0417 13.5638C12.1472 13.9025 12.0177 14.1982 11.7969 14.3656C11.5792 14.5306 11.2953 14.5583 11.0411 14.3656L8.48192 12.4261C7.89791 11.9835 7.1021 11.9835 6.51808 12.4261L3.95893 14.3656C3.70472 14.5583 3.42083 14.5306 3.20311 14.3656C2.98227 14.1982 2.85277 13.9025 2.95827 13.5638L3.93578 10.4256C4.15129 9.73374 3.9188 8.9679 3.34019 8.5294L0.781044 6.58991C0.519179 6.39146 0.446377 6.07647 0.536209 5.78808C0.625629 5.50101 0.853247 5.30564 1.15896 5.30564H4.32224C5.05379 5.30564 5.6833 4.81545 5.89974 4.12059L6.87725 0.982442Z"
              stroke="black"
            />
          </svg>
        </div>
        <p className="text-[18px]">{Mission[0]?.metadata.group_name ?? ""}</p>
      </div>

      <div>
        <div
          className={clsx(
            "z-10 relative bg-white rounded-xl w-full ",
            "border-[1px] border-[#F3F3F3] border-solid",
          )}
        >
          {/* ton mission  */}
          {tonMissions.map((tonMission: any, index: number) => {
            return (
              <div
                className={clsx(
                  "z-10 relative cursor-pointer grid grid-cols-12 gap-3 bg-white rounded-xl p-3 w-full ",
                  " border-[#F3F3F3] border-solid",
                  index === tonMissions.length - 1 && !otherMissions.length
                    ? "pb-0"
                    : "border-b-[1px] pb-3",
                )}
              >
                <div className="col-span-2 flex items-center">
                  <div>
                    <img
                      className="size-10"
                      src={tonMission?.metadata?.image_url}
                      alt="storage"
                    ></img>
                  </div>
                </div>
                <div className="col-span-6 flex items-center">
                  <div className="">
                    <p className="font-medium text-sm">{tonMission?.name}</p>
                    <div className="flex items-center gap-1 mt-2">
                      <span className="mb-[2px]">+</span>
                      <img
                        className="size-[13px]"
                        src="/images/icons/token_icon.png?v=3"
                        alt="token"
                      ></img>
                      <p className=" text-[12px] font-semibold">
                        {getNumberFormatUs(
                          formatDecimals(tonMission?.reward_amount ?? 0),
                        )}
                      </p>
                      {!tonMission?.task_user?.completed && (
                        <button
                          onClick={() => {
                            setTonMissionPos(index);
                            handleRecheckTonMission(tonMission);
                          }}
                          className={clsx(
                            "bg-[#0088CC] rounded-[38px] flex justify-center items-center w-[80px] h-[32px] ml-2 py-1 capitalize text-[#fff] text-sm font-semibold",
                            (tonChecking || showRecheck) &&
                              "pointer-events-none",
                          )}
                        >
                          {showRecheck && tonMissionPos === index ? (
                            <div className="rotateVoting">
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.0625 0.3125C16.6602 0.3125 17.1875 0.839844 17.1875 1.4375V6.5C17.1875 7.13281 16.6602 7.625 16.0625 7.625H11.5625C10.9297 7.625 10.4375 7.13281 10.4375 6.5C10.4375 5.90234 10.9297 5.375 11.5625 5.375H13.1094C12.125 4.00391 10.5078 3.125 8.75 3.125C6.39453 3.125 4.32031 4.63672 3.61719 6.85156C3.44141 7.44922 2.80859 7.76562 2.21094 7.58984C1.61328 7.41406 1.29688 6.78125 1.47266 6.18359C2.49219 3.01953 5.41016 0.875 8.75 0.875C11.2461 0.875 13.5312 2.10547 14.9375 4.07422V1.4375C14.9375 0.839844 15.4297 0.3125 16.0625 0.3125ZM15.2539 9.94531C15.8516 10.1211 16.168 10.7539 15.957 11.3516C14.9375 14.5156 12.0195 16.625 8.71484 16.625C6.21875 16.625 3.93359 15.4297 2.5625 13.4609V16.0625C2.5625 16.6953 2.03516 17.1875 1.4375 17.1875C0.804688 17.1875 0.3125 16.6953 0.3125 16.0625V11C0.3125 10.4023 0.804688 9.875 1.4375 9.875H5.9375C6.53516 9.875 7.0625 10.4023 7.0625 11C7.0625 11.6328 6.53516 12.125 5.9375 12.125H4.35547C5.33984 13.5312 6.95703 14.375 8.75 14.375C11.0703 14.375 13.1445 12.8984 13.8477 10.6836C14.0234 10.0859 14.6562 9.76953 15.2539 9.94531Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                          ) : (
                            "Check"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-span-4 flex items-center gap-1 justify-end">
                  {tonMission?.task_user?.completed ? (
                    <div className="w-full flex justify-end">
                      <div className="translate-x-1">
                        <svg
                          width="28"
                          height="24"
                          viewBox="0 0 28 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M25.9531 12C25.9531 13.7344 25.1094 15.2344 23.75 16.0781C24.0781 17.625 23.6562 19.3125 22.4375 20.4844C21.2656 21.7031 19.5781 22.1719 18.0312 21.8438C17.1406 23.1562 15.6875 24 13.9531 24C12.2656 24 10.7656 23.1562 9.875 21.8438C8.32812 22.1719 6.6875 21.7031 5.46875 20.4844C4.29688 19.3125 3.82812 17.625 4.15625 16.0781C2.84375 15.2344 1.95312 13.7344 1.95312 12C1.95312 10.3125 2.84375 8.8125 4.15625 7.96875C3.82812 6.42188 4.29688 4.73438 5.46875 3.51562C6.6875 2.34375 8.375 1.875 9.875 2.20312C10.7656 0.890625 12.2656 0 13.9531 0C15.6875 0 17.1406 0.890625 18.0312 2.20312C19.5781 1.875 21.2656 2.34375 22.4375 3.51562C23.6562 4.73438 24.0781 6.42188 23.75 7.96875C25.0625 8.8125 25.9531 10.3125 25.9531 12ZM18.5 10.1719C18.9688 9.75 18.9688 9.04688 18.5 8.625C18.0781 8.15625 17.375 8.15625 16.9531 8.625L12.5 13.0781L11 11.625C10.5781 11.1562 9.875 11.1562 9.45312 11.625C8.98438 12.0469 8.98438 12.75 9.45312 13.1719L11.7031 15.4219C11.8906 15.6562 12.1719 15.75 12.5 15.75C12.7812 15.75 13.0625 15.6562 13.25 15.4219L18.5 10.1719Z"
                            fill="#C6C6C6"
                          />
                        </svg>
                      </div>
                    </div>
                  ) : (
                    <>
                      <button
                        onClick={() => handleCheckTonStatus(tonMission)}
                        className={clsx(
                          "bg-[#0088CC] rounded-[38px] flex justify-center items-center w-[80px] py-1 capitalize text-[#fff] text-sm font-semibold",
                          (tonChecking || showRecheck) && "pointer-events-none",
                        )}
                      >
                        {!tonMission?.task_user && tonChecking ? (
                          <CircularProgress
                            style={{
                              color: "#F2FFE0",
                              width: 20,
                              height: 20,
                              marginTop: 5,
                              marginBottom: 5,
                            }}
                          />
                        ) : (
                          <TonIcon />
                        )}
                      </button>
                    </>
                  )}
                </div>
              </div>
            );
          })}

          {/* other mission  */}
          {otherMissions.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className={clsx(
                  "z-10 relative cursor-pointer grid grid-cols-12 gap-3 bg-white p-3 w-full",
                  " border-[#F3F3F3] border-solid",
                  index === otherMissions.length - 1
                    ? ""
                    : "border-b-[1px] pb-0",
                )}
              >
                <div className="col-span-2 flex items-center">
                  <div>
                    <img
                      className="size-10"
                      src={item?.metadata?.image_url}
                      alt="storage"
                    ></img>
                  </div>
                </div>
                <div className="col-span-7 flex items-center">
                  <div className="">
                    <p className="text-sm">{item.name}</p>
                    <div className="flex items-center gap-1">
                      <span className="mb-[2px]">+</span>
                      <img
                        className="size-[13px]"
                        src="/images/icons/token_icon.png?v=3"
                        alt="token"
                      ></img>
                      <p className=" text-[12px] font-semibold">
                        {getNumberFormatUs(
                          formatDecimals(item?.reward_amount ?? 0),
                          3,
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-3 flex items-center justify-end">
                  {item?.task_user?.completed ? (
                    <div className="w-full flex justify-end">
                      <div className="translate-x-1">
                        <svg
                          width="28"
                          height="24"
                          viewBox="0 0 28 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M25.9531 12C25.9531 13.7344 25.1094 15.2344 23.75 16.0781C24.0781 17.625 23.6562 19.3125 22.4375 20.4844C21.2656 21.7031 19.5781 22.1719 18.0312 21.8438C17.1406 23.1562 15.6875 24 13.9531 24C12.2656 24 10.7656 23.1562 9.875 21.8438C8.32812 22.1719 6.6875 21.7031 5.46875 20.4844C4.29688 19.3125 3.82812 17.625 4.15625 16.0781C2.84375 15.2344 1.95312 13.7344 1.95312 12C1.95312 10.3125 2.84375 8.8125 4.15625 7.96875C3.82812 6.42188 4.29688 4.73438 5.46875 3.51562C6.6875 2.34375 8.375 1.875 9.875 2.20312C10.7656 0.890625 12.2656 0 13.9531 0C15.6875 0 17.1406 0.890625 18.0312 2.20312C19.5781 1.875 21.2656 2.34375 22.4375 3.51562C23.6562 4.73438 24.0781 6.42188 23.75 7.96875C25.0625 8.8125 25.9531 10.3125 25.9531 12ZM18.5 10.1719C18.9688 9.75 18.9688 9.04688 18.5 8.625C18.0781 8.15625 17.375 8.15625 16.9531 8.625L12.5 13.0781L11 11.625C10.5781 11.1562 9.875 11.1562 9.45312 11.625C8.98438 12.0469 8.98438 12.75 9.45312 13.1719L11.7031 15.4219C11.8906 15.6562 12.1719 15.75 12.5 15.75C12.7812 15.75 13.0625 15.6562 13.25 15.4219L18.5 10.1719Z"
                            fill="#C6C6C6"
                          />
                        </svg>
                      </div>
                    </div>
                  ) : (
                    <StartBtn
                      isLoading={loadingItem === item.id && isDoingMission}
                      onClick={() => {
                        if (item.type === missionsTypes.TWITTER_FOLLOW) {
                          setLoadingItem(item.id);
                          const Url = twitterUrl(item);
                          tele.showPopup(
                            {
                              message: `Do you want to open ${item.metadata.name}`,
                              buttons: [
                                {
                                  id: "link",
                                  type: "default",
                                  text: "Open",
                                },
                                { type: "cancel" },
                              ],
                            },
                            async function (btn: any) {
                              if (btn === "link") {
                                if (
                                  item?.task_user === null ||
                                  !item?.task_user?.completed
                                ) {
                                  tele.openLink(
                                    item.type === missionsTypes.TWITTER_FOLLOW
                                      ? Url
                                      : item.metadata.url,
                                  );

                                  handleDoMission(item);
                                  return;
                                }
                                tele.openLink(
                                  item.type === missionsTypes.TWITTER_FOLLOW
                                    ? Url
                                    : item.metadata.url,
                                );
                              } else {
                              }
                            },
                          );
                          return;
                        }
                        setLoadingItem(item.id);
                        handleDoMission(item);
                      }}
                    >
                      Start
                    </StartBtn>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TonMission;
