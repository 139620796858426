import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const useGuildID = create(
  persist(
    (set) => ({
      guildId: null,
      setGuildId: (guildId: any) => set({ guildId: guildId }),
    }),
    {
      name: "guildId-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
