export const eggLevels = {
  basic: "basic",
  common: "common",
  uncommon: "uncommon",
  rare: "rare",
  epic: "epic",
  legendary: "legendary",
};

export const birdLevel = {
  sparrow: "sparrow",
  penguin: "penguin",
  parrot: "parrot",
  owl: "owl",
  hawk: "hawk",
  phoenix: "phoenix",
};

export const eggBackgroundColors = {
  [eggLevels.common]: "#F2FFE0",
  [eggLevels.uncommon]: "#139E00",
  [eggLevels.rare]: "#0063C3",
  [eggLevels.epic]: "#A434EE",
  [eggLevels.legendary]: "#FF7F00",
};

export const eggBorderColors = {
  [eggLevels.common]: "#88BC44",
  [eggLevels.uncommon]: "#92CF89",
  [eggLevels.rare]: "#2B97FF",
  [eggLevels.epic]: "#C773FF",
  [eggLevels.legendary]: "#FFAA56",
};

export const eggButtonShadowColors = {
  [eggLevels.common]: "#4C7E0B",
  [eggLevels.uncommon]: "#0B5E00",
  [eggLevels.rare]: "#003263",
  [eggLevels.epic]: "#6900AE",
  [eggLevels.legendary]: "#A15000",
};

export const eggTextShadowColors = {
  [eggLevels.common]: "transparent",
  [eggLevels.uncommon]: "#589A42",
  [eggLevels.rare]: "#127DE0",
  [eggLevels.epic]: "#C630B3",
  [eggLevels.legendary]: "#FF6B00",
};

export const eggButtonTextColors = {
  [eggLevels.common]: "#4C7E0B",
  [eggLevels.uncommon]: "#139E00",
  [eggLevels.rare]: "#0063C2",
  [eggLevels.epic]: "#A824FF",
  [eggLevels.legendary]: "#FF7F00",
};

export const birdBackgroundColors = {
  [birdLevel.sparrow]: "#F0CBAD",
  [birdLevel.penguin]: "#F2FFE0",
  [birdLevel.parrot]: "#139E00",
  [birdLevel.owl]: "#0063C3",
  [birdLevel.hawk]: "#A434EE",
  [birdLevel.phoenix]: "#FF7F00",
};

export const birdButtonTextColors = {
  [birdLevel.sparrow]: "#8D391B",
  [birdLevel.penguin]: "#3F5A78",
  [birdLevel.parrot]: "#139E00",
  [birdLevel.owl]: "#0063C2",
  [birdLevel.hawk]: "#A824FF",
  [birdLevel.phoenix]: "#FF7F00",
};

export const birdButtonShadowColors = {
  [birdLevel.sparrow]: "#8A3416",
  [birdLevel.penguin]: "transparent",
  [birdLevel.parrot]: "#589A42",
  [birdLevel.owl]: "#127DE0",
  [birdLevel.hawk]: "#C630B3",
  [birdLevel.phoenix]: "#FF6B00",
};

export const birdBorderColors = {
  [birdLevel.sparrow]: "#D66F4A",
  [birdLevel.penguin]: "#3F5A78",
  [birdLevel.parrot]: "#92CF89",
  [birdLevel.owl]: "#2B97FF",
  [birdLevel.hawk]: "#C773FF",
  [birdLevel.phoenix]: "#FFAA56",
};
