import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import clsx from "clsx";
import { motion } from "framer-motion";
import { ChangeEvent, forwardRef, useEffect, useMemo, useState } from "react";
import { isBrowser } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Backdrop from "../../../components/common/Backdrop";
import { dropIn } from "../../../components/common/ModalContainer";
import { navPaths } from "../../../constants/navbar.constants";
import useGetSetting from "../../../Hooks/useGetSetting";
import { ITEM_TYPES } from "../../Market/Pages/Market";
import useSellEgg from "../Hooks/useSellEgg";
import useTransferEgg from "../Hooks/useTransferEgg";

const SellEggModal = forwardRef(
  (
    {
      showModal,
      egg,
      tab,
      triggerReload,
      modalType,
    }: {
      showModal: any;
      egg: any;
      tab?: string;
      triggerReload?: any;
      modalType?: string;
    },
    ref: any
  ) => {
    const { sellAsync, isSellingEgg } = useSellEgg();
    const { transferAsync, isTransferEgg } = useTransferEgg();

    const navigate = useNavigate();
    const { data } = useGetSetting();

    const fee = data?.data?.data?.fee;
    const feeTransferEgg = data?.data?.data?.["fee-transfer-egg"];

    const handleSellEgg = () => {
      const sellPrice = (Number(price) * 10 ** 9).toFixed(0);
      sellAsync({
        id: egg?.id,
        price: Number(sellPrice),
        market: tab || "",
      })
        .then(() => {
          toast.error("List successfully", {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          });
          setTimeout(() => {
            navigate(navPaths.INVENTORY, {
              state: { showEgg: tab === "worm" ? false : true },
            });
          }, 1000);
          showModal(false);
          triggerReload && triggerReload();
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message, {
            style: {
              maxWidth: 337,
              height: 40,
              borderRadius: 8,
            },
            autoClose: 2000,
          });
          showModal(false);
          triggerReload && triggerReload();
        });
    };

    const handleTransferEgg = () => {
      const referalID = price?.split("=")[1];
      if (!referalID) {
        toast.error("Please input a valid referral link", {
          style: {
            maxWidth: 337,
            height: 40,
            borderRadius: 8,
          },
          autoClose: 2000,
        });
        return;
      }
      transferAsync({
        id: egg?.id,
        telegramId: Number(referalID),
      })
        .then(() => {
          toast.error("Transfer successfully", {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          });

          setTimeout(() => {
            navigate(navPaths.INVENTORY, {
              state: { showEgg: tab === "worm" ? false : true },
            });
          }, 1000);
          showModal(false);
          triggerReload && triggerReload();
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message, {
            style: {
              maxWidth: 337,
              height: 40,
              borderRadius: 8,
            },
            autoClose: 2000,
          });
          showModal(false);
          triggerReload && triggerReload();
        });
    };

    const color = ITEM_TYPES.find((item) => item.type === egg?.type)?.color;

    const [price, setPrice] = useState<string>();

    const handleInputPrice = (event: ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value;

      // if (
      //   !value ||
      //   value.match(/^\d{1,}(\.\d{0,9})?$/) ||
      //   //eslint-disable-next-line
      //   value.match(/^\d{1,}(\,\d{0,9})?$/)
      // ) {
      //   if (value?.length > 8) return;
      //   // // Remove anything that isn't valid in a number
      //   // .replace(/[^\d-.]/g, "")
      //   // // Remove all dashes unless it is the first character
      //   // .replace(/(?!^)-/g, "")
      //   // // Remove all periods unless it is the last one
      //   // .replace(/\.(?=.*\.)/g, "");
      //   setPrice(value.replace(/,/g, "."));
      // }

      if (tab === "worm") {
        if (value?.length > 8) return;
        if (
          (value.match(/^(\d*\.{0,1}\d{0,2}$)/) ||
            // eslint-disable-next-line no-useless-escape
            value.match(/^(\d*\,{0,1}\d{0,2}$)/)) &&
          value.charAt(0) !== "," &&
          value.charAt(0) !== "." &&
          value.charAt(7) !== "," &&
          value.charAt(7) !== "."
        ) {
          const formatedValue = value
            .replace(/,/g, ".")
            // eslint-disable-next-line no-useless-escape
            .replace(/[^0-9\.,]/g, "")
            .replace(/\.(?=.*\.)/g, "")
            .replace(/[^\d-.]/g, "");

          setPrice(formatedValue);
        } else {
          return;
        }
      } else {
        if (modalType === "transfer") {
          setPrice(value);
          return;
        } else {
          if (value?.length > 8) return;
          const formatedValue = value.replace(/\D+/g, "");
          setPrice(formatedValue);
        }
      }
    };

    // const handlePasteEgg = (event: any) => {
    //   const value = event?.target?.value;
    //   if (modalType === "transfer") {
    //     console.log("handlePasteEgg", event?.target);

    //     setPrice(value);
    //   } else {
    //     setPrice(value.replace(/\D+/g, ""));
    //   }
    // };

    // const handlePasteWorm = (event: any) => {
    //   const value = event?.target?.value;
    //   setPrice(value.replace(/\D+/g, ""));
    // };

    // const handlePaste = (event: any) => {
    //   const value = event?.target?.value;
    //   console.log("handlePaste", value);

    //   setPrice(value);
    // };

    useEffect(() => {
      const target = document.getElementById("modal-input") as Element;

      target.addEventListener("paste", (event: any) => {
        event.preventDefault();
        let paste = event.clipboardData.getData("text");
        if (tab === "worm") {
          setPrice(paste.replace(/\D+/g, "").substring(0, 8));
        }
        if (tab !== "worm") {
          if (modalType === "transfer") {
            setPrice(paste);
          } else {
            setPrice(paste.replace(/\D+/g, "").substring(0, 8));
          }
        }
      });
    }, [modalType, tab]);

    const [inputing, setInputing] = useState<boolean>(false);
    const [hideCarret, setHideCarret] = useState<boolean>(true);
    const handleKeyboard = () => {
      // Telegram Desktop
      if (isBrowser) {
        setHideCarret(false);
        return;
      } else {
        setInputing(true);
        setTimeout(() => setHideCarret(false), 300);
      }
    };

    const handleOnBlur = () => {
      setInputing(false);
      setHideCarret(true);
    };

    const handleSell = () => {
      if (!price) {
        toast.success(
          tab === "worm"
            ? "Please input a valid price"
            : tab !== "worm" && modalType === "sell"
            ? "Please input a valid price"
            : "Please input a valid referral link",
          {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          }
        );
        return;
      }
      if (tab === "worm") {
        handleSellEgg();
      }
      if (tab !== "worm" && modalType === "sell") {
        handleSellEgg();
      }
      if (tab !== "worm" && modalType === "transfer") {
        handleTransferEgg();
      }
    };

    // const [hideContainer, setHideContainer] = useState<boolean>(true);

    // useEffect(() => {
    //   if (!isShowModal) {
    //     setTimeout(() => {
    //       setHideContainer(false);
    //     }, 300);
    //   } else {
    //     setHideContainer(true);
    //   }
    // }, [isShowModal]);

    const inputType = useMemo(() => {
      if (tab === "worm") {
        return "decimal";
      }
      if (tab !== "worm") {
        if (modalType === "sell") {
          return "numeric";
        }
        if (modalType === "transfer") return "text";
      }
    }, [tab, modalType]);

    const feeValue = useMemo(() => {
      if (tab === "worm") {
        return `${fee ? fee / 100 : "loading "}%`;
      }
      if (modalType === "transfer" && tab !== "worm") {
        return feeTransferEgg && egg?.type && feeTransferEgg[egg?.type]
          ? feeTransferEgg[egg?.type] / 10 ** 9
          : "loading";
      }
      if (modalType === "sell" && tab !== "worm") {
        return `${fee ? fee / 100 : "loading "}%`;
      }
    }, [egg?.type, fee, feeTransferEgg, modalType, tab]);

    return (
      <Backdrop onClick={() => showModal(false)}>
        <motion.div
          onClick={(e) => e.stopPropagation()}
          variants={dropIn}
          initial="hidden"
          animate={inputing ? "inputing" : "visible"}
          exit="exit"
          className="motion-modal"
        >
          <div
            ref={ref}
            className={clsx(
              "bg-[#F2FFE0] rounded-[20px] p-[24px] flex flex-col gap-4 items-center justify-center relative"
            )}
          >
            <div className="w-[250px] text-center text-[20px] font-[800] capitalize">
              {modalType ? modalType : "Sell"}
            </div>
            <div className="w-[40%]">
              <img
                src={
                  tab === "worm"
                    ? `/images/inventory/worm/${egg?.type}.png`
                    : `/images/egg/${egg?.type}.png`
                }
                alt={"sell-egg"}
                className="w-full object-contain z-10 -mt-2"
              />
            </div>
            <div
              className={clsx(
                "font-[600] text-[16px] text-white rounded-[20px] px-2 py-1 flex items-center gap-1 uppercase -mt-2"
              )}
              style={{ backgroundColor: color }}
            >
              <div className="bg-white rounded-full text-[#646464] w-[18px] h-[18px] flex items-center justify-center">
                #
              </div>
              <span
                className={
                  egg?.type === "common" ? "text-black/70" : "text-white"
                }
              >
                {egg?.id?.slice(egg?.id?.length - 9, egg?.id?.length)}
              </span>
            </div>
            <div className="flex flex-col gap-2 w-full">
              {tab !== "worm" && (
                <span className="text-sm text-black font-[500]">
                  {modalType === "sell"
                    ? "Selling price:"
                    : "Input receiver’s referral link"}
                </span>
              )}
              {tab === "worm" && (
                <span className="text-sm text-black font-[500]">
                  {"Selling price:"}
                </span>
              )}
              <div className="flex w-full h-[46px] flex-1 bg-white relative">
                <input
                  id="modal-input"
                  // ref={inputRef}
                  value={price}
                  onChange={handleInputPrice}
                  // onPaste={tab !== "worm" ? handlePasteEgg : handlePasteWorm}
                  // onPaste={handlePaste}
                  onFocus={handleKeyboard}
                  onBlur={handleOnBlur}
                  // inputMode={tab === "worm" ? "decimal" : "numeric" }
                  inputMode={inputType}
                  placeholder={
                    tab === "worm"
                      ? "Input here"
                      : tab !== "worm" && modalType === "sell"
                      ? "Input here"
                      : "t.me/seed_coin_bot/app?startapp=xxxx"
                  }
                  className="w-full h-[46px] pl-[10px] pr-[10px] rounded-lg border border-[#A4BC82] transferEgg"
                  style={{
                    // fix caret on ios
                    caretColor: hideCarret ? "transparent" : "#A4BC82",
                  }}
                />
                {modalType === "sell" && (
                  <img
                    src="/images/icons/token_icon.png"
                    width={22}
                    height={22}
                    alt="token"
                    className="absolute my-auto top-0 bottom-0 right-[10px]"
                  ></img>
                )}
              </div>
              <div className="text-[12px] flex items-center gap-1">
                Fee:
                <img
                  src="/images/icons/token_icon.png"
                  width={10}
                  height={10}
                  alt="token"
                  className="ml-[2px]"
                ></img>
                {feeValue}
              </div>
            </div>
            <div className="flex items-center gap-3 w-full">
              <Button
                sx={{ border: 1 }}
                onClick={() => showModal(false)}
                // startIcon={<img src="images/icons/copy.svg" alt="copy" />}
                className={clsx(
                  "font-bold capitalize text-[16px] py-2 lsm:py-[18px] w-full h-[50px] rounded-xl ",
                  "btn-hover bg-[#F2FFE0] drop-shadow-[0_3px_0px_#4C7E0B] text-[#4C7E0B] border-[2px]"
                )}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={isSellingEgg || isTransferEgg}
                sx={{ border: 1 }}
                onClick={handleSell}
                // startIcon={<img src="images/icons/copy.svg" alt="copy" />}
                className={clsx(
                  "font-bold capitalize text-[16px] text-white py-2 lsm:py-[18px] w-full h-[50px] rounded-xl ",
                  "btn-hover bg-gradient-to-r from-[#97C35B] to-[#61A700] drop-shadow-[0_4px_0px_#4C7E0B] border-none"
                )}
              >
                Confirm
              </LoadingButton>
            </div>
          </div>
        </motion.div>
      </Backdrop>
    );
  }
);

export default SellEggModal;
