import { useMutation } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
// import { CatchedWorm } from "../../../types/worm";
// import { ServerResponse } from "../../../types/api";

// export async function catchWorm() {
//   return await api.post("/api/v1/worms/catch");
// }

export const catchWorm = async () => {
  const response = await fetchApi("POST", "/worms/catch");
  if (response.error) {
    return {
      error: response.error.message,
    };
  }

  const catchWormResponse: any = await response.data.json();
  if (catchWormResponse?.message) {
    return {
      error: catchWormResponse?.message,
    };
  }

  return {
    data: catchWormResponse.data,
  };
};

export default function useCatchWorm() {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["catchWorm"],
    mutationFn: catchWorm,
  });

  return { catchWormAsync: mutateAsync, isCatchingWorm: isPending };
}
