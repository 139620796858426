import { useMutation } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { Result } from "../../../types/utils";
import { ServerResponse } from "../../../types/api";

export const upgradeWater = async (): Promise<Result<boolean, string>> => {
  const response = await fetchApi("POST", `/upgrades/holy-water`);
  if (response.error) {
    return {
      error: response.error.message,
    };
  }

  const dowaterMissionResponse: ServerResponse<null> =
    await response.data.json();
  if (dowaterMissionResponse.error) {
    return {
      error: dowaterMissionResponse.error.message,
    };
  }

  return {
    data: true,
  };
};

export default function useUpgradeWater() {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["upgradeWater"],
    mutationFn: upgradeWater,
  });

  return { updagradeWaterAsync: mutateAsync, isUpgradingWater: isPending };
}
