import React from "react";
import BaseBtn from "../BaseBtn";
import clsx from "clsx";
import { formatMonthYear } from "../../../../utils/time";

const Battle = () => {
  return (
    <div
      className={clsx(
        "px-[17px] mb-3 rounded-2xl bg-cover bg-no-repeat bg-center w-full h-[149px] relative"
      )}
      style={{ backgroundImage: "url('/images/games/battle_bird_bg.png')" }}
    >
      <div className="absolute top-[50%] -translate-y-[50%] flex items-center justify-between gap-[5px]">
        <img
          className="w-[119px] h-[107px]"
          src="/images/games/battle_bird_logo.png"
          alt=""
        ></img>
        <div className="flex flex-col justify-center items-start">
          <p className="game_battle_text_border">BattleSEED</p>
          <p className="poppins-medium text-[#fff] drop-shadow-[0_1px_1px_#000000]">{`Opening time: ${formatMonthYear(
            "Monday, 16 September 2024 08:31:41"
          )}`}</p>
          <div className="flex items-start mt-[14px]">
            <BaseBtn className="border-[#A7FF62] bg-[#142D004D] size-9 mr-[6px]">
              <img
                className="size-[26px]"
                src="/images/games/chest.png"
                alt=""
              ></img>
            </BaseBtn>
            <BaseBtn className="border-[#A7FF62] bg-[#142D004D] size-9 mr-[10px]">
              <img
                className="w-[22px] h-[26px]"
                src="/images/games/unkown_egg_piece.png"
                alt=""
              ></img>
            </BaseBtn>
            <BaseBtn className="border-[#A7FF62] bg-[#5BCD00] py-1 px-[29px] h-9 game_battle_text_border text-base shadow-[0_3px_0_#075200]">
              Play
            </BaseBtn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Battle;
