import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { Result } from "../../../types/utils";
import { ServerError, ServerResponse } from "../../../types/api";

export const getPriceList = async (): Promise<Result<any, ServerError>> => {
  const response = await fetchApi("GET", `/guild/price-list`);
  if (response.error) {
    // Throw to retry
    throw new Error("network-error");
  }

  const priceListResponse: ServerResponse<any> = await response.data.json();
  if (priceListResponse.error) {
    return {
      error: priceListResponse.error,
    };
  }

  return {
    data: priceListResponse.data,
  };
};

export default function useGetPriceList() {
  const { data, refetch, isFetching, isRefetching } = useQuery({
    queryKey: ["PriceList"],
    queryFn: () => getPriceList(),
    retry: 1,
  });

  return {
    priceList: data,
    isLoadingPriceList: isFetching,
    refreshPriceList: refetch,
    isRefreshingPriceList: isRefetching,
  };
}
