import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { navPaths } from "../../../constants/navbar.constants";
import {
  formatCompactNumber,
  formatDecimals,
} from "../../../utils/formatNumber";
import { useGuildID } from "../../../store/useGuildId";

const GuildItem = ({
  item,
  rank,
  guildType,
}: {
  item: any;
  rank: number;
  guildType: string;
}) => {
  const navigate = useNavigate();
  const setGuildId = useGuildID((state: any) => state.setGuildId);

  return (
    <div
      onClick={() => {
        navigate(navPaths.GUILD_DETAILS, { state: { ...item } });
        setGuildId(item?.guild_id);
      }}
      className={clsx(
        "border-b-[1px] grid grid-cols-12  border-[#0000001A] ",
        "px-4 py-[10px] mb-4"
      )}
    >
      <div className="w-[45px] flex items-center justify-center col-span-2">
        {rank > 3 ? (
          <p className="text-sm text-center font-bold">{rank}</p>
        ) : (
          <img
            className="size-[45px]"
            src={`/images/guild/guild_rank/${rank}.png`}
            alt=""
          ></img>
        )}
      </div>
      <div className="size-[48px] rounded-[50%] overflow-hidden col-span-2">
        <img src={`/images/guild/guild_level/${guildType}.png`} alt=""></img>
      </div>
      <div className="flex flex-col justify-center items-start relative col-span-6 pl-2">
        <p className="w-full font-semibold text-[14px] leading-4 text-ellipsis overflow-hidden">
          {item.name}
        </p>
        <div className="flex items-center gap-3">
          <div
            className={clsx(
              "text-[12px] w-[50px] flex gap-[2px] justify-start items-center font-medium"
            )}
          >
            <svg
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.87305 6.1875C7.76758 6.1875 9.31055 7.73047 9.31055 9.625C9.31055 9.97656 9.01758 10.25 8.68555 10.25H1.18555C0.833984 10.25 0.560547 9.97656 0.560547 9.625C0.560547 7.73047 2.08398 6.1875 3.99805 6.1875H5.87305ZM1.49805 9.3125H8.35352C8.19727 8.08203 7.14258 7.125 5.87305 7.125H3.99805C2.70898 7.125 1.6543 8.08203 1.49805 9.3125ZM4.93555 5.25C3.54883 5.25 2.43555 4.13672 2.43555 2.75C2.43555 1.38281 3.54883 0.25 4.93555 0.25C6.30273 0.25 7.43555 1.38281 7.43555 2.75C7.43555 4.13672 6.30273 5.25 4.93555 5.25ZM4.93555 1.1875C4.05664 1.1875 3.37305 1.89062 3.37305 2.75C3.37305 3.62891 4.05664 4.3125 4.93555 4.3125C5.79492 4.3125 6.49805 3.62891 6.49805 2.75C6.49805 1.89062 5.79492 1.1875 4.93555 1.1875Z"
                fill="black"
              />
            </svg>
            {item?.number_member}/
            {item?.size === -1 ? "Unlimited" : formatCompactNumber(item?.size)}
          </div>
          <div className="bg-[#7D7D7D] size-1 rounded-[50%]"></div>
          <div className="flex justify-center items-center gap-[2px] col-span-2 text-[12px] font-semibold">
            <img
              src="/images/icons/token_icon.png?v=3"
              width={13}
              height={13}
              alt="token"
            ></img>
            <p>{formatDecimals(item?.hunted ?? 0).toFixed(0)}</p>
          </div>
          <div className="bg-[#7D7D7D] size-1 rounded-[50%]"></div>
          <div className="flex justify-center items-center gap-[2px] col-span-2 text-[12px] font-semibold">
            + <p>{5 + item?.distribution_rate / 100 ?? 0}%</p>
          </div>
        </div>
      </div>

      <div className="col-span-2 flex items-center justify-end">
        <svg
          width="8"
          height="13"
          viewBox="0 0 8 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.12891 0.34375L7.15625 5.6875C7.29688 5.86328 7.40234 6.07422 7.40234 6.25C7.40234 6.46094 7.29688 6.67188 7.15625 6.84766L2.12891 12.1914C1.8125 12.543 1.28516 12.543 0.933594 12.2266C0.582031 11.9102 0.582031 11.3828 0.898438 11.0312L5.39844 6.25L0.898438 1.50391C0.582031 1.15234 0.582031 0.625 0.933594 0.308594C1.28516 -0.0078125 1.8125 -0.0078125 2.12891 0.34375Z"
            fill="black"
          />
        </svg>
      </div>
    </div>
  );
};

export default GuildItem;
