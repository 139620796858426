import clsx from "clsx";
import { forwardRef, useEffect, useState } from "react";
import { getNumberFormatUs } from "../../../utils/formatNumber";
import {
  MARKET_HISTORY_STATUS,
  MARKET_HISTORY_STATUS_DISPLAY,
  removeDuplicate,
} from "../../../utils/helper";
import useGetHistoryData from "../Hooks/useHistoryData";
import { CircularProgress } from "@mui/material";
import Backdrop from "../../../components/common/Backdrop";
import { motion } from "framer-motion";
import { dropIn } from "../../../components/common/ModalContainer";
import { useQueryClient } from "@tanstack/react-query";
// import useInfiniteScroll from "../../../Hooks/useInfiniteScroll";

const HistoryModal = forwardRef(
  (
    {
      marketType,
      isShowModal,
      setIsShowModal,
      userID,
    }: {
      marketType: string;
      isShowModal: boolean;
      setIsShowModal: any;
      userID: string | undefined;
    },
    ref: any,
  ) => {
    const qc = useQueryClient();

    const [tab, setTab] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const { historyData, isLoadingHistoryData, refreshHistoryData } =
      useGetHistoryData({
        history_type: tab === 0 ? "buy" : "sell",
        marketType,
        loadHistory: isShowModal,
        page: page,
      });

    const [disableScroll, setDisableScroll] = useState<boolean>(false);
    const handleScroll = (e: React.UIEvent<HTMLElement>) => {
      const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
      const bottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 1;
      if (bottom) {
        setDisableScroll(true);
        setPage((prev) => prev + 1);
      }
    };

    useEffect(() => {
      return () => {
        qc.removeQueries({ queryKey: ["historyData"] });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      refreshHistoryData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, tab]);

    const [historyDataState, setHistoryDataState] = useState<any[]>([]);

    useEffect(() => {
      if (page === 1) {
        historyData?.data?.items?.length > 0
          ? setHistoryDataState(historyData?.data?.items)
          : setHistoryDataState([]);
      } else {
        historyData?.data?.items?.length &&
          setHistoryDataState((prev: any[]) => {
            return removeDuplicate([...prev, ...historyData?.data?.items]);
          });
      }
    }, [historyData, page]);

    useEffect(() => {
      isLoadingHistoryData ? setDisableScroll(true) : setDisableScroll(false);
    }, [isLoadingHistoryData]);

    const checkShowLoadMore = () => {
      return historyData?.data?.items?.length >= historyData?.data?.total
        ? false
        : true;
    };

    const handleSetTab = (index: number) => {
      setPage(1);
      setTab(index);
    };

    return (
      <Backdrop onClick={() => setIsShowModal(false)} className=" items-end">
        <motion.div
          onClick={(e) => e.stopPropagation()}
          variants={dropIn}
          initial="hiddenHistory"
          animate="visible"
          exit="exit"
          ref={ref}
          id="historyContainer"
          className={clsx(
            "bg-[#F2FFE0] rounded-t-[20px] p-[24px] w-full h-[80%] pb-[90px] flex flex-col gap-3 items-center relative",
          )}
        >
          <div className="w-full flex items-center justify-between">
            <span className="text-[20px] font-[800]">History</span>
            <div className="flex items-center bg-[#00000014] rounded-[36px] p-1 relative select-none overflow-hidden">
              {Array.from({ length: 2 }).map((_, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => handleSetTab(index)}
                    className={clsx(
                      "text-sm font-bold px-[26px] py-[5px] cursor-pointer transition-all ease-linear",
                      tab === index ? "text-white" : "text-black",
                    )}
                  >
                    <div className="z-10 relative">
                      {index === 0 ? "Buy" : "Sell"}
                    </div>
                    {tab === index && (
                      <motion.div
                        transition={{
                          type: "just",
                          stiffness: 100,
                        }}
                        className={clsx(
                          "top-1 bottom-1 bg-[#7FB731] absolute rounded-[34px] z-0",
                          index === 0
                            ? "right-[50%] left-1"
                            : "left-[50%] right-1",
                        )}
                        layoutId="history"
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-2 flex items-center justify-between w-full font-[600]">
            <div className="flex-1">Item</div>
            <div className="flex-1 text-center -ml-8 mr-2">ID</div>
            {/* <div className="flex-1 text-center">Action</div> */}
            <div className="flex-1 text-center">Price</div>
            <div className="flex-1 text-center mr-2">Time</div>
            <div className="flex-1 text-end">Status</div>
          </div>
          <hr className=" border-dashed border-t border-[#4F7815] text-white bg-white h-[1px] w-full" />
          <div
            id="historyList"
            className={clsx(
              "flex flex-col gap-3 w-full",
              disableScroll ? " overflow-hidden" : "overflow-scroll",
            )}
            onScroll={handleScroll}
          >
            {historyDataState?.map((item: any, index: number) => {
              const id =
                marketType === "egg"
                  ? item?.egg_id?.slice(
                      item?.egg_id?.length - 9,
                      item?.egg_id?.length,
                    )
                  : item?.worm_id?.slice(
                      item?.worm_id?.length - 9,
                      item?.worm_id?.length,
                    );
              const time = new Date(item?.updated_at);
              const timeString = time.getMonth() + 1 + "/" + time.getDate();
              return (
                <div
                  key={index}
                  className="flex items-center justify-between w-full"
                >
                  {/* <div>{index + 1}</div> */}
                  <div className="flex-1">
                    <img
                      src={
                        marketType === "egg"
                          ? `/images/egg/${item?.egg_type}.png`
                          : `/images/inventory/worm/${item?.worm_type}.png`
                      }
                      alt={"market-item"}
                      className="h-[25px] aspect-square object-contain"
                    />
                  </div>
                  <div className="flex-1 text-center -ml-8 uppercase">
                    {id?.slice(0, 2) +
                      "..." +
                      id?.slice(id?.length - 2, id?.length)}
                  </div>
                  {/* <div className="flex-1 text-center capitalize">
                    {getAction(item)}
                  </div> */}
                  <div className="flex-1 text-center text-ellipsis max-w-[80px] overflow-clip">
                    {getNumberFormatUs(item?.price_gross / 10 ** 9, 2)}
                  </div>
                  <div className="flex-1 text-center">{timeString}</div>
                  <div className="flex-1 min-w-[73px]">
                    {item?.status === MARKET_HISTORY_STATUS.BOUGHT &&
                      item?.bought_by === userID && (
                        <div className="flex-1 text-end font-[600] capitalize text-[#4F7815]">
                          {MARKET_HISTORY_STATUS_DISPLAY.BOUGHT}
                        </div>
                      )}
                    {item?.status === MARKET_HISTORY_STATUS.BOUGHT &&
                      item?.bought_by !== userID && (
                        <div className="flex-1 text-end font-[600] capitalize text-[#4F7815]">
                          {MARKET_HISTORY_STATUS_DISPLAY.SOLD}
                        </div>
                      )}
                    {item?.status === MARKET_HISTORY_STATUS.ON_SALE && (
                      <div className="flex-1 text-end font-[600] capitalize text-[#FF8515]">
                        {MARKET_HISTORY_STATUS_DISPLAY.LISTING}
                      </div>
                    )}
                    {item?.status === MARKET_HISTORY_STATUS.REMOVED && (
                      <div className="flex-1 text-end font-[600] capitalize text-[#C53D3D]">
                        {MARKET_HISTORY_STATUS_DISPLAY.UNLISTED}
                      </div>
                    )}
                    {/* {item?.status === MARKET_HISTORY_STATUS.CANCELED && (
                    <div className="flex-1 text-end font-[600] capitalize text-[#f63434]">
                      {MARKET_HISTORY_STATUS.CANCELED}
                    </div>
                  )} */}
                  </div>
                </div>
              );
            })}
            {checkShowLoadMore() && (
              <div className="mt-4 w-full h-[50px] flex items-center justify-center">
                <div className=" h-[36px]">
                  <CircularProgress color="success" />
                </div>
              </div>
            )}
          </div>
        </motion.div>
      </Backdrop>
    );
  },
);

export default HistoryModal;
