import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerResponse } from "../../../types/api";

export const getFusionFee = async ({ type }: { type: string }) => {
  const response = await fetchApi("GET", `/fusion-seed-fee?type=${type}`);
  if (response.error) {
    throw new Error("network-error");
  }

  const getFusionFeeResponse: ServerResponse<any> = await response.data.json();
  if (getFusionFeeResponse.error) {
    return {
      error: getFusionFeeResponse.error,
    };
  }

  return getFusionFeeResponse.data;
};

export default function useFusionFee({ type }: { type: string }) {
  const { data, isPending, refetch, isRefetching } = useQuery({
    queryKey: ["FusionFee"],
    queryFn: () => !!type && getFusionFee({ type }),
    retry: 1,
  });

  return {
    FusionFee: data,
    FusionFeeLoading: isPending,
    refeshFusionFee: refetch,
    isRefetching,
  };
}
