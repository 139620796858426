import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function disconect() {
  return await api.post("/api/v1/profile/wallets/ton/disconnect");
}

export default function useDisconectWallet() {
  return useMutation({
    mutationKey: ["disconect"],
    mutationFn: disconect,
  });
}
