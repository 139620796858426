import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function spinReward(id: string) {
  return await api.post(`/api/v1/spin-reward`, {
    ticket_id: id,
  });
}

export default function useSpinReward() {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["spinReward"],
    mutationFn: spinReward,
  });

  return { spinRewardAsync: mutateAsync, isSpiningReward: isPending };
}
