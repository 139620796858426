import clsx from "clsx";
import { motion } from "framer-motion";
import { Dispatch, SetStateAction } from "react";
import Backdrop from "../../../components/common/Backdrop";
import { dropIn } from "../../../components/common/ModalContainer";
import useGetHistoryReleaseBird from "../Hooks/useGetHistoryReleaseBird";

const HistoryReleaseBird = ({
  setShowHistoryModal,
}: {
  setShowHistoryModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const { historyReleaseBird } = useGetHistoryReleaseBird();

  const historyData = historyReleaseBird?.data;
  // const historyData = {
  //   total: 1,
  //   page: 1,
  //   page_size: 10,
  //   items: [
  //     {
  //       id: "67b72e8a-d0a4-430b-90c2-ef88a1b7c2ff",
  //       user_id: "f023344f-12ac-43fc-868e-8e0c5bd45ed1",
  //       bird_id: "b944552b-a692-4226-bf11-c81848891af7",
  //       status: "success",
  //       created_at: "2024-08-26T15:07:06.937434+07:00",
  //       updated_at: "2024-08-26T15:07:06.937434+07:00",
  //       achieve: false,
  //       egg_id: null,
  //       egg_type: null,
  //     },
  //   ],
  // };

  return (
    <Backdrop
      onClick={() => setShowHistoryModal(false)}
      className=" items-end !bg-black/80"
    >
      <motion.div
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hiddenHistory"
        animate="visible"
        exit="exit"
        id="historyContainer"
        className={clsx(
          "bg-[#F2FFE0] rounded-t-[20px] p-5 w-full h-[50%] pb-5 flex flex-col relative",
        )}
      >
        <span className="font-bold text-black">
          Release history {historyData?.total ? `(${historyData?.total})` : ""}
        </span>
        <div className="mt-3 h-full overflow-y-scroll">
          {historyData?.items?.map((egg: any) => {
            const date = new Date(egg?.updated_at).getDate();
            const month = new Date(egg?.updated_at).getMonth() + 1;
            const hour = new Date(egg?.updated_at).getHours();
            const minutes = new Date(egg?.updated_at).getMinutes();

            return (
              <div
                key={egg?.id}
                className="py-3 flex justify-between border-b border-[#D7E2C7]"
              >
                <div className="flex flex-col">
                  <span className="text-xs">
                    You have released 1{" "}
                    <span className="capitalize">{egg?.bird_type}</span> Bird
                  </span>
                  {egg?.egg_type && (
                    <span
                      className={clsx("text-xs font-bold", "text-[#4C7E0B]")}
                    >
                      +1 <span className="capitalize">{egg?.egg_type}</span> Egg
                    </span>
                  )}
                </div>
                <span className="text-xs text-[#616161]">
                  {date}/{month} {hour < 10 ? "0" + hour : hour}:
                  {minutes < 10 ? "0" + minutes : minutes}
                </span>
              </div>
            );
          })}
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default HistoryReleaseBird;
