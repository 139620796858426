import clsx from "clsx";
import {
  formatDecimals,
  getNumberFormatUs,
} from "../../../../utils/formatNumber";
import { missionsTypes } from "../../../../constants/missions.constants";
import { twitterUrl } from "../../Utils/missions";

const CompletedMissionSection = ({
  completedMissions,
  handleDoMission,
}: {
  completedMissions: any;
  handleDoMission: (item: any) => void;
}) => {
  const tele = window.Telegram.WebApp;

  return (
    <>
      <div className="pb-[10px] font-semibold flex items-center justify-start gap-1">
        <div>
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.87725 0.982442C7.07762 0.339185 7.92238 0.339187 8.12275 0.982441L9.10026 4.12059C9.3167 4.81545 9.94621 5.30564 10.6778 5.30564H13.841C14.1468 5.30564 14.3744 5.50101 14.4638 5.78808C14.5536 6.07647 14.4808 6.39146 14.219 6.58991L11.6598 8.5294C11.0812 8.9679 10.8487 9.73374 11.0642 10.4256L12.0417 13.5638C12.1472 13.9025 12.0177 14.1982 11.7969 14.3656C11.5792 14.5306 11.2953 14.5583 11.0411 14.3656L8.48192 12.4261C7.89791 11.9835 7.1021 11.9835 6.51808 12.4261L3.95893 14.3656C3.70472 14.5583 3.42083 14.5306 3.20311 14.3656C2.98227 14.1982 2.85277 13.9025 2.95827 13.5638L3.93578 10.4256C4.15129 9.73374 3.9188 8.9679 3.34019 8.5294L0.781044 6.58991C0.519179 6.39146 0.446377 6.07647 0.536209 5.78808C0.625629 5.50101 0.853247 5.30564 1.15896 5.30564H4.32224C5.05379 5.30564 5.6833 4.81545 5.89974 4.12059L6.87725 0.982442Z"
              stroke="black"
            />
          </svg>
        </div>
        <p className="text-[18px]">Completed</p>
      </div>
      <div
        className={clsx(
          "px-3 pt-3",
          "mb-6 border-[1px] border-[#F3F3F3] rounded-2xl bg-[#fff]"
        )}
      >
        {completedMissions?.map((item: any, index: number) => {
          const reward = getNumberFormatUs(
            Number(formatDecimals(item.reward_amount)),
            3
          );
          return (
            <div
              onClick={() => {
                if (item.type !== missionsTypes.TWITTER_FOLLOW) {
                  handleDoMission(item);
                  return;
                }
                const Url = twitterUrl(item);
                tele.showPopup(
                  {
                    message: `Do you want to open ${item.metadata.name}`,
                    buttons: [
                      {
                        id: "link",
                        type: "default",
                        text: "Open",
                      },
                      { type: "cancel" },
                    ],
                  },
                  async function (btn: any) {
                    if (btn === "link") {
                      if (
                        item?.task_user === null ||
                        !item?.task_user?.completed
                      ) {
                        tele.openLink(
                          item.type === missionsTypes.TWITTER_FOLLOW
                            ? Url
                            : item.metadata.url
                        );

                        handleDoMission(item);
                        return;
                      }
                      tele.openLink(
                        item.type === missionsTypes.TWITTER_FOLLOW
                          ? Url
                          : item.metadata.url
                      );
                    } else {
                    }
                  }
                );
              }}
              key={index}
              className={clsx(
                "z-10 relative cursor-pointer grid grid-cols-12 gap-3 bg-white  w-full mb-3",
                "border-[#F3F3F3] border-solid",
                index === completedMissions.length - 1
                  ? ""
                  : "border-b-[1px] pb-3"
              )}
            >
              <div className="col-span-2 flex items-center">
                <div>
                  <img
                    className="size-10"
                    src={item.metadata.image_url}
                    alt="storage"
                  ></img>
                </div>
              </div>
              <div className="col-span-7 flex items-center">
                <div className="">
                  <p className="font-medium text-sm">{item.name}</p>
                  <div className="flex items-center gap-1">
                    <span className="mb-[2px]">+</span>
                    <img
                      className="size-[13px]"
                      src="/images/icons/token_icon.png?v=3"
                      alt="token"
                    ></img>
                    <p className=" text-[12px] font-semibold">{reward}</p>
                  </div>
                </div>
              </div>
              <div className="col-span-3 flex items-center justify-end">
                <div className="translate-x-1">
                  <svg
                    width="28"
                    height="24"
                    viewBox="0 0 28 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25.9531 12C25.9531 13.7344 25.1094 15.2344 23.75 16.0781C24.0781 17.625 23.6562 19.3125 22.4375 20.4844C21.2656 21.7031 19.5781 22.1719 18.0312 21.8438C17.1406 23.1562 15.6875 24 13.9531 24C12.2656 24 10.7656 23.1562 9.875 21.8438C8.32812 22.1719 6.6875 21.7031 5.46875 20.4844C4.29688 19.3125 3.82812 17.625 4.15625 16.0781C2.84375 15.2344 1.95312 13.7344 1.95312 12C1.95312 10.3125 2.84375 8.8125 4.15625 7.96875C3.82812 6.42188 4.29688 4.73438 5.46875 3.51562C6.6875 2.34375 8.375 1.875 9.875 2.20312C10.7656 0.890625 12.2656 0 13.9531 0C15.6875 0 17.1406 0.890625 18.0312 2.20312C19.5781 1.875 21.2656 2.34375 22.4375 3.51562C23.6562 4.73438 24.0781 6.42188 23.75 7.96875C25.0625 8.8125 25.9531 10.3125 25.9531 12ZM18.5 10.1719C18.9688 9.75 18.9688 9.04688 18.5 8.625C18.0781 8.15625 17.375 8.15625 16.9531 8.625L12.5 13.0781L11 11.625C10.5781 11.1562 9.875 11.1562 9.45312 11.625C8.98438 12.0469 8.98438 12.75 9.45312 13.1719L11.7031 15.4219C11.8906 15.6562 12.1719 15.75 12.5 15.75C12.7812 15.75 13.0625 15.6562 13.25 15.4219L18.5 10.1719Z"
                      fill="#C6C6C6"
                    />
                  </svg>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CompletedMissionSection;
