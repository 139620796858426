import { RouteObject } from "react-router-dom";
import { navPaths } from "../../constants/navbar.constants";
import Inventory from "./Pages/Inventory";
import InventoryDetail from "./Pages/InventoryDetail";
import Bird from "./Pages/Bird";
import FeedBird from "./Pages/FeedBird";
import BirdDetails from "./Pages/BirdDetails";

export const inventoryRoute: RouteObject = {
  path: navPaths.INVENTORY,
  Component: Inventory,
};

export const inventoryDetailRoute: RouteObject = {
  path: "",
  children: [
    { path: navPaths.INVENTORY_DETAIL, Component: InventoryDetail },
    { path: navPaths.BIRD, Component: Bird },
    { path: navPaths.FEED_BIRD, Component: FeedBird },
    { path: navPaths.BIRD_DETAIL, Component: BirdDetails },
  ],
};
