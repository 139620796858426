import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { Slide, toast, ToastContainer } from "react-toastify";
import { navPaths } from "../../../constants/navbar.constants";
import { useMemo, useRef, useState } from "react";
import usePurchaseHistory from "../Hooks/usePurchaseHistory";
import useOpenBox from "../Hooks/useOpenBox";
import { LoadingButton } from "@mui/lab";
import LoadingScreen from "../../Global/Pages/LoadingScreen";
import { usePurchaseStore } from "../../../store/purchaseStore";
import useBoxDetails from "../Hooks/useBoxDetail";
import { usePurchaseHistoryStore } from "../../../store/purchaseHistorySotre";

const OpenBox = () => {
  const navigate = useNavigate();
  const setReceivedGifts = usePurchaseStore(
    (state: any) => state.setPurchasedItem
  );
  const tele = window.Telegram.WebApp;

  tele.BackButton.show();
  tele.BackButton.onClick(() => handleBackBtn);

  const setHasUnopenedBox = usePurchaseHistoryStore(
    (state: any) => state.setHasUnopenBox
  );

  const { purchaseHistory, refeshPurchaseHistory, purchaseHistoryLoading } =
    usePurchaseHistory();
  const { openBoxAsync } = useOpenBox();
  const [isOpening, setIsOpening] = useState(false);

  const [receivedGift, setReceivedGift] = useState<any>();

  const boxRef = useRef<any>();

  const [unOpenedBoxes, isOpenMultiBox] = useMemo(() => {
    if (purchaseHistory?.length === 0) {
      return [];
    }

    const unOpenedBoxes = purchaseHistory?.filter(
      (item: any) => item.is_opened === false && item.transaction_id != null
    );

    const isOpenMultiBox =
      unOpenedBoxes?.[0]?.type === "worm" ||
      unOpenedBoxes?.[0]?.quantity > 1 ||
      unOpenedBoxes?.[0]?.id !== "iap_box__single__0";

    return [unOpenedBoxes, isOpenMultiBox];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseHistory, isOpening]);

  const { refeshBoxDetail } = useBoxDetails(unOpenedBoxes?.[0]?.id);

  const handleBackBtn = () => {
    if (unOpenedBoxes?.length === 0) {
      navigate(navPaths.PURCHASE);
      return;
    }
    navigate("/");
  };

  const handleOpenBox = () => {
    setIsOpening(true);
    openBoxAsync(unOpenedBoxes[0]?.id)
      .then((data) => {
        refeshBoxDetail();
        setReceivedGifts(unOpenedBoxes[0]);
        if (boxRef.current) {
          boxRef.current.style.animation = "shake 0.3s ease-in-out 0s infinite";
          setTimeout(() => {
            boxRef.current.style.animation =
              "box-scale-up-glow 1s linear forwards";
          }, 1500);
          setTimeout(() => {
            if (isOpenMultiBox) {
              refeshPurchaseHistory();
              setHasUnopenedBox(unOpenedBoxes?.length === 0);
              navigate(navPaths.OPEN_PURCHASED_MULTI_BOX);
              return;
            }
            setReceivedGift(data?.data?.data);
            refeshPurchaseHistory();
          }, 2500);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
        });
      })
      .finally(() => {
        handleWaitForAnimation();
      });
  };

  const handleWaitForAnimation = () => {
    setTimeout(() => {
      setIsOpening(false);
    }, 2500);
  };

  if (purchaseHistoryLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="pt-[48px] px-4 pb-[100px] bg-[#F2FFE0] dark:bg-transparent h-screen relative z-30">
      <ToastContainer
        position="top-left"
        closeOnClick
        transition={Slide}
        hideProgressBar
        limit={1}
        className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
      />
      {!receivedGift && (
        <p className="font-spicy-rice-regular text-[20px] text-[#4C7E0B] text-center mb-[54px]">
          {isOpenMultiBox ? `${unOpenedBoxes?.[0]?.name}` : "Egg Box"}
        </p>
      )}
      <div className="h-[calc(100%-30px)] flex items-center justify-center relative z-10">
        <div className="flex w-full justify-center items-center flex-col gap-[54px]">
          {receivedGift ? (
            <img
              src={`/images/egg/${receivedGift?.eggs?.[0]?.type}.png`}
              className={clsx("w-[220px] h-[255px]")}
              alt=""
            ></img>
          ) : (
            <img
              ref={boxRef}
              src={
                unOpenedBoxes?.[0]?.package_id === "iap_box__multi__0" ||
                unOpenedBoxes?.[0]?.package_id === "iap_box__multi__0"
                  ? "/images/purchase/box.png"
                  : `/images/purchase/${unOpenedBoxes?.[0]?.package_id}.png`
              }
              className={clsx("w-[246px] h-[246px]")}
              alt=""
            ></img>
          )}
          {receivedGift ? (
            <button
              onClick={() => {
                navigate(navPaths.INVENTORY, { state: { showEgg: true } });
              }}
              className={clsx(
                "font-bold text-white py-[18px] w-[50%] rounded-xl flex items-center justify-center gap-2",
                "btn-hover bg-gradient-to-r from-[#97C35B] to-[#61A700] drop-shadow-[0_4px_0px_#4C7E0B]"
              )}
            >
              Check Inventory
            </button>
          ) : (
            <LoadingButton
              loading={isOpening}
              onClick={() => {
                handleOpenBox();
              }}
              className={clsx(
                "font-bold normal-case text-white py-[18px] w-[50%] rounded-xl flex items-center justify-center gap-2",
                "btn-hover bg-gradient-to-r from-[#97C35B] to-[#61A700] drop-shadow-[0_4px_0px_#4C7E0B]"
              )}
            >
              Claim
            </LoadingButton>
          )}
        </div>
      </div>
      <div className="fixed bottom-0 right-0 left-0 z-0">
        <svg
          width="100%"
          height="155"
          viewBox="0 0 349 155"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.7276 108.222C21.872 107.469 22.8412 107.243 23.3033 107.855L31.5022 118.717C31.8028 119.116 32.2884 119.33 32.7851 119.283L46.5528 117.985C47.3203 117.913 47.807 118.788 47.3406 119.402L39.1581 130.169C38.823 130.61 38.7709 131.204 39.024 131.697L45.675 144.637C46.0395 145.346 45.3541 146.138 44.6002 145.879L30.7906 141.133C30.2749 140.956 29.7032 141.085 29.3144 141.468L19.074 151.543C18.4846 152.123 17.4925 151.65 17.5722 150.827L18.6479 139.721C18.7495 138.673 19.241 137.7 20.0253 136.997L28.0998 129.754C28.2813 129.591 28.1044 129.297 27.8755 129.382L18.3224 132.901C17.3171 133.271 16.2084 133.244 15.2226 132.824L5.82956 128.827C5.09309 128.513 5.11783 127.461 5.86822 127.183L18.2695 122.58C18.741 122.405 19.0869 121.996 19.1816 121.502L21.7276 108.222Z"
            fill="#7AB529"
            fillOpacity="0.16"
          />
          <path
            d="M326.789 107.284C326.645 106.531 325.675 106.305 325.213 106.917L317.102 117.664C316.801 118.062 316.316 118.276 315.819 118.229L302.198 116.945C301.43 116.873 300.943 117.748 301.41 118.362L309.504 129.013C309.839 129.454 309.892 130.049 309.638 130.541L303.059 143.343C302.694 144.052 303.38 144.843 304.134 144.584L317.795 139.889C318.311 139.712 318.883 139.842 319.271 140.224L329.4 150.19C329.99 150.77 330.982 150.297 330.902 149.474L329.84 138.502C329.738 137.453 329.246 136.481 328.462 135.778L320.477 128.615C320.296 128.452 320.473 128.159 320.702 128.243L330.147 131.722C331.152 132.093 332.261 132.065 333.247 131.646L342.525 127.697C343.262 127.384 343.237 126.331 342.487 126.053L330.22 121.5C329.748 121.325 329.403 120.917 329.308 120.423L326.789 107.284Z"
            fill="#7AB529"
            fillOpacity="0.16"
          />
          <path
            d="M17.2655 58.1831C17.41 57.4297 18.3791 57.2043 18.8413 57.8165L23.6952 64.2471C23.9958 64.6454 24.4814 64.8594 24.9782 64.8126L33.136 64.0436C33.9035 63.9713 34.3902 64.8466 33.9238 65.4604L29.1093 71.7957C28.7742 72.2366 28.7221 72.8308 28.9753 73.3234L32.8946 80.949C33.2591 81.658 32.5738 82.4497 31.8198 82.1906L23.6731 79.3909C23.1573 79.2136 22.5856 79.3433 22.1969 79.7258L16.2248 85.6018C15.6354 86.1817 14.6433 85.7085 14.723 84.8855L15.3017 78.911C15.4032 77.8624 15.8948 76.8902 16.679 76.1867L21.3168 72.0266C21.4983 71.8638 21.3214 71.57 21.0925 71.6543L15.6695 73.6521C14.6642 74.0224 13.5555 73.995 12.5697 73.5755L7.56748 71.4465C6.83101 71.1331 6.85575 70.0808 7.60614 69.8023L14.8477 67.1148C15.3193 66.9398 15.6651 66.5311 15.7598 66.0371L17.2655 58.1831Z"
            fill="#7AB529"
            fillOpacity="0.16"
          />
          <path
            d="M331.212 57.6871C331.068 56.9337 330.099 56.7082 329.637 57.3205L324.84 63.6745C324.54 64.0728 324.054 64.2868 323.557 64.24L315.496 63.4801C314.729 63.4078 314.242 64.2831 314.709 64.8969L319.465 71.1556C319.8 71.5966 319.852 72.1908 319.599 72.6834L315.727 80.2172C315.362 80.9263 316.048 81.7179 316.802 81.4588L324.851 78.6927C325.366 78.5154 325.938 78.6451 326.327 79.0276L332.225 84.8311C332.815 85.411 333.807 84.9378 333.727 84.1148L333.157 78.2289C333.055 77.1803 332.564 76.2081 331.78 75.5046L327.201 71.3978C327.02 71.2349 327.197 70.9411 327.425 71.0254L332.777 72.9969C333.782 73.3673 334.891 73.3399 335.877 72.9203L340.803 70.8237C341.54 70.5102 341.515 69.4579 340.765 69.1794L333.612 66.525C333.141 66.35 332.795 65.9413 332.7 65.4474L331.212 57.6871Z"
            fill="#7AB529"
            fillOpacity="0.16"
          />
          <path
            d="M77.1553 96.6441C77.0703 95.8817 77.9293 95.3795 78.5519 95.8276L84.4485 100.071C84.8536 100.362 85.3807 100.423 85.8414 100.231L92.6637 97.3911C93.3754 97.0949 94.0994 97.7869 93.8355 98.5112L91.3837 105.241C91.1941 105.762 91.3202 106.345 91.7078 106.74L97.1117 112.255C97.6697 112.824 97.2493 113.783 96.4524 113.759L88.6963 113.522C88.1512 113.505 87.6435 113.799 87.3854 114.279L83.8244 120.906C83.433 121.635 82.3454 121.476 82.1779 120.667L81.1114 115.509C80.8981 114.477 81.0798 113.403 81.6207 112.499L84.4811 107.718C84.6064 107.509 84.3504 107.281 84.1567 107.429L80.0667 110.56C79.216 111.212 78.1489 111.514 77.0831 111.405L72.3524 110.921C71.5562 110.84 71.2684 109.827 71.9027 109.339L77.4019 105.108C77.8005 104.801 78.01 104.308 77.9542 103.808L77.1553 96.6441Z"
            fill="#7AB529"
            fillOpacity="0.16"
          />
          <path
            d="M271.847 95.8081C271.932 95.0457 271.073 94.5435 270.45 94.9916L264.625 99.1827C264.22 99.4741 263.693 99.5348 263.233 99.3431L256.494 96.5378C255.782 96.2415 255.058 96.9336 255.322 97.6579L257.743 104.304C257.933 104.825 257.807 105.408 257.419 105.803L252.082 111.25C251.524 111.819 251.944 112.778 252.741 112.754L260.402 112.52C260.947 112.503 261.454 112.796 261.713 113.276L265.228 119.82C265.62 120.548 266.707 120.39 266.875 119.58L267.924 114.503C268.138 113.471 267.956 112.397 267.415 111.493L264.593 106.776C264.467 106.566 264.723 106.338 264.917 106.486L268.951 109.575C269.802 110.226 270.869 110.528 271.935 110.419L276.589 109.943C277.386 109.862 277.673 108.849 277.039 108.361L271.61 104.184C271.211 103.877 271.002 103.384 271.057 102.884L271.847 95.8081Z"
            fill="#7AB529"
            fillOpacity="0.16"
          />
          <path
            d="M105.501 33.6055C105.416 32.8431 106.275 32.3409 106.898 32.7889L109.095 34.3705C109.5 34.6619 110.028 34.7226 110.488 34.5309L113.041 33.4682C113.753 33.1719 114.477 33.864 114.213 34.5883L113.325 37.0256C113.135 37.546 113.261 38.129 113.649 38.5246L115.62 40.5364C116.178 41.1059 115.758 42.065 114.961 42.0406L112.117 41.9537C111.572 41.937 111.064 42.2301 110.806 42.7105L109.571 45.0088C109.18 45.7372 108.092 45.5789 107.925 44.7691L107.715 43.755C107.502 42.7233 107.684 41.6491 108.225 40.7451L109.14 39.2159C109.265 39.0065 109.009 38.7783 108.815 38.9266L107.593 39.862C106.743 40.5132 105.676 40.8152 104.61 40.7062L103.775 40.6209C102.979 40.5395 102.691 39.527 103.326 39.0389L105.245 37.5621C105.644 37.2553 105.853 36.7626 105.797 36.2628L105.501 33.6055Z"
            fill="#7AB529"
            fillOpacity="0.16"
          />
          <path
            d="M243.752 33.3275C243.837 32.5651 242.978 32.0629 242.355 32.511L240.197 34.0642C239.792 34.3556 239.264 34.4163 238.804 34.2245L236.296 33.1808C235.585 32.8846 234.861 33.5766 235.125 34.3009L235.996 36.6925C236.185 37.2128 236.059 37.7959 235.672 38.1914L233.737 40.1659C233.179 40.7353 233.599 41.6944 234.396 41.67L237.188 41.5847C237.733 41.5681 238.241 41.8612 238.499 42.3416L239.709 44.5937C240.1 45.3221 241.188 45.1637 241.355 44.354L241.556 43.3841C241.769 42.3524 241.587 41.2782 241.046 40.3741L240.152 38.8796C240.027 38.6703 240.283 38.442 240.477 38.5903L241.668 39.5023C242.519 40.1536 243.586 40.4556 244.651 40.3466L245.444 40.2655C246.241 40.1841 246.528 39.1716 245.894 38.6835L244.013 37.236C243.614 36.9293 243.405 36.4366 243.461 35.9367L243.752 33.3275Z"
            fill="#7AB529"
            fillOpacity="0.16"
          />
          <path
            d="M59.8458 53.0724C59.7608 52.31 60.6198 51.8078 61.2425 52.2559L65.6353 55.4168C66.0403 55.7083 66.5675 55.769 67.0281 55.5772L72.1147 53.4599C72.8264 53.1636 73.5503 53.8556 73.2864 54.58L71.4705 59.5647C71.2809 60.0851 71.4069 60.6681 71.7946 61.0637L75.8029 65.1543C76.3609 65.7237 75.9406 66.6828 75.1437 66.6584L69.3846 66.4824C68.8395 66.4658 68.3319 66.7589 68.0737 67.2393L65.4584 72.1067C65.0671 72.835 63.9794 72.6767 63.812 71.867L63.0938 68.394C62.8805 67.3623 63.0622 66.2881 63.6031 65.384L65.6726 61.9252C65.7978 61.7159 65.5418 61.4876 65.3482 61.6359L62.4243 63.8745C61.5736 64.5258 60.5065 64.8278 59.4407 64.7188L56.2941 64.397C55.4978 64.3156 55.21 63.3031 55.8444 62.815L59.8881 59.7035C60.2867 59.3968 60.4962 58.9041 60.4404 58.4042L59.8458 53.0724Z"
            fill="#7AB529"
            fillOpacity="0.16"
          />
          <path
            d="M289.002 52.6235C289.087 51.8611 288.228 51.3589 287.605 51.8069L283.271 54.9255C282.866 55.217 282.339 55.2777 281.878 55.0859L276.86 52.9969C276.148 52.7006 275.424 53.3926 275.688 54.117L277.479 59.0334C277.669 59.5538 277.542 60.1368 277.155 60.5324L273.201 64.5672C272.643 65.1367 273.063 66.0957 273.86 66.0714L279.541 65.8978C280.086 65.8811 280.594 66.1742 280.852 66.6546L283.43 71.4531C283.822 72.1815 284.909 72.0231 285.077 71.2134L285.781 67.8063C285.995 66.7746 285.813 65.7005 285.272 64.7964L283.234 61.3893C283.108 61.18 283.364 60.9517 283.558 61.1L286.436 63.3037C287.287 63.9549 288.354 64.2569 289.42 64.1479L292.504 63.8325C293.301 63.7511 293.589 62.7386 292.954 62.2505L288.967 59.1828C288.569 58.8761 288.359 58.3834 288.415 57.8835L289.002 52.6235Z"
            fill="#7AB529"
            fillOpacity="0.16"
          />
          <path
            d="M59.8666 12.7745C59.1042 12.8595 58.602 12.0006 59.0501 11.3779L60.3456 9.57752C60.637 9.1725 60.6977 8.64536 60.506 8.1847L59.6343 6.09068C59.338 5.37896 60.0301 4.65501 60.7544 4.9189L62.7304 5.63877C63.2508 5.82835 63.8338 5.70229 64.2294 5.31467L65.8647 3.71221C66.4342 3.15421 67.3933 3.57453 67.3689 4.37144L67.2981 6.68743C67.2815 7.23256 67.5746 7.74022 68.055 7.99835L69.888 8.98324C70.6164 9.37461 70.458 10.4623 69.6483 10.6297L69.0795 10.7473C68.0478 10.9607 66.9736 10.7789 66.0695 10.238L64.8898 9.53217C64.6805 9.40694 64.4522 9.6629 64.6005 9.85658L65.2999 10.7701C65.9512 11.6207 66.2532 12.6878 66.1442 13.7536L66.1017 14.1694C66.0202 14.9656 65.0078 15.2534 64.5197 14.6191L63.3389 13.0845C63.0321 12.6859 62.5394 12.4764 62.0396 12.5322L59.8666 12.7745Z"
            fill="#7AB529"
            fillOpacity="0.16"
          />
          <path
            d="M288.964 12.6595C289.726 12.7446 290.229 11.8856 289.781 11.2629L288.511 9.49851C288.22 9.09349 288.159 8.56635 288.351 8.10569L289.205 6.05317C289.501 5.34145 288.809 4.6175 288.085 4.88139L286.151 5.58606C285.63 5.77564 285.047 5.64958 284.652 5.26196L283.05 3.69287C282.481 3.13486 281.522 3.55519 281.546 4.35209L281.616 6.62033C281.632 7.16546 281.339 7.67312 280.859 7.93126L279.068 8.89354C278.339 9.2849 278.498 10.3725 279.307 10.54L279.836 10.6493C280.868 10.8626 281.942 10.6809 282.846 10.14L283.994 9.45305C284.203 9.32781 284.432 9.58377 284.283 9.77745L283.605 10.6631C282.954 11.5137 282.652 12.5808 282.761 13.6466L282.8 14.0245C282.881 14.8207 283.894 15.1086 284.382 14.4742L285.536 12.9744C285.842 12.5758 286.335 12.3664 286.835 12.4221L288.964 12.6595Z"
            fill="#7AB529"
            fillOpacity="0.16"
          />
          <path
            d="M11.5717 23.338C10.8093 23.423 10.3071 22.564 10.7552 21.9414L12.0507 20.141C12.3421 19.736 12.4028 19.2088 12.2111 18.7482L11.3394 16.6542C11.0431 15.9424 11.7352 15.2185 12.4595 15.4824L14.4355 16.2022C14.9559 16.3918 15.5389 16.2658 15.9345 15.8781L17.5698 14.2757C18.1393 13.7177 19.0983 14.138 19.074 14.9349L19.0032 17.2509C18.9866 17.796 19.2797 18.3037 19.7601 18.5618L21.5931 19.5467C22.3215 19.9381 22.1631 21.0257 21.3534 21.1932L20.7846 21.3108C19.7529 21.5241 18.6787 21.3424 17.7746 20.8015L16.5949 20.0956C16.3856 19.9704 16.1573 20.2264 16.3056 20.4201L17.005 21.3335C17.6563 22.1842 17.9582 23.2513 17.8493 24.3171L17.8067 24.7329C17.7253 25.5291 16.7129 25.8169 16.2247 25.1826L15.0439 23.648C14.7372 23.2494 14.2445 23.0399 13.7446 23.0957L11.5717 23.338Z"
            fill="#7AB529"
            fillOpacity="0.16"
          />
          <path
            d="M336.835 23.1312C337.598 23.2162 338.1 22.3573 337.652 21.7346L336.382 19.9702C336.091 19.5652 336.03 19.038 336.222 18.5774L337.076 16.5249C337.372 15.8131 336.68 15.0892 335.956 15.3531L334.022 16.0577C333.501 16.2473 332.918 16.1213 332.523 15.7336L330.921 14.1645C330.352 13.6065 329.393 14.0269 329.417 14.8238L329.487 17.092C329.503 17.6371 329.21 18.1448 328.73 18.4029L326.939 19.3652C326.21 19.7566 326.369 20.8442 327.179 21.0117L327.707 21.121C328.739 21.3343 329.813 21.1525 330.717 20.6116L331.865 19.9247C332.074 19.7995 332.303 20.0555 332.154 20.2491L331.476 21.1347C330.825 21.9854 330.523 23.0525 330.632 24.1183L330.671 24.4962C330.752 25.2924 331.765 25.5802 332.253 24.9459L333.407 23.4461C333.713 23.0475 334.206 22.838 334.706 22.8938L336.835 23.1312Z"
            fill="#7AB529"
            fillOpacity="0.16"
          />
        </svg>
      </div>
    </div>
  );
};

export default OpenBox;
