import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useState } from "react";

// const IconSort = () => {
//     return (
//         <svg
//             width="15"
//             height="16"
//             viewBox="0 0 15 16"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg"
//         >
//             <path
//                 d="M0.5 1.25C0.541667 0.791667 0.791667 0.541667 1.25 0.5H13.75C14.2083 0.541667 14.4583 0.791667 14.5 1.25C14.4583 1.70833 14.2083 1.95833 13.75 2H1.25C0.791667 1.95833 0.541667 1.70833 0.5 1.25ZM2.5 6.25C2.54167 5.79167 2.79167 5.54167 3.25 5.5H11.75C12.2083 5.54167 12.4583 5.79167 12.5 6.25C12.4583 6.70833 12.2083 6.95833 11.75 7H3.25C2.79167 6.95833 2.54167 6.70833 2.5 6.25ZM8.75 12H6.25C5.79167 11.9583 5.54167 11.7083 5.5 11.25C5.54167 10.7917 5.79167 10.5417 6.25 10.5H8.75C9.20833 10.5417 9.45833 10.7917 9.5 11.25C9.45833 11.7083 9.20833 11.9583 8.75 12Z"
//                 fill="black"
//             />
//         </svg>
//     );
// };

const DistributeRateSelector = ({
  changeRate,
  isDisabled,
  DistributeRate
}: {
  changeRate: any;
  isDisabled: boolean;
  DistributeRate?: string;
}) => {
  const [value, setValue] = useState(DistributeRate ?? "1");

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
    changeRate(event.target.value);
  };
  return (
    <FormControl fullWidth>
      <Select
        disabled={isDisabled}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={handleChange}
        sx={{
          border: "2px solid #A4BC82",
          borderRadius: "8px",
          "&:hover": {
            "&& fieldset": {
              border: "none",
            },
          },

          boxShadow: "none",
          "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            border: "none",
          },
        }}
        className="w-full h-[45px] bg-white text-[14px] font-semibold"
      >
        {[...Array(5)].map((_, index) => {
          const rate = index + 1;
          return (
            <MenuItem
              key={index}
              className="font-semibold text-sm"
              value={rate}
            >{`${rate}%`}</MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default DistributeRateSelector;
