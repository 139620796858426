import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerResponse } from "../../../types/api";

export const getHistoryMerge = async () => {
  const response = await fetchApi("GET", `/history-egg-piece-merge`);
  if (response.error) {
    throw new Error("network-error");
  }

  const historyMergeEggResponse: ServerResponse<any> =
    await response.data.json();
  if (historyMergeEggResponse.error) {
    return {
      error: historyMergeEggResponse.error,
    };
  }

  return historyMergeEggResponse.data;
};

export default function useHistoryMergeEgg() {
  const { data, isPending, refetch } = useQuery({
    queryKey: ["HistoryMergeEgg"],
    queryFn: () => getHistoryMerge(),
  });

  return {
    HistoryMergeEgg: data,
    HistoryMergeEggLoading: isPending,
    refeshHistoryMergeEgg: refetch,
  };
}
