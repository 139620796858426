import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerResponse } from "../../../types/api";

export const getAllTickets = async () => {
  const response = await fetchApi("GET", "/spin-ticket");
  if (response.error) {
    throw new Error("network-error");
  }

  const ticketsResponse: ServerResponse<any> = await response.data.json();
  if (ticketsResponse.error) {
    return {
      error: ticketsResponse.error,
    };
  }

  return ticketsResponse.data;
};

export default function useAlltickets() {
  const { data, isPending, refetch } = useQuery({
    queryKey: ["Alltickets"],
    queryFn: () => getAllTickets(),
  });

  return {
    Alltickets: data,
    AllticketsLoading: isPending,
    refeshAlltickets: refetch,
  };
}
