import { useMutation } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { Result } from "../../../types/utils";

export const upgradeSpeed = async (): Promise<Result<boolean, string>> => {
  const response = await fetchApi("POST", `/seed/mining-speed/upgrade`);
  if (response.error) {
    return {
      error: response.error.message,
    };
  }

  const updagradeSpeedResponse: any = await response.data.json();
  if (updagradeSpeedResponse.error) {
    return {
      error: updagradeSpeedResponse.error.message,
    };
  }

  if(updagradeSpeedResponse?.message){
    return {
      error: updagradeSpeedResponse?.message
    }
  }

  return {
    data: true,
  };
};

export default function useUpgradeSpeed() {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["upgradeSpeed"],
    mutationFn: upgradeSpeed,
  });
  return { upgradeSpeedAsync: mutateAsync, isUpgradingSpeed: isPending };
}
