import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerResponse } from "../../../types/api";

export const getAllEggPieces = async () => {
  const response = await fetchApi("GET", "/egg-piece");
  if (response.error) {
    throw new Error("network-error");
  }

  const eggPiecesResponse: ServerResponse<any> = await response.data.json();
  if (eggPiecesResponse.error) {
    return {
      error: eggPiecesResponse.error,
    };
  }

  return eggPiecesResponse.data;
};

export default function useEggPieces() {
  const { data, isPending, refetch } = useQuery({
    queryKey: ["EggPieces"],
    queryFn: () => getAllEggPieces(),
  });

  return {
    EggPieces: data,
    EggPiecesLoading: isPending,
    refeshEggPieces: refetch,
  };
}
