import { useMutation } from "@tanstack/react-query";
import { api } from "../../../config/api";

export async function claimGift(id: string) {
  return await api.post(`/api/v1/bird-hunt/complete`, {
    bird_id: id,
  });
}

export default function useClaimGift() {
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["claimGift"],
    mutationFn: claimGift,
  });

  return { claimGiftAsync: mutateAsync, isClaimingGift: isPending };
}
