import { Navigate, Outlet, useLocation } from "react-router-dom";
import { navPaths } from "../../constants/navbar.constants";
import { useAccount } from "../Hooks/useRegister";
import { Suspense } from "react";
import { ResponseCode } from "../../constants/response";
import LoadingScreen from "../../modules/Global/Pages/LoadingScreen";
import { useAccountBalance } from "../../modules/Home/Hooks/useGetAcountBalance";
import { useCheckLoadImg } from "../../store/imgStore";

const AuthLayout = () => {
  const location = useLocation();
  const tele = window.Telegram.WebApp;
  try {
    tele.setHeaderColor("#F7FFEB");
  } catch (err) {
    console.debug(err);
  }
  tele.expand();

  const isLoadedImg = useCheckLoadImg((state: any) => state.isLoaded);
  const overflow = 100;
  document.body.style.overflowY = "hidden";
  document.body.style.marginTop = `${overflow}px`;
  document.body.style.height = window.innerHeight + overflow + "px";
  document.body.style.paddingBottom = `${overflow}px`;
  window.scrollTo(0, overflow);

  const { account, isLoading } = useAccount(isLoadedImg);
  const { isLoading: isBalanceLoading } = useAccountBalance(isLoadedImg);

  if (isLoading || isBalanceLoading) {
    return <LoadingScreen />;
  }

  const statusCode = account?.status ?? 0;

  // navigate to reload page if has error
  if (
    (statusCode === ResponseCode.SERVER_ERR ||
      statusCode === ResponseCode.SERVER_UNKNOWN ||
      statusCode === ResponseCode.UNAUTHORIZED) &&
    location.pathname !== navPaths.RELOAD
  )
    return <Navigate to={navPaths.RELOAD} />;

  // navigate to maintenance when fix maintain server
  if (
    statusCode === ResponseCode.SERVER_MAINTAIN &&
    location.pathname !== navPaths.MAINTENANCE
  )
    return <Navigate to={navPaths.MAINTENANCE} />;

  // navigate to register page if dont have acount
  if (
    !account?.data &&
    location.pathname !== navPaths.REGISTER &&
    statusCode === ResponseCode.NOT_FOUND
  )
    return <Navigate to={navPaths.REGISTER} />;

  // navigate to home page if user has acount
  if (
    account?.data &&
    (location.pathname === navPaths.REGISTER ||
      location.pathname === navPaths.MAINTENANCE ||
      location.pathname === navPaths.RELOAD)
  )
    return <Navigate to="/" />;

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Outlet />
    </Suspense>
  );
};

export default AuthLayout;
