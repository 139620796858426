import { useState } from "react";
import { bootOptions, bootTypeEnum } from "../../../constants/boots.constants";
import { Slide, ToastContainer, toast } from "react-toastify";
import BootsModal from "../Components/BootsModal";
import { useNavigate } from "react-router-dom";
import useUpgradeStorage from "../Hooks/useUpgradeStoarage";
import useUpgradeSpeed from "../Hooks/useUpgradeSpeed";
import clsx from "clsx";
import { useAccountBalance } from "../../Home/Hooks/useGetAcountBalance";
import {
  formatDecimals,
  formatNumberFloatFix,
} from "../../../utils/formatNumber";
import { useAccount } from "../../../components/Hooks/useRegister";
import {
  getSpeedUpgradesLevel,
  getStorageUpgradesLevel,
  getWaterUpgradesLevel,
} from "../../../utils/minedSeed";
import HolyWaterModal from "../Components/HolyWaterModal";
import useGetWaterMissions from "../Hooks/useGetHolyTask";
import WaterMissionsModal from "../Components/WaterMissionModal";
import useUpgradeWater from "../Hooks/useUpgradeHolyWater";
// import { navPaths } from "../../../constants/navbar.constants";
// import useGetMissionsStatus from "../../Missions/Hooks/useGetMissionStatus";
import DailyMissonModal from "../../Missions/Components/DailyMissonModal";
import {
  useCheckDailyMission,
  useDailyMissions,
} from "../../Missions/Hooks/useGetDaily";
import { useCheckMission } from "../Hooks/useDoWaterMission";
import { useBoostStore } from "../../../store/boostStore";

const Boots = () => {
  const navigate = useNavigate();
  const tele = window.Telegram.WebApp;

  const boostSpeedLevel = useBoostStore((state: any) => state.boostSpeedLevel);
  const boostStorageLevel = useBoostStore(
    (state: any) => state.boostStorageLevel
  );
  const boostWaterLevel = useBoostStore((state: any) => state.boostWaterLevel);

  const { upgradingStorage, isUpgradingStorage } = useUpgradeStorage();
  const { upgradeSpeedAsync, isUpgradingSpeed } = useUpgradeSpeed();
  const { balance, refetch: refreshBalance } = useAccountBalance();
  const { account, refetch: refreshAccount } = useAccount();
  const { holyWaterMissions, refreshWaterMissions } = useGetWaterMissions();
  const { isLoading } = useCheckMission();
  const { updagradeWaterAsync, isUpgradingWater } = useUpgradeWater();
  const { dailyMissions } = useDailyMissions();
  const { checkDailyMission, isPending: checkDailyLoading } =
    useCheckDailyMission();

  tele.BackButton.show();
  tele.BackButton.onClick(() => handleBackBtn());

  const [isOpen, setisOpen] = useState<any>({ isOpen: false, type: 0 });
  const [isOpenWater, setIsOpenWater] = useState<boolean>(false);
  const [isWaterMissionOpen, setIsWaterMissionOpen] = useState(false);
  // const [missionId, setMissionId] = useState("");
  const [isOpenDailyMission, setIsOpenDailyMission] = useState<boolean>(false);

  const isDesktop = window.innerHeight < 610 ? true : false;
  // const GetMissionStatus = useGetMissionsStatus(String(missionId));
  const handleUpgrade = () => {
    if (isOpen.type === bootTypeEnum.STORAGE) {
      upgradingStorage()
        .then((data) => {
          if (data?.error) {
            refreshAccount();
            refreshBalance();
            toast.success(data?.error, {
              style: { maxWidth: 337, height: 40, borderRadius: 8 },
              autoClose: 2000,
            });
            return;
          }

          refreshAccount();
          refreshBalance();
          toast.success("Upgraded Successfully", {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          });
          setisOpen({ isOpen: false, type: 0 });
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message, {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          });
          // setisOpen({ isOpen: false, type: 0 });
        });

      return;
    }
    if (isOpen.type === bootTypeEnum.SPEED) {
      upgradeSpeedAsync()
        .then((data) => {
          if (data?.error) {
            refreshAccount();
            refreshBalance();
            toast.success(data?.error, {
              style: { maxWidth: 337, height: 40, borderRadius: 8 },
              autoClose: 2000,
            });
            return;
          }

          refreshAccount();
          refreshBalance();
          toast.success("Upgraded Successfully", {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          });
          setisOpen({ isOpen: false, type: 0 });
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message, {
            style: { maxWidth: 337, height: 40, borderRadius: 8 },
            autoClose: 2000,
          });
          // setisOpen({ isOpen: false, type: 0 });
        });
    }
  };

  const handleBackBtn = () => {
    navigate("/");
  };

  const handleOpenModal = (type: number) => {
    if (
      type === bootTypeEnum.WATER &&
      getWaterUpgradesLevel(account?.data) + 1 <
        Object.keys(boostWaterLevel).length
    ) {
      setIsOpenWater(true);
      return;
    }

    if (
      type === bootTypeEnum.STORAGE &&
      getStorageUpgradesLevel(account?.data) + 1 <
        Object.keys(boostStorageLevel).length
    ) {
      setisOpen({ isOpen: true, type: type });
      return;
    }

    if (
      type === bootTypeEnum.SPEED &&
      getSpeedUpgradesLevel(account?.data) + 1 <
        Object.keys(boostSpeedLevel).length
    ) {
      setisOpen({ isOpen: true, type: type });
      return;
    }

    toast.error("You have reached the maximum level", {
      style: { maxWidth: 337, height: 40, borderRadius: 8 },
      autoClose: 2000,
    });
  };

  // const handleDoWaterMision = (item: any) => {
  //   if (item.type === "like and retweet") {
  //     tele.openLink(item.metadata.url);
  //     DoWaterMission.mutateAsync(item.id)
  //       .then(() => {
  //         toast.success("Mission completed", {
  //           style: { maxWidth: 337, height: 40, borderRadius: 8 },
  //           autoClose: 2000,
  //         });
  //         WaterMission.refetch();
  //       })
  //       .catch((err) => {
  //         toast.error(err?.response?.data?.message, {
  //           style: { maxWidth: 337, height: 40, borderRadius: 8 },
  //           autoClose: 2000,
  //         });
  //       });

  //     return;
  //   }
  //   if (item.type === "check-in") {
  //     DoWaterMission.mutateAsync(item.id)
  //       .then((data) => {
  //         const missisonId = data?.data?.data;
  //         setMissionId(missisonId);
  //       })
  //       .then(() => {
  //         if (GetMissionStatus.data?.data?.data?.repeats >= item.repeats) {
  //           toast.success("Mission completed", {
  //             style: { maxWidth: 337, height: 40, borderRadius: 8 },
  //             autoClose: 2000,
  //           });
  //         } else {
  //           toast.error(GetMissionStatus.data?.data?.data?.error, {
  //             style: { maxWidth: 337, height: 40, borderRadius: 8 },
  //             autoClose: 2000,
  //           });
  //         }
  //         WaterMission.refetch();
  //       })
  //       .catch((err) => {
  //         if (err.response?.data?.message === "incomplete task") {
  //           navigate(navPaths.MISSIONS, { state: { isOpenDailyModal: true } });
  //         }
  //       });
  //   }
  //   if (item.type === "refer") {
  //     DoWaterMission.mutateAsync(item.id)
  //       .then((data) => {
  //         const missisonId = data?.data?.data;
  //         setMissionId(missisonId);
  //       })
  //       .then(() => {
  //         if (GetMissionStatus.data?.data?.data?.repeats >= item.repeats) {
  //           toast.success("Mission completed", {
  //             style: { maxWidth: 337, height: 40, borderRadius: 8 },
  //             autoClose: 2000,
  //           });
  //         } else {
  //           toast.error(GetMissionStatus.data?.data?.data?.error, {
  //             style: { maxWidth: 337, height: 40, borderRadius: 8 },
  //             autoClose: 2000,
  //           });
  //         }
  //         WaterMission.refetch();
  //       })
  //       .catch((err) => {
  //         toast.error(err?.response?.data?.message, {
  //           style: { maxWidth: 337, height: 40, borderRadius: 8 },
  //           autoClose: 2000,
  //         });
  //       });
  //   }
  // };

  const handleOpenWaterMissionModal = () => {
    setIsWaterMissionOpen(true);
  };

  const handleUpgradeWater = () => {
    updagradeWaterAsync()
      .then(() => {
        refreshAccount();
        refreshBalance();
        toast.success("Upgraded Successfully", {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
        });
        setisOpen({ isOpen: false, type: 0 });
        setIsOpenWater(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          style: { maxWidth: 337, height: 40, borderRadius: 8 },
          autoClose: 2000,
        });
        // setisOpen({ isOpen: false, type: 0 });
      });
  };

  const handleDoDailyMission = async () => {
    const reward = await checkDailyMission();
    if (reward.error) {
      toast.error(reward.error.message, {
        style: { maxWidth: 337, height: 40, borderRadius: 8 },
        autoClose: 2000,
      });
      return;
    }

    toast.success("Mission completed", {
      style: { maxWidth: 337, height: 40, borderRadius: 8 },
      autoClose: 2000,
    });
  };

  return (
    <div
      className={clsx(
        "overflow-auto pt-5 px-4  relative z-40 h-screen bg-[#fff]"
      )}
    >
      <ToastContainer
        position="top-left"
        closeOnClick
        transition={Slide}
        hideProgressBar
        limit={1}
        stacked
        className="top-3 max-w-[337px] left-[50%] -translate-x-[50%]"
      />
      {/* boot info */}
      <div className="flex flex-col items-center ">
        <div className="flex items-center gap-1">
          <img
            className="size-[34px]"
            src="/images/icons/token_icon.png?v=3"
            alt="token"
          ></img>
          <p className=" text-[32px] font-semibold">
            {formatNumberFloatFix(Number(formatDecimals(balance)) ?? 0, 2)}
          </p>
        </div>
        <div>
          <p className="text-[12px] text-center poppins-regular">
            Tip 2: This metric shows us how engaged you are.
          </p>
        </div>
      </div>
      <div className="pt-6 font-semibold  flex items-center justify-start gap-1">
        <div>
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.87725 0.982442C7.07762 0.339185 7.92238 0.339187 8.12275 0.982441L9.10026 4.12059C9.3167 4.81545 9.94621 5.30564 10.6778 5.30564H13.841C14.1468 5.30564 14.3744 5.50101 14.4638 5.78808C14.5536 6.07647 14.4808 6.39146 14.219 6.58991L11.6598 8.5294C11.0812 8.9679 10.8487 9.73374 11.0642 10.4256L12.0417 13.5638C12.1472 13.9025 12.0177 14.1982 11.7969 14.3656C11.5792 14.5306 11.2953 14.5583 11.0411 14.3656L8.48192 12.4261C7.89791 11.9835 7.1021 11.9835 6.51808 12.4261L3.95893 14.3656C3.70472 14.5583 3.42083 14.5306 3.20311 14.3656C2.98227 14.1982 2.85277 13.9025 2.95827 13.5638L3.93578 10.4256C4.15129 9.73374 3.9188 8.9679 3.34019 8.5294L0.781044 6.58991C0.519179 6.39146 0.446377 6.07647 0.536209 5.78808C0.625629 5.50101 0.853247 5.30564 1.15896 5.30564H4.32224C5.05379 5.30564 5.6833 4.81545 5.89974 4.12059L6.87725 0.982442Z"
              stroke="black"
            />
          </svg>
        </div>
        <p className="text-[18px] poppins-semibold">Boost Item</p>
      </div>
      {/* options */}
      <div className={clsx(isDesktop ? "mt-2" : "mt-3")}>
        {account?.data &&
          bootOptions.map((item, index) => {
            let price = 0.2;
            let level = 0;
            let icon = "";
            const storagePrice =
              boostStorageLevel[getStorageUpgradesLevel(account?.data) + 1]
                ?.price;
            const speedPrice =
              boostSpeedLevel[getSpeedUpgradesLevel(account?.data) + 1]?.price;

            const storageMaxLevel = Object.keys(boostStorageLevel).length;
            const speedMaxLevel = Object.keys(boostSpeedLevel).length;

            if (index === 0) {
              price = storagePrice;
              level = getStorageUpgradesLevel(account?.data);
              icon = `/images/storage/${
                getStorageUpgradesLevel(account?.data) + 1
              }.png?v=3`;
            } else if (index === 1) {
              price = speedPrice;
              level = getSpeedUpgradesLevel(account?.data);
              icon = `/images/trees/${
                getSpeedUpgradesLevel(account?.data) + 1
              }.png?v=3`;
            } else {
              price = 0.2;
              level = getWaterUpgradesLevel(account?.data);
              icon = `/images/holy/${
                getWaterUpgradesLevel(account?.data) + 1
              }.png?v=3`;
            }

            return (
              <div
                onClick={() => handleOpenModal(index)}
                key={index}
                className={clsx(
                  " z-10 relative cursor-pointer grid grid-cols-7 gap-0 bg-white rounded-xl p-4 w-full mb-[12px] ",
                  "border-[1px] border-[#F3F3F3] border-solid"
                )}
              >
                <div className="col-span-2 flex items-center">
                  <div className="">
                    <img
                      className="object-contain w-[73px] h-[67px]"
                      src={icon}
                      width={73}
                      height={67}
                      alt="storage"
                    ></img>
                  </div>
                </div>
                <div className="col-span-5 ">
                  <div className="flex items-center gap-1 mb-1">
                    <p className="poppins-semibold text-sm ">{`${item.title}`}</p>
                    <p
                      className={clsx(
                        "text-[12px] poppins-regular",
                        "bg-[#E8E8E8] rounded-2xl px-1 py-[2px]"
                      )}
                    >{`Level ${level + 1}`}</p>
                  </div>
                  <div className="mb-1">
                    <p className="text-xs poppins-regular text-[#7D7D7D]">
                      {item.description}
                    </p>
                  </div>
                  <div>
                    {(level + 1 === storageMaxLevel &&
                      index === bootTypeEnum.STORAGE) ||
                    (level + 1 === speedMaxLevel &&
                      index === bootTypeEnum.SPEED) ? (
                      <div className="text-xs poppins-regular">
                        {index === bootTypeEnum.STORAGE
                          ? `Max level - Claim every 24h`
                          : "Max level - Mine 0.08 SEED/h"}
                      </div>
                    ) : (
                      <div className={clsx("flex items-center gap-1")}>
                        {index !== 2 && (
                          <img
                            className={clsx(index === 2 ? "w-[21px] h-5" : "")}
                            src={`/images/icons/token_icon.png?v=3`}
                            width={14}
                            height={14}
                            alt="token"
                          ></img>
                        )}
                        <p className="text-xs poppins-regular">
                          <span className="poppins-semibold">{`${
                            index === 2 ? "" : price
                          }`}</span>
                          {`${
                            index === 2 ? "Complete mission" : ""
                          } to upgrade`}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      {isOpen.isOpen && account?.data && (
        <BootsModal
          isLoading={isUpgradingStorage || isUpgradingSpeed}
          storageLevel={getStorageUpgradesLevel(account?.data) ?? 0}
          speedLevel={getSpeedUpgradesLevel(account?.data) ?? 0}
          type={isOpen.type}
          closeModal={() => setisOpen({ isOpen: false, type: 0 })}
          handleUpgrade={() => handleUpgrade()}
        />
      )}

      {isOpenWater && (
        <HolyWaterModal
          userData={holyWaterMissions?.data ?? []}
          isLoading={isUpgradingStorage || isUpgradingSpeed || isUpgradingWater}
          storageLevel={getWaterUpgradesLevel(account?.data) ?? 0}
          speedLevel={getWaterUpgradesLevel(account?.data) ?? 0}
          type={2}
          closeModal={() => setIsOpenWater(false)}
          handleUpgrade={() => handleUpgradeWater()}
          handleOpenMission={() => handleOpenWaterMissionModal()}
        />
      )}

      {isWaterMissionOpen && holyWaterMissions?.data && (
        <WaterMissionsModal
          reFetch={() => {
            refreshWaterMissions();
            refreshAccount();
            refreshBalance();
          }}
          isPending={isLoading}
          data={holyWaterMissions?.data ?? []}
          handleOpenDailyMission={() => {
            setIsOpenDailyMission(true);
          }}
          closeModal={() => {
            setIsWaterMissionOpen(false);
            setIsOpenWater(false);
          }}
          closeWaterMissionModal={() => {
            setIsWaterMissionOpen(false);
          }}
        />
      )}

      {isOpenDailyMission && dailyMissions && (
        <DailyMissonModal
          isLoading={checkDailyLoading}
          handleDoMission={() => handleDoDailyMission()}
          data={dailyMissions ?? []}
          closeModal={() => setIsOpenDailyMission(false)}
        />
      )}
    </div>
  );
};

export default Boots;
