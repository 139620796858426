import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../../config/api";
import { ServerError, ServerResponse } from "../../../types/api";
import { Result } from "../../../types/utils";
import { MyBox } from "../../../types/box";

export const getMyBox = async (): Promise<Result<MyBox, ServerError>> => {
  const response = await fetchApi("GET", "/beta-gratitude-mystery-box/my-box");
  if (response.error) {
    // Throw to retry
    throw new Error("network-error");
  }

  const myBoxResponse: ServerResponse<MyBox> = await response.data.json();
  if (myBoxResponse.error) {
    return {
      error: myBoxResponse.error,
    };
  }

  return {
    data: myBoxResponse.data,
  };
};

export default function useGetMyBox() {
  const { data, isPending, refetch } = useQuery({
    queryKey: ["MyBox"],
    queryFn: getMyBox,
    retry: 1,
  });

  return {
    myBox: data,
    isLoadingMyBox: isPending,
    refreshMyBox: refetch,
  };
}
