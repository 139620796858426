import { Button, Modal } from "@mui/material";
import clsx from "clsx";

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  wormLevel: number;
}

const ConfirmFeedBirdModal = ({
  isOpen,
  handleClose,
  wormLevel,
}: ModalProps) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div className="w-[277px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#F2FFE0] p-[25px] rounded-3xl">
        <div className="flex flex-col items-center justify-center">
          <img
            src={`/images/inventory/worm/${wormLevel}.png?v=5`}
            className="w-[143px] h-[58px]"
            alt=""
          ></img>
          <p className="my-6 text-center">
            Are you sure you want to feed this{" "}
            <span className="uppercase">{wormLevel}</span> worm to the bird?
          </p>
        </div>

        <div className="flex justify-center">
          <Button
            onClick={handleClose}
            className={clsx(
              "normal-case text-white py-3 px-8 w-full",
              "bg-gradient-to-r from-[#97C35B] to-[#61A700]     drop-shadow-[0_4px_0px_#4C7E0B]",
              "hover:drop-shadow-none"
            )}
          >
            Yep!
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmFeedBirdModal;
